import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Flex, greyPalette } from '@lama/design-system';
import type { MemoSection } from '@lama/contracts';
import { ApplicationContext } from '../../../ApplicationContext';
import { SideNavContainer } from '../../../../../shared/components/PageSideNav/SideNavContainer';
import { SideNavFilterButton } from '../../../../../shared/components/PageSideNav/SideNavFilterButton';
import { CreditMemoSideNavItem } from './CreditMemoSideNavItem';

export const CreditMemoSideNav: FC<{ sections: MemoSection[] }> = ({ sections }) => {
  const { opportunity } = useContext(ApplicationContext);
  const [filter, setFilter] = useState('all');

  const reviewedSections = useMemo(
    () => sections.filter((s) => !!opportunity.underwriting?.notes?.find((n) => n.relatedSection === s.id && n.type === 'reviewed')),
    [sections, opportunity.underwriting?.notes],
  );

  const pendingReviewSection = useMemo(() => sections.filter((s) => !reviewedSections.includes(s)), [sections, reviewedSections]);

  const filteredCreditMemoConfiguration = useMemo(
    () =>
      sections.filter((s) =>
        filter === 'pendingReview' ? pendingReviewSection.includes(s) : filter === 'reviewed' ? reviewedSections.includes(s) : true,
      ),
    [sections, filter, pendingReviewSection, reviewedSections],
  );

  const onFilterClick = useCallback(
    (filterValue: string) => {
      setFilter(filterValue);
    },
    [setFilter],
  );

  return (
    <SideNavContainer>
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        flexWrap={'wrap'}
        gap={2}
        px={2}
        py={3}
        borderBottom={`1px solid ${greyPalette[200]}`}
      >
        <SideNavFilterButton
          text={'All'}
          count={sections.length}
          isSelected={filter === 'all'}
          filterValue={'all'}
          onClick={onFilterClick}
        />
        <SideNavFilterButton
          text={'Pending'}
          count={pendingReviewSection.length}
          isSelected={filter === 'pendingReview'}
          filterValue={'pendingReview'}
          onClick={onFilterClick}
        />
        <SideNavFilterButton
          text={'Reviewed'}
          count={reviewedSections.length}
          isSelected={filter === 'reviewed'}
          filterValue={'reviewed'}
          onClick={onFilterClick}
        />
      </Flex>
      <Flex flexDirection={'column'} bg={'white'}>
        {filteredCreditMemoConfiguration.map((section) => (
          <CreditMemoSideNavItem key={section.id} section={section} />
        ))}
      </Flex>
    </SideNavContainer>
  );
};
