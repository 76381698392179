import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import * as yup from 'yup';
import { Formik, type FormikHelpers } from 'formik';
import { Flex, Grid, greyPalette, Text, Button } from '@lama/design-system';
import { FormikAutoSave, FormikDatePicker, PropertyFormikInput } from '@lama/app-components';
import { dateValidation, sbssValidation } from '@lama/yup-validations';
import { getSourcedProperty } from '@lama/properties';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OpenInNewOutlined } from '@mui/icons-material';
import { Link } from '@mui/material';
import { ConfirmLeaveWithUnsavedChanges } from '../../../../../../shared/components/ConfirmLeaveWithUnsavedChanges';
import { ApplicationContext } from '../../../../ApplicationContext';
import { useExportToEtranFlow } from '../../../../shared/ExportToEtran/useExportToEtranFlow';
import { EtranAuthenticationDialog } from '../../../../shared/ExportToEtran/EtranAuthenticationDialog';
import { useUpdateApplicationMutation } from '../../../../../../shared/hooks/react-query/application/useUpdateApplication';
import { EtranExportErrorsDialog } from '../../../../shared/ExportToEtran/EtranExportErrorsDialog';
import { EtranPayloadPreviewSegment } from '../../../../shared/ExportToEtran/EtranPreviewSegment';
import type { ClosingTaskComponentProps } from '../closingTaskComponentProps';
import { SaveFormButton } from '../../../../shared/SaveFormButton';

const sbssScorePropertyKey = 'application_sbss';
const sbssDatePropertyKey = 'application_sbssDate';
const sbssLoanAppPropertyKey = 'application_sbssLoanAppNumber';

const etranLinkForApplicationNumber = (applicationNumber: string) =>
  `https://catweb2.sba.gov/elend/applications/act_search.cfm?GoToAppID=${applicationNumber}&GoToAudtIdx=0&CameFromSearchPage=Y`;

const validationSchema = yup.object().shape({
  sbss: sbssValidation,
  sbssDate: dateValidation,
});

export const EtranPullTaskComponent: FC<ClosingTaskComponentProps> = () => {
  const {
    application,
    opportunity: { id: opportunityId },
    properties,
  } = useContext(ApplicationContext);
  const {
    dialogMode,
    onNextStepClick,
    onClose,
    hasCode,
    exportErrors,
    payload,
    showDialog,
    onDialogSuccess,
    clearErrors,
    exporting,
    payloadErrorStatus,
  } = useExportToEtranFlow();
  const { etranExportEnabled, autoSaveEnabled } = useFlags();
  const sbssScoreProperty = useMemo(() => properties[sbssScorePropertyKey], [properties]);
  const sbssDateProperty = useMemo(() => properties[sbssDatePropertyKey], [properties]);
  const sbssLoanAppProperty = useMemo(() => properties[sbssLoanAppPropertyKey], [properties]);
  const sourcedSbssScore = useMemo(() => {
    if (!sbssScoreProperty) {
      return;
    }
    return getSourcedProperty(sbssScoreProperty, application, 0);
  }, [sbssScoreProperty, application]);
  const sourcedSbssDate = useMemo(() => {
    if (!sbssDateProperty) {
      return;
    }
    return getSourcedProperty(sbssDateProperty, application, 0);
  }, [sbssDateProperty, application]);
  const sourcedSbssLoanApp = useMemo(() => {
    if (!sbssLoanAppProperty) {
      return;
    }
    return getSourcedProperty(sbssLoanAppProperty, application, 0);
  }, [sbssLoanAppProperty, application]);
  const { mutate: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(application.id, opportunityId);

  const onSubmit = useCallback(
    async (values: typeof initialValues, { resetForm }: FormikHelpers<any>) => {
      resetForm({ values });
      const { sbss, sbssDate, sbssLoanAppNum } = values;
      updateApplication({
        updateApplicationPayload: {
          sbss: sbss ? Number(sbss) : null,
          sbssDate: sbssDate ? new Date(sbssDate).toISOString() : undefined,
          loanApplicationNumber: sbssLoanAppNum,
        },
      });
    },
    [updateApplication],
  );
  const onErrorDialogClose = useCallback(() => {
    clearErrors();
  }, [clearErrors]);
  const initialValues = useMemo(
    () => ({
      sbss: (sourcedSbssScore?.selectedPropertyValue?.value as string | undefined) ?? '',
      sbssDate: (sourcedSbssDate?.selectedPropertyValue?.value as string | undefined) ?? '',
      sbssLoanAppNum: (sourcedSbssLoanApp?.selectedPropertyValue?.value as string | undefined) ?? '',
    }),
    [sourcedSbssDate, sourcedSbssScore, sourcedSbssLoanApp],
  );
  const fetchedFromEtran = useMemo(() => sourcedSbssScore?.selectedPropertyValue?.source.source === 'creditReport', [sourcedSbssScore]);
  const exportButtonEnabled = useMemo(
    () => !payloadErrorStatus.applicationHasErrors && !payloadErrorStatus.businessesHaveErrors && !payloadErrorStatus.peopleHaveErrors,
    [payloadErrorStatus],
  );
  return (
    <Flex flexDirection={'column'} gap={4}>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ dirty, isValid, handleSubmit }) => (
          <ConfirmLeaveWithUnsavedChanges dirty={dirty}>
            <Flex flexDirection={'column'} gap={4}>
              <Grid columns={3}>
                <PropertyFormikInput
                  name={'sbss'}
                  label={'SBSS Score'}
                  type={'number'}
                  sourcesValues={sourcedSbssScore?.propertyValues}
                  fullWidth
                />
                <FormikDatePicker sourcesValues={sourcedSbssDate?.propertyValues} name={'sbssDate'} label={'SBSS Pull Date'} fullWidth />
                {etranExportEnabled ? (
                  <PropertyFormikInput
                    name={'sbssLoanAppNum'}
                    label={'Loan Application Number'}
                    sourcesValues={sourcedSbssLoanApp?.propertyValues}
                    fullWidth
                    helperText={
                      initialValues.sbssLoanAppNum ? (
                        <Link
                          alignSelf={'flex-end'}
                          href={etranLinkForApplicationNumber(initialValues.sbssLoanAppNum)}
                          target={'_blank'}
                          width={'fit-content'}
                        >
                          {'Open on Etran'}
                          <OpenInNewOutlined sx={{ width: '12px', height: '12px', verticalAlign: 'middle', marginLeft: '3px' }} />
                        </Link>
                      ) : null
                    }
                  />
                ) : null}
              </Grid>
              {autoSaveEnabled ? (
                <FormikAutoSave />
              ) : (
                <SaveFormButton loading={updatingApplication} submit={handleSubmit} disabled={!dirty || !isValid} />
              )}
            </Flex>
          </ConfirmLeaveWithUnsavedChanges>
        )}
      </Formik>
      {etranExportEnabled ? (
        <>
          <Flex justifyContent={'space-between'}>
            <Flex flexDirection={'column'}>
              <EtranExportErrorsDialog
                open={exportErrors?.length > 0}
                onClose={onErrorDialogClose}
                errors={exportErrors}
                onSuccess={onErrorDialogClose}
              />
            </Flex>
          </Flex>
          <Flex height={'100%'} maxHeight={'100%'} justifyContent={'space-between'}>
            <Flex alignSelf={'flex-end'}>
              <Text variant={'body1'} color={greyPalette[500]}>
                {'Inquiry Details'}
              </Text>
            </Flex>
            <Button disabled={!exportButtonEnabled && fetchedFromEtran} loading={exporting} onClick={onNextStepClick}>
              {'Pull SBSS Score'}
            </Button>
          </Flex>
          <EtranPayloadPreviewSegment payload={payload} />
          <EtranAuthenticationDialog
            open={showDialog}
            mode={dialogMode}
            onlyCollectCredentials={hasCode}
            onClose={onClose}
            onSuccess={onDialogSuccess}
            buttonTitle={hasCode ? 'Submit' : 'Login'}
          />
        </>
      ) : null}
    </Flex>
  );
};
