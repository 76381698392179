import type { SegmentConfiguration } from '@lama/contracts';

// TODO: This is an interim state and these configurations will be persisted using keys in the future

export const underwritingSectionDisplayConfigurations: Record<string, SegmentConfiguration[]> = {
  southstateBorrowersDetails: [
    {
      description: 'General Information',
      entityType: 'application',
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'application_primaryContactNameSelector' },
            { propertyKey: 'application_primaryContactPhoneNumberSelector', displayType: 'phoneNumber' },
            { propertyKey: 'application_primaryContactEmailSelector', displayType: 'email' },
            { propertyKey: 'application_applyingForJointCredit' },
          ],
          title: 'General',
        },
      ],
    },
    {
      description: 'borrower individual details',
      entityType: 'person',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'people_ownershipPercentage', displayNameOverride: 'Ownership', displayType: 'percent' },
            { propertyKey: 'people_position' },
            { propertyKey: 'people_ficoScore', displayNameOverride: 'Soft Pull FICO Score' },
            { propertyKey: 'people_hardPullCreditScore', displayNameOverride: 'Hard Pull FICO Score' },
            { propertyKey: 'people_ssn' },
            { propertyKey: 'people_phoneNumber', displayType: 'phoneNumber' },
            { propertyKey: 'people_email', displayType: 'email' },
            { propertyKey: 'people_address' },
            { propertyKey: 'people_citizenship' },
            { propertyKey: 'people_placeOfBirth', displayNameOverride: 'Place of Birth' },
            { propertyKey: 'people_creditCheckConsent' },
          ],
          title: 'General Information',
        },
        {
          type: 'inlineNote',
          title: 'CBR Comment',
          noteName: 'CBR-Comment',
        },
      ],
    },
    {
      description: 'borrower business details',
      entityType: 'business',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_description',
          },
          title: 'Business Description',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'business_businessName' },
            { propertyKey: 'business_dba', displayNameOverride: 'DBA' },
            { propertyKey: 'business_tin', displayNameOverride: 'TIN' },
            { propertyKey: 'business_legalEntityType' },
            { propertyKey: 'business_industryTypes', displayNameOverride: 'Business Type' },
            { propertyKey: 'business_phoneNumber', displayNameOverride: 'Phone Number', displayType: 'phoneNumber' },
            { propertyKey: 'business_startDate', displayNameOverride: 'Formation Date' },
            { propertyKey: 'business_monthsInBusiness', displayType: 'monthsAsYearsAndMonths' },
            { propertyKey: 'business_addresses', displayNameOverride: 'Address' },
            { propertyKey: 'business_stateOfFormation' },
            { propertyKey: 'business_monthsBanked' },
            { propertyKey: 'business_totalAssets', displayNameOverride: 'Total Assets' },
            { propertyKey: 'business_totalLiabilities', displayNameOverride: 'Total Liabilities' },
            { propertyKey: 'business_nonSufficientFunds', displayNameOverride: 'Non-Sufficient Funds' },
            { propertyKey: 'business_slowPayments' },
          ],
          title: 'Business Details',
        },
        {
          type: 'genericForm',
          properties: [{ propertyKey: 'business_excludedFromFinancialCalculations', optional: true }],
        },
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_history',
          },
          title: 'Business History',
        },
        {
          type: 'inlineNote',
          title: 'Business History Details',
          noteName: 'Business History Details',
        },
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_competitive_advantage',
          },
          title: 'Competitive Advantage',
        },
        {
          type: 'inlineNote',
          title: 'Competitive Advantage Details',
          noteName: 'Competitive Advantage Details',
        },
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_management_experience',
          },
          title: 'Management Experience',
        },
        {
          type: 'inlineNote',
          title: 'Management Experience Details',
          noteName: 'Management Experience Details',
        },
        {
          type: 'inlineNote',
          title: 'Business Comments',
          noteName: 'Business Comments',
          backwardCompatibleNoteName: 'Business Comments',
        },
        {
          type: 'inlineNote',
          title: 'Business Location Comments',
          noteName: 'Business Location Comments',
          backwardCompatibleNoteName: 'Business Location Comments',
        },
      ],
    },
    {
      components: [{ type: 'inlineNote', title: 'Borrower Details', noteName: 'Borrower Details' }],
    },
  ],
  southstateGuarantorsDetails: [
    {
      description: 'guarantor individual details',
      entityType: 'person',
      entityGroups: ['guarantor'],
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'people_ownershipPercentage', displayNameOverride: 'Ownership', displayType: 'percent' },
            { propertyKey: 'people_position' },
            { propertyKey: 'people_ficoScore', displayNameOverride: 'Soft Pull FICO Score' },
            { propertyKey: 'people_hardPullCreditScore', displayNameOverride: 'Hard Pull FICO Score' },
            { propertyKey: 'people_ssn' },
            { propertyKey: 'people_phoneNumber', displayType: 'phoneNumber' },
            { propertyKey: 'people_email', displayType: 'email' },
            { propertyKey: 'people_address' },
            { propertyKey: 'people_citizenship' },
            { propertyKey: 'people_placeOfBirth', displayNameOverride: 'Place of Birth' },
            { propertyKey: 'people_creditCheckConsent' },
          ],
          title: 'General Information',
        },
        {
          type: 'inlineNote',
          title: 'CBR Comment',
          noteName: 'CBR-Comment',
        },
      ],
    },
    {
      description: 'guarantor business details',
      entityType: 'business',
      entityGroups: ['guarantor'],
      components: [
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_description',
          },
          title: 'Business Description',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'business_businessName' },
            { propertyKey: 'business_dba', displayNameOverride: 'DBA' },
            { propertyKey: 'business_tin', displayNameOverride: 'TIN' },
            { propertyKey: 'business_legalEntityType' },
            { propertyKey: 'business_industryTypes', displayNameOverride: 'Business Type' },
            { propertyKey: 'business_phoneNumber', displayNameOverride: 'Phone Number', displayType: 'phoneNumber' },
            { propertyKey: 'business_startDate', displayNameOverride: 'Formation Date' },
            { propertyKey: 'business_monthsInBusiness', displayType: 'monthsAsYearsAndMonths' },
            { propertyKey: 'business_addresses', displayNameOverride: 'Address' },
            { propertyKey: 'business_stateOfFormation' },
            { propertyKey: 'business_monthsBanked' },
            { propertyKey: 'business_totalAssets', displayNameOverride: 'Total Assets' },
            { propertyKey: 'business_totalLiabilities', displayNameOverride: 'Total Liabilities' },
            { propertyKey: 'business_nonSufficientFunds', displayNameOverride: 'Non-Sufficient Funds' },
            { propertyKey: 'business_slowPayments' },
          ],
          title: 'Business Details',
        },
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_history',
          },
          title: 'Business History',
        },
        {
          type: 'inlineNote',
          title: 'Business History Details',
          noteName: 'Business History Details',
        },
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_competitive_advantage',
          },
          title: 'Competitive Advantage',
        },
        {
          type: 'inlineNote',
          title: 'Competitive Advantage Details',
          noteName: 'Competitive Advantage Details',
        },
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_management_experience',
          },
          title: 'Management Experience',
        },
        {
          type: 'inlineNote',
          title: 'Management Experience Details',
          noteName: 'Management Experience Details',
        },
        {
          type: 'inlineNote',
          title: 'Business Comments',
          noteName: 'Business Comments',
          backwardCompatibleNoteName: 'Business Comments',
        },
        {
          type: 'inlineNote',
          title: 'Business Location Comments',
          noteName: 'Business Location Comments',
          backwardCompatibleNoteName: 'Business Location Comments',
        },
      ],
    },
    {
      components: [{ type: 'inlineNote', title: 'Guarantors Details', noteName: 'Guarantors Details' }],
    },
  ],
  sellersDetails: [
    {
      description: 'Seller Details',
      entityType: 'business',
      entityGroups: ['seller'],
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'business_businessName' },
            { propertyKey: 'business_dba', displayNameOverride: 'DBA' },
            { propertyKey: 'business_tin', displayNameOverride: 'TIN' },
            { propertyKey: 'business_legalEntityType' },
            { propertyKey: 'business_industryTypes', displayNameOverride: 'Business Type' },
            { propertyKey: 'business_phoneNumber', displayNameOverride: 'Phone Number', displayType: 'phoneNumber' },
            { propertyKey: 'business_startDate', displayNameOverride: 'Formation Date' },
            { propertyKey: 'business_monthsInBusiness', displayType: 'monthsAsYearsAndMonths' },
            { propertyKey: 'business_addresses', displayNameOverride: 'Address' },
            { propertyKey: 'business_stateOfFormation' },
            { propertyKey: 'business_monthsBanked' },
            { propertyKey: 'business_totalAssets', displayNameOverride: 'Total Assets' },
            { propertyKey: 'business_totalLiabilities', displayNameOverride: 'Total Liabilities' },
            { propertyKey: 'business_nonSufficientFunds', displayNameOverride: 'Non-Sufficient Funds' },
            { propertyKey: 'business_slowPayments' },
          ],
          title: 'Business Details',
        },
      ],
    },
    {
      components: [{ type: 'inlineNote', title: 'Seller Details', noteName: 'Seller Details' }],
    },
  ],
  scoreCard: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'scoreCard',
        },
      ],
    },
  ],
  operationalEfficiency: [
    {
      components: [{ type: 'custom', customComponentName: 'operationalEfficiency' }],
    },
  ],
  onlineActivity: [
    {
      components: [{ type: 'custom', customComponentName: 'onlineActivity' }],
    },
  ],
  localActivity: [
    {
      components: [{ type: 'custom', customComponentName: 'localActivity' }],
    },
  ],
  compliance: [
    {
      entityType: 'opportunity',
      components: [
        {
          type: 'genericForm',
          properties: [
            {
              propertyKey: 'opportunity_underwriting_compliance_totalCommercialExposure',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_craReportable',
              customComponent: 'yesNoQuestionPicker',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_craAnnualRevenue',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_craSourceDocument',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_craSourceDocumentDate',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_hmdaReportable',
              customComponent: 'yesNoQuestionPicker',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_minorityOrNonProfit',
              customComponent: 'yesNoQuestionPicker',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_fiuExposure',
            },
          ],
          title: 'Reporting',
        },
      ],
    },
    {
      components: [
        { type: 'inlineNote', title: 'Underwriter Comments', noteName: 'Underwriter Comments' },
        { type: 'inlineNote', title: 'Closing Conditions', noteName: 'Closing Conditions' },
        { type: 'inlineNote', title: 'Risk Rating', noteName: 'Risk Rating' },
        { type: 'inlineNote', title: 'Covenants', noteName: 'Covenants' },
      ],
    },
  ],
  eligibilities: [
    {
      components: [
        {
          type: 'inlineNote',
          title: 'General Requirements',
          noteName: 'General Requirements',
        },
        {
          type: 'inlineNote',
          title: 'Insurance/License/Permits Requirements',
          noteName: 'License Requirements',
        },
        {
          type: 'inlineNote',
          title: 'Other Requirements',
          noteName: 'Other Requirements',
        },
        {
          type: 'custom',
          customComponentName: 'creditElsewhere',
          params: {
            editable: true,
          },
        },
        {
          type: 'inlineNote',
          title: 'Credit Elsewhere - Comments',
          noteName: 'Credit Elsewhere',
        },
        {
          type: 'custom',
          customComponentName: 'sizeStandard',
        },
      ],
    },
  ],
  fraudShieldAlerts: [
    {
      entityType: 'person',
      entityGroups: ['guarantor', 'borrower'],
      components: [
        {
          type: 'custom',
          customComponentName: 'fraudShieldAlerts',
        },
        {
          type: 'genericForm',
          properties: [{ propertyKey: 'person_verifications_fraudShieldAlert' }],
        },
      ],
    },
    {
      components: [
        {
          type: 'inlineNote',
          title: 'Fraud Shield Alerts',
          noteName: 'Fraud Shield Alerts',
        },
      ],
    },
  ],
  idCard: [
    {
      entityType: 'person',
      entityGroups: ['guarantor', 'borrower'],
      components: [
        {
          type: 'genericForm',
          properties: [
            { propertyKey: 'people_identificationCard_type' },
            { propertyKey: 'people_identificationCard_number' },
            { propertyKey: 'people_identificationCard_issueDate' },
            { propertyKey: 'people_identificationCard_expirationDate' },
            { propertyKey: 'people_identificationCard_issuingAuthority' },
          ],
        },
      ],
    },
  ],
  businessRegistration: [
    {
      entityType: 'business',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'genericForm',
          properties: [
            { propertyKey: 'business_verifications_sosCurrentStatus' },
            { propertyKey: 'business_naicsCodes' },
            { propertyKey: 'business_verifications_mapSearch' },
          ],
        },
      ],
    },
    {
      components: [
        {
          type: 'inlineNote',
          title: 'Business Registration Details',
          noteName: 'Business Registration Details',
        },
      ],
    },
  ],
  existingRelationship: [
    {
      entityType: 'person',
      entityGroups: ['guarantor', 'borrower'],
      components: [
        {
          type: 'genericForm',
          properties: [
            { propertyKey: 'people_existingRelationship_currentCustomer', customComponent: 'yesNoQuestion', size: 'large' },
            {
              propertyKey: 'person_verifications_internalMatch',
              visibilityCondition: {
                fieldName: 'existingRelationship.currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'person_existingRelationship_monthsBanked',
              visibilityCondition: {
                fieldName: 'existingRelationship.currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'person_existingRelationship_slowPayments',
              visibilityCondition: {
                fieldName: 'existingRelationship.currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'person_existingRelationship_nonSufficientFunds',
              visibilityCondition: {
                fieldName: 'existingRelationship.currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'people_existingRelationship_totalDeposits',
              visibilityCondition: {
                fieldName: 'existingRelationship.currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'people_existingRelationship_totalLiabilities',
              visibilityCondition: {
                fieldName: 'existingRelationship.currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'people_existingRelationship_realEstateLiabilitiesWithBank',
              visibilityCondition: {
                fieldName: 'existingRelationship.currentCustomer',
                value: true,
              },
            },
          ],
        },
      ],
    },
    {
      entityType: 'business',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'genericForm',
          properties: [
            {
              propertyKey: 'business_currentCustomer',
              customComponent: 'yesNoQuestion',
              size: 'large',
            },
            {
              propertyKey: 'business_verifications_internalMatch',
              visibilityCondition: {
                fieldName: 'currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'business_totalAssets',
              visibilityCondition: {
                fieldName: 'currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'business_nonSufficientFunds',
              visibilityCondition: {
                fieldName: 'currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'business_monthsBanked',
              visibilityCondition: {
                fieldName: 'currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'business_slowPayments',
              visibilityCondition: {
                fieldName: 'currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'business_totalLiabilities',
              visibilityCondition: {
                fieldName: 'currentCustomer',
                value: true,
              },
            },
            {
              propertyKey: 'business_realEstateLiabilitiesWithBank',
              visibilityCondition: {
                fieldName: 'currentCustomer',
                value: true,
              },
            },
          ],
        },
      ],
    },
  ],
  childSupport: [
    {
      entityType: 'person',
      entityGroups: ['guarantor', 'borrower'],
      components: [
        {
          type: 'genericForm',
          properties: [{ propertyKey: 'person_verifications_childSupport' }],
        },
      ],
    },
  ],
  hmdaBorrowingIndividuals: [
    {
      entityType: 'person',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            {
              propertyKey: 'person_personAgeSelector',
              displayNameOverride: 'Age of Applicant or Borrower',
            },
            {
              propertyKey: 'Personal Cash Available to Service Debt',
              displayNameOverride: 'Income',
            },
            { propertyKey: 'people_ficoScore', displayNameOverride: 'Credit Score' },
            { propertyKey: 'person_creditScoreProviderSelector' },
          ],
        },
        {
          type: 'genericForm',
          properties: [
            { propertyKey: 'people_hmda_ethnicity', customComponent: 'hierarchicalMultiPicker' },
            { propertyKey: 'people_hmda_ethnicityCollectedFromVisualOrSurname' },
            {
              propertyKey: 'people_ethnicityOther',
              size: 'large',
              optional: true,
            },
            { propertyKey: 'people_hmda_races', customComponent: 'hierarchicalMultiPicker' },
            { propertyKey: 'people_hmda_raceCollectedFromVisualOrSurname' },
            {
              propertyKey: 'people_hmda_raceTribe',
              size: 'large',
              optional: true,
            },
            {
              propertyKey: 'people_hmda_raceOtherAsian',
              size: 'large',
              optional: true,
            },
            {
              propertyKey: 'people_hmda_raceOtherPacificIslander',
              size: 'large',
              optional: true,
            },
            { propertyKey: 'people_hmda_gender' },
            { propertyKey: 'people_hmda_sexCollectedFromVisualOrSurname' },
          ],
        },
      ],
    },
  ],
  hmdaPropertyDetails: [
    {
      entityType: 'business',
      entityGroups: ['property'],
      components: [
        {
          type: 'genericForm',
          properties: [
            {
              propertyKey: 'business_addresses',
              customComponent: 'propertyAddress',
              size: 'large',
              childProperties: [
                {
                  propertyKey: 'business_hmda_streetAddress',
                },
                {
                  propertyKey: 'business_hmda_city',
                },
                {
                  propertyKey: 'business_hmda_state',
                },
                {
                  propertyKey: 'business_hmda_zipCode',
                },
                {
                  propertyKey: 'business_hmda_county',
                },
                {
                  propertyKey: 'business_hmda_censusTract',
                },
              ],
            },
            { propertyKey: 'business_hmda_propertyValue' },
            { propertyKey: 'business_hmda_manufacturedHomeSecuredPropertyType' },
            { propertyKey: 'business_hmda_manufacturedHomeLandPropertyInterest' },
            { propertyKey: 'business_hmda_totalUnits' },
            { propertyKey: 'business_hmda_multifamilyAffordableUnits' },
            { propertyKey: 'business_hmda_constructionMethod' },
            { propertyKey: 'business_hmda_occupancyType' },
          ],
        },
      ],
    },
  ],
  hmdaLoanApplication: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'hmdaLoanApplicationDetailsSegment',
        },
      ],
    },
    {
      entityType: 'application',
      components: [
        {
          type: 'genericForm',
          properties: [
            { propertyKey: 'application_hmda_loanType' },
            { propertyKey: 'application_hmda_loanPurpose' },
            { propertyKey: 'application_hmda_Preapproval' },
            { propertyKey: 'application_hmda_actionTaken' },
            { propertyKey: 'application_hmda_actionTakenDate' },
            { propertyKey: 'application_hmda_typeOfPurchaser' },
            { propertyKey: 'application_hmda_hoepaStatus' },
            { propertyKey: 'application_hmda_lienStatus' },
            { propertyKey: 'application_hmda_introductoryRatePeriod' },
            { propertyKey: 'application_hmda_balloonPayment' },
            { propertyKey: 'application_hmda_interestOnlyPayments' },
            { propertyKey: 'application_hmda_negativeAmortization' },
            { propertyKey: 'application_hmda_otherNonAmortizingFeatures' },
            { propertyKey: 'application_hmda_submissionOfApplication' },
            { propertyKey: 'application_hmda_initiallyPayable' },
            { propertyKey: 'application_hmda_reverseMortgage' },
            { propertyKey: 'application_hmda_openEndLineOfCredit' },
            { propertyKey: 'application_hmda_businessOrCommercialPurpose' },
          ],
        },
        {
          type: 'custom',
          customComponentName: 'hmdaPropertySelection',
        },
      ],
    },
    {
      entityType: 'opportunity',
      components: [
        {
          type: 'genericForm',
          properties: [
            {
              propertyKey: 'opportunity_terms_termLength',
            },
          ],
        },
      ],
    },
  ],
  keyIndicators: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'conditions',
        },
      ],
    },
  ],
  affiliates: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'affiliates',
        },
      ],
    },
  ],
  collateral: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'collateral',
        },
        {
          type: 'inlineNote',
          title: 'Collateral Details',
          noteName: 'Collateral Comment',
        },
      ],
    },
  ],
  debtSchedule: [
    {
      entityType: 'business',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'custom',
          customComponentName: 'debtSchedule',
        },
      ],
    },
    {
      components: [
        {
          type: 'inlineNote',
          noteName: 'Debt Schedule Details',
          title: 'Debt Schedule Details',
        },
      ],
    },
  ],
  propertyDetails: [
    {
      entityType: 'business',
      entityGroups: ['property'],
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            {
              propertyKey: 'business_addresses',
              displayNameOverride: 'Address',
            },
            {
              propertyKey: 'business_hmda_propertyValue',
            },
            {
              propertyKey: 'business_hmda_propertyType',
            },
            {
              propertyKey: 'business_hmda_constructionMethod',
            },
            {
              propertyKey: 'business_hmda_occupancyType',
            },
            {
              propertyKey: 'business_hmda_manufacturedHomeSecuredPropertyType',
            },
            {
              propertyKey: 'business_hmda_manufacturedHomeLandPropertyInterest',
            },
            {
              propertyKey: 'business_hmda_totalUnits',
            },
            {
              propertyKey: 'business_hmda_multifamilyAffordableUnits',
              visibilityCondition: {
                fieldName: 'hmda.totalUnits',
                type: 'greaterThan',
                value: 4,
              },
            },
          ],
        },
      ],
    },
  ],
  hardCreditPull: [
    {
      entityType: 'person',
      entityGroups: ['guarantor'],
      components: [
        {
          type: 'genericForm',
          properties: [
            {
              propertyKey: 'person_hardPullCreditScore',
            },
            {
              propertyKey: 'person_hardPullCreditScoreDate',
            },
          ],
        },
      ],
    },
  ],
  sbssScore: [
    {
      entityType: 'application',
      components: [
        {
          type: 'genericForm',
          properties: [
            {
              propertyKey: 'application_sbss',
            },
            {
              propertyKey: 'application_sbssDate',
            },
            {
              propertyKey: 'application_sbssLoanAppNumber',
            },
          ],
        },
      ],
    },
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'sbssEtran',
        },
      ],
    },
  ],
  creditSummary: [
    {
      components: [
        {
          type: 'inlineNote',
          title: 'Credit Summary',
          noteName: 'Credit Summary',
          placeholder:
            'Add details for the request i.e., request for working capital, refinance, change of ownership, construction, startup, etc. and required details.',
        },
      ],
    },
  ],
  additionalFinancialInfo: [
    {
      entityType: 'business',
      entityGroups: ['borrower', 'guarantor'],
      components: [
        {
          type: 'custom',
          customComponentName: 'additionalFinancialInfo',
        },
      ],
    },
  ],
  ofacLookupResults: [
    {
      entityType: 'business',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'custom',
          customComponentName: 'ofacReportBusiness',
        },
        {
          type: 'genericForm',
          properties: [
            {
              propertyKey: 'business_verifications_ofac',
            },
          ],
        },
      ],
    },
    {
      entityType: 'person',
      entityGroups: ['borrower', 'guarantor'],
      components: [
        {
          type: 'custom',
          customComponentName: 'ofacReportIndividual',
        },
        {
          type: 'genericForm',
          properties: [
            {
              propertyKey: 'person_verifications_ofac',
            },
          ],
        },
      ],
    },
    {
      components: [
        {
          type: 'inlineNote',
          title: 'OFAC Lookup Results Details',
          noteName: 'OFAC Lookup Results Details',
        },
        {
          type: 'custom',
          customComponentName: 'ofacReportReview',
        },
      ],
    },
  ],
  useOfFunds: [
    {
      entityType: 'application',
      components: [
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'application_loanDescription',
          },
          title: 'Loan Description',
        },
      ],
    },
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'useOfFundsSummaryTable',
        },
        {
          type: 'custom',
          customComponentName: 'useOfFundsBreakdown',
        },
        {
          type: 'inlineNote',
          title: 'Use of Funds Details',
          noteName: 'Use of Funds Details',
        },
      ],
    },
  ],
  loanTerms: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'loanTermsAssigners',
        },
        {
          type: 'custom',
          customComponentName: 'loanTermsTerms',
        },
        {
          type: 'custom',
          customComponentName: 'loanTermsDetails',
        },
        {
          type: 'inlineNote',
          title: 'Application Details',
          noteName: 'Application Details',
        },
      ],
    },
  ],
  gcLoanTerms: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'loanTermsAssigners',
        },
        {
          type: 'custom',
          customComponentName: 'gcLoanTermsTerms',
        },
        {
          type: 'custom',
          customComponentName: 'loanTermsDetails',
        },
        {
          type: 'inlineNote',
          title: 'Application Details',
          noteName: 'Application Details',
        },
      ],
    },
  ],
  creditReport: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'creditReport',
        },
      ],
    },
  ],
  fibtRiskRating: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'fibtScoreCard',
        },
      ],
    },
  ],
  fibtRiskLevel: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'fibtRiskLevel',
        },
      ],
    },
  ],
  fibtBorrowersDetails: [
    {
      description: 'General Information',
      entityType: 'application',
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'application_primaryContactNameSelector' },
            { propertyKey: 'application_primaryContactPhoneNumberSelector', displayType: 'phoneNumber' },
            { propertyKey: 'application_primaryContactEmailSelector', displayType: 'email' },
            { propertyKey: 'application_applyingForJointCredit' },
          ],
          title: 'General',
        },
      ],
    },
    {
      description: 'borrower individual details',
      entityType: 'person',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'people_ownershipPercentage', displayNameOverride: 'Ownership', displayType: 'percent' },
            { propertyKey: 'people_position' },
            { propertyKey: 'people_ficoScore', displayNameOverride: 'Soft Pull FICO Score' },
            { propertyKey: 'people_hardPullCreditScore', displayNameOverride: 'Hard Pull FICO Score' },
            { propertyKey: 'people_ssn' },
            { propertyKey: 'people_phoneNumber', displayType: 'phoneNumber' },
            { propertyKey: 'people_email', displayType: 'email' },
            { propertyKey: 'people_address' },
            { propertyKey: 'people_citizenship' },
            { propertyKey: 'people_placeOfBirth', displayNameOverride: 'Place of Birth' },
            { propertyKey: 'people_creditCheckConsent' },
          ],
          title: 'General Information',
        },
        {
          type: 'inlineNote',
          title: 'CBR Comment',
          noteName: 'CBR-Comment',
        },
      ],
    },
    {
      description: 'borrower business details',
      entityType: 'business',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_description',
          },
          title: 'Business Description',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'business_businessName' },
            { propertyKey: 'business_dba', displayNameOverride: 'DBA' },
            { propertyKey: 'business_tin', displayNameOverride: 'TIN' },
            { propertyKey: 'business_legalEntityType' },
            { propertyKey: 'business_industryTypes', displayNameOverride: 'Business Type' },
            { propertyKey: 'business_phoneNumber', displayNameOverride: 'Phone Number', displayType: 'phoneNumber' },
            { propertyKey: 'business_startDate', displayNameOverride: 'Formation Date' },
            { propertyKey: 'business_monthsInBusiness', displayType: 'monthsAsYearsAndMonths' },
            { propertyKey: 'business_addresses', displayNameOverride: 'Address' },
            { propertyKey: 'business_stateOfFormation' },
            { propertyKey: 'business_monthsBanked' },
            { propertyKey: 'business_totalAssets', displayNameOverride: 'Total Assets' },
            { propertyKey: 'business_totalLiabilities', displayNameOverride: 'Total Liabilities' },
            { propertyKey: 'business_nonSufficientFunds', displayNameOverride: 'Non-Sufficient Funds' },
            { propertyKey: 'business_slowPayments' },
          ],
          title: 'Business Details',
        },
        {
          type: 'genericForm',
          properties: [{ propertyKey: 'business_excludedFromFinancialCalculations', optional: true }],
        },
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_history',
          },
          title: 'Business History',
        },
        {
          type: 'inlineNote',
          title: 'Business History Details',
          noteName: 'Business History Details',
        },
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_competitive_advantage',
          },
          title: 'Competitive Advantage',
        },
        {
          type: 'inlineNote',
          title: 'Competitive Advantage Details',
          noteName: 'Competitive Advantage Details',
        },
        {
          type: 'genericPropertyText',
          property: {
            propertyKey: 'business_management_experience',
          },
          title: 'Management Experience',
        },
        {
          type: 'inlineNote',
          title: 'Management Experience Details',
          noteName: 'Management Experience Details',
        },
        {
          type: 'inlineNote',
          title: 'Business Comments',
          noteName: 'Business Comments',
          backwardCompatibleNoteName: 'Business Comments',
        },
        {
          type: 'inlineNote',
          title: 'Business Location Comments',
          noteName: 'Business Location Comments',
          backwardCompatibleNoteName: 'Business Location Comments',
        },
      ],
    },
    {
      components: [{ type: 'inlineNote', title: 'Applicant Details', noteName: 'Applicant Details' }],
    },
  ],
};

export const creditMemoSectionDisplayConfigurations: Record<string, SegmentConfiguration[]> = {
  southstateBorrowersDetails: [
    {
      description: 'borrower individual details',
      entityType: 'person',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'people_ownershipPercentage', displayNameOverride: 'Ownership', displayType: 'percent' },
            { propertyKey: 'people_position' },
            { propertyKey: 'people_ficoScore', displayNameOverride: 'FICO Score' },
            { propertyKey: 'people_ssn' },
            { propertyKey: 'people_phoneNumber', displayType: 'phoneNumber' },
            { propertyKey: 'people_email', displayType: 'email' },
            { propertyKey: 'people_address' },
            {
              propertyKey: 'people_residenceInformation_timeAtCurrentAddress',
              displayNameOverride: 'Time at Current Address',
              displayType: 'monthsAsYearsAndMonths',
            },
            { propertyKey: 'people_residenceInformation_numberOfDependents' },
            { propertyKey: 'people_residenceInformation_rentOrMortgagePayment' },
            { propertyKey: 'people_residenceInformation_residentialStatus' },
            { propertyKey: 'people_residenceInformation_comments' },
            { propertyKey: 'people_citizenship' },
            { propertyKey: 'people_placeOfBirth', displayNameOverride: 'Place of Birth' },
            { propertyKey: 'people_existingRelationship_currentCustomer', displayNameOverride: 'Is Current Customer' },
            { propertyKey: 'people_creditCheckConsent' },
          ],
          title: 'General Information',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'personalAnnualIncome' },
            { propertyKey: 'monthlyDebtPayments' },
            { propertyKey: 'people_employment_occupation' },
            { propertyKey: 'people_employment_employmentStatus' },
            { propertyKey: 'people_employment_employer' },
            { propertyKey: 'people_employment_jobTitle' },
            { propertyKey: 'people_employment_yearsEmployed' },
            { propertyKey: 'people_existingRelationship_totalDeposits' },
            { propertyKey: 'people_existingRelationship_totalLiabilities', displayNameOverride: 'Total Bank Liabilities' },
          ],
          title: 'Personal Financials',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'person_verifications_ofac' },
            { propertyKey: 'person_verifications_internalMatch' },
            { propertyKey: 'person_verifications_fraudShieldAlert' },
            { propertyKey: 'person_verifications_childSupport', displayNameOverride: 'Child Support' },
            { propertyKey: 'person_existingRelationship_nonSufficientFunds', displayNameOverride: 'Non-Sufficient Funds' },
            { propertyKey: 'person_existingRelationship_slowPayments' },
            { propertyKey: 'person_existingRelationship_monthsBanked' },
          ],
          title: 'Personal Verification',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'people_identificationCard_type' },
            { propertyKey: 'people_identificationCard_number' },
            { propertyKey: 'people_identificationCard_issueDate' },
            { propertyKey: 'people_identificationCard_expirationDate' },
            { propertyKey: 'people_identificationCard_issuingAuthority' },
          ],
          title: 'ID Card',
        },
      ],
    },
    {
      description: 'borrower business details',
      entityType: 'business',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'business_businessName' },
            { propertyKey: 'business_dba', displayNameOverride: 'DBA' },
            { propertyKey: 'business_tin', displayNameOverride: 'TIN' },
            { propertyKey: 'business_legalEntityType' },
            { propertyKey: 'business_industryTypes', displayNameOverride: 'Business Type' },
            { propertyKey: 'business_phoneNumber', displayNameOverride: 'Phone Number', displayType: 'phoneNumber' },
            { propertyKey: 'business_startDate', displayNameOverride: 'Formation Date' },
            { propertyKey: 'business_monthsInBusiness', displayType: 'monthsAsYearsAndMonths' },
            { propertyKey: 'business_addresses', displayNameOverride: 'Address' },
            { propertyKey: 'business_stateOfFormation' },
            { propertyKey: 'business_monthsBanked' },
            { propertyKey: 'business_totalAssets', displayNameOverride: 'Total Assets' },
            { propertyKey: 'business_totalLiabilities', displayNameOverride: 'Total Liabilities' },
            { propertyKey: 'business_nonSufficientFunds', displayNameOverride: 'Non-Sufficient Funds' },
            { propertyKey: 'business_slowPayments' },
          ],
          title: 'Business Details',
        },
        {
          type: 'inlineNote',
          title: 'Business History Details',
          noteName: 'Business History Details',
        },
        {
          type: 'inlineNote',
          title: 'Competitive Advantage Details',
          noteName: 'Competitive Advantage Details',
        },
        {
          type: 'inlineNote',
          title: 'Management Experience Details',
          noteName: 'Management Experience Details',
        },
        {
          type: 'inlineNote',
          title: 'Business Location Comments',
          noteName: 'Business Location Comments',
          backwardCompatibleNoteName: 'Business Location Comments',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'business_naicsCodes' },
            { propertyKey: 'business_verifications_sosCurrentStatus', displayNameOverride: 'SoS Current Status' },
            { propertyKey: 'business_verifications_ofac', displayNameOverride: 'OFAC' },
            { propertyKey: 'business_verifications_ofacClearedReason' },
            { propertyKey: 'business_verifications_mapSearch', displayNameOverride: 'Map Search' },
            { propertyKey: 'business_verifications_internalMatch', displayNameOverride: 'Internal Match' },
          ],
          title: 'Business Verifications',
        },
        {
          type: 'inlineNote',
          title: 'Business Comments',
          noteName: 'Business Comments',
          backwardCompatibleNoteName: 'Business Comments',
        },
      ],
    },
    {
      components: [{ type: 'inlineNote', title: 'Application Details', noteName: 'Application Details' }],
    },
  ],
  southstateGuarantorDetails: [
    {
      description: 'guarantor individual details',
      entityType: 'person',
      entityGroups: ['guarantor'],
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'people_ownershipPercentage', displayNameOverride: 'Ownership', displayType: 'percent' },
            { propertyKey: 'people_position' },
            { propertyKey: 'people_ssn' },
            { propertyKey: 'people_ficoScore', displayNameOverride: 'Soft Credit Score' },
            { propertyKey: 'people_hardPullCreditScore', displayNameOverride: 'Hard Credit Score' },
            { propertyKey: 'people_phoneNumber', displayType: 'phoneNumber' },
            { propertyKey: 'people_email', displayType: 'email' },
            { propertyKey: 'people_address' },
            {
              propertyKey: 'people_residenceInformation_timeAtCurrentAddress',
              displayNameOverride: 'Time at Current Address',
              displayType: 'monthsAsYearsAndMonths',
            },
            { propertyKey: 'people_residenceInformation_numberOfDependents' },
            { propertyKey: 'people_residenceInformation_rentOrMortgagePayment' },
            { propertyKey: 'people_residenceInformation_residentialStatus' },
            { propertyKey: 'people_residenceInformation_comments' },
            { propertyKey: 'people_citizenship' },
            { propertyKey: 'people_placeOfBirth', displayNameOverride: 'Place of Birth' },
            { propertyKey: 'people_existingRelationship_currentCustomer', displayNameOverride: 'Is Current Customer' },
            { propertyKey: 'people_creditCheckConsent' },
          ],
          title: 'General Information',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'personalAnnualIncome' },
            { propertyKey: 'monthlyDebtPayments' },
            { propertyKey: 'people_employment_occupation' },
            { propertyKey: 'people_employment_employmentStatus' },
            { propertyKey: 'people_employment_employer' },
            { propertyKey: 'people_employment_jobTitle' },
            { propertyKey: 'people_employment_yearsEmployed' },
            { propertyKey: 'people_existingRelationship_totalDeposits' },
            { propertyKey: 'people_existingRelationship_totalLiabilities', displayNameOverride: 'Total Bank Liabilities' },
          ],
          title: 'Personal Financials',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'person_verifications_ofac' },
            { propertyKey: 'person_verifications_internalMatch' },
            { propertyKey: 'person_verifications_fraudShieldAlert' },
            { propertyKey: 'person_verifications_childSupport', displayNameOverride: 'Child Support' },
            { propertyKey: 'person_existingRelationship_nonSufficientFunds', displayNameOverride: 'Non-Sufficient Funds' },
            { propertyKey: 'person_existingRelationship_slowPayments' },
            { propertyKey: 'person_existingRelationship_monthsBanked' },
          ],
          title: 'Personal Verification',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'people_identificationCard_type' },
            { propertyKey: 'people_identificationCard_number' },
            { propertyKey: 'people_identificationCard_issueDate' },
            { propertyKey: 'people_identificationCard_expirationDate' },
            { propertyKey: 'people_identificationCard_issuingAuthority' },
          ],
          title: 'ID Card',
        },
        {
          type: 'inlineNote',
          title: 'CBR Comment',
          noteName: 'CBR-Comment',
        },
      ],
    },
    {
      description: 'guarantor business details',
      entityType: 'business',
      entityGroups: ['guarantor'],
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'business_businessName' },
            { propertyKey: 'business_dba', displayNameOverride: 'DBA' },
            { propertyKey: 'business_tin', displayNameOverride: 'TIN' },
            { propertyKey: 'business_legalEntityType' },
            { propertyKey: 'business_industryTypes', displayNameOverride: 'Business Type' },
            { propertyKey: 'business_phoneNumber', displayNameOverride: 'Phone Number', displayType: 'phoneNumber' },
            { propertyKey: 'business_startDate', displayNameOverride: 'Formation Date' },
            { propertyKey: 'business_monthsInBusiness', displayType: 'monthsAsYearsAndMonths' },
            { propertyKey: 'business_addresses', displayNameOverride: 'Address' },
            { propertyKey: 'business_stateOfFormation' },
            { propertyKey: 'business_monthsBanked' },
            { propertyKey: 'business_totalAssets', displayNameOverride: 'Total Assets' },
            { propertyKey: 'business_totalLiabilities', displayNameOverride: 'Total Liabilities' },
            { propertyKey: 'business_nonSufficientFunds', displayNameOverride: 'Non-Sufficient Funds' },
            { propertyKey: 'business_slowPayments' },
          ],
          title: 'Business Details',
        },
        {
          type: 'inlineNote',
          title: 'Business History Details',
          noteName: 'Business History Details',
        },
        {
          type: 'inlineNote',
          title: 'Competitive Advantage Details',
          noteName: 'Competitive Advantage Details',
        },
        {
          type: 'inlineNote',
          title: 'Management Experience Details',
          noteName: 'Management Experience Details',
        },
        {
          type: 'inlineNote',
          title: 'Business Location Comments',
          noteName: 'Business Location Comments',
          backwardCompatibleNoteName: 'Business Location Comments',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'business_naicsCodes' },
            { propertyKey: 'business_verifications_sosCurrentStatus', displayNameOverride: 'SoS Current Status' },
            { propertyKey: 'business_verifications_ofac', displayNameOverride: 'OFAC' },
            { propertyKey: 'business_verifications_ofacClearedReason' },
            { propertyKey: 'business_verifications_mapSearch', displayNameOverride: 'Map Search' },
            { propertyKey: 'business_verifications_internalMatch', displayNameOverride: 'Internal Match' },
          ],
          title: 'Business Verifications',
        },
        {
          type: 'inlineNote',
          title: 'Business Comments',
          noteName: 'Business Comments',
          backwardCompatibleNoteName: 'Business Comments',
        },
      ],
    },
    {
      components: [{ type: 'inlineNote', title: 'Guarantors Details', noteName: 'Guarantors Details' }],
    },
  ],
  sellersDetails: [
    {
      description: 'Seller Details',
      entityType: 'business',
      entityGroups: ['seller'],
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'business_businessName' },
            { propertyKey: 'business_dba', displayNameOverride: 'DBA' },
            { propertyKey: 'business_tin', displayNameOverride: 'TIN' },
            { propertyKey: 'business_legalEntityType' },
            { propertyKey: 'business_industryTypes', displayNameOverride: 'Business Type' },
            { propertyKey: 'business_phoneNumber', displayNameOverride: 'Phone Number', displayType: 'phoneNumber' },
            { propertyKey: 'business_startDate', displayNameOverride: 'Formation Date' },
            { propertyKey: 'business_monthsInBusiness', displayType: 'monthsAsYearsAndMonths' },
            { propertyKey: 'business_addresses', displayNameOverride: 'Address' },
            { propertyKey: 'business_stateOfFormation' },
            { propertyKey: 'business_monthsBanked' },
            { propertyKey: 'business_totalAssets', displayNameOverride: 'Total Assets' },
            { propertyKey: 'business_totalLiabilities', displayNameOverride: 'Total Liabilities' },
            { propertyKey: 'business_nonSufficientFunds', displayNameOverride: 'Non-Sufficient Funds' },
            { propertyKey: 'business_slowPayments' },
          ],
          title: 'Business Details',
        },
        {
          type: 'inlineNote',
          title: 'Business History Details',
          noteName: 'Business History Details',
        },
        {
          type: 'inlineNote',
          title: 'Competitive Advantage Details',
          noteName: 'Competitive Advantage Details',
        },
        {
          type: 'inlineNote',
          title: 'Management Experience Details',
          noteName: 'Management Experience Details',
        },
        {
          type: 'inlineNote',
          title: 'Business Location Comments',
          noteName: 'Business Location Comments',
          backwardCompatibleNoteName: 'Business Location Comments',
        },
        {
          type: 'genericDataGrid',
          properties: [
            { propertyKey: 'business_naicsCodes' },
            { propertyKey: 'business_verifications_sosCurrentStatus', displayNameOverride: 'SoS Current Status' },
            { propertyKey: 'business_verifications_ofac', displayNameOverride: 'OFAC' },
            { propertyKey: 'business_verifications_ofacClearedReason' },
            { propertyKey: 'business_verifications_mapSearch', displayNameOverride: 'Map Search' },
            { propertyKey: 'business_verifications_internalMatch', displayNameOverride: 'Internal Match' },
          ],
          title: 'Business Verifications',
        },
        {
          type: 'inlineNote',
          title: 'Business Comments',
          noteName: 'Business Comments',
          backwardCompatibleNoteName: 'Business Comments',
        },
      ],
    },
  ],
  firstBankDemoLoanSummary: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'firstBankDemoLoanSummary',
        },
      ],
    },
  ],
  firstBankDemoFacility: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'firstBankDemoFacility',
        },
      ],
    },
  ],
  firstBankDemoRiskRating: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'firstBankDemoRiskRating',
        },
      ],
    },
  ],
  firstBankConsumerCollateral: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'firstBankConsumerCollateral',
        },
      ],
    },
  ],
  firstBankConsumerBorrowers: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'firstBankConsumerBorrowers',
        },
      ],
    },
  ],
  firstBankConsumerLoanDetails: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'firstBankConsumerLoanDetails',
        },
      ],
    },
  ],
  firstBankConsumerDecision: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'firstBankConsumerDecision',
        },
      ],
    },
  ],
  firstBankBusinessCashFlow: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'firstBankBusinessCashFlow',
        },
      ],
    },
  ],
  firstBankBusinessBalanceSheet: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'firstBankBusinessBalanceSheet',
        },
      ],
    },
  ],
  firstBankCommercialCollateral: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'firstBankCommercialCollateral',
        },
      ],
    },
  ],
  hanoverLoanDetails: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'hanoverLoanDetails',
        },
      ],
    },
  ],
  hanoverApproved: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'hanoverApproved',
        },
      ],
    },
  ],
  hanoverClosingConditions: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'hanoverClosingConditions',
        },
      ],
    },
  ],
  hanoverGuarantorAnalysis: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'hanoverGuarantorAnalysis',
        },
      ],
    },
  ],
  hanoverLoanOverview: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'hanoverLoanOverview',
        },
      ],
    },
  ],
  hanoverSbaEligibility: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'hanoverSbaEligibility',
        },
      ],
    },
  ],
  hanoverAffiliation: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'hanoverAffiliation',
        },
      ],
    },
  ],
  scoreCard: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'scoreCard',
        },
      ],
    },
  ],
  decision: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'flags',
        },
        {
          type: 'custom',
          customComponentName: 'exceptions',
        },
        {
          type: 'custom',
          customComponentName: 'decision',
        },
      ],
    },
  ],
  southStateDecision: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'flags',
        },
        {
          type: 'custom',
          customComponentName: 'exceptions',
        },
        { type: 'inlineNote', title: 'Underwriter Comments', noteName: 'Underwriter Comments' },
        { type: 'inlineNote', title: 'Closing Conditions', noteName: 'Closing Conditions' },
      ],
    },
    {
      entityType: 'opportunity',
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            {
              propertyKey: 'opportunity_underwriting_compliance_totalCommercialExposure',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_craReportable',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_craAnnualRevenue',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_craSourceDocument',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_craSourceDocumentDate',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_hmdaReportable',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_minorityOrNonProfit',
            },
            {
              propertyKey: 'opportunity_underwriting_compliance_fiuExposure',
            },
          ],
          title: 'Reporting',
        },
      ],
    },
    {
      components: [
        { type: 'inlineNote', title: 'Risk Rating', noteName: 'Risk Rating' },
        { type: 'inlineNote', title: 'Covenants', noteName: 'Covenants' },
        {
          type: 'custom',
          customComponentName: 'decision',
        },
      ],
    },
  ],
  creditSummary: [
    {
      components: [
        {
          type: 'inlineNote',
          title: 'Credit Summary',
          noteName: 'Credit Summary',
          placeholder:
            'Add details for the request i.e., request for working capital, refinance, change of ownership, construction, startup, etc. and required details.',
        },
      ],
    },
  ],
  eligibilities: [
    {
      components: [
        {
          type: 'inlineNote',
          title: 'General Requirements',
          noteName: 'General Requirements',
        },
        {
          type: 'inlineNote',
          title: 'Insurance/License/Permits Requirements',
          noteName: 'License Requirements',
        },
        {
          type: 'inlineNote',
          title: 'Other Requirements',
          noteName: 'Other Requirements',
        },
        {
          type: 'custom',
          customComponentName: 'creditElsewhere',
          params: {
            editable: false,
          },
        },
        {
          type: 'inlineNote',
          title: 'Credit Elsewhere - Comments',
          noteName: 'Credit Elsewhere',
        },
        {
          type: 'custom',
          customComponentName: 'sizeStandard',
        },
      ],
    },
  ],
  fraudShieldAlerts: [
    {
      entityType: 'person',
      entityGroups: ['guarantor', 'borrower'],
      components: [
        {
          type: 'custom',
          customComponentName: 'fraudShieldAlerts',
        },
      ],
    },
    {
      components: [
        {
          type: 'inlineNote',
          title: 'Fraud Shield Alerts',
          noteName: 'Fraud Shield Alerts',
        },
      ],
    },
  ],
  conditions: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'conditions',
        },
      ],
    },
  ],
  affiliates: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'affiliates',
        },
      ],
    },
  ],
  debtSchedule: [
    {
      entityType: 'business',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'custom',
          customComponentName: 'debtSchedule',
        },
      ],
    },
    {
      components: [
        {
          type: 'inlineNote',
          noteName: 'Debt Schedule Details',
          title: 'Debt Schedule Details',
        },
      ],
    },
  ],
  collateral: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'collateral',
        },
        {
          type: 'inlineNote',
          title: 'Collateral Details',
          noteName: 'Collateral Comment',
        },
      ],
    },
  ],
  southStateCollateral: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'southStateCollateral',
        },
        {
          type: 'inlineNote',
          title: 'Collateral Details',
          noteName: 'Collateral Comment',
        },
      ],
    },
  ],
  ofacReport: [
    {
      entityType: 'business',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'custom',
          customComponentName: 'ofacReportBusiness',
        },
      ],
    },
    {
      entityType: 'person',
      entityGroups: ['borrower', 'guarantor'],
      components: [
        {
          type: 'custom',
          customComponentName: 'ofacReportIndividual',
        },
      ],
    },
    {
      components: [
        {
          type: 'inlineNote',
          title: 'OFAC Lookup Results Details',
          noteName: 'OFAC Lookup Results Details',
        },
      ],
    },
    {
      entityType: 'opportunity',
      components: [
        {
          type: 'genericDataGrid',
          properties: [
            {
              propertyKey: 'opportunity_underwriting_ofacSearchReview_designation',
            },
            {
              propertyKey: 'opportunity_underwriting_ofacSearchReview_reviewedByUser',
            },
          ],
          title: 'Review',
        },
      ],
    },
  ],
  relationshipAndTerms: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'termsView',
        },
        {
          type: 'inlineNote',
          title: 'Terms & Relationship Details',
          noteName: 'Terms & Relationship Details',
        },
      ],
    },
  ],
  loanSummary: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'loanTermsBorrowerDetails',
        },
        {
          type: 'custom',
          customComponentName: 'loanTermsAssigners',
        },
        {
          type: 'custom',
          customComponentName: 'loanTermsTermsView',
        },
        {
          type: 'custom',
          customComponentName: 'loanTermsDetails',
        },
        {
          type: 'custom',
          customComponentName: 'SBSSAndCreditScoreTable',
        },
      ],
    },
    {
      entityType: 'business',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'custom',
          customComponentName: 'businessOwnershipTable',
        },
      ],
    },
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'useOfFundsSummaryTable',
        },
      ],
    },
  ],
  fibtLoanSummary: [
    {
      components: [
        {
          type: 'custom',
          customComponentName: 'loanTermsBorrowerDetails',
        },
        {
          type: 'custom',
          customComponentName: 'loanTermsAssigners',
        },
        {
          type: 'custom',
          customComponentName: 'fibtLoanTermsTermsView',
        },
      ],
    },
    {
      entityType: 'business',
      entityGroups: ['borrower'],
      components: [
        {
          type: 'custom',
          customComponentName: 'businessOwnershipTable',
        },
      ],
    },
  ],
};
