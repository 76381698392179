import React, { useMemo } from 'react';
import type { ConnectionAudit } from '@lama/contracts';
import type { FC } from 'react';
import { capitalCase } from 'change-case-all';
import type { AuditGroupInfo } from '../types';
import { AuditLog } from '../../AuditLog';

interface ConnectionEstablishedAuditLogProps {
  audit: ConnectionAudit[];
  groupInfo: AuditGroupInfo;
}

export const ConnectionEstablishedAuditLog: FC<ConnectionEstablishedAuditLogProps> = ({ audit, groupInfo }) => {
  const connectedServices = useMemo(() => audit.map((entry) => capitalCase(entry.serviceName)).join(', '), [audit]);

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Connected ${connectedServices}`}
      timestamp={groupInfo.timestamp}
    />
  );
};
