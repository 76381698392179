import type { AffiliateApiModel, BusinessApiModel } from '@lama/clients';
import React, { useMemo } from 'react';
import { personFullName } from '@lama/selectors';
import { RelatedCompanyCard } from './RelatedCompanyCard';

interface RelatedCompanyListItemProps {
  affiliate: AffiliateApiModel;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  business: BusinessApiModel;
}

export const RelatedCompanyListItem: React.FC<RelatedCompanyListItemProps> = ({ affiliate, onDelete, onEdit, business }) => {
  const ownerName = useMemo(() => {
    const ownerId = affiliate.relatedPeople?.[0]?.id ?? affiliate.owningBusinesses?.[0]?.id ?? null;

    if (!ownerId) {
      return '';
    }

    const person = business.people.find((principal) => principal.id === ownerId);

    if (person) {
      return personFullName(person);
    }

    const owner = business.affiliates?.find((b) => b.id === ownerId) ?? (business.id === ownerId ? business : null);

    return owner?.legalName ?? '';
  }, [affiliate.relatedPeople, affiliate.owningBusinesses, business]);

  return (
    <RelatedCompanyCard
      id={affiliate.id}
      name={affiliate.legalName ?? ''}
      ownerName={ownerName}
      deleteEnabled
      onDelete={onDelete}
      onEdit={onEdit}
    />
  );
};
