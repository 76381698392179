import type { FC } from 'react';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import type { Collateral } from '@lama/contracts';
import { formatValue } from '@lama/data-formatters';
import { theme } from '../../../../../../shared/styling/theme';

interface CollateralItemProps {
  item: Collateral;
}

export const CollateralItem: FC<CollateralItemProps> = ({ item: { type, totalValue, method, description } }) => (
  <>
    <Stack gap={0.5} direction={'row'} sx={{ color: theme.palette.text.secondary }}>
      <Typography variant={'subtitle2'} maxWidth={'65%'} noWrap>
        {type}
      </Typography>
      {totalValue ? (
        <Typography variant={'subtitle2'} noWrap>
          {` • ${formatValue(totalValue, 'currency')}`}
        </Typography>
      ) : null}
      {method ? (
        <Typography variant={'subtitle2'} noWrap>
          {` • Valuation Method: ${method}`}
        </Typography>
      ) : null}
    </Stack>
    <Stack>
      <Typography variant={'body2'} noWrap>
        {description}
      </Typography>
    </Stack>
  </>
);
