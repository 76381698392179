import React, { forwardRef, useContext, useMemo } from 'react';
import { LoadingPage } from '@lama/app-components';
import { Text } from '@lama/design-system';
import type { ApplicationRelatedBusinessApiModel } from '@lama/clients';
import { ApplicationContext } from '../../ApplicationContext';
import type { EntitiesByType } from '../../CreditMemo/Spreads/components/SpreadTable/types';
import { SpreadTable } from '../../CreditMemo/Spreads/components/SpreadTable';
import { useGetMultiEntityFinancialsQuery } from '../../../../shared/hooks/react-query/financials/useGetMultiEntityFinancialsQuery';
import type { SpreadSegmentProps, SpreadTableRef } from './types';
import { useSpreadConfigurationById } from './hooks/useSpreadConfiguration';

interface PropertiesCashflowSegmentProps extends SpreadSegmentProps {
  property: ApplicationRelatedBusinessApiModel;
}

export const PropertiesCashFlowSpreadSegment = forwardRef<SpreadTableRef, PropertiesCashflowSegmentProps>(
  ({ printMode, spreadId, allowAdjustments, property, ...props }, spreadTableRef) => {
    const { application, opportunity, properties } = useContext(ApplicationContext);

    const { financials, loading } = useGetMultiEntityFinancialsQuery([{ entityId: property.business.id }], false);

    const spreadConfiguration = useSpreadConfigurationById(spreadId);

    const spreadEntities: EntitiesByType = useMemo(
      () => ({
        affiliate: [],
        business: [property],
        person: [],
        application: [application],
        opportunity: [],
      }),
      [application, property],
    );

    if (!spreadConfiguration) {
      return <Text variant={'body1'}>{'Spreads are not configured, please contact support.'}</Text>;
    }

    if (loading) {
      return <LoadingPage />;
    }

    return (
      <SpreadTable
        entities={spreadEntities}
        properties={properties}
        applicationId={application.id}
        opportunity={opportunity}
        spreadsSettings={opportunity.spreadsSettings}
        spreadConfig={spreadConfiguration}
        financials={financials}
        defaultYearColumns={spreadConfiguration?.spreadYears ?? [2022]}
        printMode={printMode}
        spreadName={'PropertiesCashFlow'}
        periodDisplayFormat={'income statement'}
        allowAdjustments={allowAdjustments}
        showPeopleNames={false}
        ref={spreadTableRef}
        {...props}
      />
    );
  },
);
