/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useContext, useState, useMemo } from 'react';
import type { FC } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { displayToast } from '@lama/app-components';
import type { PersonApiModel } from '@lama/clients';
import { Flex, Spinner, Text } from '@lama/design-system';
import { getApplicationEntityByType } from '@lama/properties';
import { personFullName as personFullNameSelector } from '@lama/selectors';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { ApplicationContext } from '../../ApplicationContext';
import { useUpdateApplicationMutation } from '../../../../shared/hooks/react-query/application/useUpdateApplication';
import { useCreateBorrowerMutation } from '../../../Pipeline/hooks/useCreateBorrowerMutation';
import { ActionMenuSearchInput } from '../../ActionsMenu/ActionMenuSearchInput';

interface SetPrimaryContactMenuItemProps {
  person: PersonApiModel;
  onClick: (person: PersonApiModel) => void;
  closeMenu: () => void;
  selected: boolean;
}

export interface SetPrimaryContactSearchMenuProps {
  menuProps: any;
  onBack: () => void;
  onClose: () => void;
  open: boolean;
  menuRef: React.MutableRefObject<null>;
}

export const SetPrimaryContactSearchMenuItem: FC<SetPrimaryContactMenuItemProps> = ({ person, onClick, selected }) => {
  const onSetPrimaryContact = useCallback(async () => {
    onClick(person);
  }, [onClick, person]);

  const personFullName = useMemo(() => personFullNameSelector(person), [person]);

  return (
    <MenuItem onClick={onSetPrimaryContact} sx={{ borderRadius: '4px', pl: 2, pr: 4 }} selected={selected}>
      <Flex maxWidth={'90%'} flexDirection={'row'} gap={2} alignItems={'center'}>
        <Text variant={'body1'} ellipsis>
          {personFullName}
        </Text>
      </Flex>
    </MenuItem>
  );
};

export const SetPrimaryContactSearchMenu: FC<SetPrimaryContactSearchMenuProps> = ({ menuProps, open, menuRef, onClose }) => {
  const { application, opportunity } = useContext(ApplicationContext);
  const [filterOptions, setFilterOptions] = useState('');
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(application.id, opportunity.id);
  const { mutateAsync: createBorrowerUser, isPending: creatingBorrower } = useCreateBorrowerMutation();

  const handleClose = useCallback(() => {
    setFilterOptions('');
    onClose();
  }, [onClose]);

  const onFilterChange = useCallback((value: string) => {
    setFilterOptions(value);
  }, []);

  const onItemClick = useCallback(
    async (person: PersonApiModel) => {
      if (application.primaryContactPersonId === person.id) {
        onClose();
        return;
      }

      if (!person.email) {
        displayToast('Primary contact must have an email address', 'error', {
          toastId: 'update-primary-contact',
          autoClose: 5000,
          hideProgressBar: true,
        });

        return;
      }

      await updateApplication({
        updateApplicationPayload: {
          primaryContactPersonId: person.id,
        },
      });

      try {
        await createBorrowerUser({
          createBorrowerPayload: {
            personId: person.id,
            email: person.email,
            firstName: person.firstName,
            lastName: person.lastName,
            partner: opportunity.partnerId,
            id: uuidv4(),
          },
        });
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status !== 409) {
          throw error;
        }
      }

      onClose();
    },
    [application.primaryContactPersonId, createBorrowerUser, onClose, opportunity.partnerId, updateApplication],
  );

  const people = useMemo(
    () =>
      (getApplicationEntityByType(application, 'person', ['all']) as PersonApiModel[]).filter((person) =>
        personFullNameSelector(person).toLowerCase().includes(filterOptions.toLowerCase()),
      ),
    [application, filterOptions],
  );

  const isLoading = useMemo(() => updatingApplication || creatingBorrower, [creatingBorrower, updatingApplication]);

  return (
    <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={handleClose}>
      {isLoading ? (
        <Flex width={'100%'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
          <Spinner size={'40px'} />
        </Flex>
      ) : (
        <Flex flexDirection={'column'} gap={2}>
          <Flex flexDirection={'column'} gap={2}>
            <ActionMenuSearchInput onInputChange={onFilterChange} />
          </Flex>
          <Flex flexDirection={'column'}>
            {people.map((person) => (
              <SetPrimaryContactSearchMenuItem
                key={person.id}
                person={person}
                closeMenu={handleClose}
                onClick={onItemClick}
                selected={application.primaryContactPersonId === person.id}
              />
            ))}
          </Flex>
        </Flex>
      )}
    </Menu>
  );
};
