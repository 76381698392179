import React, { useCallback, useContext, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAsyncFn, useMap } from 'react-use';
import { useAuth0 } from '@auth0/auth0-react';

import type { CreateCondition } from '@lama/clients';
import type { ConditionConfiguration } from '@lama/conditions';
import { conditionConfigurations } from '@lama/conditions';
import type { Condition } from '@lama/contracts';
import { productServiceClient } from '../../../../../shared/clients/productService';
import { ProductSettingsContext } from '../ProductSettingsContext';
import { conditionByTypeComponent } from './ConditionComponents/conditionComponentByType';
import { AppetiteSettingsV2 } from './AppetiteSettingsV2';

export const AppetiteSettingsV1 = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { product, registerSaveFn, setIsDirty } = useContext(ProductSettingsContext);

  const [conditionsMap, { set: setCondition, setAll: setAllConditions }] = useMap(
    Object.fromEntries(product?.conditions?.map((condition) => [condition.id, condition]) ?? []) as Record<
      string,
      Condition | CreateCondition
    >,
  );

  const [, saveAppetite] = useAsyncFn(async (conditions: (Condition | CreateCondition)[]) => {
    if (!product) {
      return;
    }

    const token = await getAccessTokenSilently();
    const exisitingConditions = conditions.filter((condition) => product.conditions?.find((c) => c.id === condition.id));
    const newConditions = conditions.filter(
      (currentCondition) =>
        !product.conditions?.find(
          ({ id, conditionMatcher }) =>
            id === currentCondition.id ||
            (conditionMatcher.fieldName === currentCondition.conditionMatcher.fieldName &&
              conditionMatcher.type === currentCondition.conditionMatcher.type &&
              conditionMatcher.entityType === currentCondition.conditionMatcher.entityType),
        ),
    );

    await Promise.all([
      ...exisitingConditions.map((condition) => productServiceClient.updateCondition(product.id, condition.id, condition, token)),
      ...newConditions.map((condition) => productServiceClient.addCondition(product.id, condition, token)),
    ]);
    setAllConditions({});
  });

  const updateCondition = useCallback(
    (condition: Condition | CreateCondition) => {
      setIsDirty(true);
      setCondition(condition.id, condition);
    },
    [setCondition, setIsDirty],
  );

  const [, updateConditions] = useAsyncFn(() => saveAppetite(Object.values(conditionsMap)), [conditionsMap, saveAppetite]);

  const configToComponent = useCallback(
    (config: ConditionConfiguration) => {
      const condition =
        Object.values(conditionsMap).find(
          ({ conditionMatcher: c }) => c.type === config.type && c.fieldName === config.selector && c.entityType === config.entityType,
        ) ??
        product.conditions?.find(
          ({ conditionMatcher: c }) => c.type === config.type && c.fieldName === config.selector && c.entityType === config.entityType,
        );

      if (!condition) {
        return null;
      }

      return conditionByTypeComponent(config, updateCondition, condition);
    },
    [conditionsMap, product.conditions, updateCondition],
  );

  useEffect(() => {
    registerSaveFn('appetite', updateConditions);
  }, [registerSaveFn, updateConditions]);

  return <Stack gap={5}>{conditionConfigurations.map(configToComponent)}</Stack>;
};

export const AppetiteSettingsPage = () => {
  const { enableConditionsV2 } = useFlags();

  return enableConditionsV2 ? <AppetiteSettingsV2 /> : <AppetiteSettingsV1 />;
};
