import type { ApplicationStatus, UpdateAudit } from '@lama/contracts';
import { type FC } from 'react';
import type { AuditGroupInfo } from '../../types';
import { ApplicationOnboardingCompleteAuditLog } from './ApplicationOnboardingCompleteAuditLog';
import { ApplicationRejectedAuditLog } from './ApplicationRejectedAuditLog';
import { getApplicationStatusAuditLog } from './getApplicationStatusAuditLog';

export interface ApplicationStatusAuditProps {
  audit: UpdateAudit;
  groupInfo: AuditGroupInfo;
}

export const applicationStatusAuditComponentMap: Record<ApplicationStatus, FC<ApplicationStatusAuditProps> | null> = {
  Rejected: ApplicationRejectedAuditLog,
  Cancelled: getApplicationStatusAuditLog('Cancelled'),
  Closed: getApplicationStatusAuditLog('Closed'),
  Closing: getApplicationStatusAuditLog('Closing'),
  Deleted: null,
  Draft: getApplicationStatusAuditLog('Draft'),
  InReview: getApplicationStatusAuditLog('InReview'),
  OnboardingCompleted: ApplicationOnboardingCompleteAuditLog,
  ExpresslyWithdrawn: getApplicationStatusAuditLog('ExpresslyWithdrawn'),
  PendingApproval: getApplicationStatusAuditLog('PendingApproval'),
  ApprovedNotAccepted: getApplicationStatusAuditLog('ApprovedNotAccepted'),
  PendingLost: getApplicationStatusAuditLog('PendingLost'),
  WithdrawalOfApprovedApplication: getApplicationStatusAuditLog('WithdrawalOfApprovedApplication'),
};
