import { useMediaQuery, useTheme } from '@mui/material';
import { isMobile } from 'react-device-detect';
import type { FC, ReactElement } from 'react';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flex, Text } from '@lama/design-system';

export const MobileBoundary: FC<{ children: ReactElement }> = ({ children }) => {
  const themeFromHook = useTheme();
  const isMobileMediaQuery = useMediaQuery(themeFromHook.breakpoints.down('sm'));
  const { enableMobileBoundary } = useFlags();

  if (enableMobileBoundary && (isMobileMediaQuery || isMobile)) {
    return (
      <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} justifyItems={'center'} height={'100vh'}>
        <Flex flexDirection={'column'} maxWidth={'350px'}>
          <Text variant={'h6'}>{'This app is not available on mobile. Please use a desktop browser.'}</Text>
        </Flex>
      </Flex>
    );
  }

  return children;
};
