import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import type { SearchResultProps } from '@lama/clients';
import { searchExistingEntitiesClient } from '../../../clients/searchExistingCustomers';

export const useSearchExistingCustomers = (partnerId: string, searchTerm: string, enabled: boolean) => {
  const { getAccessTokenSilently } = useAuth0();
  const searchExistingCustomers = useCallback<() => Promise<SearchResultProps[]>>(async () => {
    if (!partnerId) {
      return [];
    }
    const token = await getAccessTokenSilently();

    const results = await searchExistingEntitiesClient.searchExistingEntities(searchTerm, partnerId, token);
    return results?.data;
  }, [getAccessTokenSilently, partnerId, searchTerm]);

  return useQuery<SearchResultProps[]>({
    queryKey: ['searchExistingCustomer', partnerId, searchTerm],
    queryFn: searchExistingCustomers,
    enabled,
  });
};
