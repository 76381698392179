import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { Autocomplete, Button, Dialog, DialogContent, TextField } from '@mui/material';
import { Text, Flex, greyPalette } from '@lama/design-system';

interface DismissTradelineDialogProps {
  open: boolean;
  onDismissClick: (reason: string) => void;
  onCancelClick: () => void;
}

const reasons = ['Duplicate'];

export const DismissTradelineDialog: FC<DismissTradelineDialogProps> = ({ open, onDismissClick, onCancelClick }) => {
  const [reason, setReason] = useState('Duplicate');

  const onValueChange = useCallback((_e: React.SyntheticEvent, value: string) => {
    setReason(value);
  }, []);

  const renderInput = useCallback(
    (props: any) => <TextField id={'reasons'} name={'reasons'} label={'Reason'} placeholder={'Reason for dismiss'} {...props} />,
    [],
  );

  const onDismissButtonClick = useCallback(() => {
    onDismissClick(reason);
  }, [onDismissClick, reason]);

  return (
    <Dialog maxWidth={'md'} open={open} disableEscapeKeyDown fullWidth>
      <DialogContent sx={{ p: 4 }}>
        <Flex flexDirection={'column'} gap={8}>
          <Text variant={'h5'}>{'Dismiss Tradeline'}</Text>
          <Autocomplete renderInput={renderInput} disableClearable options={reasons} value={reason} onChange={onValueChange} fullWidth />
          <Flex justifyContent={'flex-end'} gap={3}>
            <Button sx={{ color: greyPalette[500] }} onClick={onCancelClick}>
              {'Cancel'}
            </Button>
            <Button onClick={onDismissButtonClick}>{'Dismiss'}</Button>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
};
