import React, { useMemo } from 'react';
import type { Person } from '@lama/contracts';
import type { FC } from 'react';
import { personFullName } from '@lama/selectors';
import type { CreateAuditLogProps } from '../types';
import { AuditLog } from '../../AuditLog';

export const CreatePersonAuditLog: FC<CreateAuditLogProps> = ({ audit, groupInfo }) => {
  const createdPeople = useMemo(
    () =>
      audit
        .filter((entry) => (entry.entity as Person).id !== groupInfo.user?.personId)
        .map((entry) => {
          const person = entry.entity as Person;

          return personFullName(person);
        })
        .join(', '),
    [audit, groupInfo.user?.personId],
  );

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Registered ${createdPeople}`}
      timestamp={groupInfo.timestamp}
    />
  );
};
