import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import * as selectors from '@lama/selectors';
import { getSelectedPropertyValue } from '@lama/properties';
import { formatValue } from '@lama/data-formatters';
import type { CellContent } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../ApplicationContext';
import { SegmentContainer } from '../SegmentContainer';

const sbssPropertyKey = 'application_sbss';
const sbssDatePropertyKey = 'application_sbssDate';
const ficoHardScorePropertyKey = 'people_hardPullCreditScore';

const headers = [
  {
    text: 'Entity Type',
  },
  {
    text: 'Entity Name',
  },
  {
    text: 'Score',
  },
  {
    text: 'As of',
  },
];

export const SBSSAndCreditScoreTable: FC<{ title?: string }> = ({ title }) => {
  const { application, properties } = useContext(ApplicationContext);

  const borrowers = useMemo(() => selectors.applicationBorrowingRelatedBusinessesSelector(application), [application]);
  const guarantors = useMemo(() => selectors.applicationGuarantorsSelector(application), [application]);

  const { sbssProperty, sbssDateProperty, ficoScoreProperty } = useMemo(() => {
    const sbss = properties[sbssPropertyKey];
    const sbssDate = properties[sbssDatePropertyKey];
    const ficoScore = properties[ficoHardScorePropertyKey];

    return {
      sbssProperty: sbss,
      sbssDateProperty: sbssDate,
      ficoScoreProperty: ficoScore,
    };
  }, [properties]);

  const rows = useMemo(() => {
    const borrowerRows: CellContent[][] = borrowers.map((b) => [
      {
        value: 'Borrower',
      },
      {
        value: selectors.businessName(b.business) ?? '-',
      },
      ...(sbssProperty
        ? [
            {
              value: getSelectedPropertyValue(sbssProperty.fieldName, application, sbssProperty.sources, sbssProperty.type).value ?? '',
              align: 'right' as const,
            },
          ]
        : []),
      ...(sbssDateProperty
        ? [
            {
              value: formatValue(
                getSelectedPropertyValue(sbssDateProperty.fieldName, application, sbssDateProperty.sources, sbssDateProperty.type).value,
                'date',
              ),
              type: 'date' as const,
              align: 'right' as const,
            },
          ]
        : []),
    ]);
    const guarantorRows: CellContent[][] = guarantors.map((guarantor) => [
      {
        value: 'Guarantor',
      },
      {
        value: selectors.personFullName(guarantor),
      },
      ...(ficoScoreProperty
        ? [
            {
              value:
                getSelectedPropertyValue(ficoScoreProperty.fieldName, guarantor, ficoScoreProperty.sources, ficoScoreProperty.type).value ??
                '',
              align: 'right' as const,
            },
          ]
        : []),
      ...(guarantor.hardPullCreditScoreDate
        ? [
            {
              value: guarantor.hardPullCreditScoreDate,
              type: 'date' as const,
              align: 'right' as const,
            },
          ]
        : []),
    ]);

    return [...borrowerRows, ...guarantorRows];
  }, [borrowers, ficoScoreProperty, guarantors, sbssDateProperty, sbssProperty, application]);

  return (
    <SegmentContainer title={title} bordered={false}>
      <CreditMemoTable headers={headers} rows={rows} />
    </SegmentContainer>
  );
};
