import React from 'react';
import type { FC } from 'react';
import { useTheme } from '@mui/material';

export const CoinStackLogo: FC = () => {
  const theme = useTheme();

  return (
    <svg width={'251'} height={'248'} viewBox={'0 0 251 248'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M165.319 147.044V157.398C165.33 160.534 161.684 163.681 154.351 166.114C139.688 170.979 115.764 171.183 100.443 166.634C100.19 166.559 99.9412 166.484 99.6982 166.409C91.9324 163.976 88.0333 160.749 88.0279 157.505L88.0171 147.151C88.0171 150.39 91.9216 153.622 99.6874 156.055C99.9358 156.13 100.184 156.206 100.433 156.281C115.759 160.829 139.683 160.625 154.34 155.76C161.668 153.327 165.319 150.18 165.313 147.044H165.319Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M152.898 137.914C153.152 137.99 153.4 138.065 153.649 138.145C168.932 142.93 169.245 150.814 154.345 155.76C139.683 160.625 115.759 160.829 100.438 156.281C100.184 156.205 99.9361 156.13 99.6931 156.055C84.4099 151.27 84.0966 143.387 98.9964 138.435C113.653 133.57 137.577 133.366 152.903 137.914H152.898Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M167.457 132.689V143.043C167.468 146.179 163.822 149.326 156.489 151.759C141.826 156.624 117.903 156.828 102.582 152.28C102.328 152.205 102.079 152.13 101.836 152.054C94.0705 149.622 90.1714 146.394 90.166 143.151V132.797C90.166 136.035 94.0651 139.268 101.826 141.701C102.074 141.776 102.322 141.851 102.571 141.926C117.897 146.475 141.821 146.271 156.478 141.405C163.806 138.972 167.457 135.826 167.451 132.689H167.457Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M155.036 123.56C155.29 123.635 155.538 123.71 155.786 123.791C171.07 128.576 171.383 136.459 156.483 141.405C141.821 146.271 117.897 146.475 102.576 141.926C102.322 141.851 102.074 141.776 101.831 141.701C86.5476 136.916 86.2343 129.032 101.134 124.081C115.791 119.215 139.715 119.011 155.041 123.56H155.036Z'
        }
        fill={'#EEEEEE'}
      />
      <path
        d={
          'M161.333 118.222V128.576C161.344 131.712 157.699 134.859 150.365 137.292C135.703 142.157 111.779 142.361 96.4581 137.813C96.2042 137.737 95.9558 137.662 95.7128 137.587C87.947 135.154 84.0479 131.927 84.0425 128.683V118.329C84.0425 121.568 87.9416 124.8 95.702 127.233C95.9504 127.308 96.1988 127.384 96.4473 127.459C111.774 132.007 135.698 131.803 150.354 126.938C157.683 124.505 161.333 121.358 161.328 118.222H161.333Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M148.912 109.087C149.166 109.162 149.414 109.238 149.663 109.318C164.946 114.103 165.259 121.986 150.36 126.938C135.697 131.803 111.773 132.007 96.4525 127.459C96.1987 127.384 95.9502 127.308 95.7072 127.233C80.424 122.448 80.1108 114.565 95.0106 109.613C109.667 104.748 133.591 104.544 148.918 109.093L148.912 109.087Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M168.926 106.3L168.936 116.654C168.936 119.79 165.291 122.937 157.963 125.37C143.301 130.235 119.377 130.439 104.056 125.89C103.802 125.815 103.554 125.74 103.311 125.665C95.5447 123.232 91.6456 120.005 91.6402 116.761L91.6294 106.407C91.6294 109.646 95.5339 112.878 103.3 115.311C103.548 115.386 103.797 115.461 104.045 115.537C119.371 120.085 143.295 119.881 157.952 115.016C165.28 112.583 168.931 109.436 168.926 106.3V106.3Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M156.505 97.1706C156.759 97.2458 157.008 97.3209 157.256 97.4015C172.539 102.186 172.853 110.07 157.953 115.016C143.291 119.881 119.367 120.085 104.046 115.537C103.792 115.462 103.544 115.386 103.3 115.311C88.0173 110.526 87.7041 102.643 102.604 97.6915C117.261 92.8261 141.184 92.622 156.511 97.1706H156.505Z'
        }
        fill={'#EEEEEE'}
      />
      <path
        d={
          'M159.945 94.4964V104.85C159.956 107.986 156.311 111.133 148.977 113.566C134.315 118.432 110.391 118.636 95.0699 114.087C94.8161 114.012 94.5677 113.937 94.3247 113.861C86.5589 111.423 82.6544 108.196 82.6544 104.952L82.6436 94.5985C82.6436 97.8367 86.5481 101.07 94.3139 103.502C94.5623 103.578 94.8107 103.653 95.0591 103.728C110.386 108.276 134.309 108.072 148.966 103.207C156.295 100.774 159.945 97.6273 159.94 94.4911L159.945 94.4964Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M147.524 85.3616C147.778 85.4368 148.026 85.512 148.275 85.5926C163.558 90.3774 163.871 98.2609 148.971 103.207C134.309 108.072 110.385 108.276 95.0643 103.728C94.8105 103.653 94.5621 103.577 94.319 103.502C79.0358 98.7174 78.7226 90.8339 93.6224 85.8879C108.279 81.0225 132.203 80.8184 147.529 85.367L147.524 85.3616Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M166.485 81.1888V91.5426C166.496 94.6788 162.85 97.8258 155.516 100.258C140.854 105.124 116.93 105.328 101.609 100.779C101.356 100.704 101.107 100.629 100.864 100.554C93.0984 98.1211 89.1992 94.8936 89.1938 91.65V81.2962C89.1938 84.5345 93.093 87.7673 100.853 90.2C101.102 90.2752 101.35 90.3504 101.599 90.4256C116.925 94.9742 140.849 94.7701 155.506 89.9047C162.834 87.472 166.485 84.325 166.479 81.1888H166.485Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M127.694 104.882C118.291 104.882 108.884 103.776 101.377 101.547C101.123 101.472 100.87 101.397 100.616 101.316C92.7312 98.8461 88.3838 95.4145 88.3784 91.65V81.2962H88.373C88.373 80.8505 88.7349 80.4907 89.1831 80.4907C89.6313 80.4907 89.9932 80.8505 89.9932 81.2962C89.9932 84.2606 94.0435 87.2249 101.096 89.4321C101.339 89.5073 101.582 89.5825 101.831 89.6576C116.93 94.1418 140.892 93.9109 155.252 89.1421C161.878 86.9403 165.675 84.0457 165.669 81.1942C165.669 80.7484 166.031 80.3886 166.479 80.3886C166.928 80.3886 167.289 80.7484 167.289 81.1942L167.3 91.5479C167.3 95.1943 163.212 98.5561 155.77 101.026C148.037 103.593 137.868 104.888 127.688 104.888L127.694 104.882ZM89.9986 85.1681V91.6446C90.0094 94.609 94.0543 97.5733 101.107 99.7805C101.35 99.8557 101.593 99.9309 101.842 100.006C116.941 104.49 140.903 104.259 155.263 99.4905C161.889 97.2887 165.686 94.3942 165.68 91.5426V85.0607C163.839 87.182 160.469 89.1045 155.765 90.6672C140.892 95.6025 116.995 95.8334 101.366 91.1935C101.113 91.1183 100.859 91.0431 100.605 90.9626C95.5286 89.373 91.9211 87.386 89.9932 85.1681H89.9986Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M154.064 72.0595C154.318 72.1347 154.566 72.2099 154.815 72.2904C170.098 77.0753 170.411 84.9588 155.511 89.9048C140.849 94.7702 116.925 94.9743 101.604 90.4257C101.351 90.3505 101.102 90.2753 100.859 90.2001C85.5759 85.4153 85.2626 77.5318 100.162 72.5804C114.819 67.715 138.743 67.5109 154.069 72.0595H154.064Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M127.683 94.5285C118.281 94.5285 108.873 93.4222 101.367 91.1936C101.113 91.1184 100.859 91.0432 100.605 90.9627C92.7476 88.5031 88.4003 85.0823 88.3679 81.3285C88.3355 77.6714 92.429 74.2881 99.8978 71.8124C114.771 66.8772 138.662 66.6463 154.291 71.2808C154.55 71.356 154.798 71.4365 155.052 71.5117C162.91 73.9713 167.257 77.3921 167.29 81.1459C167.322 84.803 163.229 88.1809 155.76 90.6619C148.026 93.2289 137.857 94.5231 127.678 94.5231L127.683 94.5285ZM128.012 69.6106C117.946 69.6106 107.88 70.8619 100.411 73.343C93.7629 75.5501 89.9664 78.4554 89.988 81.3177C90.015 84.2714 94.0599 87.2304 101.091 89.4268C101.334 89.502 101.577 89.5771 101.826 89.6523C116.925 94.1365 140.887 93.9056 155.247 89.1368C161.895 86.9296 165.691 84.0243 165.664 81.162C165.637 78.2084 161.592 75.2494 154.561 73.053C154.318 72.9778 154.075 72.9026 153.821 72.8274C146.574 70.6793 137.29 69.6106 128.002 69.6106H128.012Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M137.317 73.9069L135.465 75.5179C137.144 75.7328 138.408 75.9852 139.385 76.2483C140.066 76.4309 140.606 76.6135 141.054 76.7961L137.414 78.7347C136.945 78.5682 136.313 78.3105 135.373 78.0634C134.509 77.8325 133.386 77.6016 131.89 77.4459C129.076 77.1451 127.575 77.5586 126.981 78.0742C126.284 78.681 127.359 79.2288 130.372 80.2062C134.644 81.5272 135.567 82.7624 133.666 84.411C131.793 86.0436 127.461 87.1445 122.056 86.9726L119.906 88.8415L115.661 88.385L117.665 86.6451C116.066 86.441 114.592 86.1456 113.399 85.8288C112.475 85.5818 111.719 85.3186 111.201 85.0662L114.965 83.0631C115.634 83.3692 116.498 83.6914 117.546 83.9707C118.594 84.2499 119.825 84.4916 121.235 84.642C123.703 84.9051 125.857 84.6849 126.7 83.9492C127.499 83.2511 126.619 82.6657 123.94 81.8656C120.063 80.7056 117.951 79.4543 119.993 77.6768C121.85 76.0657 125.987 75.0883 131.069 75.2011L133.072 73.4612L137.317 73.9176V73.9069Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M202.979 63.309C239.435 109.08 229.003 176.866 194.242 208.915C188.225 214.463 169.99 229.164 144.691 230.107C118.2 231.095 85.7448 216.875 71.9961 194.858C67.3174 187.364 64.6018 178.642 55.3186 174.88C47.9497 171.892 38.8325 172.999 31.8283 165.725C31.8283 165.725 30.3238 164.156 28.9921 162.04C6.1883 125.827 34.2869 37.6403 99.7938 22.9333C140.031 13.8995 180.916 35.6105 202.978 63.3113L202.979 63.309Z'
        }
        stroke={'#C5C5C5'}
        strokeDasharray={'5 5'}
      />
      <path
        d={
          'M56.0668 88.2323C54.799 88.2323 53.7681 87.2068 53.7681 85.9457C53.7681 84.6846 54.799 83.6591 56.0668 83.6591C57.3346 83.6591 58.3655 84.6846 58.3655 85.9457C58.3655 87.2068 57.3346 88.2323 56.0668 88.2323ZM56.0668 84.4334C55.2285 84.4334 54.5491 85.1119 54.5491 85.9431C54.5491 86.7744 55.2311 87.4528 56.0668 87.4528C56.9024 87.4528 57.5845 86.7744 57.5845 85.9431C57.5845 85.1119 56.9024 84.4334 56.0668 84.4334Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M58.3706 76.6503C58.1544 76.6503 57.98 76.4768 57.98 76.2619V74.255C57.98 74.0401 58.1544 73.8666 58.3706 73.8666C58.5867 73.8666 58.7612 74.0401 58.7612 74.255V76.2619C58.7612 76.4768 58.5867 76.6503 58.3706 76.6503Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M58.3706 81.3292C58.1544 81.3292 57.98 81.1557 57.98 80.9408V78.9313C57.98 78.7164 58.1544 78.5429 58.3706 78.5429C58.5867 78.5429 58.7612 78.7164 58.7612 78.9313V80.9408C58.7612 81.1557 58.5867 81.3292 58.3706 81.3292Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M57.028 77.9861H55.0073C54.7912 77.9861 54.6167 77.8126 54.6167 77.5977C54.6167 77.3827 54.7912 77.2092 55.0073 77.2092H57.028C57.2441 77.2092 57.4186 77.3827 57.4186 77.5977C57.4186 77.8126 57.2441 77.9861 57.028 77.9861V77.9861Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M61.7316 77.9861H59.7109C59.4948 77.9861 59.3203 77.8126 59.3203 77.5977C59.3203 77.3827 59.4948 77.2092 59.7109 77.2092H61.7316C61.9477 77.2092 62.1222 77.3827 62.1222 77.5977C62.1222 77.8126 61.9477 77.9861 61.7316 77.9861Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M203.495 170.118C203.485 170.038 203.5 169.955 203.539 169.879C209.273 159.521 212.356 147.687 214.404 138.534C214.451 138.324 214.66 138.194 214.871 138.239C215.082 138.286 215.213 138.493 215.168 138.703C213.111 147.906 210.006 159.809 204.226 170.252C204.123 170.441 203.885 170.507 203.695 170.404C203.582 170.344 203.511 170.233 203.495 170.115L203.495 170.118Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M197.04 173.332C197.028 173.25 197.043 173.167 197.086 173.091C202.295 163.901 207.099 150.447 209.152 141.908C209.203 141.7 209.414 141.569 209.623 141.62C209.832 141.67 209.963 141.879 209.913 142.087C207.848 150.678 203.014 164.217 197.768 173.472C197.662 173.658 197.424 173.725 197.236 173.619C197.125 173.556 197.058 173.45 197.042 173.332L197.04 173.332Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M209.765 138.344C209.758 138.295 209.76 138.248 209.774 138.196C210.463 135.449 211.042 132.657 211.493 129.894C211.527 129.683 211.728 129.539 211.94 129.573C212.153 129.607 212.298 129.807 212.264 130.018C211.808 132.801 211.225 135.615 210.53 138.384C210.477 138.593 210.266 138.718 210.056 138.668C209.896 138.629 209.786 138.495 209.765 138.344V138.344Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M185.326 188.014C185.304 187.852 185.389 187.687 185.545 187.611L186.658 187.082C186.853 186.99 187.085 187.071 187.18 187.265C187.272 187.459 187.19 187.689 186.996 187.783L185.882 188.312C185.687 188.404 185.455 188.323 185.36 188.129C185.342 188.093 185.332 188.052 185.326 188.014Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M181.428 189.867C181.406 189.705 181.491 189.54 181.646 189.464L182.76 188.935C182.955 188.843 183.187 188.924 183.281 189.118C183.376 189.311 183.292 189.542 183.097 189.636L181.984 190.166C181.789 190.257 181.557 190.176 181.462 189.983C181.444 189.946 181.433 189.905 181.428 189.867Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M182.733 186.185C182.711 186.024 182.794 185.858 182.952 185.782C183.147 185.69 183.379 185.772 183.473 185.965L184.006 187.072C184.098 187.265 184.016 187.496 183.821 187.59C183.626 187.682 183.394 187.601 183.3 187.407L182.767 186.301C182.749 186.264 182.739 186.224 182.733 186.185Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M184.599 190.061C184.577 189.899 184.66 189.734 184.818 189.657C185.013 189.566 185.245 189.647 185.34 189.84L185.872 190.947C185.964 191.141 185.882 191.371 185.688 191.465C185.492 191.557 185.261 191.476 185.166 191.282L184.634 190.176C184.616 190.139 184.605 190.099 184.599 190.061Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M53.6539 191.238C54.5514 192.568 55.1965 194.08 55.521 195.706L55.0307 195.804C55.1806 196.555 55.2592 197.333 55.2592 198.128C55.2592 198.924 55.1806 199.701 55.0307 200.452L55.521 200.55C55.1965 202.177 54.5514 203.689 53.6539 205.018L53.2395 204.739C52.3657 206.033 51.2426 207.147 49.9384 208.013L50.2149 208.429C48.881 209.315 47.3654 209.951 45.7355 210.271L45.6392 209.78C44.8872 209.928 44.1094 210.005 43.313 210.005C42.5165 210.005 41.7387 209.928 40.9867 209.78L40.8904 210.271C39.2605 209.951 37.7449 209.315 36.411 208.429L36.6875 208.013C35.3834 207.147 34.2602 206.033 33.3865 204.739L32.9721 205.018C32.0746 203.689 31.4294 202.177 31.1049 200.55L31.5952 200.452C31.4454 199.701 31.3667 198.924 31.3667 198.128C31.3667 197.333 31.4454 196.555 31.5952 195.804L31.1049 195.706C31.4294 194.08 32.0746 192.568 32.9721 191.238L33.3865 191.518C34.2602 190.224 35.3834 189.11 36.6875 188.244L36.411 187.827C37.7449 186.942 39.2605 186.306 40.8904 185.986L40.9867 186.477C41.7387 186.329 42.5165 186.252 43.313 186.252C44.1094 186.252 44.8872 186.329 45.6392 186.477L45.7355 185.986C47.3654 186.306 48.881 186.942 50.2149 187.827L49.9384 188.244C51.2426 189.11 52.3657 190.224 53.2395 191.518L53.6539 191.238Z'
        }
        stroke={'#C5C5C5'}
        strokeDasharray={'5 5'}
      />
      <path
        d={
          'M240.707 70.5415C242.777 71.3926 244.433 73.0367 245.293 75.0929L244.832 75.2858C245.223 76.2202 245.439 77.2455 245.439 78.3222C245.439 79.3989 245.223 80.4242 244.832 81.3586L245.293 81.5515C244.433 83.6077 242.777 85.2518 240.707 86.1029L240.517 85.6405C239.581 86.0256 238.553 86.2383 237.475 86.2383C236.397 86.2383 235.37 86.0256 234.433 85.6405L234.243 86.1029C232.173 85.2518 230.517 83.6077 229.657 81.5515L230.118 81.3586C229.728 80.4242 229.512 79.3989 229.512 78.3222C229.512 77.2455 229.728 76.2202 230.118 75.2858L229.657 75.0929C230.517 73.0367 232.173 71.3926 234.243 70.5415L234.433 71.0039C235.37 70.6188 236.397 70.4061 237.475 70.4061C238.553 70.4061 239.581 70.6188 240.517 71.0039L240.707 70.5415Z'
        }
        stroke={'#C5C5C5'}
        strokeDasharray={'5 5'}
      />
    </svg>
  );
};
