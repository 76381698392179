import React, { useContext } from 'react';
import type { Document } from '@lama/document-service-client';
import { Dialog, Divider, Slide } from '@mui/material';
import type { FC, Ref } from 'react';
import type { TransitionProps } from '@mui/material/transitions';
import { ApplicationContext } from '../../Application/ApplicationContext';
import { SpreadingDocumentProvider } from '../SpreadingDocumentContext';
import { SpreadingDialogContent } from './SpreadingContent';
import { SpreadingToolbar } from './SpreadingToolbar/SpreadingToolbar';

interface DocumentFinancialDataDialogProps {
  toggleDialog: () => void;
  open: boolean;
  title: string;
  requirementDocuments: Document[];
  applicationId: string;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: Ref<unknown>,
  ) => <Slide direction={'up'} ref={ref} {...props} />,
);

export const SpreadingDialog: FC<DocumentFinancialDataDialogProps> = ({ open, toggleDialog, requirementDocuments }) => {
  const {
    application: { id: applicationId },
    opportunity,
  } = useContext(ApplicationContext);

  return (
    <Dialog open={open} onClose={toggleDialog} fullScreen TransitionComponent={Transition} PaperProps={{ sx: { overflow: 'hidden' } }}>
      {open && requirementDocuments.length ? (
        <SpreadingDocumentProvider documents={requirementDocuments} applicationId={applicationId}>
          <SpreadingToolbar
            documents={requirementDocuments}
            onClose={toggleDialog}
            applicationId={applicationId}
            opportunity={opportunity}
          />
          <Divider />
          <SpreadingDialogContent />
        </SpreadingDocumentProvider>
      ) : null}
    </Dialog>
  );
};
