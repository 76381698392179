import { Tooltip } from '@lama/app-components';
import type { User } from '@lama/user-service-client';
import { formatValue } from '@lama/data-formatters';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { personFullName } from '@lama/selectors';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { UnderwritingNoteType } from '@lama/contracts';

interface NoteTitleProps {
  tabName?: string;
  sectionName?: string;
  user?: User;
  createdAt?: string;
  type: UnderwritingNoteType;
}

const DotDivider = () => (
  <Text variant={'body2'} color={greyPalette[500]}>
    {' • '}
  </Text>
);
const ChevronDivider: FC<{ color?: string }> = () => (
  <Text variant={'body2'} color={greyPalette[500]}>
    {' > '}
  </Text>
);

export const NoteTitle: FC<NoteTitleProps> = ({ tabName, sectionName, user, createdAt, type }) => {
  const titleParts = useMemo(() => {
    const parts = [];

    if (user) {
      parts.push(
        <Flex gap={1}>
          {type === 'cleared' ? (
            <Text variant={'body2'} color={'secondary'}>
              {'Flag Cleared by '}
            </Text>
          ) : null}
          <Tooltip title={personFullName(user)}>
            <Text variant={'body2'} color={'secondary'}>
              {`${user.firstName} ${user.lastName[0]}.`}
            </Text>
          </Tooltip>
        </Flex>,
      );
    }

    if (createdAt) {
      const noteDate = formatValue(createdAt, 'date');
      const noteDateTime = formatValue(createdAt, 'datetime');
      parts.push(
        <Tooltip title={noteDateTime}>
          <Text variant={'body2'} color={'secondary'}>
            {noteDate}
          </Text>
        </Tooltip>,
      );
    }
    return parts.flatMap((part, index) => [part, index < parts.length - 1 ? <DotDivider key={index} /> : null]);
  }, [createdAt, type, user]);

  const sectionParts = useMemo(() => {
    if (!tabName && !sectionName) {
      return null;
    }
    return (
      <Flex gap={1}>
        {tabName ? (
          <Text variant={'body2'} color={greyPalette[500]}>
            {tabName}
          </Text>
        ) : null}
        {!!tabName && !!sectionName ? <ChevronDivider /> : null}
        {sectionName ? (
          <Text variant={'body2'} color={greyPalette[500]}>
            {sectionName}
          </Text>
        ) : null}
      </Flex>
    );
  }, [sectionName, tabName]);

  return (
    <Flex gap={2} alignItems={'center'} width={'100%'}>
      {titleParts}
      {sectionParts}
    </Flex>
  );
};
