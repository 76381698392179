import React, { useMemo } from 'react';
import type { FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flex } from '@lama/design-system';
import type { SpreadRow } from '@lama/spreads-generator-client';
import type { SpreadTableProps } from '../SpreadTable/types';
import { getDisplayedPeriods } from '../getDisplayedPeriods';
import { getSpreadRows } from '../../utils/getSpreadRows';
import { TableHeader } from './TableHeader';
import { PrintSpreadTableBody } from './PrintSpreadRow';
import './printSpreadTable.css';

export const PrintSpreadTable: FC<SpreadTableProps> = ({
  spreadsSettings,
  spreadConfig: { tableConfig },
  entities,
  properties,
  financials,
  spreadName,
  periodDisplayFormat = 'income statement',
  showPeopleNames,
}) => {
  const { showFormTypeInSpreadColumnHeader } = useFlags();

  const displayedPeriods = useMemo(
    () => getDisplayedPeriods(financials ?? [], spreadsSettings ?? [], spreadName),
    [financials, spreadsSettings, spreadName],
  );

  // TODO: replace this with the spread from the backend
  const spreadRows: SpreadRow[] = useMemo(
    () =>
      getSpreadRows({
        entities,
        financials,
        properties,
        displayedPeriods,
        spreadConfigurations: tableConfig,
        showPeopleNames,
      }),
    [entities, financials, properties, displayedPeriods, tableConfig, showPeopleNames],
  );

  return (
    <Flex flexDirection={'column'} height={'100%'}>
      <table className={'spread-table'} border={1}>
        <TableHeader
          periods={displayedPeriods}
          spreadRows={spreadRows}
          showFormTypeInSpreadColumnHeader={showFormTypeInSpreadColumnHeader}
          periodDisplayFormat={periodDisplayFormat}
        />
        <PrintSpreadTableBody spreadRows={spreadRows} displayedPeriods={displayedPeriods} />
      </table>
    </Flex>
  );
};
