import React from 'react';
import type { CreateCondition } from '@lama/clients';
import type { Condition } from '@lama/contracts';
import type { ConditionConfiguration } from '@lama/conditions';
import type { ConditionWithInequalityMatcher } from './InequalityCondition';
import { InequalityCondition } from './InequalityCondition';
import type { ConditionWithSelectMatcher } from './SelectCondition';
import { SelectCondition } from './SelectCondition';
import type { ConditionWithRangeMatcher } from './RangeCondition';
import { RangeCondition } from './RangeCondition';

const isCondition = (condition?: Condition | CreateCondition): condition is Condition =>
  !!condition && (condition as Condition).conditionMatcher !== undefined;

export const conditionByTypeComponent = (
  configuration: ConditionConfiguration,
  onConditionChange: (condition: Condition | CreateCondition) => void,
  condition?: Condition | CreateCondition,
) => {
  if (isCondition(condition) && condition.conditionMatcher.type !== configuration.type) {
    return null;
  }
  switch (configuration.type) {
    case 'range': {
      return (
        <RangeCondition
          key={configuration.displayName}
          condition={condition as ConditionWithRangeMatcher}
          configuration={configuration}
          onChange={onConditionChange}
        />
      );
    }
    case 'greaterThan': {
      return (
        <InequalityCondition
          key={configuration.displayName}
          condition={condition as ConditionWithInequalityMatcher}
          configuration={configuration}
          onChange={onConditionChange}
        />
      );
    }
    case 'lessThan': {
      return (
        <InequalityCondition
          key={configuration.displayName}
          condition={condition as ConditionWithInequalityMatcher}
          configuration={configuration}
          onChange={onConditionChange}
        />
      );
    }
    case 'noneOf': {
      return (
        <SelectCondition
          key={configuration.displayName}
          condition={condition as ConditionWithSelectMatcher}
          configuration={configuration}
          onChange={onConditionChange}
        />
      );
    }
    case 'anyOf': {
      return (
        <SelectCondition
          key={configuration.displayName}
          condition={condition as ConditionWithSelectMatcher}
          configuration={configuration}
          onChange={onConditionChange}
        />
      );
    }
    default: {
      return null;
    }
  }
};
