import type { Editor, UseEditorOptions } from '@tiptap/react';
import { useEditor, mergeAttributes, Extension } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import MentionPlugin from '@tiptap/extension-mention';

import type { SuggestionProps } from '@tiptap/suggestion';
import Fuse from 'fuse.js';
import type { MutableRefObject } from 'react';
import type { SuggestionOption } from './types';

const extensions = [StarterKit];

const filterAndSortNames = (query: string, names: SuggestionOption[]): SuggestionOption[] => {
  const fuse = new Fuse(names, {
    keys: ['label'],
    threshold: 0.3,
  });

  const result = fuse.search(query);

  return result.map(({ item }) => item) ?? [];
};

export interface MentionSuggestionsHandle {
  onKeyDown: (event: KeyboardEvent) => boolean;
}

export const mentionListKeyStrokes = ['ArrowUp', 'ArrowDown', 'Enter'];

interface EditModeTextProps extends UseEditorOptions {
  enableMentions?: boolean;
  currentUserId?: string;
  suggestions?: SuggestionOption[];
  suggestionsListRef?: MutableRefObject<any>;
  setSuggestionProps?: (props: SuggestionProps | null) => void;
  submit?: (editor: Editor) => Promise<void>;
}
export const useRichEditor = ({
  suggestionsListRef,
  enableMentions,
  currentUserId,
  suggestions = [],
  setSuggestionProps,
  submit,
  ...restOfProps
}: EditModeTextProps) =>
  useEditor({
    extensions: [
      Extension.create({
        addKeyboardShortcuts: () => ({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Cmd-Enter': ({ editor }) => {
            void submit?.(editor);
            return true;
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Ctrl-Enter': ({ editor }) => {
            void submit?.(editor);
            return true;
          },
        }),
      }),
      ...extensions,
      ...(enableMentions
        ? [
            MentionPlugin.configure({
              renderHTML: ({ node, options }) => {
                const isCurrentUser = node.attrs.id === currentUserId;
                const customClass = isCurrentUser ? 'mention-current-user' : 'mention';

                return ['span', mergeAttributes(options.HTMLAttributes, { class: customClass }), `${node.attrs.label}`];
              },
              suggestion: {
                items: ({ query }) => (query.length > 0 ? filterAndSortNames(query, suggestions ?? []) : suggestions ?? []),
                render: () => ({
                  onKeyDown: ({ event }) => {
                    if (mentionListKeyStrokes.includes(event.key)) {
                      event.preventDefault();
                      event.stopPropagation();

                      return suggestionsListRef?.current?.onKeyDown(event);
                    }
                    return false;
                  },
                  onStart: (props) => {
                    setSuggestionProps?.(props);
                  },
                  onUpdate: (props) => {
                    setSuggestionProps?.(props);
                  },
                  onExit: () => {
                    setSuggestionProps?.(null);
                  },
                }),
              },
            }),
          ]
        : []),
    ],
    ...restOfProps,
  });
