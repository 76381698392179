import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../../../ApplicationContext';
import type { CreditMemoSectionProps } from '../creditMemoSectionProps';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { getPrimaryBusinessRelation } from '../../../../../shared/utils/relatedBusinesses';
import { getSpreadIdBySectionAndRelations } from '../../../shared/sectionSpreadId';
import { BusinessBalanceSheetSpread } from './BusinessBalanceSheetSpread';

const note = 'Business Balance Sheet Details';

export const MultiBusinessBalanceSheetSection: FC<CreditMemoSectionProps> = ({ printMode, section }) => {
  const { application } = useContext(ApplicationContext);

  const relevantBusinesses = useMemo(
    () => application.relatedBusinesses.filter((r) => !['property', undefined].includes(getPrimaryBusinessRelation(r.relations))),
    [application.relatedBusinesses],
  );

  return (
    <Flex flexDirection={'column'} gap={8}>
      {relevantBusinesses.map((relatedBusiness) => (
        <BusinessBalanceSheetSpread
          key={relatedBusiness.business.id}
          application={application}
          business={relatedBusiness}
          printMode={!!printMode}
          spreadId={getSpreadIdBySectionAndRelations(section, relatedBusiness.relations)}
        />
      ))}
      <InlineNote title={note} noteName={note} />
    </Flex>
  );
};
