import React from 'react';
import type { FC } from 'react';
import type { ProductApiModel } from '@lama/contracts';
import { Outlet, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProductSettingsHeader } from './ProductSettingsHeader';
import { ProductSettingsProvider } from './ProductSettingsContext';
import { ProductSettingsHeaderV2 } from './ProductSettingsHeaderV2';

export const ProductSettings: FC<{
  product: ProductApiModel;
}> = ({ product }) => {
  const location = useLocation();
  const { enableConditionsV2 } = useFlags();
  return (
    <ProductSettingsProvider product={product} returnToAfterSave={location.state?.returnTo}>
      {enableConditionsV2 ? (
        <ProductSettingsHeaderV2 productPrettyName={product.prettyName} />
      ) : (
        <ProductSettingsHeader productPrettyName={product.prettyName} />
      )}
      <Outlet />
    </ProductSettingsProvider>
  );
};
