import React, { Suspense, useCallback, useState } from 'react';
import type { FC } from 'react';
import type { AccountData } from '@lama/contracts';
import { Box, Stack } from '@mui/material';
import { LoadingPage } from '@lama/app-components';
import type { ChartType, Granularity } from '../../types';
import { isGranularity, isChart } from '../../types';
import { ChartsToggle } from './ChartsToggle';
import { GranularityToggle } from './GranularityToggle';
import { Balances } from './Balances';
import { Transactions } from './Transactions';

export const Charts: FC<{ account: AccountData }> = ({ account }) => {
  const { balances, transactions } = account;
  const [chart, setChart] = useState<ChartType>('balance');
  const [granularity, setGranularity] = useState<Granularity>('month');

  const handleGranularityChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: string | null) => {
      if (value && isGranularity(value)) {
        setGranularity(value);
      }
    },
    [setGranularity],
  );

  const handleChartChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: string | null) => {
      if (value && isChart(value)) {
        setChart(value);
      }
    },
    [setChart],
  );

  return (
    <Suspense
      fallback={
        <Box height={'470px'} width={'90%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <LoadingPage />
        </Box>
      }
    >
      <Stack spacing={3}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <ChartsToggle value={chart} toggleChartView={handleChartChange} />
          {chart !== 'balance' && <GranularityToggle value={granularity} toggleGranularityScale={handleGranularityChange} />}
        </Stack>
        {chart === 'balance' ? (
          <Balances balances={balances} granularity={'month'} />
        ) : (
          <Transactions transactions={transactions} granularity={granularity} />
        )}
      </Stack>
    </Suspense>
  );
};
