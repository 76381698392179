import React from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import type { UnderwritingSectionProps } from '../underwritingSectionProps';
import { CombinedPropertiesCashFlowSpreadSegment } from '../../../shared/Spreads/CombinedPropertiesCashflowSpreadSegment';

const note = 'Combined Properties Cash Flow Details';

export const CombinedPropertiesCashFlowSection: FC<UnderwritingSectionProps> = ({ printMode, section }) => (
  <Flex flexDirection={'column'} gap={8}>
    <CombinedPropertiesCashFlowSpreadSegment printMode={!!printMode} spreadId={section.spreadId} />
    <InlineNote title={note} noteName={note} />
  </Flex>
);
