import type { EvaluatedOpportunityRequirement, ProductApiModel, UnderwritingNote } from '@lama/contracts';
import type { UnderwritingNoteRelatedSectionType } from '../../components/Application/shared/noteUtils';

export interface NoteRelation {
  sectionName: string;
  tabName?: string;
  relatedItemId?: string;
  relatedItemType?: UnderwritingNoteRelatedSectionType;
}

export const getNoteRelatedItems = (
  note: UnderwritingNote,
  opportunityRequirements: EvaluatedOpportunityRequirement[],
  product: ProductApiModel,
): NoteRelation => {
  if (note.title) {
    return { sectionName: note.title };
  }

  const creditMemoTitle = product.creditMemoConfiguration?.sections.find((section) => section.id === note.relatedSection)?.prettyName;
  if (creditMemoTitle) {
    return {
      sectionName: creditMemoTitle,
      tabName: 'Credit Memo',
      relatedItemId: note.relatedSection,
      relatedItemType: 'creditMemoSection',
    };
  }

  const underwritingTitle = product.underwritingConfiguration
    ?.flatMap(({ sections }) => sections)
    .find((section) => section.id === note.relatedUnderwritingSectionId)?.prettyName;

  if (underwritingTitle) {
    return {
      sectionName: underwritingTitle,
      tabName: 'Underwriting',
      relatedItemId: note.relatedUnderwritingSectionId,
      relatedItemType: 'underwriting',
    };
  }

  const applicationRequirementsTitle = opportunityRequirements?.find((requirement) => requirement.id === note.relatedRequirementId)?.name;

  if (applicationRequirementsTitle) {
    return {
      sectionName: applicationRequirementsTitle,
      tabName: 'Application',
      relatedItemId: note.relatedRequirementId,
      relatedItemType: 'requirement',
    };
  }

  return { sectionName: '' };
};
