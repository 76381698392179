import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import type { FormikHelpers, FormikProps } from 'formik';
import { Formik } from 'formik';
import * as yup from 'yup';
import { isEmpty } from 'lodash-es';
import type { Entity } from '@lama/common-types';
import type { SourcedProperty } from '@lama/properties';
import { getApplicationEntityByType, getSourcedProperty } from '@lama/properties';
import {
  getInitialFormValues,
  GenericPropertiesGrid,
  formValuesToEntityPartial,
  createUpdatePayload,
  getValidationSchema,
  usePrompt,
  GenericPropertiesProvider,
  getBusinessIdByEntity,
} from '@lama/app-components';
import { Flex } from '@lama/design-system';
import type { OpportunityApiModel } from '@lama/clients';
import { ApplicationContext } from '../../../ApplicationContext';
import { useUpdateApplicationMutation } from '../../../../../shared/hooks/react-query/application/useUpdateApplication';
import { useUpdateBusiness } from '../../../../../shared/hooks/react-query/business/useUpdateBusiness';
import { useUpdatePerson } from '../../../../../shared/hooks/react-query/people/useUpdatePerson';
import { SaveFormButton } from '../../../shared/SaveFormButton';
import type { RequirementScreenProps } from './types';
import { customComponents } from './customComponentsMap';

export const GenericForm = ({
  formikProps: { handleSubmit, dirty, isValid, setFieldValue },
  shouldShowSave,
  entityType,
  properties,
  submitted,
  updating,
  fieldsOverrides,
  entity,
  opportunity,
}: {
  formikProps: FormikProps<any>;
  shouldShowSave: boolean;
  entityType: Entity;
  properties: SourcedProperty[];
  submitted: boolean;
  updating: boolean;
  fieldsOverrides?: Record<string, any>;
  entity: Record<string, any>;
  opportunity: OpportunityApiModel;
}) => {
  useEffect(() => {
    if (!isEmpty(fieldsOverrides)) {
      Object.entries(fieldsOverrides).forEach(([key, value]) => {
        void setFieldValue(key, value);
      });
    }
  }, [fieldsOverrides, setFieldValue]);

  usePrompt('You have unsaved changes, Are you sure you want to leave without saving?', dirty);

  return (
    <Flex flexDirection={'column'} gap={8}>
      <GenericPropertiesGrid
        properties={properties}
        entityType={entityType}
        submitted={submitted}
        entity={entity}
        application={opportunity.application}
        opportunity={opportunity}
      />
      {shouldShowSave ? <SaveFormButton loading={updating} submit={handleSubmit} disabled={!dirty || !isValid} /> : null}
    </Flex>
  );
};

export const GenericRequirementForm: FC<RequirementScreenProps & { fieldsOverrides: Record<string, any> }> = ({
  requirement,
  fieldsOverrides = {},
}) => {
  const { application, opportunity, product } = useContext(ApplicationContext);
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(application.id, opportunity.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(opportunity.id);
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(opportunity.id);

  const [submitted, setSubmitted] = useState(false);
  const shouldShowSave = useMemo(() => requirement.properties.some((p) => p.type !== 'table'), [requirement.properties]);

  const requirementEntity = useMemo(() => {
    const entities = getApplicationEntityByType(application, requirement.entityType, requirement.entityGroups);
    return entities.find(({ id }) => id === requirement.entityId)!;
  }, [application, requirement.entityGroups, requirement.entityId, requirement.entityType]);

  const propertiesWithDecidedSource = useMemo<SourcedProperty[]>(() => {
    const yearsBack = new Date().getUTCFullYear() - opportunity.referenceYear;

    return requirement.properties.map((p) => getSourcedProperty(p, requirementEntity, yearsBack)) ?? [];
  }, [opportunity.referenceYear, requirement.properties, requirementEntity]);

  const initialFormValues = useMemo(
    () => getInitialFormValues(propertiesWithDecidedSource, requirementEntity),
    [propertiesWithDecidedSource, requirementEntity],
  );

  const onSubmit = useCallback(
    async (values: Record<string, any>, { resetForm }: FormikHelpers<any>) => {
      if (!requirement) {
        return;
      }

      setSubmitted(true);
      resetForm({ values });

      const { entityType, entityId, properties } = requirement;
      const entityPartial = formValuesToEntityPartial(values, initialFormValues, properties);

      const updatePayload = createUpdatePayload(application, entityType, entityId, entityPartial);

      if (!isEmpty(updatePayload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: updatePayload.updateApplicationPayload });
      }

      if (!isEmpty(updatePayload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, entityType, entityId);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: updatePayload.updateBusinessPayload });
        }
      }

      if (!isEmpty(updatePayload.updatePersonPayload)) {
        await updatePerson({ personId: entityId, updatePersonPayload: updatePayload.updatePersonPayload });
      }
    },
    [application, initialFormValues, requirement, updateApplication, updateBusiness, updatePerson],
  );

  const validationSchema = useMemo(
    () => (requirement ? getValidationSchema({ properties: requirement.properties }) : yup.object()),
    [requirement],
  );

  return (
    <GenericPropertiesProvider customComponents={customComponents} customSourceToValues={product.customOptionsLists ?? {}}>
      <Formik validationSchema={validationSchema} initialValues={initialFormValues} onSubmit={onSubmit}>
        {(formikProps) => (
          <GenericForm
            formikProps={formikProps}
            shouldShowSave={shouldShowSave}
            entityType={requirement.entityType}
            properties={propertiesWithDecidedSource}
            submitted={submitted}
            updating={updatingApplication || updatingBusiness || updatingPerson}
            fieldsOverrides={fieldsOverrides}
            opportunity={opportunity}
            entity={requirementEntity ?? {}}
          />
        )}
      </Formik>
    </GenericPropertiesProvider>
  );
};
