/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { FC } from 'react';
import type { Document } from '@lama/document-service-client';
import { DocumentBox } from '@lama/app-components';
import { useDeleteDocumentQuery } from '../../../../shared/components/DocumentBox/hooks/useDeleteDocumentQuery';
import { useUpdateDocumentMutation } from '../OpportunityRequirements/RequirementScreens/financials/hooks/useUpdateDocumentMutation';

interface MiscellaneousDocumentBoxProps {
  description: string;
  document: Document;
}

export const MiscellaneousDocumentBox: FC<MiscellaneousDocumentBoxProps> = ({ description, document }) => {
  const { mutate: deleteDocument, isPending: isDeletingDocument } = useDeleteDocumentQuery();
  const { mutate: updateDocument, isPending: isUpdatingDocument } = useUpdateDocumentMutation({
    applicationId: document.applicationId,
  });
  const { downloadDocumentsEnabled } = useFlags();

  const onDeleteDocument = useCallback(() => {
    deleteDocument({ documentId: document.id });
  }, [deleteDocument, document]);

  const onUpdateDocumentName = useCallback(
    async (name: string) => {
      if (name !== document.filename) {
        updateDocument({
          documentId: document.id,
          payload: { filename: name },
        });
      }
    },
    [document.filename, document.id, updateDocument],
  );

  return (
    <DocumentBox
      description={description}
      document={document}
      loading={isDeletingDocument || isUpdatingDocument}
      onDocumentRemoved={onDeleteDocument}
      downloadEnabled={downloadDocumentsEnabled}
      onChangeDocumentName={onUpdateDocumentName}
    />
  );
};
