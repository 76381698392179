import React, { useContext, type FC } from 'react';
import { useAsyncFn } from 'react-use';
import { displayToast } from '@lama/app-components';
import type { CreditAdminReportExportRow } from '@lama/reports-service-client';
import { creditAdminReportExcelSchema } from '@lama/reports-service-client';
import { format } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReportsFilterContext } from '../ReportsFilterContext';
import { ReportCard } from '../ReportCard';
import { useDownloadReportMutation } from '../../hooks/downloadReportMutation';
import { useApplicationsByStatusChartData } from '../Charts/hooks/useApplicationsByStatusChartData';
import { ApplicationsByStatusChart } from '../Charts/ApplicationsByStatusBarChart';

const REPORT_NAME = 'Credit Admin';

export const CreditAdminReport: FC = () => {
  const { partnerId, productId, startDate, endDate } = useContext(ReportsFilterContext);
  const { data: chartData, isPending: loadingChartData } = useApplicationsByStatusChartData({ endDate, startDate, productId, partnerId });
  const { getPersistCreditAdminReport } = useFlags();
  const { mutateAsync: downloadReport } = useDownloadReportMutation(getPersistCreditAdminReport ? 'PersistCreditAdmin' : 'CreditAdmin');

  const [{ loading: exporting }, exportReport] = useAsyncFn(async () => {
    if (!startDate || !endDate || !partnerId || !productId) {
      displayToast(`${REPORT_NAME} report download unavailable, please contact support`, 'error');

      console.error('some params are missing for downloading', REPORT_NAME, { partnerId, productId, startDate, endDate });

      return;
    }

    const filename = `${REPORT_NAME} Report ${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}.xlsx`;

    const { default: writeXlsxFile } = await import('write-excel-file');

    const reportData: CreditAdminReportExportRow[] = await downloadReport({
      body: {
        partnerId,
        productId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        format: 'application/json',
      },
      filename,
    });

    await writeXlsxFile(reportData, {
      schema: creditAdminReportExcelSchema,
      fileName: `${REPORT_NAME} Report ${new Date().toLocaleDateString()}.xlsx`,
    });
  }, [downloadReport, endDate, partnerId, productId, startDate]);

  return (
    <ReportCard
      disableActions={loadingChartData || exporting}
      actionText={'Download Report'}
      onClickAction={exportReport}
      actionLoading={exporting}
      loading={loadingChartData}
      title={`${REPORT_NAME} Report`}
    >
      <ApplicationsByStatusChart chartData={chartData} />
    </ReportCard>
  );
};
