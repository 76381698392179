import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Flex } from '@lama/design-system';
import {
  formValuesToEntityPartial,
  GenericPropertiesGrid,
  GenericPropertiesProvider,
  getInitialFormValues,
  getValidationSchema,
} from '@lama/app-components';
import { getSourcedProperty } from '@lama/properties';
import { Formik } from 'formik';
import type { PropertyKeyWithFormConfig } from '../../../../../shared/utils/getFormProperties';
import { getFormProperties } from '../../../../../shared/utils/getFormProperties';
import { ApplicationContext } from '../../../ApplicationContext';
import { useUpdateApplicationMutation } from '../../../../../shared/hooks/react-query/application/useUpdateApplication';
import { useUpdateOpportunityMutation } from '../../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { SaveFormButton } from '../../../shared/SaveFormButton';
import { FIXED_RATE_TYPE, PRIME_RATE_TYPE } from '../../../shared/LoanTerms/termsUtils';
import type { RequirementScreenProps } from './types';
import { customComponents } from './customComponentsMap';

export const gc14FamilyLoanDetailsApplicationProperties: PropertyKeyWithFormConfig[] = [
  { propertyKey: 'application_requestedAmount', group: 'a' },
  { propertyKey: 'application_loanType', group: 'a' },
  {
    propertyKey: 'application_loanDescription',
    customComponent: 'description',
    size: 'large',
    helperText: 'Loan Description',
    group: 'a',
  },
  { propertyKey: 'application_applyingForJointCredit', size: 'large', group: 'b', optional: true },
];

export const gc14FamilyLoanDetailsOpporunityProperties: PropertyKeyWithFormConfig[] = [
  {
    propertyKey: 'opportunity_terms_termLength',
    group: 'a',
  },
  {
    propertyKey: 'opportunity_terms_paymentPeriod',
    group: 'a',
  },
  {
    propertyKey: 'opportunity_terms_rate_benchmarkRateType',
    group: 'a',
    displayNameOverride: 'Rate Type',
    valueOptions: [FIXED_RATE_TYPE, PRIME_RATE_TYPE],
  },
  {
    propertyKey: 'opportunity_terms_rate_spread',
    optional: true,
    group: 'a',
    displayNameOverride: 'Spread/Rate',
  },
];

export const GatecityLoanDetailsRequirementScreen: FC<RequirementScreenProps> = () => {
  const [submitted, setSubmitted] = useState(false);

  const { application, opportunity, properties, product } = useContext(ApplicationContext);

  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(application.id, opportunity.id);
  const { mutateAsync: updateOpportunity, isPending: updatingOpportunity } = useUpdateOpportunityMutation(opportunity.id);

  const yearsBack = new Date().getUTCFullYear() - opportunity.referenceYear;
  const sourcedApplicationProperties = useMemo(
    () =>
      getFormProperties(gc14FamilyLoanDetailsApplicationProperties, properties).map((p) => getSourcedProperty(p, application, yearsBack)),
    [application, properties, yearsBack],
  );

  const sourcedOpportunityProperties = useMemo(
    () =>
      getFormProperties(gc14FamilyLoanDetailsOpporunityProperties, properties).map((p) => getSourcedProperty(p, opportunity, yearsBack)),
    [opportunity, properties, yearsBack],
  );

  const allProperties = useMemo(
    () => [...sourcedApplicationProperties, ...sourcedOpportunityProperties],
    [sourcedApplicationProperties, sourcedOpportunityProperties],
  );

  const initialApplicationValues = useMemo(
    () => getInitialFormValues(sourcedApplicationProperties, application),
    [application, sourcedApplicationProperties],
  );

  const initialOpportunityValues = useMemo(
    () => getInitialFormValues(sourcedOpportunityProperties, opportunity),
    [opportunity, sourcedOpportunityProperties],
  );

  const initialValues = useMemo(
    () => ({
      ...initialApplicationValues,
      ...initialOpportunityValues,
    }),
    [initialApplicationValues, initialOpportunityValues],
  );

  const validationSchema = useMemo(() => getValidationSchema({ properties: allProperties }), [allProperties]);

  const onSubmit = useCallback(
    async (values: Record<string, any>) => {
      const applicationNewValues = Object.fromEntries(Object.entries(values).filter(([key]) => key in initialApplicationValues));
      const opportunitiesNewValues = Object.fromEntries(Object.entries(values).filter(([key]) => key in initialOpportunityValues));

      const applicationUpdate = formValuesToEntityPartial(applicationNewValues, initialApplicationValues, sourcedApplicationProperties);
      const opportunityUpdate = formValuesToEntityPartial(opportunitiesNewValues, initialOpportunityValues, sourcedOpportunityProperties);

      await Promise.all([updateApplication({ updateApplicationPayload: applicationUpdate }), updateOpportunity(opportunityUpdate)]);

      setSubmitted(true);
    },
    [
      initialApplicationValues,
      initialOpportunityValues,
      sourcedApplicationProperties,
      sourcedOpportunityProperties,
      updateApplication,
      updateOpportunity,
    ],
  );

  return (
    <GenericPropertiesProvider customComponents={customComponents} customSourceToValues={product.customOptionsLists ?? {}}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
        {({ handleSubmit, dirty, isValid }) => (
          <Flex flexDirection={'column'} gap={8}>
            <GenericPropertiesGrid
              properties={[...sourcedApplicationProperties, ...sourcedOpportunityProperties]}
              entityType={'opportunity'}
              submitted={submitted}
              entity={opportunity}
              application={application}
            />
            <SaveFormButton loading={updatingApplication || updatingOpportunity} submit={handleSubmit} disabled={!dirty || !isValid} />
          </Flex>
        )}
      </Formik>
    </GenericPropertiesProvider>
  );
};
