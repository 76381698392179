import type { FC } from 'react';
import React from 'react';
import { Flex, Text } from '@lama/design-system';
import { PipelineEmptyStateSvg } from '../../shared/icons/PipelineEmptyState';

export const PipelineEmptyState: FC = () => (
  <Flex flexDirection={'column'} flex={1} alignItems={'center'} justifyContent={'center'} pt={20} gap={16}>
    <PipelineEmptyStateSvg />
    <Text variant={'body1'} color={'secondary'}>
      {'No Applications Found'}
    </Text>
  </Flex>
);
