import React, { useCallback } from 'react';
import type { FC } from 'react';
import type { MRT_ColumnDef } from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useToggle } from 'react-use';
import { StatusFlagOutlined } from '../StatusFlag';
import type { ScoreCardData } from './scoreCardData';
import { getWeightedValueFlagStatus, scoreCardData } from './scoreCardData';
import { ExpandedScoreCard } from './ExpandedScoreCard';
import { WeightedAverageLabel } from './WeightedAverageLabel';

const columns: MRT_ColumnDef<ScoreCardData>[] = [
  {
    id: 'category',
    header: 'Category',
    accessorKey: 'category',
    Cell: ({ cell }) => <Typography variant={'body2'}>{cell.getValue<string>()}</Typography>,
  },
  {
    id: 'weightedScore',
    header: 'Weighted Score',
    accessorFn: (row) => row.value * row.coefficient,
    size: 40,
    maxSize: 40,
    Cell: ({ cell }) => {
      const weightedScore = cell.getValue<number>();
      const weightedScoreStatus = getWeightedValueFlagStatus(weightedScore);

      return (
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <StatusFlagOutlined status={weightedScoreStatus} />
          <Typography variant={'body2'}>{weightedScore}</Typography>
        </Stack>
      );
    },
  },
];

export const ScoreCard: FC = () => {
  const [modalOpen, toggleModalOpen] = useToggle(false);

  const onMoreDetailsClick = useCallback(() => {
    toggleModalOpen();
  }, [toggleModalOpen]);

  const onClose = useCallback(() => {
    toggleModalOpen();
  }, [toggleModalOpen]);

  return (
    <>
      <Stack spacing={2}>
        <MaterialReactTable
          muiTableProps={{
            sx: {
              tableLayout: 'fixed',
            },
          }}
          enablePagination={false}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          enableSorting={false}
          muiTableHeadRowProps={{
            sx: {
              backgroundColor: '#F5F5F5',
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              border: 'solid #0000001F',
              borderWidth: '1px 0px 0px 0px',
            },
          }}
          muiTableContainerProps={{
            sx: {
              border: 'solid #E0E0E0',
              borderWidth: '1px',
              borderRadius: '4px',
            },
          }}
          muiTablePaperProps={{
            sx: {
              boxShadow: 'none',
            },
          }}
          columns={columns}
          data={scoreCardData}
        />
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Button variant={'text'} sx={{ width: '176px' }} endIcon={<ArrowOutwardIcon />} onClick={onMoreDetailsClick}>
            {'Show Details'}
          </Button>
          <Box pr={9}>
            <WeightedAverageLabel />
          </Box>
        </Stack>
      </Stack>
      <Dialog open={modalOpen} onClose={onClose} maxWidth={'xl'}>
        <ExpandedScoreCard />
      </Dialog>
    </>
  );
};
