import axios from 'axios';
import { saveAs } from 'file-saver';
import { loanDisplayNameSelector } from '@lama/selectors';
import type { ApplicationApiModel } from '@lama/clients';
import { documentServiceUrl } from '../../../framework/environment';

export const downloadDocumentsZip = async (application: ApplicationApiModel, token: string) => {
  const res = await axios.get(`${documentServiceUrl}/documentsZip?applicationId=${application.id}`, {
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob',
  });

  const blob = new Blob([res.data], {
    type: 'application/zip',
  });

  saveAs(blob, `${loanDisplayNameSelector(application)}.zip`);
};
