import React, { useMemo } from 'react';
import type { UpdateAudit } from '@lama/contracts';
import type { FC } from 'react';
import type { AuditGroupInfo } from '../types';
import { AuditLog } from '../../AuditLog';
import { useProductQuery } from '../../../../hooks/react-query/product/useProductQuery';

interface OpportunityProductChangeAuditLogProps {
  audit: UpdateAudit[];
  groupInfo: AuditGroupInfo;
}

export const OpportunityProductChangeAuditLog: FC<OpportunityProductChangeAuditLogProps> = ({ audit, groupInfo }) => {
  const { data: product } = useProductQuery(audit[0]?.changes.productId as string | undefined);

  const text = useMemo(
    () =>
      audit[0]?.originType === 'user'
        ? `Application product was changed to ${product?.prettyName}`
        : `Application was routed to ${product?.prettyName} product`,
    [audit, product?.prettyName],
  );

  if (!product) {
    return null;
  }

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={text}
      timestamp={groupInfo.timestamp}
    />
  );
};
