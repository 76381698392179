import React from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { GlobalCashFlowSpreadSegment } from '../../../shared/Spreads/GlobalCashFlowSpreadSegment';
import type { UnderwritingSectionProps } from '../underwritingSectionProps';

const note = 'Global Cash Flow Details';

export const GlobalCashFlowSection: FC<UnderwritingSectionProps> = ({ printMode, section }) => (
  <Flex flexDirection={'column'} gap={8}>
    <GlobalCashFlowSpreadSegment printMode={!!printMode} spreadId={section.spreadId} />
    <InlineNote title={note} noteName={note} />
  </Flex>
);
