import type { FC } from 'react';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import type { UseOfFundsBreakdown } from '@lama/contracts';
import { formatValue } from '@lama/data-formatters';

interface UseOfFundsBreakdownItemProps {
  item: UseOfFundsBreakdown;
}

export const UseOfFundsBreakdownItem: FC<UseOfFundsBreakdownItemProps> = ({ item: { type, amount, description } }) => (
  <Stack gap={1}>
    <Typography variant={'h6'} noWrap>
      {`${type ?? 'Other'}${amount ? ` - ${formatValue(amount, 'currency')}` : ''}`}
    </Typography>
    {description ? (
      <Typography variant={'body2'} color={'text.secondary'}>
        {description}
      </Typography>
    ) : null}
  </Stack>
);
