import type { ColumnState, FilterModel } from '@ag-grid-community/core';
import { pipelineCategories } from './PipelineCategoryTabs';

const pipelineKey = 'pipelinePreservedState';

export interface PipelinePreservedState {
  selectedCategory: string;
  searchTerm?: string | null;
  filterModel?: FilterModel | null;
  columnState?: ColumnState[];
  page?: number;
}

export const loadPipelinePreservedState = () => {
  const savedState = localStorage.getItem(pipelineKey);
  return savedState ? (JSON.parse(savedState) as PipelinePreservedState) : null;
};

export const savePipelineState = (partialState: Partial<PipelinePreservedState>) => {
  const existingState: PipelinePreservedState = loadPipelinePreservedState() || { selectedCategory: pipelineCategories[1]! };
  const newState = { ...existingState, ...partialState };
  localStorage.setItem(pipelineKey, JSON.stringify(newState));

  return newState;
};

export const clearPipelineState = () => {
  localStorage.removeItem(pipelineKey);
};

export const searchFromState = (state: Partial<PipelinePreservedState>) => {
  let sort = '';
  let order = '';
  let filter = '';
  let q = '';
  let p = '';
  let category = '';

  if (state.searchTerm) {
    q = `&q=${state.searchTerm}`;
  }

  if (state.page) {
    p = `&p=${state.page}`;
  }

  if (state.selectedCategory) {
    category = `&category=${state.selectedCategory}`;
  }

  if (state.filterModel) {
    const filterModel = JSON.stringify(state.filterModel);
    filter = `&filter=${filterModel}`;
  }

  if (state.columnState?.length) {
    const sortedColumn = state.columnState.find((column) => column.sort);
    if (sortedColumn) {
      sort = `&sort=${sortedColumn.colId}`;
      order = `&order=${sortedColumn.sort}`;
    }
  }

  return encodeURI(`?${q}${p}${category}${filter}${sort}${order}`);
};
export const searchToState = (search: string): PipelinePreservedState => {
  const params = new URLSearchParams(search);

  const q = params.get('q') ?? '';
  const p = params.get('p') ?? '';
  const category = params.get('category') ?? '';
  const filter = params.get('filter') ?? '';
  const sort = params.get('sort') ?? '';
  const order = params.get('order') ?? '';

  return {
    searchTerm: q,
    page: p ? Number.parseInt(p) : undefined,
    selectedCategory: category,
    filterModel: filter ? JSON.parse(filter) : undefined,
    columnState: sort ? [{ colId: sort, sort: order } as ColumnState] : undefined,
  };
};
