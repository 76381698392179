import type { ApplicationStatus } from '@lama/contracts';
import { amberPalette, colors, greenPalette, greyPalette, lightBluePalette, redPalette } from '@lama/design-system';

export const applicationStatusToColors: Record<ApplicationStatus, { bgcolor: string; color: string; borderColor: string }> = {
  Cancelled: { bgcolor: greyPalette[100], color: greyPalette[500], borderColor: greyPalette[500] },
  ExpresslyWithdrawn: { bgcolor: greyPalette[100], color: greyPalette[500], borderColor: greyPalette[500] },
  WithdrawalOfApprovedApplication: { bgcolor: greyPalette[100], color: greyPalette[500], borderColor: greyPalette[500] },
  Rejected: { bgcolor: redPalette[50], color: redPalette[400], borderColor: redPalette[400] },
  Deleted: { bgcolor: redPalette[50], color: redPalette[400], borderColor: redPalette[400] },
  ApprovedNotAccepted: { bgcolor: redPalette[50], color: redPalette[400], borderColor: redPalette[400] },
  Draft: { bgcolor: lightBluePalette[50], color: lightBluePalette[700], borderColor: lightBluePalette[700] },
  OnboardingCompleted: { bgcolor: lightBluePalette[50], color: lightBluePalette[700], borderColor: lightBluePalette[700] },
  PendingApproval: { bgcolor: amberPalette[50], color: amberPalette[700], borderColor: amberPalette[700] },
  PendingLost: { bgcolor: amberPalette[50], color: amberPalette[700], borderColor: amberPalette[700] },
  Closing: { bgcolor: amberPalette[50], color: amberPalette[700], borderColor: amberPalette[700] },
  InReview: { bgcolor: amberPalette[50], color: amberPalette[700], borderColor: amberPalette[700] },
  Closed: { bgcolor: greenPalette[50], color: colors.success.light, borderColor: colors.success.light },
};
