import React, { forwardRef, useContext, useMemo } from 'react';
import type { ApplicationRelatedPersonApiModel } from '@lama/clients';
import { LoadingPage } from '@lama/app-components';
import { Text } from '@lama/design-system';
import { ApplicationContext } from '../../ApplicationContext';
import { useGetMultiEntityFinancialsQuery } from '../../../../shared/hooks/react-query/financials/useGetMultiEntityFinancialsQuery';
import { SpreadTable } from '../../CreditMemo/Spreads/components/SpreadTable';
import type { SpreadSegmentProps, SpreadTableRef } from './types';
import { useSpreadConfigurationById } from './hooks/useSpreadConfiguration';

interface PersonCashFlowSpreadSegmentProps extends SpreadSegmentProps {
  person: ApplicationRelatedPersonApiModel;
}

export const PersonCashFlowSpreadSegment = forwardRef<SpreadTableRef, PersonCashFlowSpreadSegmentProps>(
  ({ printMode, spreadId, person, allowAdjustments, ...spreadTableProps }, spreadTableRef) => {
    const { application, opportunity, properties } = useContext(ApplicationContext);
    const { financials, loading } = useGetMultiEntityFinancialsQuery([{ entityId: person.person.id }], false);
    const spreadConfiguration = useSpreadConfigurationById(spreadId);

    const entities = useMemo(
      () => ({
        application: [application],
        business: [],
        affiliate: [],
        person: [person],
        opportunity: [opportunity],
      }),
      [application, opportunity, person],
    );

    if (!spreadConfiguration) {
      return <Text variant={'body1'}>{'Spreads are not configured, please contact support.'}</Text>;
    }

    if (loading) {
      return <LoadingPage />;
    }

    return (
      <SpreadTable
        {...spreadTableProps}
        defaultYearColumns={spreadConfiguration?.spreadYears ?? [2022]}
        financials={financials}
        spreadConfig={spreadConfiguration}
        applicationId={application.id}
        opportunity={opportunity}
        spreadsSettings={opportunity.spreadsSettings}
        entities={entities}
        properties={properties}
        printMode={printMode}
        spreadName={'PersonalCashFlow'}
        periodDisplayFormat={'income statement'}
        showPeopleNames={false}
        allowAdjustments={allowAdjustments}
        ref={spreadTableRef}
      />
    );
  },
);
