import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import { personFullName, applicationGuarantorsSelector } from '@lama/selectors';
import { ApplicationContext } from '../../../ApplicationContext';
import type { UnderwritingSectionProps } from '../../../Underwriting/Sections/underwritingSectionProps';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { PersonalFinancialStatementsSpreadSegment } from '../../../shared/Spreads/PersonalFinancialStatementsSpreadSegment';

const note = 'Personal Financial Statements Note';

export const PersonalFinancialStatementsSection: FC<UnderwritingSectionProps> = ({ printMode, section }) => {
  const { application } = useContext(ApplicationContext);
  const guarantors = useMemo(() => applicationGuarantorsSelector(application), [application]).map((person) => ({
    person,
    relation: 'guarantor' as const,
    relations: ['guarantor' as const],
  }));

  return (
    <Flex flexDirection={'column'} gap={8}>
      {guarantors?.map((relatedPerson) => (
        <Flex flexDirection={'column'} gap={4} key={relatedPerson.person.id}>
          <Text variant={'h6'}>{personFullName(relatedPerson.person)}</Text>
          <PersonalFinancialStatementsSpreadSegment
            printMode={!!printMode}
            spreadId={section?.spreadId}
            guarantor={relatedPerson}
            allowAdjustments={false}
          />
        </Flex>
      ))}
      <InlineNote title={note} noteName={note} />
    </Flex>
  );
};
