import React, { useMemo, useCallback } from 'react';
import type { ApplicationStatus, DecisionReason, ProductApiModel } from '@lama/contracts';
import { TextField, MenuItem, Checkbox, Autocomplete } from '@mui/material';
import { useField } from 'formik';
import type { FC } from 'react';
import { Text } from '@lama/design-system';
import { defaultDecisionReasons, filterReasonsForStatus } from './defaultDecisionReasons';

interface ReasonsAutoCompleteProps {
  product: ProductApiModel;
  decision: ApplicationStatus;
}

export const ReasonsAutoComplete: FC<ReasonsAutoCompleteProps> = ({ product, decision }) => {
  const [, { error, value }, { setValue: setFieldValue }] = useField('reasons');

  const decisionReasons = useMemo(() => {
    if (product?.decisionReasons) {
      return filterReasonsForStatus(product.decisionReasons, [decision]);
    }

    return filterReasonsForStatus(defaultDecisionReasons, [decision]);
  }, [decision, product.decisionReasons]);

  const renderReasonInput = useCallback(
    (props: any) => (
      <TextField
        id={'reasons'}
        name={'reasons'}
        label={'Reason'}
        placeholder={`Reason for ${decision === 'Rejected' ? 'declining' : 'withdrawing'}`}
        helperText={error}
        error={!!error}
        required
        {...props}
      />
    ),
    [decision, error],
  );
  const renderReasonOption = useCallback(
    (props: any, reason: DecisionReason, { selected }: { selected: boolean }) => (
      <MenuItem name={reason.id} direction={'row'} alignItems={'center'} gap={1} {...props}>
        <Checkbox checked={selected} />
        <Text variant={'body1'} ellipsis>
          {reason.text}
        </Text>
      </MenuItem>
    ),
    [],
  );

  const internalOnChange = useCallback(
    async (e: any, internalValue: any) => {
      await setFieldValue(internalValue);
    },
    [setFieldValue],
  );

  const getReasonOptionLabel = useCallback((option: DecisionReason) => option.text, []);

  const checkDisable = useCallback(() => (value?.length ?? 0) >= 4, [value]);

  return (
    <Autocomplete
      getOptionDisabled={checkDisable}
      onChange={internalOnChange}
      renderInput={renderReasonInput}
      getOptionLabel={getReasonOptionLabel}
      options={decisionReasons}
      renderOption={renderReasonOption}
      limitTags={1}
      disableCloseOnSelect
      multiple
    />
  );
};
