import { Switch } from '@lama/design-system';
import type { ConditionConfiguration } from '@lama/conditions';
import { Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { FC } from 'react';
import React, { useCallback, useEffect } from 'react';
import { useToggle } from 'react-use';

interface RangeConditionProps {
  active?: boolean;
  configuration: ConditionConfiguration;
  children: React.ReactNode;
  onChange: (active: boolean) => void;
}

export const ConditionCard: FC<RangeConditionProps> = ({ configuration, active, children, onChange }) => {
  const [conditionActive, toggleActiveCondition] = useToggle(active ?? false);
  const theme = useTheme();

  const internalOnChange = useCallback(() => {
    toggleActiveCondition();

    onChange(!conditionActive);
  }, [conditionActive, onChange, toggleActiveCondition]);

  useEffect(() => {
    toggleActiveCondition(active ?? false);
  }, [active, toggleActiveCondition]);

  return (
    <Stack p={5} gap={5} sx={{ backgroundColor: 'white', border: '1px solid #E0E0E0', borderRadius: '8px' }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'h6'}>{configuration.displayName}</Typography>
        <Switch
          checked={conditionActive}
          onChange={internalOnChange}
          onColor={theme.palette.success.light}
          offColor={grey[400]}
          size={'m'}
          uncheckedText={'Off'}
          checkedText={'On'}
        />
      </Stack>
      {children}
    </Stack>
  );
};
