import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { Button, Flex, Text } from '@lama/design-system';
import { businessName } from '@lama/selectors';
import { useConfirmModal } from '@lama/app-components';
import type { BusinessApiModel } from '@lama/clients';
import { Alert } from '@mui/material';
import type { ClosingTaskComponentProps } from '../closingTaskComponentProps.js';
import { AdditionalDocumentsSection } from '../../ClosingTaskCard.js';
import { PullDocumentBox } from './PullDocumentsBox.js';
import { useRequestMiddeskDocumentsMutation } from './hooks/useRequestMiddeskDocumentsMutation.js';
import { useMiddeskReportTrackingQuery } from './hooks/useMiddeskReportTrackingQuery.js';

export const MiddeskBusinessTaskComponent: FC<ClosingTaskComponentProps & { business: BusinessApiModel }> = ({
  task,
  taskDocuments,
  business,
}) => {
  const { confirm } = useConfirmModal();
  const { mutateAsync: requestMiddeskDocuments } = useRequestMiddeskDocumentsMutation();
  const [shouldFetchTracking, setShouldFetchTracking] = useState(false);
  const { data: businessMiddeskReportTracking } = useMiddeskReportTrackingQuery(business.id, {
    ...(shouldFetchTracking ? { refetchInterval: 5000 } : {}),
  });

  const middeskDocumentType = useMemo(() => task.taskParams?.documentType ?? 'registrations', [task.taskParams?.documentType]);

  const middeskOrderType = useMemo(
    () => (middeskDocumentType === 'liens' ? 'liens' : middeskDocumentType === 'registrations' ? 'documents' : undefined),
    [middeskDocumentType],
  );

  const orderStatus = useMemo(
    () =>
      businessMiddeskReportTracking?.ordersTracking?.find(
        (order) => order.orderType === middeskOrderType && order.relatedClosingTaskId === task.id,
      )?.status,
    [businessMiddeskReportTracking?.ordersTracking, middeskOrderType, task.id],
  );

  const middeskDocuments = useMemo(
    () => businessMiddeskReportTracking?.documentsTracking?.filter(({ documentId }) => task.documentIds?.includes(documentId)),
    [businessMiddeskReportTracking?.documentsTracking, task.documentIds],
  );

  useEffect(() => {
    if (orderStatus === 'completed') {
      setShouldFetchTracking(false);
    }
  }, [orderStatus]);

  const onPullDocumentsClick = useCallback(async () => {
    const confirmed = await confirm({
      title: 'Pull Documents',
      message: 'Are you sure you want to pull documents? This action may incur charges.',
    });

    if (confirmed) {
      await requestMiddeskDocuments({
        relatedClosingTaskId: task.id,
        businessId: business.id,
        documentType: middeskDocumentType,
      });
      setShouldFetchTracking(true);
    }
  }, [business.id, confirm, middeskDocumentType, requestMiddeskDocuments, task.id]);

  return (
    <Flex>
      <Flex key={business.id} flexDirection={'column'} gap={2} width={'100%'}>
        <Flex flexDirection={'row'} justifyContent={'space-between'}>
          <Text variant={'body1'}>{businessName(business)}</Text>
          <Button variant={'tertiary'} onClick={onPullDocumentsClick}>
            {'Repull'}
          </Button>
        </Flex>
        {orderStatus === 'completed' ? (
          middeskDocuments?.length ? null : (
            <Alert severity={'info'}>{'No documents found'}</Alert>
          )
        ) : (
          <PullDocumentBox
            taskId={task.id}
            description={task.taskParams?.documentPlaceholder ?? task.name}
            orderStatus={orderStatus}
            fetchingDocuments={shouldFetchTracking}
            middeskDocumentType={middeskDocumentType}
            onClick={onPullDocumentsClick}
          />
        )}
        <AdditionalDocumentsSection
          taskId={task.id}
          documents={taskDocuments ?? []}
          relatedEntityId={business.id}
          relatedEntityType={'business'}
        />
      </Flex>
    </Flex>
  );
};
