import React, { forwardRef, useContext, useMemo } from 'react';
import { LoadingPage } from '@lama/app-components';
import { Text } from '@lama/design-system';
import { relatedBusinessesByRelationSelector } from '@lama/selectors';
import { ApplicationContext } from '../../ApplicationContext';
import type { EntitiesByType } from '../../CreditMemo/Spreads/components/SpreadTable/types';
import { SpreadTable } from '../../CreditMemo/Spreads/components/SpreadTable';
import { useGetMultiEntityFinancialsQuery } from '../../../../shared/hooks/react-query/financials/useGetMultiEntityFinancialsQuery';
import type { SpreadSegmentProps, SpreadTableRef } from './types';
import { useSpreadConfigurationById } from './hooks/useSpreadConfiguration';

export const CombinedPropertiesCashFlowSpreadSegment = forwardRef<SpreadTableRef, SpreadSegmentProps>(
  ({ printMode, spreadId, allowAdjustments }, spreadTableRef) => {
    const { application, opportunity, properties } = useContext(ApplicationContext);

    const realEstateProperties = useMemo(() => relatedBusinessesByRelationSelector(application, 'property'), [application]);

    const { financials, loading } = useGetMultiEntityFinancialsQuery(
      realEstateProperties.map(({ business: { id: businessId } }) => ({ entityId: businessId })),
      false,
    );

    const spreadConfiguration = useSpreadConfigurationById(spreadId);

    const spreadEntities: EntitiesByType = useMemo(
      () => ({
        affiliate: [],
        business: realEstateProperties,
        person: [],
        application: [application],
        opportunity: [],
      }),
      [application, realEstateProperties],
    );

    if (!spreadConfiguration) {
      return <Text variant={'body1'}>{'Spreads are not configured, please contact support.'}</Text>;
    }

    if (loading) {
      return <LoadingPage />;
    }

    return (
      <SpreadTable
        entities={spreadEntities}
        properties={properties}
        applicationId={application.id}
        opportunity={opportunity}
        spreadsSettings={opportunity.spreadsSettings}
        spreadConfig={spreadConfiguration}
        financials={financials}
        defaultYearColumns={spreadConfiguration?.spreadYears ?? [2022]}
        printMode={printMode}
        spreadName={'CombinedPropertiesCashFlow'}
        periodDisplayFormat={'income statement'}
        allowAdjustments={allowAdjustments}
        showPeopleNames
        ref={spreadTableRef}
      />
    );
  },
);
