import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { creditReportRetrieverClient } from '../../../clients/creditReportRetrieverClient';

export const useRepullCreditReport = (opportunityId: string, options: UseMutationOptions<void, unknown> = {}) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const repullCreditReportQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return creditReportRetrieverClient.pullCreditReport({ opportunityId, token });
  }, [getAccessTokenSilently, opportunityId]);

  return useMutation({
    mutationFn: repullCreditReportQuery,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityAlerts', opportunityId],
        }),
      ]);
    },
    ...options,
  });
};
