import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { personFullName } from '@lama/selectors';
import type { UpdateAudit } from '@lama/contracts';
import type { EmailTrackingInternalStatus } from '@lama/email-sender-client';
import type { AuditGroupInfo, UpdateAuditLogProps } from '../types';
import { AuditLog } from '../../AuditLog';
import { useUserQuery } from '../../../../hooks/react-query/user/useUserQuery';
import { useOpportunityEmailTrackingsQuery } from '../../../../hooks/react-query/emailTrackings/useOpportunityEmailTrackingsQuery';
import { ApplicationContext } from '../../../../../components/Application/ApplicationContext';
import { emailTrackingStatusToDisplay, emailTypeToDisplay } from './EmailTrackingAuditLog';

interface EmailTrackingUpdatedAuditLogProps {
  audit: UpdateAudit;
  groupInfo: AuditGroupInfo;
}

const EmailTrackingUpdatedAuditLog: FC<EmailTrackingUpdatedAuditLogProps> = ({ audit, groupInfo }) => {
  const { opportunity } = useContext(ApplicationContext);
  const { data: emailTrackings } = useOpportunityEmailTrackingsQuery(opportunity.id);

  const emailTracking = useMemo(() => emailTrackings?.find((et) => et.id === audit.entityId), [emailTrackings, audit.entityId]);

  const { data: recipient, isLoading: loadingRecipient } = useUserQuery(emailTracking?.recipientId, { enabled: !!emailTracking });

  const text = useMemo(() => {
    if (!emailTracking) {
      return null;
    }

    const emailTypeDisplay = emailTypeToDisplay(emailTracking.emailType);
    const statusDisplay = emailTrackingStatusToDisplay(emailTracking.currentStatus as EmailTrackingInternalStatus);

    if (!emailTypeDisplay || !recipient || !statusDisplay) {
      return null;
    }

    return `${emailTypeDisplay} was ${statusDisplay} ${personFullName(recipient)}`;
  }, [emailTracking, recipient]);

  if (loadingRecipient) {
    return null;
  }

  if (!emailTracking || !recipient || !text) {
    return null;
  }

  return (
    <AuditLog
      text={text}
      timestamp={groupInfo.timestamp}
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
    />
  );
};

export const EmailTrackingUpdateAuditLogs: FC<UpdateAuditLogProps> = ({ audit, groupInfo }) => {
  const EmailTrackingUpdatedAuditLogs = useMemo(
    () => audit.map((a) => <EmailTrackingUpdatedAuditLog key={a.id} groupInfo={groupInfo} audit={a} />),
    [groupInfo, audit],
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{EmailTrackingUpdatedAuditLogs}</>;
};
