import { useMutation } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import type { RequestDocumentsBody } from '@lama/verify-business-service-client';
import { useAuth0 } from '@auth0/auth0-react';
import { verifyBusinessServiceClient } from '../../../../../../../shared/clients/verifyBusinessServiceClient';

export const useRequestMiddeskDocumentsMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async (requestDocumentsBody: RequestDocumentsBody) => {
      const token = await getAccessTokenSilently();

      await verifyBusinessServiceClient.requestDocuments(requestDocumentsBody, token);
    },
    onError: () => {
      displayToast('Something went wrong. Please contact support.', 'error');
    },
  });
};
