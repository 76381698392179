import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { BusinessApiModel } from '@lama/clients';
import { OfacReportSummary } from './OfacReportSummary';

interface OfacReportBusinessSegmentProps {
  business: BusinessApiModel;
}

export const OfacReportBusinessSegment: FC<OfacReportBusinessSegmentProps> = ({ business }) => {
  const { t } = useTranslation();

  if (business.legalEntityType === 'Sole Proprietorship') {
    return null;
  }

  return (
    <OfacReportSummary
      timestamp={business.verifications?.middesk?.reportDate}
      searchLists={business.verifications?.middesk?.searchResult?.lists ?? []}
      ofacStatus={business.verifications?.middesk?.ofacStatus}
      dataFetched={!!business.verifications?.middesk?.ofacStatus}
      searchResults={business.verifications?.middesk?.searchResult?.results ?? []}
      dataNotFetchedText={t('ofacLookupResults.middeskNotFetched')}
    />
  );
};
