import React, { Suspense, useMemo } from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';
import { groupBy, isEmpty, orderBy } from 'lodash-es';
import { format } from 'date-fns';
import { Flex, Spinner, Text } from '@lama/design-system';
import styled from 'styled-components';
import type { AuditEntry } from '@lama/contracts';
import { useOpportunityAuditQuery } from '../../hooks/react-query/audit/useOpportunityAuditQuery';
import { GroupedAudit } from './GroupedAudit';
import { auditConnectorClassName, auditLogClassName } from './AuditLog';

interface OpportunityAuditFeedProps {
  opportunityId: string;
}

export const AuditConnectorLine = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '1px solid lightgrey',
      height: '24px',
      marginY: '4px',
      marginLeft: '16px',
    }}
  />
);

export const StyledAuditFeedContainer = styled(Flex)`
  // eslint-disable-next-line @typescript-eslint/naming-convention
  .${auditLogClassName}:nth-last-of-type(1) .${auditConnectorClassName} {
    display: none;
  }
`;

const AuditLoading = (
  <Flex flexDirection={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
    <Spinner size={'s'} />
  </Flex>
);

export const auditFieldsToHide = new Set([
  'id',
  'entityId',
  'timestamp',
  'type',
  'externalIds',
  'externalId',
  'googlePlaceId',
  'createdAt',
  'updatedAt',
]);

const sanitizeAuditEntryEntries = (auditEntries: AuditEntry[]) =>
  auditEntries
    ?.filter((a) => a.type !== 'update' || !isEmpty(a.changes))
    .map((auditEntry) => {
      if (auditEntry.type !== 'update') {
        return auditEntry;
      }

      const changes = Object.fromEntries(Object.entries(auditEntry.changes).filter(([key]) => !auditFieldsToHide.has(key)));

      return {
        ...auditEntry,
        changes,
      };
    });

export const OpportunityAuditFeed: FC<OpportunityAuditFeedProps> = ({ opportunityId }) => {
  const { data: auditEntries } = useOpportunityAuditQuery(opportunityId, { staleTime: 30_000 });

  const groupedAudits = useMemo(() => {
    const filteredEntries = sanitizeAuditEntryEntries(auditEntries ?? []);

    return orderBy(
      Object.values(
        groupBy(filteredEntries, (auditEntry) =>
          [
            format(new Date(auditEntry.timestamp), 'yyyy/MM/dd:HH'),
            auditEntry.type,
            auditEntry.originType === 'user' ? auditEntry.origin!.id : 'system',
            auditEntry.entityId,
          ].join('_'),
        ),
      ).map((groupedAuditEntries) => orderBy(groupedAuditEntries, (x) => x.timestamp, 'desc')),
      (x) => x[0]?.timestamp,
      'desc',
    );
  }, [auditEntries]);

  if (!auditEntries?.length) {
    return null;
  }

  return (
    <Suspense fallback={AuditLoading}>
      <Flex flexDirection={'column'} pt={12} gap={6}>
        <Text variant={'body1'}>{'Audit'}</Text>
        <StyledAuditFeedContainer flexDirection={'column'}>
          {groupedAudits.map((hourAudits) => (
            <GroupedAudit key={hourAudits[0]?.id} groupedAudits={hourAudits} />
          ))}
        </StyledAuditFeedContainer>
      </Flex>
    </Suspense>
  );
};
