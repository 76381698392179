import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Chip } from '@mui/material';
import { type ApplicationStatus } from '@lama/contracts';
import { applicationStatusToColors } from '../utils/applicationStatusToColors';
import type { ApplicationStatusDisplayNameMapping } from '../context/ApplicationStatusDisplayNameMappingContext';
import { ApplicationStatusDisplayNameMappingContext } from '../context/ApplicationStatusDisplayNameMappingContext';

export const statusToChip = (
  status: ApplicationStatus,
  applicationStatusToDisplayName: ApplicationStatusDisplayNameMapping,
  isApplicationAutoDeclined?: boolean,
): { label: string; style: { bgcolor: string; color: string; borderColor: string } } => ({
  label: isApplicationAutoDeclined ? 'Auto Declined' : applicationStatusToDisplayName[status],
  style: isApplicationAutoDeclined ? applicationStatusToColors.Rejected : applicationStatusToColors[status],
});

interface ApplicationStatusChipProps {
  status: ApplicationStatus;
  size?: 'medium' | 'small';
  sx?: SxProps<Theme>;
  isApplicationAutoDeclined?: boolean;
}

export const ApplicationStatusChip: FC<ApplicationStatusChipProps> = ({ status, size, sx, isApplicationAutoDeclined }) => {
  const { applicationStatusToDisplayName } = useContext(ApplicationStatusDisplayNameMappingContext);
  const { label, style } = useMemo(
    () => statusToChip(status, applicationStatusToDisplayName, isApplicationAutoDeclined),
    [status, applicationStatusToDisplayName, isApplicationAutoDeclined],
  );
  return <Chip label={label} size={size} variant={'outlined'} sx={{ ...style, ...sx }} />;
};
