import { useAuth0 } from '@auth0/auth0-react';
import type { UnderwritingNoteUpdateModel } from '@lama/clients';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

interface UpdateNoteParams {
  noteId: string;
  notePayload: UnderwritingNoteUpdateModel;
}

export const useUpdateNoteMutation = (opportunityId: string, userId: string | undefined) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ noteId, notePayload }: UpdateNoteParams) => {
      const token = await getAccessTokenSilently();

      if (!userId) {
        return;
      }

      const note: UnderwritingNoteUpdateModel = {
        ...notePayload,
        updatedBy: userId,
        ...(notePayload.type === 'cleared' ? { clearedBy: userId } : {}),
      };

      return applicationServiceClient.updateNote(opportunityId, noteId, note, token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunityId] });
      await queryClient.invalidateQueries({ queryKey: ['opportunityEvaluation', opportunityId] });
    },
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });
};
