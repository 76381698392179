/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import type { FC } from 'react';
import type { UnderwritingSectionProps } from './Sections/underwritingSectionProps';
import { BusinessBalanceSheetSection } from './Spreads/BusinessBalanceSheetSection';
import { BusinessCashFlowSection } from './Spreads/BusinessCashFlowSection';
import { PersonCashFlowSection } from './Spreads/PersonCashFlowSection';
import { GlobalCashFlowSection } from './Sections/GlobalCashFlow/GlobalCashFlowSection';
import { PersonalFinancialStatementsSection } from './Spreads/PersonalFinancialStatementsSection';
import { PropertiesCashFlowSection } from './Sections/PropertiesCashFlow/PropertiesCashFlowSection';
import { CombinedPropertiesCashFlowSection } from './Sections/PropertiesCashFlow/CombinedPropertiesCashflowSection';

export const sectionComponentByName: Record<string, FC<UnderwritingSectionProps>> = {
  businessBalanceSheet: BusinessBalanceSheetSection,
  businessCashFlow: BusinessCashFlowSection,
  personalFinancialStatements: PersonalFinancialStatementsSection,
  guarantorCashFlow: PersonCashFlowSection,
  globalCashFlow: GlobalCashFlowSection,
  propertiesCashFlow: PropertiesCashFlowSection,
  combinedPropertiesCashFlow: CombinedPropertiesCashFlowSection,
};
