import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { businessNameWithRelationsSelector } from '@lama/selectors';
import { InlineNote } from '../../shared/InlineNote/InlineNote';
import { BusinessBalanceSheetSpreadSegment } from '../../shared/Spreads/BusinessBalanceSheetSpreadSegment';
import type { UnderwritingSectionProps } from '../Sections/underwritingSectionProps';
import { ApplicationContext } from '../../ApplicationContext';
import { getSpreadIdBySectionAndRelations } from '../../shared/sectionSpreadId';
import { getPrimaryBusinessRelation } from '../../../../shared/utils/relatedBusinesses';

const note = 'Business Balance Sheet Details';

export const BusinessBalanceSheetSection: FC<UnderwritingSectionProps> = ({ printMode, section }) => {
  const { application } = useContext(ApplicationContext);

  const relevantBusinesses = useMemo(
    () => application.relatedBusinesses.filter((r) => !['property', undefined].includes(getPrimaryBusinessRelation(r.relations))),
    [application.relatedBusinesses],
  );

  return (
    <Flex flexDirection={'column'} gap={8}>
      {relevantBusinesses.map((relatedBusiness) => (
        <Flex key={relatedBusiness.business.id} flexDirection={'column'} gap={2}>
          <BusinessBalanceSheetSpreadSegment
            title={businessNameWithRelationsSelector(relatedBusiness.business.id, application)}
            printMode={!!printMode}
            spreadId={getSpreadIdBySectionAndRelations(section, relatedBusiness.relations)}
            business={relatedBusiness}
          />
        </Flex>
      ))}
      <InlineNote title={note} noteName={note} />
    </Flex>
  );
};
