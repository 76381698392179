/* eslint-disable react/jsx-no-bind */
import React, { useMemo, useCallback, useContext } from 'react';
import type { FC } from 'react';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import * as yup from 'yup';
import { v4 as uuid4 } from 'uuid';
import { Button, Flex, Text } from '@lama/design-system';
import { type ApplicationStatus, type DecisionReason } from '@lama/contracts';
import { PropertyFormikInput, displayToast } from '@lama/app-components';
import { ApplicationContext } from '../../../components/Application/ApplicationContext';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { useUserQuery } from '../../hooks/react-query/user/useUserQuery';
import { useCreateDecisionMutation } from '../../hooks/react-query/opportunity/useCreateDecisionMutation';
import { ApplicationStatusDisplayNameMappingContext } from '../../context/ApplicationStatusDisplayNameMappingContext';
import { ReasonsAutoComplete } from './DecisionAutoComplete';

interface DecisionFormProps {
  onDecision: () => void;
  decision: ApplicationStatus;
}

const reasonRequiredStatuses = new Set<ApplicationStatus>(['Rejected', 'ExpresslyWithdrawn']);

interface DecisionFormValues {
  otherReason: string;
  reasons: DecisionReason[];
}

export const DecisionForm: FC<DecisionFormProps> = ({ onDecision, decision }) => {
  const { opportunity, product } = useContext(ApplicationContext);
  const { applicationStatusToDisplayName } = useContext(ApplicationStatusDisplayNameMappingContext);
  const { userId } = useContext(UserDetailsContext);

  const { data: user } = useUserQuery(userId);
  const { mutateAsync: createDecision, isPending } = useCreateDecisionMutation(opportunity.id, {
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });

  const reasonRequired = useMemo(() => decision && reasonRequiredStatuses.has(decision), [decision]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        text: yup.string(),
        reasons: reasonRequired ? yup.array().min(1, 'At least one reason is required').required() : yup.array(),
        showOtherReason: yup.boolean(),
        otherReason: yup
          .string()
          .when('reasons', ([reasons], schema: any) =>
            (reasons as DecisionReason[]).some((reason) => reason.id === 'other') ? schema.required('Other reason is required') : schema,
          ),
      }),
    [reasonRequired],
  );

  const initialValues: DecisionFormValues = useMemo(
    () => ({
      otherReason: '',
      reasons: [],
    }),
    [],
  );

  const onSubmit = useCallback(
    async (values: DecisionFormValues, { setSubmitting }: FormikHelpers<DecisionFormValues>) => {
      setSubmitting(true);

      if (!user || !decision) {
        displayToast('There was a problem processing the request. Please try again.', 'error');
        return;
      }

      await createDecision({
        decision,
        reasons: values.reasons.map((reason) => ({
          ...reason,
          text: reason.id === 'other' ? `Other - ${values.otherReason}` : reason.text,
        })),
        roles: user?.roles ?? [],
        userId: userId ?? '',
        id: uuid4(),
      });

      setSubmitting(false);
      onDecision();
    },
    [createDecision, decision, onDecision, user, userId],
  );

  const actionText = useMemo(() => `Mark as ${applicationStatusToDisplayName[decision]}`, [applicationStatusToDisplayName, decision]);

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit} validateOnChange enableReinitialize>
      {({ handleChange, handleSubmit, values }) => (
        <Flex flexDirection={'column'} gap={8} width={'100%'} justifyContent={'center'}>
          <Text variant={'h5'} textAlign={'center'}>
            {actionText}
          </Text>
          <Flex flexDirection={'column'} gap={4}>
            {reasonRequired ? <ReasonsAutoComplete product={product} decision={decision} /> : null}
            {values.reasons.some((reason) => reason.id === 'other') ? (
              <PropertyFormikInput
                name={'otherReason'}
                label={'Explain the "Other" reason'}
                variant={'outlined'}
                onChange={handleChange}
                helperText={'Note: This will be shared with the applicant'}
                required
                multiline
                rows={4}
              />
            ) : null}
          </Flex>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <Button
              size={'l'}
              variant={'primary'}
              onClick={() => {
                handleSubmit();
              }}
              loading={isPending}
            >
              {actionText}
            </Button>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};
