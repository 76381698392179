import { Flex, greyPalette } from '@lama/design-system';
import styled from 'styled-components';

export const SideNavContainer = styled(Flex)`
  overflow-y: auto;
  overflow-x: hidden;
  width: 360px;
  flex-direction: column;
  background-color: ${greyPalette[50]};
  border-right: 1px solid ${greyPalette[100]};
`;
