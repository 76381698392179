import { ToggleButton, styled, Typography, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import type { ChartType } from '../../types';

interface ChartsToggleProps {
  value: ChartType;
  toggleChartView: (event: React.MouseEvent<HTMLElement>, value: string | null) => void;
}

const ChartToggleButton = styled(ToggleButton)({
  height: '32px',
  backgroundColor: 'white',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.hover': {
    backgroundColor: '#EEEEEE',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: '#FAFAFA',
    zIndex: 2,
  },
});

export const ChartsToggle: React.FC<ChartsToggleProps> = ({ value, toggleChartView }) => (
  // eslint-disable-next-line @typescript-eslint/naming-convention
  <ToggleButtonGroup value={value} exclusive onChange={toggleChartView} sx={{ '& .MuiToggleButtonGroup-grouped': { borderRadius: '4px' } }}>
    <ChartToggleButton value={'balance'}>
      <Typography variant={'buttonSmall'} color={'text.secondary'}>
        {'Balance'}
      </Typography>
    </ChartToggleButton>
    <ChartToggleButton value={'profitAndLoss'}>
      <Typography variant={'buttonSmall'} color={'text.secondary'}>
        {'P&L'}
      </Typography>
    </ChartToggleButton>
  </ToggleButtonGroup>
);
