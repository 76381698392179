import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { allApplicationPeopleSelector } from '@lama/selectors';
import { ApplicationContext } from '../../../../ApplicationContext';
import type { RequirementScreenProps } from '../types';
import { CreditReport } from './CreditReport';

export const CreditReportRequirementScreen: FC<RequirementScreenProps> = ({ requirement }) => {
  const { application } = useContext(ApplicationContext);

  const person = useMemo(
    () => allApplicationPeopleSelector(application).find((g) => g.id === requirement.entityId),
    [application, requirement.entityId],
  );

  if (!person) {
    return null;
  }

  return <CreditReport person={person} />;
};
