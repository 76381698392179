import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { GetPipelineItemsQueryParams, GetPipelineItemsResponse } from '@lama/pipeline-items-service-client';
import { pipelineItemsServiceClient } from '../../../clients/pipelineItemsServiceClient';

export const useGetPipelineQuery = (params: GetPipelineItemsQueryParams, options?: Partial<UseQueryOptions<GetPipelineItemsResponse>>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getPipelineQueryFunction = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return pipelineItemsServiceClient.getPipeline({ ...params }, token);
  }, [getAccessTokenSilently, params]);

  return useQuery<GetPipelineItemsResponse>({ queryKey: ['pipeline'], queryFn: getPipelineQueryFunction, ...options });
};
