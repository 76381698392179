import { BaseDialog } from '@lama/app-components';
import React from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import type { EtranCredentials, EtranDialogProps } from './types';

export interface EtranExportErrorsDialogProps extends EtranDialogProps<EtranCredentials> {
  errors: string[];
}

export const EtranExportErrorsDialog: FC<EtranExportErrorsDialogProps> = ({ open, onClose, errors }) => (
  <BaseDialog open={open} onClose={onClose} title={'Failed to pull SBSS score'} fullWidth={false}>
    <Flex flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} width={'100%'}>
      {errors.map((error) => (
        <Text key={error} variant={'body1'}>
          {error}
        </Text>
      ))}
    </Flex>
  </BaseDialog>
);
