/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Tabs, Tab, useTheme } from '@mui/material';
import { capitalCase } from 'change-case-all';
import { sumBy } from 'lodash-es';
import { formatValue } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';
import styled, { css } from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetPipelineCategoriesQueries } from '../../shared/hooks/react-query/pipeline/useGetPipelineCategoriesQueries';

const HidingContainer = styled(Flex)<{ hideCategories: boolean }>`
  width: 100%;
  transition: display 0.5s ease-out;
  ${({ hideCategories }) =>
    hideCategories &&
    css`
      display: none;
    `}
`;

export const workflowPipelineCategories = ['Leads', 'Qualified', 'Pending Decision', 'Closing', 'Closed', 'Lost'];

interface PipelineCategoryTabsV2Props {
  selectedPipelineCategory: string | null;
  onChange: (_: React.SyntheticEvent, category: string) => void;
  hideCategories: boolean;
}

const CategoryTabLabel = ({
  category,
  totalAmount,
  numberOfApplications,
}: {
  category: string;
  totalAmount: number;
  numberOfApplications: number;
}) => {
  const { fibtDemo } = useFlags();
  const theme = useTheme();
  return (
    <Flex flexDirection={'column'} alignItems={'baseline'} mr={'auto'}>
      <Text variant={'body1'}>{capitalCase(category)}</Text>
      <Flex gap={1}>
        {fibtDemo ? null : (
          <Text variant={'h5'} color={theme.palette.text.primary}>
            {formatValue(totalAmount, 'currencyCompact')}
          </Text>
        )}
        <Text variant={'h5'} color={theme.palette.text.disabled}>{`(${numberOfApplications})`}</Text>
      </Flex>
    </Flex>
  );
};

export const PipelineCategoryTabsV2: FC<PipelineCategoryTabsV2Props> = ({ selectedPipelineCategory, onChange, hideCategories }) => {
  const relevantPipelineCategories = useMemo(() => workflowPipelineCategories, []);

  const { data: pipelineCategoriesData } = useGetPipelineCategoriesQueries(relevantPipelineCategories);

  const all = useMemo(
    () => ({
      count: sumBy(pipelineCategoriesData, (item) => item.count || 0),
      totalRequestedAmount: sumBy(pipelineCategoriesData, (item) => item.totalRequestedAmount || 0),
    }),
    [pipelineCategoriesData],
  );

  const selectedCategoryExists = useMemo(
    () => pipelineCategoriesData.some((categoryData) => categoryData.category === selectedPipelineCategory),
    [pipelineCategoriesData, selectedPipelineCategory],
  );

  return (
    <HidingContainer hideCategories={hideCategories}>
      <Tabs
        sx={{ textTransform: 'none', width: '100%' }}
        centered={false}
        value={selectedCategoryExists ? selectedPipelineCategory : false}
        variant={'fullWidth'}
        onChange={onChange}
      >
        {pipelineCategoriesData.map(({ category, count, totalRequestedAmount }) =>
          category ? (
            <Tab
              key={category}
              sx={{ textTransform: 'none' }}
              label={<CategoryTabLabel category={category} totalAmount={totalRequestedAmount ?? 0} numberOfApplications={count ?? 0} />}
              value={category}
            />
          ) : null,
        )}
        <Tab
          sx={{ textTransform: 'none' }}
          label={<CategoryTabLabel category={'all'} totalAmount={all.totalRequestedAmount} numberOfApplications={all.count} />}
          value={'all'}
        />
      </Tabs>
    </HidingContainer>
  );
};
