import React, { useContext, useMemo, useRef } from 'react';
import type { FC } from 'react';
import { Divider } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { loanDisplayNameSelector } from '@lama/selectors';
import { useAsyncFn } from 'react-use';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex, greyPalette } from '@lama/design-system';
import { ApplicationContext } from '../../../ApplicationContext';
import { useOpportunityRequirementsQuery } from '../../../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { ApplicationExport } from '../../../../ApplicationPrint/ApplicationExport/ApplicationExport';
import { downloadDocumentsZip } from '../../../ActionsMenu/downloadDocumentsZip';
import { CreditMemoExport } from '../../../../ApplicationPrint/CreditMemoExport/CreditMemoExport';
import type { ClosingTaskComponentProps } from './closingTaskComponentProps';
import { ExportLine } from './shared/ExportLine';

export const ExportClosingTask: FC<ClosingTaskComponentProps> = () => {
  const { opportunity, application } = useContext(ApplicationContext);
  const businessName = useMemo(() => loanDisplayNameSelector(application), [application]);
  const { data: requirements, isPending: loadingRequirements } = useOpportunityRequirementsQuery(opportunity.id);
  const { getAccessTokenSilently } = useAuth0();

  const applicationRef = useRef(null);
  const creditMemoRef = useRef(null);

  const exportApplication = useReactToPrint({
    content: () => applicationRef.current,
    documentTitle: `${businessName} Application`,
  });

  const exportCreditMemo = useReactToPrint({
    content: () => creditMemoRef.current,
    documentTitle: `${businessName} Credit Memo`,
  });

  const [, downloadDocuments] = useAsyncFn(async () => {
    const token = await getAccessTokenSilently();
    await downloadDocumentsZip(application, token);
  }, [getAccessTokenSilently, application]);

  return (
    <Flex flexDirection={'column'} gap={4} width={'100%'} p={4} border={`1px solid ${greyPalette[300]}`} borderRadius={'4px'}>
      <ExportLine name={'Application'} onExportClick={exportApplication} />
      <Divider orientation={'horizontal'} />
      <ExportLine name={'All Documents'} onExportClick={downloadDocuments} />
      <Divider orientation={'horizontal'} />
      <ExportLine name={'Credit Memo'} onExportClick={exportCreditMemo} />
      {!loadingRequirements && requirements?.length ? (
        <ApplicationExport key={'applicationExport'} ref={applicationRef} requirements={requirements} />
      ) : null}
      {businessName ? <CreditMemoExport key={'creditMemoExport'} ref={creditMemoRef} businessName={businessName} /> : null}
    </Flex>
  );
};
