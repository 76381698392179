import { Flex, Text } from '@lama/design-system';
import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationContext } from '../../components/Application/ApplicationContext';
import { UserDetailsContext } from '../context/UserDetailsContext';
import { useGetPartnerQuery } from '../hooks/react-query/partner/useGetPartnerQuery';

export const ProductionApplicationIndication: FC = () => {
  const {
    opportunity: { partnerId },
  } = useContext(ApplicationContext);
  const { email: userEmail } = useContext(UserDetailsContext);
  const { showAdminIndication } = useFlags();

  const { data: opportunityPartner } = useGetPartnerQuery(partnerId);

  const showIndication = useMemo(
    () => showAdminIndication && opportunityPartner?.status === 'production' && userEmail?.endsWith('@lama.ai'),
    [opportunityPartner?.status, showAdminIndication, userEmail],
  );

  return showIndication ? (
    <Flex backgroundColor={'red'} justifyContent={'center'}>
      <Text>{'Production Application'}</Text>
    </Flex>
  ) : null;
};
