import React from 'react';

export const PipelineEmptyStateSvg: React.FC = () => (
  <svg width={'401'} height={'291'} viewBox={'0 0 401 291'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <circle cx={'200.37'} cy={'149.5'} r={'141.087'} fill={'#E2FBF3'} />
    <mask
      id={'mask0_14741_174161'}
      style={{ maskType: 'alpha' }}
      maskUnits={'userSpaceOnUse'}
      x={'59'}
      y={'8'}
      width={'283'}
      height={'283'}
    >
      <circle cx={'200.37'} cy={'149.5'} r={'141.087'} fill={'#E2FBF3'} />
    </mask>
    <g mask={'url(#mask0_14741_174161)'}>
      <rect x={'157.679'} y={'28.8982'} width={'85.7079'} height={'110.841'} rx={'1.5'} fill={'white'} stroke={'#0C2007'} />
      <rect x={'168.489'} y={'39.7079'} width={'64.0885'} height={'45.2389'} rx={'2'} fill={'#EEEEEE'} />
      <rect x={'177.286'} y={'102.54'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'168.489'} y={'112.593'} width={'64.0885'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'177.286'} y={'122.646'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'259.467'} y={'28.8982'} width={'85.7079'} height={'110.841'} rx={'1.5'} fill={'white'} stroke={'#0C2007'} />
      <rect x={'270.277'} y={'39.7079'} width={'64.0885'} height={'45.2389'} rx={'2'} fill={'#EEEEEE'} />
      <rect x={'279.073'} y={'102.54'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'270.277'} y={'112.593'} width={'64.0885'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'279.073'} y={'122.646'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'55.8918'} y={'28.8982'} width={'85.7079'} height={'110.841'} rx={'1.5'} fill={'white'} stroke={'#0C2007'} />
      <rect x={'66.7015'} y={'39.7079'} width={'64.0885'} height={'45.2389'} rx={'2'} fill={'#EEEEEE'} />
      <rect x={'75.498'} y={'102.54'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'66.7015'} y={'112.593'} width={'64.0885'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'75.498'} y={'122.646'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'157.679'} y={'158.332'} width={'85.7079'} height={'110.841'} rx={'1.5'} fill={'white'} stroke={'#0C2007'} />
      <rect x={'168.489'} y={'169.142'} width={'64.0885'} height={'45.2389'} rx={'2'} fill={'#EEEEEE'} />
      <rect x={'177.286'} y={'231.973'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'168.489'} y={'242.026'} width={'64.0885'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'177.286'} y={'252.08'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'55.8918'} y={'158.332'} width={'85.7079'} height={'110.841'} rx={'1.5'} fill={'white'} stroke={'#0C2007'} />
      <rect x={'66.7016'} y={'169.142'} width={'64.0885'} height={'45.2389'} rx={'2'} fill={'#EEEEEE'} />
      <rect x={'75.498'} y={'231.973'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'66.7016'} y={'242.026'} width={'64.0885'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
      <rect x={'75.498'} y={'252.08'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
    </g>
    <rect x={'259.467'} y={'28.8982'} width={'85.7079'} height={'110.841'} rx={'1.5'} fill={'white'} stroke={'#0C2007'} />
    <rect x={'270.277'} y={'39.7079'} width={'64.0885'} height={'45.2389'} rx={'2'} fill={'#EEEEEE'} />
    <rect x={'279.073'} y={'102.54'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
    <rect x={'270.277'} y={'112.593'} width={'64.0885'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
    <rect x={'279.073'} y={'122.646'} width={'46.4956'} height={'3.76991'} rx={'1.88496'} fill={'#EEEEEE'} />
    <rect
      x={'256.197'}
      y={'161.345'}
      width={'84.7079'}
      height={'109.841'}
      rx={'1'}
      fill={'#F3F0FF'}
      stroke={'#714EF5'}
      strokeWidth={'2'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      strokeDasharray={'9 9'}
    />
    <mask
      id={'mask1_14741_174161'}
      style={{ maskType: 'alpha' }}
      maskUnits={'userSpaceOnUse'}
      x={'274'}
      y={'190'}
      width={'51'}
      height={'51'}
    >
      <rect x={'274.047'} y={'190.504'} width={'50.2655'} height={'50.2655'} fill={'#D9D9D9'} />
    </mask>
    <g mask={'url(#mask1_14741_174161)'}>
      <path
        d={
          'M299.179 229.251C298.865 229.251 298.612 229.155 298.419 228.964C298.228 228.771 298.132 228.518 298.132 228.203V216.684H286.613C286.299 216.684 286.046 216.58 285.855 216.37C285.662 216.161 285.566 215.916 285.566 215.637C285.566 215.323 285.662 215.07 285.855 214.877C286.046 214.686 286.299 214.59 286.613 214.59H298.132V203.071C298.132 202.757 298.237 202.503 298.446 202.31C298.656 202.119 298.9 202.024 299.179 202.024C299.494 202.024 299.747 202.119 299.94 202.31C300.131 202.503 300.227 202.757 300.227 203.071V214.59H311.746C312.06 214.59 312.313 214.695 312.506 214.904C312.697 215.114 312.793 215.358 312.793 215.637C312.793 215.951 312.697 216.205 312.506 216.397C312.313 216.589 312.06 216.684 311.746 216.684H300.227V228.203C300.227 228.518 300.122 228.771 299.912 228.964C299.703 229.155 299.459 229.251 299.179 229.251Z'
        }
        fill={'#714EF5'}
      />
    </g>
    <path
      d={
        'M3.62884 108.499C4.26373 100.764 11.0395 95.001 18.7789 95.6292C26.5183 96.2574 32.2792 103.047 31.6546 110.785C31.0197 118.52 24.2439 124.282 16.5045 123.654C8.76506 123.026 3.00422 116.237 3.62884 108.499Z'
      }
      fill={'#E2FBF3'}
    />
    <path
      d={
        'M385.71 7.77464C386.009 4.12972 389.202 1.41418 392.849 1.71019C396.496 2.0062 399.211 5.20547 398.916 8.85192C398.617 12.4968 395.424 15.2124 391.777 14.9164C388.13 14.6204 385.416 11.4211 385.71 7.77464Z'
      }
      fill={'#E2FBF3'}
    />
    <path
      d={
        'M56.3669 42.6473C55.6456 42.4197 54.9503 42.1101 54.2825 41.713C52.0321 40.3718 50.4396 38.241 49.7992 35.7016C49.157 33.1676 49.5388 30.5302 50.88 28.2799C53.6435 23.6365 59.6698 22.1139 64.3131 24.8774C66.5635 26.2186 68.156 28.3496 68.7965 30.889C69.4369 33.4284 69.0569 36.0602 67.7157 38.3106C66.3745 40.5609 64.2435 42.1535 61.7041 42.794C59.9155 43.2448 58.0826 43.1889 56.3669 42.6473ZM54.1329 30.2139C53.3124 31.5952 53.0719 33.2137 53.4701 34.7694C53.8683 36.325 54.8424 37.6359 56.222 38.4619C57.6033 39.2824 59.2218 39.5229 60.7774 39.1247C62.3331 38.7265 63.644 37.7523 64.4699 36.3728C65.2905 34.9915 65.5255 33.3714 65.1327 31.8174C64.7345 30.2617 63.7604 28.9509 62.3808 28.1249C60.9995 27.3044 59.3812 27.0638 57.8255 27.462C56.2698 27.8602 54.9589 28.8343 54.1329 30.2139Z'
      }
      fill={'#E2FBF3'}
    />
    <path
      d={
        'M370.367 41.6473C369.646 41.4197 368.95 41.1101 368.282 40.713C366.032 39.3718 364.44 37.241 363.799 34.7016C363.157 32.1676 363.539 29.5302 364.88 27.2799C367.643 22.6365 373.67 21.1139 378.313 23.8774C380.563 25.2186 382.156 27.3496 382.796 29.889C383.437 32.4284 383.057 35.0602 381.716 37.3106C380.374 39.5609 378.243 41.1535 375.704 41.794C373.915 42.2448 372.083 42.1889 370.367 41.6473ZM368.133 29.2139C367.312 30.5952 367.072 32.2137 367.47 33.7694C367.868 35.325 368.842 36.6359 370.222 37.4619C371.603 38.2824 373.222 38.5229 374.777 38.1247C376.333 37.7265 377.644 36.7523 378.47 35.3728C379.29 33.9915 379.525 32.3714 379.133 30.8174C378.734 29.2617 377.76 27.9509 376.381 27.1249C374.999 26.3044 373.381 26.0638 371.825 26.462C370.27 26.8602 368.959 27.8343 368.133 29.2139Z'
      }
      fill={'#E2FBF3'}
    />
  </svg>
);
