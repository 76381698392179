/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext, useMemo } from 'react';
import type { Entity } from '@lama/common-types';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import type { DocumentWithIssues } from '@lama/document-service-client';
import type { DocumentBoxProps as InnerDocumentBoxProps } from '@lama/app-components';
import { DocumentBox as DocumentBoxInner, useConfirmModal } from '@lama/app-components';
import { useAsyncFn } from 'react-use';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUpdateRequirement } from '../../hooks/react-query/requirement/useUpdateRequirement';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { useUpdateDocumentMutation } from '../../../components/Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useUpdateDocumentMutation';
import { useDeleteDocumentQuery } from './hooks/useDeleteDocumentQuery';
import { useUploadDocumentsMutation } from './hooks/useUploadDocumentsQuery';

interface DocumentBoxProps extends Partial<InnerDocumentBoxProps> {
  description?: string;
  topic?: string;
  document?: DocumentWithIssues;
  requirement?: EvaluatedOpportunityRequirement;
  entityId?: string;
  entityType?: Entity;
  applicationId?: string;
  sharedRequirementId?: string;
  requirementKey?: string | undefined;
}

export const DocumentBox: React.FC<DocumentBoxProps> = ({
  requirement: { id: requirementId, opportunityId, sources, exportConfiguration } = {},
  topic: topicProp,
  entityId,
  entityType,
  document,
  applicationId,
  dismissed,
  sharedRequirementId,
  requirementKey,
  description,
}) => {
  const { firstName, lastName } = useContext(UserDetailsContext);
  const { downloadDocumentsEnabled } = useFlags();
  const { mutate: uploadDocuments, isPending: isUploading } = useUploadDocumentsMutation();
  const { mutate: deleteDocument, isPending: isDeleting } = useDeleteDocumentQuery();
  const { mutateAsync: updateRequirement, isPending: isUpdatingRequirement } = useUpdateRequirement(
    opportunityId ?? '',
    requirementId ?? '',
  );
  const { mutate: updateDocument, isPending: isUpdatingDocument } = useUpdateDocumentMutation({ applicationId });

  const innerDescription = useMemo(() => (description || document?.description) ?? '', [description, document?.description]);
  const innerTopic = useMemo(() => (topicProp || document?.topic) ?? '', [topicProp, document?.topic]);

  const onUpdateDocumentName = useCallback(
    async (name: string) => {
      if (!document || document.filename === name) {
        return;
      }

      updateDocument({
        documentId: document?.id,
        payload: { filename: name },
      });
    },
    [document, updateDocument],
  );

  const { confirm } = useConfirmModal();

  const onUploadDocument = useCallback(
    async (file: File) => {
      if (!applicationId || !entityId || !entityType || !innerTopic || !innerDescription) {
        throw new Error(' required when adding a document, contact support');
      }
      uploadDocuments([
        {
          file,
          topic: innerTopic,
          entityId,
          entityType,
          description: innerDescription,
          requirementId,
          applicationId,
          sharedRequirementId,
          requirementKey,
          exportConfiguration,
        },
      ]);
    },
    [
      uploadDocuments,
      innerTopic,
      entityId,
      entityType,
      innerDescription,
      requirementId,
      applicationId,
      sharedRequirementId,
      requirementKey,
      exportConfiguration,
    ],
  );

  const [, onDescriptionChange] = useAsyncFn(
    async (newDescription: string) => {
      const requirementUpdatePayload = {
        sources: {
          uploadFilesSource: sources?.uploadFilesSource?.map((s) => (s.name === description ? { ...s, name: newDescription } : s)),
        },
      };
      await updateRequirement({ updateRequirementPayload: requirementUpdatePayload });
    },
    [description, sources?.uploadFilesSource, updateRequirement],
  );

  const onDeleteDocument = useCallback(() => {
    if (document) {
      deleteDocument({ documentId: document?.id });
    }
  }, [deleteDocument, document]);

  const onDismissDocument = useCallback(async () => {
    if (!applicationId || !entityId) {
      throw new Error('required when dismissing a document, contact support');
    }

    const confirmed = await confirm({
      title: 'Dismiss Document',
      message: 'Are you sure this document is not needed for this application?',
    });

    if (!confirmed) {
      return;
    }

    await updateRequirement({
      updateRequirementPayload: {
        sources: {
          uploadFilesSource: sources?.uploadFilesSource?.map((s) =>
            s.name === description
              ? {
                  ...s,
                  dismissDataByEntity: {
                    ...s.dismissDataByEntity,
                    [entityId]: {
                      reason: 'Other',
                      description: `Dismissed by ${firstName && lastName ? `${firstName} ${lastName}` : 'lender'}`,
                    },
                  },
                }
              : s,
          ),
        },
      },
    });
  }, [applicationId, confirm, description, entityId, firstName, lastName, sources?.uploadFilesSource, updateRequirement]);

  const onUndismissDocument = useCallback(async () => {
    await updateRequirement({
      updateRequirementPayload: {
        sources: {
          uploadFilesSource: sources?.uploadFilesSource?.map((s) =>
            s.name === description
              ? {
                  ...s,
                  dismissDataByEntity: {},
                }
              : s,
          ),
        },
      },
    });
  }, [description, sources?.uploadFilesSource, updateRequirement]);

  return (
    <DocumentBoxInner
      onChangeDocumentName={onUpdateDocumentName}
      description={innerDescription}
      document={document}
      dismissed={dismissed}
      onDescriptionChange={onDescriptionChange}
      onDocumentUpload={onUploadDocument}
      onDocumentRemoved={onDeleteDocument}
      onDismissSource={onDismissDocument}
      onUndismissSource={onUndismissDocument}
      downloadEnabled={downloadDocumentsEnabled}
      loading={isUploading || isDeleting || isUpdatingRequirement || isUpdatingDocument}
      dismissible
    />
  );
};
