import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { keyBy } from 'lodash-es';
import type { UnderwritingNote } from '@lama/contracts';
import { Flex } from '@lama/design-system';
import { Alert } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { useUsersByPartnerQuery } from '../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { DismissedFlagsCard } from '../CreditMemo/Sections/components/Notes/ExceptionCard';
import { FlagCard } from '../CreditMemo/Sections/components/Notes/FlagCard';
import { ApplicationContext } from '../ApplicationContext';

export const FlagsAndExceptionsList: FC<{ notes: UnderwritingNote[] }> = ({ notes }) => {
  const { opportunity } = useContext(ApplicationContext);
  const { partner } = useContext(UserDetailsContext);
  const { data: users } = useUsersByPartnerQuery(partner);

  mixpanel.track_pageview({
    applicationId: opportunity.application.id,
    opportunityId: opportunity.id,
    productId: opportunity.productId,
    page: 'flags & exceptions',
  });

  const usersById = useMemo(() => keyBy(users, 'id'), [users]);

  return (
    <Flex gap={4} flexDirection={'column'}>
      {notes?.length ? (
        <Alert severity={'warning'}>
          {`There are ${notes?.length} flagged issues. They should be marked as exceptions before approving the loan.`}
        </Alert>
      ) : null}
      {notes.map((note) =>
        note.type === 'cleared' ? (
          <DismissedFlagsCard key={note.id} note={note} user={usersById[note.clearedBy ?? note.createdBy]} />
        ) : note.type === 'exception' ? (
          <DismissedFlagsCard key={note.id} note={note} user={usersById[note.updatedBy]} />
        ) : note.type === 'flag' ? (
          <FlagCard key={note.id} flag={note} user={usersById[note.createdBy]} />
        ) : null,
      )}
    </Flex>
  );
};
