import { grey } from '@mui/material/colors';
import { isNil } from 'lodash-es';
import type { InformationTypes } from '@lama/app-components';
import type { CellContent } from './CreditMemoTable';

const numericInformationTypes = new Set<InformationTypes>([
  'currency',
  'currencyCompact',
  'percent',
  'number',
  'decimal',
  'date',
  'datetime',
]);

const getCellAlignment = (value: any, type: InformationTypes) =>
  numericInformationTypes.has(type) || isNil(value) ? ('right' as const) : ('left' as const);

export const valuesRow = (name: string, values: any[], type: InformationTypes): CellContent[] => [
  { value: name },
  ...values.map((value) => ({ value, type, align: getCellAlignment(value, type) })),
];

export const totalRow = (name: string, values: any[], type: InformationTypes): CellContent[] => [
  { value: name, bold: true },
  ...values.map((value) => ({ value, type, bold: true, align: getCellAlignment(value, type) })),
];

export const titleRow = (name: string, colspan = 2): CellContent[] => [{ value: name, colspan, bgColor: grey[100] }];
