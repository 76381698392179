import { Stack } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import CollateralEmptyStateImage from './collateralEmptyState.svg';

export const CollateralEmptyState: FC = () => (
  <Stack direction={'column'} alignItems={'center'} gap={2}>
    <CollateralEmptyStateImage />
  </Stack>
);
