import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import {
  ModifyItemButton,
  createUpdatePayload,
  getInitialFormValues,
  GenericAddOrEditDialog,
  getBusinessIdByEntity,
  ItemCard,
} from '@lama/app-components';
import { get, isEmpty, merge } from 'lodash-es';
import type { Entity } from '@lama/common-types';
import type { RequirementProperty } from '@lama/contracts';
import { useAsyncFn, useToggle } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { getApplicationEntityByType, getSourcedProperty } from '@lama/properties';
import { listRequirementToEmptyStateImage, requirementToListItemComponent } from '../requirementScreenToComponent';
import { ApplicationContext } from '../../../ApplicationContext';
import { useUpdateApplicationMutation } from '../../../../../shared/hooks/react-query/application/useUpdateApplication';
import { useGetCurrentRequirement } from '../hooks/useGetCurrentRequirement';
import { useUpdateBusiness } from '../../../../../shared/hooks/react-query/business/useUpdateBusiness';
import { useUpdatePerson } from '../../../../../shared/hooks/react-query/people/useUpdatePerson';

interface GenericListFormProps {
  fieldName: string;
  displayName: string;
  entityType: Entity;
  noItemsFieldName?: string;
  itemProperties: RequirementProperty[] | undefined;
}

export const GenericListForm: React.FC<GenericListFormProps> = ({
  fieldName,
  noItemsFieldName,
  displayName,
  entityType,
  itemProperties,
}) => {
  const { opportunity, application } = useContext(ApplicationContext);
  const itemRender = useMemo(() => requirementToListItemComponent[fieldName], [fieldName]);

  const currentRequirement = useGetCurrentRequirement(opportunity.id);

  const requirementEntity = useMemo(() => {
    const entities = currentRequirement
      ? getApplicationEntityByType(application, currentRequirement.entityType, currentRequirement.entityGroups)
      : [];
    return entities.find(({ id }) => id === currentRequirement?.entityId);
  }, [application, currentRequirement]);

  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(application.id, opportunity.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(opportunity.id);
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(opportunity.id);

  const listItems = useMemo<any[]>(() => get(requirementEntity, fieldName) ?? [], [requirementEntity, fieldName]);

  const [openDialog, toggleDialog] = useToggle(false);
  const [dialogInitialValues, setDialogInitialValues] = useState<Record<string, any> | null>();
  const markedNoItems = useMemo(
    () => (noItemsFieldName ? Boolean(get(requirementEntity, noItemsFieldName)) : false),
    [requirementEntity, noItemsFieldName],
  );

  const onClose = useCallback(() => {
    setDialogInitialValues(null);
    toggleDialog();
  }, [toggleDialog]);

  const [, onSubmit] = useAsyncFn(
    async (values: any) => {
      if (!currentRequirement) {
        return;
      }

      const updatedListItem = [...listItems];
      if (values.id) {
        const originalUpdatedItem = listItems.find((listItem: { id: string }) => listItem.id === values.id);
        updatedListItem[updatedListItem.findIndex((item: { id: any }) => item.id === values.id)] = merge({}, originalUpdatedItem, values);
      } else {
        updatedListItem.push({ ...values, id: uuidv4() });
      }
      const payload = createUpdatePayload(application, entityType, '', { [fieldName]: updatedListItem });

      if (!isEmpty(payload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: payload.updateApplicationPayload });
      }

      if (!isEmpty(payload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, currentRequirement.entityType, currentRequirement.entityId);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: payload.updateBusinessPayload });
        }
      }

      if (!isEmpty(payload.updatePersonPayload)) {
        await updatePerson({ personId: currentRequirement.entityId, updatePersonPayload: payload.updatePersonPayload });
      }
      onClose();
    },
    [currentRequirement, listItems, application, entityType, fieldName, onClose, updateApplication, updateBusiness, updatePerson],
  );

  const [, onDelete] = useAsyncFn(
    async (id: string) => {
      if (!currentRequirement) {
        return;
      }

      const updatedList = listItems.filter((listItem: { id: string }) => listItem.id !== id);
      const payload = createUpdatePayload(application, entityType, '', { [fieldName]: updatedList });

      if (!isEmpty(payload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: payload.updateApplicationPayload });
      }

      if (!isEmpty(payload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, currentRequirement.entityType, currentRequirement.entityId);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: payload.updateBusinessPayload });
        }
      }
      if (!isEmpty(payload.updatePersonPayload)) {
        await updatePerson({ personId: currentRequirement.entityId, updatePersonPayload: payload.updatePersonPayload });
      }
    },
    [application, currentRequirement, entityType, fieldName, listItems, updateApplication, updateBusiness, updatePerson],
  );

  const updateDialogInitialValues = useCallback(
    (listItem: any) => {
      const yearsBack = new Date().getUTCFullYear() - opportunity.referenceYear;

      const propertiesWithDecidedSource = itemProperties?.map((p) => getSourcedProperty(p, listItem, yearsBack)) ?? [];
      const initialValues = getInitialFormValues(propertiesWithDecidedSource, listItem);
      setDialogInitialValues(initialValues);
      toggleDialog();
    },
    [itemProperties, opportunity.referenceYear, toggleDialog],
  );

  const onClickEdit = useCallback(
    (id: string) => {
      const listItem = listItems.find((item: { id: string }) => item.id === id);
      updateDialogInitialValues(listItem);
    },
    [listItems, updateDialogInitialValues],
  );

  const onNoItemsClick = useCallback(async () => {
    if (noItemsFieldName && currentRequirement) {
      const payload = createUpdatePayload(application, entityType, '', { [noItemsFieldName]: !markedNoItems });

      if (!isEmpty(payload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: payload.updateApplicationPayload });
      }

      if (!isEmpty(payload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, currentRequirement.entityType, currentRequirement.entityId);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: payload.updateBusinessPayload });
        }
      }

      if (!isEmpty(payload.updatePersonPayload)) {
        await updatePerson({ personId: currentRequirement.entityId, updatePersonPayload: payload.updatePersonPayload });
      }
    }
  }, [application, currentRequirement, entityType, markedNoItems, noItemsFieldName, updateApplication, updateBusiness, updatePerson]);

  const onClickAddItem = useCallback(() => {
    updateDialogInitialValues({});
  }, [updateDialogInitialValues]);

  const EmptyState = useMemo(() => listRequirementToEmptyStateImage[fieldName], [fieldName]);

  return (
    <Stack flex={1} alignItems={'center'} gap={4}>
      {listItems.length ? (
        listItems.map((item: { id: string }) => (
          <ItemCard key={item.id} onDelete={onDelete} onEdit={onClickEdit} item={item} itemRender={itemRender} />
        ))
      ) : EmptyState ? (
        <EmptyState />
      ) : null}
      {!listItems.length && noItemsFieldName ? (
        <FormControlLabel
          label={'No items to add'}
          control={<Checkbox checked={markedNoItems} onChange={onNoItemsClick} />}
          sx={{ marginRight: 0 }}
        />
      ) : null}
      <ModifyItemButton onClick={onClickAddItem} text={'Add Item'} variant={'text'} disabled={markedNoItems || updatingApplication} />
      <GenericAddOrEditDialog
        entityType={entityType}
        open={openDialog}
        application={application}
        initialValues={dialogInitialValues ?? undefined}
        onClose={onClose}
        onSubmit={onSubmit}
        itemProperties={itemProperties}
        parentFieldName={fieldName}
        parentDisplayName={displayName}
        entity={requirementEntity}
        isLoading={updatingApplication || updatingBusiness || updatingPerson}
      />
    </Stack>
  );
};
