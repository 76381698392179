import type { Tradeline } from '@lama/contracts';
import { sumBy } from 'lodash-es';

export const getTradelinesSummary = (tradelines: Tradeline[]) => ({
  count: tradelines?.length ?? 0,
  balance: sumBy(tradelines, (t) => t.balanceAmount ?? 0),
  payments: sumBy(tradelines, (t) => t.monthlyPaymentAmount ?? 0),
  amountPastDue: sumBy(tradelines, (t) => t.amountPastDue ?? 0),
  highCredit: sumBy(tradelines, (t) => t.creditLimitAmount ?? t.originalLoanAmount ?? 0),
});
