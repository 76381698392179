import type { FinancialData, SpreadsSetting } from '@lama/contracts';
import { getPeriodsWithFinancials, sortPeriodsByYear } from '@lama/selectors';
import { getDefaultSpreadPeriods } from '../utils/getSpreadPeriods';

const getPeriods = (financials: FinancialData[], spreadsSettings: SpreadsSetting[], spreadName: string) => {
  const savedPeriods = spreadsSettings?.find((s) => s.spreadName === spreadName)?.selectedPeriods;

  if (savedPeriods?.length) {
    return savedPeriods;
  }

  const financialPeriods = financials?.length ? getPeriodsWithFinancials(financials) : [];
  return getDefaultSpreadPeriods(financialPeriods.map((p) => p.period));
};

export const getDisplayedPeriods = (financials: FinancialData[], spreadsSettings: SpreadsSetting[], spreadName: string) => {
  const periods = getPeriods(financials, spreadsSettings, spreadName);
  return sortPeriodsByYear(periods);
};
