import type { AffiliateApiModel } from '@lama/clients';
import React, { useMemo } from 'react';
import * as selectors from '@lama/selectors';
import type { FC } from 'react';
import { Flex, Text, greyPalette } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { InfoLine } from '@lama/app-components';
import { BorderedContainer } from '../../../../Application/shared/BorderedContainer';

export const AffiliateSegment: FC<{ affiliate: AffiliateApiModel }> = ({ affiliate }) => {
  const info: { title: string; lines: InfoLineProps[] }[] = useMemo(
    () => [
      {
        title: 'General Information',
        lines: [
          {
            label: 'LegalName',
            values: affiliate.legalName,
          },
          {
            label: 'Ownership',
            values: affiliate.ownershipPercentage ?? 0,
            type: 'percent',
          },
          {
            label: 'TIN',
            values: affiliate.tin,
          },
          {
            label: 'Address',
            values: selectors.businessFullAddress(affiliate),
          },
        ],
      },
    ],
    [affiliate],
  );

  return (
    <Flex flexDirection={'column'} gap={6}>
      <Text variant={'h5'} color={greyPalette[500]}>
        {`${affiliate.dba ?? affiliate.legalName}`}
      </Text>
      <BorderedContainer>
        {info.map((i) => (
          <Flex flexDirection={'column'} key={i.title} gap={4}>
            <Text variant={'body1'} color={greyPalette[500]}>
              {i.title}
            </Text>
            <Flex flexDirection={'column'} gap={2}>
              {i.lines.map((line) => (
                <InfoLine key={line.label} label={line.label} values={line.values} type={line.type} />
              ))}
            </Flex>
          </Flex>
        ))}
      </BorderedContainer>
    </Flex>
  );
};
