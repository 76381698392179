import type { FC } from 'react';
import React, { useMemo } from 'react';
import { compact } from 'lodash-es';
import { Flex, Grid } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';
import type { OfacSearchResult, SearchStatusResult } from '@lama/contracts';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../SegmentContainer';
import { ofacStatusToLabel } from './ofacStatusToLabel';

export const OfacReportSummary: FC<{
  timestamp?: string;
  searchLists: string[];
  dataFetched: boolean;
  ofacStatus?: SearchStatusResult;
  dataNotFetchedText: string;
  searchResults?: OfacSearchResult[];
}> = ({ timestamp, dataFetched, ofacStatus, dataNotFetchedText, searchLists, searchResults }) => {
  const ofacStatusLabel = useMemo(() => {
    if (!dataFetched) {
      return { text: dataNotFetchedText };
    }

    return ofacStatusToLabel(ofacStatus);
  }, [dataFetched, dataNotFetchedText, ofacStatus]);
  const names = searchResults && compact(searchResults.map((result) => result.name));
  const aliases = searchResults && compact(searchResults.flatMap((result) => result.alternativeNames));

  return (
    <SegmentContainer>
      <Flex flexDirection={'column'} gap={4}>
        <KeyValuePair name={'Results'} value={ofacStatusLabel.text} valueColor={ofacStatusLabel.color} />
        <Grid columns={3}>
          <KeyValuePair name={'Timestamp'} value={dataFetched ? formatValue(timestamp, 'datetime') || '-' : '-'} />
          <KeyValuePair name={'OFAC Search Lists'} value={dataFetched && searchLists.length ? searchLists.join(', ') : '-'} />
          <KeyValuePair name={'Fuzzy Search'} value={dataFetched ? 'Yes' : '-'} />
          {names ? <KeyValuePair name={'Names found'} value={names ?? '-'} /> : null}
          {aliases ? <KeyValuePair name={'Aliases'} value={aliases ?? '-'} /> : null}
        </Grid>
      </Flex>
    </SegmentContainer>
  );
};
