import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { applicationBorrowingRelatedBusinessesSelector } from '@lama/selectors';
import type { ClosingTaskComponentProps } from '../closingTaskComponentProps.js';
import { ApplicationContext } from '../../../../ApplicationContext.js';
import { MiddeskBusinessTaskComponent } from './MiddeskBusinessTaskComponent.js';

export const MiddeskPullDocumentsTaskComponent: FC<ClosingTaskComponentProps> = (props) => {
  const { application } = useContext(ApplicationContext);

  const relevantBusinesses = useMemo(() => applicationBorrowingRelatedBusinessesSelector(application), [application]);

  return (
    <Flex flexDirection={'column'} gap={6}>
      {relevantBusinesses.map(({ business }) => (
        <MiddeskBusinessTaskComponent key={business.id} business={business} {...props} />
      ))}
    </Flex>
  );
};
