import type { OpportunityClosingTask } from '@lama/contracts';
import type { Document } from '@lama/document-service-client';
import type { Entity } from '@lama/common-types';
import { Flex, Text, grayPalette, Collapse } from '@lama/design-system';
import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { styled } from '@mui/material';
import { ApplicationContext } from '../../ApplicationContext';
import { useApplicationDocumentsQuery } from '../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { CommentsSegment } from '../../../../shared/components/Comments/CommentsSegment';
import { MiscellaneousDocumentBox } from '../../OpportunityRequirements/MiscellaneousDocumentList/MiscellaneousDocumentBox';
import { closingTasksComponentsByType } from './ClosingTasksTypes/closingTasksComponentsMap';
import { TaskCardHeader } from './TaskCardHeader';
import { TaskCardFooter } from './TaskCardFooter';
import { ArbitraryClosingTaskDocumentUpload } from './ArbitraryClosingTaskDocumentUpload';

interface ClosingTaskCardProps {
  task: OpportunityClosingTask;
  taskDocuments?: Document[];
  open: boolean;
  onClick: (taskId: string) => void;
}

const StyledCard = styled(Flex)<{ open: boolean }>(
  ({ open }) => `
    border-radius: 8px;
    border: 1px solid;
    background: white;
    flex-direction: column;
    border-color: ${grayPalette[300]};
    transition: background 0.1s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    ${open && 'box-shadow: 0px 8px 25px 0px rgba(56, 0, 100, 0.08);'}
`,
);

interface AdditionalDocumentsSectionProps {
  documents: Document[];
  taskId: string;
  relatedEntityId?: string;
  relatedEntityType?: Entity;
}

export const AdditionalDocumentsSection = ({ documents, taskId, relatedEntityId, relatedEntityType }: AdditionalDocumentsSectionProps) => {
  const {
    opportunity,
    application: { id: applicationId },
  } = useContext(ApplicationContext);
  const relatedDocuments = useMemo(
    () => documents.filter((document) => !relatedEntityId || document.relatedEntityId === relatedEntityId),
    [documents, relatedEntityId],
  );
  return (
    <>
      {relatedDocuments.map((document) => (
        <MiscellaneousDocumentBox key={document.id} description={document.filename} document={document} />
      ))}
      <Flex p={1} borderRadius={'8px'}>
        <ArbitraryClosingTaskDocumentUpload
          opportunityId={opportunity.id}
          applicationId={applicationId}
          taskId={taskId}
          relatedEntityId={relatedEntityId}
          relatedEntityType={relatedEntityType}
        />
      </Flex>
    </>
  );
};

export const ClosingTaskCard: FC<ClosingTaskCardProps> = ({ task, open, onClick }) => {
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);

  const { data: relatedDocuments } = useApplicationDocumentsQuery({ applicationId });

  const TaskComponent = useMemo(() => closingTasksComponentsByType[task.type], [task]);

  const taskDocuments = useMemo(
    () => relatedDocuments?.filter((document) => task.documentIds?.includes(document.id)) ?? [],
    [relatedDocuments, task.documentIds],
  );
  const { hideAdditionalDocumentsSection, id: taskId, description } = task;
  return (
    <StyledCard open={open}>
      <TaskCardHeader open={open} onClick={onClick} task={task} />
      <Collapse expanded={open}>
        <Flex flexDirection={'column'} gap={6} p={6}>
          {description ? (
            <Text variant={'body1'} color={'secondary'}>
              {description}
            </Text>
          ) : null}
          {TaskComponent ? <TaskComponent task={task} taskDocuments={taskDocuments} /> : null}
          <CommentsSegment relatedItemId={taskId} relatedItemType={'closingTask'} showExportHelperText={false} />
          {hideAdditionalDocumentsSection || task.type === 'middesk' ? null : (
            <AdditionalDocumentsSection taskId={taskId} documents={taskDocuments} />
          )}
          <TaskCardFooter task={task} />
        </Flex>
      </Collapse>
    </StyledCard>
  );
};
