import React, { useMemo, useCallback, useContext } from 'react';
import {
  BaseDialog,
  PropertyFormikInput,
  FormikPicker,
  LoadingPage,
  ModifyItemButton,
  FormikMoneyInputField,
  displayToast,
} from '@lama/app-components';
import type { ApplicationCreateApiModel, CreateBusinessModelApi, PersonCreateApiModel } from '@lama/clients';
import type { CreateBorrowerRequestBody } from '@lama/user-service-client';
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import type { FC } from 'react';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { orderBy } from 'lodash-es';
import { emailValidation, firstNameValidation, lastNameValidation, requestAmountValidation } from '@lama/yup-validations';
import { Flex } from '@lama/design-system';
import type { LabeledValue } from '@lama/contracts';
import { UserDetailsContext } from '../../shared/context/UserDetailsContext';
import { useProductsByPartnerQuery } from '../../shared/hooks/react-query/product/useProductsByPartnerQuery';
import { useCreatePerson } from '../../shared/hooks/react-query/people/useCreatePerson';
import { useCreateApplicationMutation } from './hooks/useCreateApplicationMutation';
import { useCreateBorrowerMutation } from './hooks/useCreateBorrowerMutation';
import { useCreateBusinessMutation } from './hooks/useCreateBusinessMutation';

interface CreateApplicationDetails {
  businessName: string;
  ownerEmail: string;
  ownerFirstName: string;
  ownerLastName: string;
  requestedAmount: string;
  productId: string;
  soleOwner: boolean;
}

const initialValues: CreateApplicationDetails = {
  businessName: '',
  ownerEmail: '',
  ownerFirstName: '',
  ownerLastName: '',
  requestedAmount: '',
  productId: '',
  soleOwner: false,
};

interface AddApplicationDialogProps {
  open: boolean;
  handleClose: (applicationId?: string) => void;
}

const validationSchema = yup.object({
  businessName: yup.string().required('Business name is required'),
  ownerEmail: emailValidation,
  ownerFirstName: firstNameValidation,
  ownerLastName: lastNameValidation,
  requestedAmount: requestAmountValidation.required('Required'),
  productId: yup.string().required('Product is required'),
});

export const AddApplicationDialog: FC<AddApplicationDialogProps> = ({ open, handleClose }) => {
  const { partner, email: lenderEmail } = useContext(UserDetailsContext);
  const { data: allPartnerProducts, isPending: loadingPartnerProducts } = useProductsByPartnerQuery(partner);
  const { mutateAsync: createApplication, isPending: isCreatingApplication } = useCreateApplicationMutation();
  const { mutateAsync: createBusiness, isPending: isCreatingBusiness } = useCreateBusinessMutation();
  const { mutateAsync: createPerson, isPending: isCreatingPerson } = useCreatePerson();
  const { mutateAsync: createBorrower, isPending: isCreatingBorrower } = useCreateBorrowerMutation();

  const onSumbit = useCallback(
    async (formValues: CreateApplicationDetails) => {
      if (!partner || !lenderEmail) {
        displayToast('Invalid user credentials', 'error');
        return;
      }

      const personToCreate: PersonCreateApiModel = {
        id: uuidv4(),
        firstName: formValues.ownerFirstName,
        lastName: formValues.ownerLastName,
        email: formValues.ownerEmail,
      };

      const businessToCreate: CreateBusinessModelApi = {
        id: uuidv4(),
        people: [
          {
            ...personToCreate,
            guarantor: true,
          },
        ],
        legalName: formValues.businessName,
      };

      const createApplicationPayload: ApplicationCreateApiModel = {
        id: uuidv4(),
        initiator: personToCreate.id,
        requestedAmount: +formValues.requestedAmount,
        originalProductId: formValues.productId,
        originatingPartner: partner,
        onboardingComplete: true,
        relatedBusinesses: [
          {
            businessId: businessToCreate.id,
            relation: 'borrower',
          },
        ],
        relatedPeople: [],
      };

      const createBorrowerPayload: CreateBorrowerRequestBody = {
        email: formValues.ownerEmail,
        firstName: formValues.ownerFirstName,
        lastName: formValues.ownerLastName,
        id: uuidv4(),
        partner,
        personId: personToCreate.id,
      };

      await createPerson({ person: personToCreate });

      await createBusiness({ business: businessToCreate });

      await createBorrower({ createBorrowerPayload });

      await createApplication({ createApplicationPayload });

      handleClose(createApplicationPayload.id);
    },
    [createApplication, createBorrower, createBusiness, createPerson, handleClose, lenderEmail, partner],
  );

  const productOptions: LabeledValue[] = useMemo(
    () =>
      orderBy(allPartnerProducts, ['prettyName'], ['asc'])?.map((product) => ({
        value: product.id,
        label: product.prettyName,
      })) ?? [],
    [allPartnerProducts],
  );

  return (
    <BaseDialog open={open} onClose={handleClose} title={'Create Application'}>
      {loadingPartnerProducts ? (
        <LoadingPage />
      ) : (
        <Formik initialValues={initialValues} onSubmit={onSumbit} validationSchema={validationSchema}>
          {({ handleSubmit }) => (
            <Flex flexDirection={'column'} gap={8} width={'100%'}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PropertyFormikInput name={'businessName'} label={'Business Name'} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <PropertyFormikInput name={'ownerFirstName'} label={'First Name'} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <PropertyFormikInput name={'ownerLastName'} label={'Last Name'} fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <PropertyFormikInput name={'ownerEmail'} label={'Email'} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <FormikMoneyInputField name={'requestedAmount'} label={'Requested Amount'} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <FormikPicker name={'productId'} label={'Product'} values={productOptions} fullWidth />
                </Grid>
              </Grid>
              <ModifyItemButton
                text={'Create'}
                showIcon={false}
                variant={'outlined'}
                onClick={handleSubmit}
                loading={isCreatingApplication || isCreatingBusiness || isCreatingBorrower || isCreatingPerson}
                sx={{ height: '56px' }}
              />
            </Flex>
          )}
        </Formik>
      )}
    </BaseDialog>
  );
};
