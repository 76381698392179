import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useOpportunityRequirementsQuery } from '../../../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';

export const useGetCurrentRequirement = (opportunityId?: string) => {
  const { data: requirements } = useOpportunityRequirementsQuery(opportunityId);
  const [searchParams] = useSearchParams();

  const currentRequirementId = searchParams.get('requirementId');

  const currentRequirement = useMemo(() => requirements?.find((r) => r.id === currentRequirementId), [requirements, currentRequirementId]);

  return currentRequirement;
};
