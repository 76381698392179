import React, { useMemo, type FC } from 'react';
import type { UpdateAuditLogProps } from '../types';
import { AuditLog } from '../../AuditLog';

export const OpportunityCreditFraudulentActivityCleared: FC<UpdateAuditLogProps> = ({ audit, groupInfo }) => {
  const reason = audit[0]?.changes?.creditReportFraudulentActivityClearance?.reason;
  const text = useMemo(() => `Fraudulent activity alert has been cleared. Comment: "${reason}"`, [reason]);

  if (!reason) {
    return null;
  }

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={text}
      timestamp={groupInfo.timestamp}
    />
  );
};
