import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { InputValue } from '@lama/design-system';
import { RichInput } from '@lama/design-system';
import { ApplicationContext } from '../../ApplicationContext';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useCreateNoteMutation } from '../../../../shared/hooks/react-query/opportunity/useCreateNoteMutation';
import { useUpdateNoteMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateNoteMutation';
import { SegmentContainer } from '../SegmentContainer';

export const InlineNote: FC<{
  title: string;
  noteName: string;
  valueTemplate?: string;
  placeholder?: string;
  backwardCompatibleNoteName?: string;
  entityId?: string;
}> = ({ title, noteName, valueTemplate, placeholder, backwardCompatibleNoteName, entityId }) => {
  const { opportunity } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const { mutateAsync: addNote } = useCreateNoteMutation(opportunity.id, userId);
  const { mutateAsync: updateNote } = useUpdateNoteMutation(opportunity.id, userId);

  const sectionNote = useMemo(
    () =>
      opportunity.underwriting?.notes?.find(
        ({ title: noteTitle, entityId: noteEntityId }) =>
          noteName &&
          noteTitle === noteName &&
          (entityId ? noteEntityId === entityId || noteEntityId === backwardCompatibleNoteName : true),
      ),
    [backwardCompatibleNoteName, entityId, noteName, opportunity.underwriting?.notes],
  );

  const onNoteSubmit = useCallback(
    async ({ value }: InputValue) => {
      await (sectionNote
        ? updateNote({
            noteId: sectionNote.id,
            notePayload: { text: value, entityId, title: noteName },
          })
        : addNote({
            id: uuidv4(),
            text: value,
            title: noteName,
            entityId,
            type: 'inline',
          }));
    },
    [sectionNote, updateNote, addNote, noteName, entityId],
  );

  return (
    <SegmentContainer padding={'12px'} title={title}>
      <RichInput placeholder={placeholder ?? 'Type here'} value={sectionNote?.text ?? valueTemplate} onSubmit={onNoteSubmit} />
    </SegmentContainer>
  );
};
