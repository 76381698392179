import React, { useContext, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { LoadingPage, getRequirementsByCategory } from '@lama/app-components';
import { useSearchParams } from 'react-router-dom';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../../ApplicationContext';
import { useOpportunityRequirementsQuery } from '../../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { RequirementsSideNav } from './RequirementsSideNav';
import { RequirementContent } from './RequirementContent';

export const OpportunityRequirementsPage: FC = () => {
  const { opportunity, application } = useContext(ApplicationContext);
  const { id: opportunityId } = opportunity;

  const { data: requirements, isPending: loadingRequirements } = useOpportunityRequirementsQuery(opportunityId);
  const [searchParams, setSearchParams] = useSearchParams();

  const currentRequirementId = searchParams.get('requirementId');

  const currentRequirement = useMemo(() => requirements?.find((r) => r.id === currentRequirementId), [requirements, currentRequirementId]);

  const requirementsByCategory = useMemo(
    () => (requirements?.length ? getRequirementsByCategory(requirements, application) : []),
    [application, requirements],
  );

  useEffect(() => {
    if (!currentRequirement) {
      const firstRequirementIdOfFirstCategory = requirementsByCategory[0]?.requirements[0]?.id;

      if (firstRequirementIdOfFirstCategory) {
        setSearchParams({ requirementId: firstRequirementIdOfFirstCategory }, { replace: true });
      }
    }
  }, [currentRequirement, requirementsByCategory, setSearchParams]);

  if (loadingRequirements) {
    return <LoadingPage />;
  }

  if (!requirements) {
    return null;
  }

  return (
    <Flex flexDirection={'row'} flex={1} border={'1px solid #E0E0E0'} borderRadius={1} minHeight={'90vh'}>
      <RequirementsSideNav requirementsByCategory={requirementsByCategory} />
      {currentRequirement ? <RequirementContent requirement={currentRequirement} /> : null}
    </Flex>
  );
};
