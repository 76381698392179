import React, { Flex, Grid, Text } from '@lama/design-system';
import { type FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReportCard } from '../ReportCard';
import { EmptyReportCardState } from '../EmptyReportCardState';
import { PipelineReport } from './PipelineReport';
import { PipelineReportOld } from './PipelineReportOld';
import { HmdaReport } from './HmdaReport';
import { AanReport } from './AanReport';
import { WithdrawlReport } from './WithdrawlReport';
import { OfacResultsReport } from './OfacResultsReport';
import { NoaReport } from './NoaReport';
import { RegBReport } from './RegBReport';

const AdditionalReport: FC = () => (
  <ReportCard>
    <Flex flexDirection={'column'} gap={2} alignItems={'center'} justifyContent={'center'}>
      <Flex flex={'column'} width={'80%'} height={'55%'} alignItems={'center'} justifyContent={'center'} gap={2}>
        <EmptyReportCardState />
      </Flex>
      <Text textAlign={'center'} variant={'body2'}>
        {'Please contact your account manager to set up additional reports.'}
      </Text>
    </Flex>
  </ReportCard>
);
export const Reports: FC = () => {
  const { enableRejectionReports, enableHmdaReport, enableOfacResultsReport, enableNoaReport, enableRegBReport, getPipelineReportFromBE } =
    useFlags();

  return (
    <Grid columns={2} pb={8}>
      {getPipelineReportFromBE ? <PipelineReport /> : <PipelineReportOld />}
      {enableRejectionReports ? (
        <>
          <AanReport />
          <WithdrawlReport />
        </>
      ) : null}
      {enableHmdaReport ? <HmdaReport /> : null}
      {enableNoaReport ? <NoaReport /> : null}
      {enableOfacResultsReport ? <OfacResultsReport /> : null}
      {enableRegBReport ? <RegBReport /> : null}
      <AdditionalReport />
    </Grid>
  );
};
