/* eslint-disable @typescript-eslint/naming-convention */
import type React from 'react';
import { createTheme } from '@mui/material/styles';
import { amber, grey, red } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Theme {
    borders: {
      light: React.CSSProperties['color'];
    };
  }

  interface Palette {
    borders: Palette['primary'];
    neutral: Palette['primary'];
    greyHover: Palette['primary'];
    link: {
      color: React.CSSProperties['color'];
    };
  }

  interface PaletteOptions {
    borders: PaletteOptions['primary'];
    neutral: PaletteOptions['primary'];
    greyHover: Palette['primary'];
    link: {
      color: React.CSSProperties['color'];
    };
  }

  interface TypographyVariants {
    badge: React.CSSProperties;
    tooltip: React.CSSProperties;
    subTooltip: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    chartTotal: React.CSSProperties;
    signature: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    badge?: React.CSSProperties;
    tooltip?: React.CSSProperties;
    subTooltip?: React.CSSProperties;
    buttonSmall?: React.CSSProperties;
    chartTotal?: React.CSSProperties;
    signature?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    badge: true;
    tooltip: true;
    subTooltip: true;
    buttonSmall: true;
    chartTotal: true;
    signature: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#714EF5',
      dark: '#3221C1',
      light: '#F3F0FF',
    },
    secondary: {
      main: '#79F5BA',
      dark: '#40C18A',
      light: '#DAFFEE',
    },
    text: {
      primary: '#0C2007',
      secondary: '#00000099',
      disabled: '#C5C5C5',
    },
    borders: {
      main: grey[300],
    },
    link: {
      color: '#1565C0',
    },
    warning: {
      main: amber[700],
      contrastText: '#fff',
    },
    error: {
      main: red[400],
      light: '#EF5350',
      contrastText: '#fff',
    },
    success: {
      main: '#31A985',
      light: '#44CFA6',
      dark: '#31A985',
      contrastText: '#fff',
    },
    greyHover: createTheme().palette.augmentColor({
      color: { main: '#f5f6f6' },
      name: 'greyHover',
    }),
    neutral: createTheme().palette.augmentColor({
      color: { main: '#EEEEEE' },
      name: 'neutral',
    }),
  },
  typography: {
    h3: {
      fontFamily: "'Ubuntu', sans-serif",
    },
    h4: {
      fontFamily: "'Ubuntu', sans-serif",
    },
    h5: {
      fontFamily: "'Ubuntu', sans-serif",
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 500,
    },
    h6: {
      fontFamily: "'Ubuntu', sans-serif",
      fontSize: '20px ',
      lineHeight: '28px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    button: {
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '26px',
      textTransform: 'none',
    },
    buttonSmall: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '22px',
    },
    badge: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '12px',
    },
    tooltip: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '14px',
    },
    subTooltip: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '500',
      fontSize: '6px',
      lineHeight: '14px',
    },
    chartTotal: {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
    },
    signature: {
      fontFamily: "'Angello', sans-serif",
      fontWeight: '400',
      fontSize: '64px',
      lineHeight: '64px',
    },
    allVariants: {
      fontFamily: "'Poppins', sans-serif",
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: "'Poppins', sans-serif",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '4px',
          textTransform: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          textTransform: 'none',
        },
      },
    },
    MuiStack: {
      defaultProps: {
        borderRadius: '4px',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#EF5350',
          '&$error': {
            color: '#EF5350',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        p: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 400,
          marginBlockStart: '0px',
          marginBlockEnd: '0px',
        },
      },
    },
  },
});
