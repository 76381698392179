/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import type { FC } from 'react';
import type { AgGridReact } from '@ag-grid-community/react';
import React, { useContext, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { compact, intersection, isEmpty, isNil, keyBy, uniq } from 'lodash-es';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Add } from '@mui/icons-material';
import type { GridReadyEvent, IRowNode } from '@ag-grid-community/core';
import { useDebounce, useSessionStorage, useToggle } from 'react-use';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingPage } from '@lama/app-components';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Flex } from '@lama/design-system';
import type { PipelineItem } from '@lama/pipeline-items-service-client';
import { applicationServiceClient } from '../../shared/clients/applicationServiceClient';
import { UserDetailsContext } from '../../shared/context/UserDetailsContext';
import { useUsersByPartnerQuery } from '../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { useGetPipelineQuery } from '../../shared/hooks/react-query/pipeline/useGetPipelineQuery';
import { useGetPartnersQuery } from '../../shared/hooks/react-query/partner/useGetPartnersQuery';
import { useGetPartnersProductsQuery } from '../../shared/hooks/react-query/product/useGetPartnersProductsQuery';
import { ApplicationStatusDisplayNameMappingContext } from '../../shared/context/ApplicationStatusDisplayNameMappingContext';
import { PipelineEmptyState } from './PipelineEmptyState';
import { getPipelineCategoriesForItem } from './getPipelineCategoryForItem';
import { PipelineFilters } from './PipelineFilters';
import { PipelineCategoryTabs, pipelineCategories } from './PipelineCategoryTabs';
import { AddApplicationDialog } from './AddApplicationDialog';
import { useGetBusinessImages } from './hooks/useGetBusinessImages';
import { PipelineTable } from './PipelineTable';
import { getColumnDefinitions } from './PipelineColumns';
import './styles/pipelineTable.css';
import type { CalculatedPipelineItem } from './types';
import { AddApplicationDialogV2 } from './AddApplicationDialogV2';
import type { PipelinePreservedState } from './localStorageUtils';
import { savePipelineState, loadPipelinePreservedState, searchFromState, searchToState, clearPipelineState } from './localStorageUtils';
import { SyncSpreadingSamplesButton } from './SyncSpreadingSamplesButton';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { PipelinePageV2 } from './PipelinePageV2';

const PIPELINE_LIMIT = 100;

export const PipelinePageV1: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [createApplicationDialogOpen, toggleCreateApplicationDialogOpen] = useToggle(false);
  const [globalFilter, setGlobalFilter] = useState<string>(searchParams.get('q') || '');
  const [agFilterCount, setAgFilterCount] = useState<number>(0);
  const queryClient = useQueryClient();
  const {
    showApplicationNumber,
    showReferrerColumnInPipeline,
    showRegBColumnInPipeline,
    showSourceColumnInPipeline,
    showCreateApplicationButton,
    showLastStatusUpdateInPipeline,
    limitPipelineResults,
    addApplicationDialogV2,
    enableWorkflows,
  } = useFlags();
  const [, defaultCategory] = pipelineCategories;
  const gridRef = useRef<AgGridReact>(null);

  const [selectedPipelineCategory, setSelectedPipelineCategory] = useSessionStorage(
    'selectedPipelineCategory',
    searchParams.get('category') || defaultCategory,
  );

  const { partner: userPartner, email, partnerPermissions } = useContext(UserDetailsContext);
  const { applicationStatusToDisplayName } = useContext(ApplicationStatusDisplayNameMappingContext);

  const userPartners = useMemo(() => {
    const res = uniq([
      ...(userPartner ? [userPartner] : []),
      ...(showSourceColumnInPipeline && partnerPermissions
        ? Object.entries(partnerPermissions)
            .filter(([, value]) => intersection(value, ['read', 'write']).length > 0)
            .map(([key]) => key)
        : []),
    ]);

    return res;
  }, [userPartner, showSourceColumnInPipeline, partnerPermissions]);

  const { data: partners, pending: loadingPartners } = useGetPartnersQuery(userPartners);

  const partnerOptions = useMemo(() => (loadingPartners ? [] : partners ?? []), [loadingPartners, partners]);

  const { data: users } = useUsersByPartnerQuery(userPartner);
  const { data: products, pending: loadingProducts } = useGetPartnersProductsQuery(userPartners);
  const { data: persistantPipeline, isPending: loadingPersistantPipeline } = useGetPipelineQuery({
    ...(limitPipelineResults ? { limit: PIPELINE_LIMIT, page: 1 } : {}),
  });

  const saveQueryChanges = useCallback(
    (partialState: Partial<PipelinePreservedState>) => {
      const fullState = savePipelineState(partialState);
      const search = searchFromState(fullState);
      navigate(`${location.pathname}${search}`, { replace: true });
    },
    [location.pathname, navigate],
  );

  const productOptions = useMemo(() => (loadingProducts ? [] : products ?? []), [loadingProducts, products]);

  const onGridReady = useCallback(
    (params: GridReadyEvent<CalculatedPipelineItem>) => {
      if (location.search) {
        const state = searchToState(location.search);

        if (!isNil(state) && !isEmpty(state)) {
          setSelectedPipelineCategory(state.selectedCategory ?? defaultCategory);

          if (state.searchTerm) {
            setGlobalFilter(state.searchTerm);
            params.api.setGridOption('quickFilterText', state.searchTerm);
          }
          if (state.columnState) {
            params.api.applyColumnState({ state: state.columnState, defaultState: { sort: null } });
          }

          if (state.filterModel) {
            params.api.setFilterModel(state.filterModel);
            setAgFilterCount(Object.keys(state.filterModel).length);
          }
        }
      }
    },
    [defaultCategory, location, setSelectedPipelineCategory],
  );

  const hideCategories = useMemo(() => !!globalFilter, [globalFilter]);

  useEffect(() => {
    if (location.state?.preserved) {
      const state = loadPipelinePreservedState();

      if (state) {
        saveQueryChanges(state);
      }

      return;
    }

    if (!location.search) {
      clearPipelineState();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: businessImages } = useGetBusinessImages(
    persistantPipeline?.pipeline?.filter((p) => p.businessGooglePlaceId).map((p) => p.businessGooglePlaceId!) ?? [],
  );

  const userOptions = useMemo(
    () => users?.filter((user) => (email?.includes('@lama.ai') ? true : !user.email.includes('@lama.ai'))) ?? [],
    [email, users],
  );

  const businessImagesByPlaceId = useMemo(() => keyBy(businessImages, (r) => r.placeId), [businessImages]);

  const { getAccessTokenSilently } = useAuth0();

  const handleCategoryChange = useCallback(
    (_: React.SyntheticEvent, category: string) => {
      setSelectedPipelineCategory(category);

      if (gridRef.current) {
        gridRef.current.api.onFilterChanged();
      }

      saveQueryChanges({ selectedCategory: category });
    },
    [saveQueryChanges, setSelectedPipelineCategory],
  );

  const pipelineItemsWithCategories = useMemo(
    () =>
      compact(
        persistantPipeline?.pipeline?.map(
          (item: PipelineItem) =>
            ({
              ...item,
              pipelineCategories: getPipelineCategoriesForItem(item, !!enableWorkflows),
              assignedTo: users ? users.filter((user) => item.assignedToIds?.includes(user.id)) : [],
              imageUrl: businessImagesByPlaceId[item.businessGooglePlaceId ?? '']?.imageUrl,
            } as CalculatedPipelineItem),
        ),
      ),
    [persistantPipeline?.pipeline, enableWorkflows, users, businessImagesByPlaceId],
  );

  const onCreateApplicationClose = useCallback(
    async (applicationId?: string) => {
      toggleCreateApplicationDialogOpen();

      if (applicationId) {
        const token = await getAccessTokenSilently();
        try {
          const application = await applicationServiceClient.getApplication(applicationId, token);
          queryClient.setQueryData(['application', applicationId], application);
          navigate(`./${application.leadingOpportunityId}/requirements`);
        } catch (error) {
          console.log(error);
        }
      }
    },
    [getAccessTokenSilently, navigate, queryClient, toggleCreateApplicationDialogOpen],
  );

  const updateGlobalFilter = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.setGridOption('quickFilterText', globalFilter);
    }

    saveQueryChanges({ searchTerm: globalFilter });
  }, [globalFilter, saveQueryChanges]);

  const clearGlobalFilter = useCallback(() => {
    setGlobalFilter('');
    saveQueryChanges({ searchTerm: '' });
    if (gridRef.current) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      gridRef.current.api.setGridOption('quickFilterText', undefined);
    }
  }, [saveQueryChanges]);

  const doesItemFitCategory = useCallback(
    (item: IRowNode<CalculatedPipelineItem>) => !!item.data?.pipelineCategories.includes(selectedPipelineCategory),
    [selectedPipelineCategory],
  );

  const clearFilters = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.setFilterModel(null);
    }
    saveQueryChanges({ filterModel: {}, searchTerm: '', selectedCategory: defaultCategory });
  }, [defaultCategory, saveQueryChanges]);

  const onFilterChanged = useCallback(() => {
    if (gridRef.current) {
      const filters = gridRef.current.api.getFilterModel();
      setAgFilterCount(Object.keys(filters).length);
      saveQueryChanges({ filterModel: filters });
    }
  }, [saveQueryChanges]);

  const onSortChanged = useCallback(() => {
    if (gridRef.current) {
      const columnState = gridRef.current.api.getColumnState();
      saveQueryChanges({ columnState });
    }
  }, [saveQueryChanges]);

  const agColumnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        userOptions,
        productOptions,
        partnerOptions,
        showApplicationNumberColumnInPipeline: showApplicationNumber,
        showReferrerColumnInPipeline,
        showRegBColumnInPipeline,
        showSourceColumnInPipeline,
        showLastStatusUpdateInPipeline,
        applicationStatusToDisplayName,
      }),
    [
      userOptions,
      productOptions,
      partnerOptions,
      showApplicationNumber,
      showReferrerColumnInPipeline,
      showRegBColumnInPipeline,
      showSourceColumnInPipeline,
      showLastStatusUpdateInPipeline,
      applicationStatusToDisplayName,
    ],
  );

  const isExternalFilterPresent = useCallback(
    () => selectedPipelineCategory !== 'all' && !globalFilter,
    [globalFilter, selectedPipelineCategory],
  );

  useDebounce(updateGlobalFilter, 150, [globalFilter]);

  if (loadingPersistantPipeline || loadingProducts || loadingPartners) {
    return <LoadingPage />;
  }

  return (
    <>
      <Flex flexDirection={'column'} pt={4} height={'100%'}>
        <Flex justifyContent={'space-between'} alignItems={'center'} pt={6} pb={6} px={8} gap={16}>
          <PipelineFilters
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            onClearFilters={clearFilters}
            clearGlobalFilter={clearGlobalFilter}
            agFilterCount={agFilterCount}
          />
          <Flex gap={2}>
            <SyncSpreadingSamplesButton />
            {showCreateApplicationButton ? (
              <Button variant={'primary'} color={'primary'} size={'l'} startIcon={<Add />} onClick={toggleCreateApplicationDialogOpen}>
                {'Application'}
              </Button>
            ) : null}
          </Flex>
        </Flex>
        <PipelineCategoryTabs
          pipelineItems={pipelineItemsWithCategories}
          selectedPipelineCategory={selectedPipelineCategory}
          onChange={handleCategoryChange}
          hideCategories={hideCategories}
        />
        {pipelineItemsWithCategories.length ? (
          <PipelineTable
            ref={gridRef}
            columnDefs={agColumnDefinitions}
            rowData={pipelineItemsWithCategories}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesItemFitCategory}
            onFilterChanged={onFilterChanged}
            onGridReady={onGridReady}
            onSortChanged={onSortChanged}
          />
        ) : (
          <PipelineEmptyState />
        )}
      </Flex>
      {createApplicationDialogOpen ? (
        addApplicationDialogV2 ? (
          <AddApplicationDialogV2 open={createApplicationDialogOpen} handleClose={onCreateApplicationClose} />
        ) : (
          <AddApplicationDialog open={createApplicationDialogOpen} handleClose={onCreateApplicationClose} />
        )
      ) : null}
    </>
  );
};

export const PipelinePage: FC = () => {
  const { enablePaginatedPipeline } = useFlags();

  return enablePaginatedPipeline ? <PipelinePageV2 /> : <PipelinePageV1 />;
};
