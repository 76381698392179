import React, { useCallback, useContext, useEffect, useMemo, useRef, useState, type FC } from 'react';
import type { UnderwritingNote } from '@lama/contracts';
import type { InputValue } from '@lama/design-system';
import { Flex, greyPalette, RichInput } from '@lama/design-system';
import { useLocation, useToggle } from 'react-use';
import styled, { css, keyframes } from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUpdateNoteMutation } from '../../hooks/react-query/opportunity/useUpdateNoteMutation';
import { useUserQuery } from '../../hooks/react-query/user/useUserQuery';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { CommentTitle } from './CommentTitle';
import { CommentActions } from './CommentActions';
import { useUserSuggestions } from './hooks/useUserSuggestions';

interface CommentProps {
  comment: UnderwritingNote;
  opportunityId: string;
  rootComment?: boolean;
  titleSuffix?: React.ReactNode;
}

const flashAnimation = () => keyframes`
  0% {
    border-color: ${greyPalette[400]};
    box-shadow: 0px 0px 0px ${greyPalette[400]};
  }
  30% {
    box-shadow: 0px 4px 20px ${greyPalette[400]};
  }
  100% {
    border-color: ${greyPalette[300]};
    box-shadow: none;
  }
`;

// Styled component with the flashing animation
const CommentContainer = styled(Flex)<{ flash?: string }>`
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  ${({ flash }) =>
    flash &&
    css`
      animation: ${flashAnimation()} 1.2s ease-in-out;
      animation-delay: 1s;
    `}
`;

export const Comment: FC<CommentProps> = ({ comment, opportunityId, rootComment, titleSuffix }) => {
  const { userId } = useContext(UserDetailsContext);
  const [editMode, toggleEditMode] = useToggle(false);
  const { mutateAsync: updateNote } = useUpdateNoteMutation(opportunityId, userId);
  const { data: commentUser } = useUserQuery(comment.createdBy);
  const userSuggestions = useUserSuggestions();
  const location = useLocation();
  const commentRef = useRef<HTMLDivElement>(null);
  const { enableCommentMentions } = useFlags();
  const [hoveringOnComment, setHoveringOnComment] = useState(false);

  const onCommentHover = useCallback(() => {
    if (!hoveringOnComment) {
      setHoveringOnComment(true);
    }
  }, [hoveringOnComment]);

  const onCommentMouseLeave = useCallback(() => {
    if (hoveringOnComment) {
      setHoveringOnComment(false);
    }
  }, [hoveringOnComment]);

  const isCurrentComment = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('commentId') === comment.id;
  }, [comment.id]);

  const isCurrentUserSameAsCommentUser = useMemo(() => commentUser?.id === userId, [commentUser, userId]);

  const onTextSubmit = useCallback(
    async (change: InputValue) => {
      const fullPath = `${location.pathname}${location.search}`;

      await updateNote({
        noteId: comment.id,
        notePayload: { text: change.value, updatedBy: userId, mentionedUserIds: change.mentionedIds, url: fullPath },
      });
    },
    [comment.id, location.pathname, location.search, updateNote, userId],
  );

  useEffect(() => {
    if (isCurrentComment) {
      setTimeout(() => commentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 500);
    }
  }, [isCurrentComment]);

  return (
    <CommentContainer
      key={comment.id}
      flash={isCurrentComment ? comment.id : undefined}
      ref={commentRef}
      flexDirection={'column'}
      gap={'2px'}
      px={4}
      pr={4}
      pb={4}
      maxWidth={'100%'}
      pt={rootComment ? 4 : 0}
      borderTop={rootComment ? `1px solid ${greyPalette[300]}` : 'none'}
      borderLeft={`1px solid ${greyPalette[300]}`}
      borderRight={`1px solid ${greyPalette[300]}`}
      borderTopRightRadius={rootComment ? '8px' : '0px'}
      borderTopLeftRadius={rootComment ? '8px' : '0px'}
      onMouseEnter={onCommentHover}
      onMouseLeave={onCommentMouseLeave}
    >
      <Flex flex={0.5} alignItems={'center'} justifyContent={'space-between'}>
        <CommentTitle user={commentUser} createdAt={comment.updatedAt} titleSuffix={titleSuffix} />
        {!editMode ? (
          <CommentActions
            note={comment}
            opportunityId={opportunityId}
            toggleEditMode={toggleEditMode}
            isCurrentUserSameAsCommentUser={isCurrentUserSameAsCommentUser}
            visible={hoveringOnComment}
          />
        ) : null}
      </Flex>
      <Flex flex={0.85} flexDirection={'column'}>
        <RichInput
          value={comment.text}
          currentUserId={userId}
          suggestions={userSuggestions}
          onSubmit={onTextSubmit}
          allowEditing={isCurrentUserSameAsCommentUser}
          editMode={isCurrentUserSameAsCommentUser ? editMode : false}
          toggleEditMode={toggleEditMode}
          enableMentions={enableCommentMentions}
          editOnClick={false}
        />
      </Flex>
    </CommentContainer>
  );
};
