import React, { useMemo, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { CreateCondition } from '@lama/clients';
import type { AnyOfConditionConfiguration, NoneOfConditionConfiguration } from '@lama/conditions';
import type { NoneOfMatcher, AnyOfMatcher, HasAllMatcher, Condition } from '@lama/contracts';
import { TextField } from '@mui/material';
import type { FC } from 'react';
import { useDebounce } from 'react-use';
import { isNil } from 'lodash-es';
import { AutocompleteMultiPicker } from '@lama/app-components';
import { ConditionCard } from './ConditionCard';

export interface ConditionWithSelectMatcher extends Condition {
  conditionMatcher: AnyOfMatcher | HasAllMatcher | NoneOfMatcher;
}

interface SelectConditionProps {
  condition?: ConditionWithSelectMatcher;
  configuration: AnyOfConditionConfiguration | NoneOfConditionConfiguration;
  onChange: (condition: Condition | CreateCondition) => void;
}

export const SelectCondition: FC<SelectConditionProps> = ({ condition, configuration, onChange: onConditionChange }) => {
  const [value, setValue] = useState<string[]>(condition?.conditionMatcher.value || configuration.default);

  const conditionUpdated = useMemo(() => {
    if (condition) {
      return value !== condition?.conditionMatcher.value;
    }

    return value !== configuration.default;
  }, [condition, configuration.default, value]);

  const upsertCondition = useCallback(
    (active?: boolean) => {
      if (!conditionUpdated && isNil(active)) {
        return;
      }

      const conditionUpdate: CreateCondition = condition
        ? { ...condition, conditionMatcher: { ...condition.conditionMatcher, value }, active: active ?? true }
        : {
            id: uuidv4(),
            conditionMatcher: {
              entityType: configuration.entityType,
              fieldName: configuration.selector,
              type: configuration.type,
              value,
            },
            name: configuration.displayName,
            active: true,
          };

      onConditionChange(conditionUpdate);
    },
    [
      condition,
      conditionUpdated,
      configuration.displayName,
      configuration.entityType,
      configuration.selector,
      configuration.type,
      onConditionChange,
      value,
    ],
  );

  useDebounce(upsertCondition, 200, [value]);

  const onChange = useCallback((values: string[]) => {
    setValue(values);
  }, []);

  const options = useMemo(() => configuration.options.map((option) => option), [configuration.options]);

  const renderInput = useCallback(
    (params: any) => <TextField {...params} label={configuration.displayName} />,
    [configuration.displayName],
  );

  return (
    <ConditionCard configuration={configuration} active={condition?.active} onChange={upsertCondition}>
      <AutocompleteMultiPicker value={value} onChange={onChange} options={options} renderInput={renderInput} />
    </ConditionCard>
  );
};
