import { Flex } from '@lama/design-system';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { GenericPropertiesProvider } from '@lama/app-components';
import { CommentsSegment } from '../../../shared/components/Comments/CommentsSegment';
import { SectionDecisioning } from '../shared/SectionDecisioning';
import { SectionTitle } from '../shared/SectionTitle/SectionTitle';
import { customComponents } from '../OpportunityRequirements/OpportunityRequirements/RequirementScreens/customComponentsMap';
import { GenericSection } from '../shared/GenericSection/GenericSection';
import { underwritingSectionDisplayConfigurations } from '../shared/sectionDisplayConfigurations';
import { sectionComponentByName } from './sectionComponentByName';
import type { MultipliedUnderwritingSection } from './UnderwritingPage';

export const UnderwritingSectionContent: FC<{ section?: MultipliedUnderwritingSection }> = ({ section }) => {
  const sectionComponent = useMemo(() => {
    if (!section?.name) {
      return null;
    }

    const sectionSegments = underwritingSectionDisplayConfigurations[section.name];
    if (sectionSegments) {
      return <GenericSection segments={sectionSegments} sectionId={section.id} sectionEntity={section.entity} />;
    }

    const SectionComponent = sectionComponentByName[section.name];

    if (SectionComponent) {
      return <SectionComponent section={section} />;
    }

    return null;
  }, [section]);

  if (!section || !sectionComponent) {
    return null;
  }

  return (
    <GenericPropertiesProvider customComponents={customComponents} customSourceToValues={{}}>
      <Flex flexDirection={'column'} px={14} py={10} flex={1} overflow={'auto'} alignItems={'center'}>
        <Flex flexDirection={'column'} width={'100%'} maxWidth={'960px'} gap={8}>
          <Flex flexDirection={'column'} gap={4}>
            <SectionTitle sectionId={section.id} sectionType={'underwriting'} title={section.prettyName} />
            {section.hideUnderwritingActions ? null : <SectionDecisioning sectionId={section.id} sectionType={'underwriting'} />}
          </Flex>
          {sectionComponent}
          <Flex flexDirection={'column'} pt={20}>
            <CommentsSegment relatedItemId={section.id} relatedItemType={'underwriting'} />
          </Flex>
        </Flex>
      </Flex>
    </GenericPropertiesProvider>
  );
};
