/* eslint-disable @typescript-eslint/naming-convention */
import type { MutableRefObject, FC } from 'react';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Avatar, useConfirmModal } from '@lama/app-components';
import { useAsyncFn } from 'react-use';
import { Spinner } from '@lama/design-system';
import { grey } from '@mui/material/colors';
import { personFullName } from '@lama/selectors';
import type { User } from '@lama/user-service-client';
import { ApplicationContext } from '../../ApplicationContext';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { ActionMenuSearchInput } from '../../ActionsMenu/ActionMenuSearchInput';

interface ClosingTaskCompletedByMenuProps {
  menuProps: any;
  open: boolean;
  menuRef: MutableRefObject<null>;
  users?: User[];
  onClose: (assigneeId?: string) => void;
}

export const ClosingTaskCompletedByMenu: FC<ClosingTaskCompletedByMenuProps> = ({ users, menuProps, open, menuRef, onClose }) => {
  const { confirm } = useConfirmModal();
  const {
    opportunity: { assigneeIds },
  } = useContext(ApplicationContext);

  const { email } = useContext(UserDetailsContext);
  const [filterOptions, setFilterOptions] = useState('');
  const [modfiedAssigneeId, setModfiedAssigneeId] = useState<string | null>(null);

  const [{ loading }, updateOpportunityOwner] = useAsyncFn(
    async (event: React.MouseEvent<HTMLElement>) => {
      const userId = event.currentTarget.getAttribute('value');

      if (!userId) {
        return;
      }

      setModfiedAssigneeId(userId);

      if (assigneeIds?.includes(userId)) {
        const confirmed = await confirm({
          title: `Are you sure you want to unassign ${users?.find((user) => user.id === userId)?.firstName}?`,
        });
        if (confirmed) {
          onClose(userId);
        }
      } else {
        onClose(userId);
      }

      setModfiedAssigneeId(null);
    },
    [assigneeIds, confirm, onClose, users],
  );

  const handleClose = useCallback(() => {
    setFilterOptions('');
    onClose();
  }, [onClose]);

  const onFilterChange = useCallback((value: string) => {
    setFilterOptions(value);
  }, []);

  const userOptions = useMemo(() => {
    const filteredUsers = users?.filter((user) =>
      `${user.firstName.toLocaleLowerCase()} ${user.lastName.toLocaleLowerCase()}`.includes(filterOptions.toLocaleLowerCase()),
    );

    if (email?.includes('@lama.ai')) {
      return filteredUsers?.map((user) => ({ user, id: user.id })) ?? [];
    }
    return filteredUsers?.filter((user) => !user.email.includes('@lama.ai')).map((user) => ({ user, id: user.id })) ?? [];
  }, [email, filterOptions, users]);

  return (
    <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={handleClose}>
      <Stack gap={1}>
        <ActionMenuSearchInput onInputChange={onFilterChange} />
        <Stack gap={1} pt={1} px={1} sx={{ maxHeight: '300px', overflow: 'auto' }}>
          {userOptions.map(({ id, user }) => (
            <MenuItem
              key={id}
              value={id}
              onClick={updateOpportunityOwner}
              sx={{
                backgroundColor: assigneeIds?.includes(id) ? 'primary.light' : '',
                '&:hover': { bgcolor: grey[100] },
                borderRadius: '4px',
                px: 2,
              }}
            >
              <Stack flex={1} direction={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                <Stack maxWidth={'90%'} direction={'row'} gap={1} alignItems={'center'}>
                  <Avatar firstName={user.firstName} lastName={user.lastName} />
                  <Typography noWrap>{personFullName(user)}</Typography>
                </Stack>
                <Stack flex={0.2} direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                  {modfiedAssigneeId === id && loading ? <Spinner size={'s'} /> : null}
                </Stack>
              </Stack>
            </MenuItem>
          ))}
        </Stack>
      </Stack>
    </Menu>
  );
};
