/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import type { FormikHelpers } from 'formik';
import { Field, Formik } from 'formik';
import { Flex, Text, greyPalette } from '@lama/design-system';
import { displayToast, FormikAutoSave } from '@lama/app-components';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationContext } from '../../ApplicationContext';
import { useUpdateOpportunityMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { ConfirmLeaveWithUnsavedChanges } from '../../../../shared/components/ConfirmLeaveWithUnsavedChanges';
import { theme } from '../../../../shared/styling/theme';
import { SaveFormButton } from '../SaveFormButton';

export const creditElsewhereIdToLabel: Record<string, string> = {
  longerBusinessMaturity: 'Business needs longer maturity',
  loanExceedsLimit: 'Loan exceeds lenders policy limit it can lend to one customer',
  inadequateCollateral: 'Inadequate collateral',
  startup: 'Startup business, or ineligible industry',
  other: 'Other',
};

const CreditElsewhereContainer = styled(Flex)`
  @media print {
    page-break-inside: avoid;
  }
`;

export const CreditElsewhereSegment: FC<{ editable?: boolean }> = ({ editable = true }) => {
  const {
    opportunity: { id: opportunityId, underwriting },
  } = useContext(ApplicationContext);
  const { autoSaveEnabled } = useFlags();
  const { mutate: updateOpportunity, isPending: updatingOpportunity } = useUpdateOpportunityMutation(opportunityId);

  const initialValues = useMemo(
    () => ({
      creditElsewhere: underwriting?.additionalData?.creditElsewhere ?? [],
    }),
    [underwriting],
  );

  const onSubmit = useCallback(
    async (values: typeof initialValues, { resetForm }: FormikHelpers<any>) => {
      resetForm({ values });
      updateOpportunity(
        {
          underwriting: {
            additionalData: {
              creditElsewhere: values.creditElsewhere,
            },
          },
        },
        {
          onError: () => {
            displayToast('There was a problem processing the request. Please try again.', 'error');
          },
        },
      );
    },
    [updateOpportunity],
  );

  return (
    <CreditElsewhereContainer flexDirection={'column'} gap={2}>
      <Text variant={'body1'} color={greyPalette[500]}>
        {'Credit Elsewhere'}
      </Text>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ dirty, isValid, values, handleSubmit }) => (
          <ConfirmLeaveWithUnsavedChanges dirty={dirty}>
            <Flex flexDirection={'column'} gap={4}>
              <Flex flexDirection={'column'} gap={2}>
                {Object.entries(creditElsewhereIdToLabel).map(([id, label]) => (
                  <Field
                    key={id}
                    type={'checkbox'}
                    name={'creditElsewhere'}
                    control={<Checkbox disabled={!editable} />}
                    as={FormControlLabel}
                    value={id}
                    label={label}
                    checked={values.creditElsewhere.includes(id)}
                    sx={{ '& .MuiFormControlLabel-label': { color: `${theme.palette.text.primary} !important` } }}
                  />
                ))}
              </Flex>
              {autoSaveEnabled ? (
                <FormikAutoSave />
              ) : (
                <SaveFormButton loading={updatingOpportunity} submit={handleSubmit} disabled={!dirty || !isValid} />
              )}
            </Flex>
          </ConfirmLeaveWithUnsavedChanges>
        )}
      </Formik>
    </CreditElsewhereContainer>
  );
};
