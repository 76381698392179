import type { Relation } from '@lama/contracts';
import { orderBy } from 'lodash-es';

export const getPrimaryBusinessRelation = (businessRelations: Relation[]): Relation | undefined => {
  if (businessRelations.includes('borrower')) {
    return 'borrower';
  }

  return orderBy(businessRelations, (s) => s, ['desc'])[0];
};
