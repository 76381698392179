import type { AccountData } from '@lama/contracts';
import { Button, Card, CardContent, Collapse } from '@mui/material';
import { Avatar } from '@lama/app-components';
import React, { useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { sentenceCase } from 'change-case-all';
import { ArrowForward } from '@mui/icons-material';
import { formatValue } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';
import { sumBy } from 'lodash-es';
import ExpandButton from '../../../../../shared/components/ExpandButton';
import { AccountOwners } from './AccountOwners';
import { Charts } from './Charts';
import { TransactionsDialog } from './Transactions/TransactionsDialog';

interface AccountCardProps {
  account: AccountData;
  institution: { logo?: string; name?: string; institutionId: string };
  expand: boolean;
}

export const AccountCard: React.FC<AccountCardProps> = ({ account, institution, expand }) => {
  const [expanded, toggleExpanded] = useToggle(expand ?? false);
  const averageBalance = useMemo(() => {
    const exisitingBalances = account.balances.filter((balance) => balance?.date);

    if (!exisitingBalances.length) {
      return null;
    }

    return sumBy(account.balances, (balance) => balance?.current ?? 0) / exisitingBalances.length;
  }, [account.balances]);

  const positive = useMemo(() => averageBalance && averageBalance >= 0, [averageBalance]);

  const [open, setOpen] = useState(false);

  const onClickCard = useCallback(() => {
    toggleExpanded();
  }, [toggleExpanded]);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Card
      elevation={0}
      variant={'outlined'}
      sx={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '& .MuiCard-root': {
          border: 'border: 1px solid #E0E0E0',
        },
        padding: '12px 24px',
      }}
    >
      <CardContent
        sx={{
          p: 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:last-child': {
            paddingBottom: 0,
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={onClickCard}
      >
        <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Flex gap={2} alignItems={'center'}>
            <Avatar
              imgSrc={institution.logo}
              imgSize={'24px'}
              size={32}
              firstName={institution.name ?? '-'}
              lastName={institution.name?.split(' ')?.[1] ?? ''}
            />
            <Text variant={'body2'}>{institution.name ?? '-'}</Text>
            <Text variant={'h4'} color={'grey.300'}>
              {'\u00B7'}
            </Text>
            <Text variant={'body2'} color={'secondary'}>{`****${account.mask}`}</Text>
            <Text variant={'h4'} color={'grey.300'}>
              {'\u00B7'}
            </Text>
            <Text variant={'body2'} color={'secondary'}>
              {sentenceCase(account.type)}
            </Text>
          </Flex>
          <Flex gap={2} alignItems={'center'}>
            <Text variant={'body2'} color={'secondary'}>
              {'Avg. daily balance: '}
              <Text variant={'body2'} color={averageBalance ? (positive ? 'green.500' : 'red.500') : 'secondary'}>
                {averageBalance === null ? 'N/A' : `${positive ? '+' : ''}${formatValue(averageBalance, 'currency')}`}
              </Text>
            </Text>
            <ExpandButton expand={expanded} />
          </Flex>
        </Flex>
      </CardContent>
      <Collapse in={expanded} timeout={'auto'} unmountOnExit>
        <CardContent>
          <Flex flexDirection={'column'} gap={8}>
            <Charts account={account} />
            <Button
              endIcon={<ArrowForward />}
              variant={'contained'}
              sx={{ maxWidth: '240px' }}
              onClick={openDialog}
              disabled={!account.transactions.length}
            >
              {'Show Transactions'}
            </Button>
            <AccountOwners owners={account.owners} />
          </Flex>
        </CardContent>
      </Collapse>
      <TransactionsDialog transactions={account.transactions} open={open} onClose={closeDialog} />
    </Card>
  );
};
