import React, { useMemo } from 'react';
import type { SpreadRow } from '@lama/spreads-generator-client';
import type { Period } from '@lama/selectors';
import { Flex, Text } from '@lama/design-system';
import { compact } from 'lodash-es';
import type { PeriodDisplayFormat } from '../../types';
import { getFormTypeColumnTitle, getPeriodColumnTitle, getPeriodMonthsTitle } from '../../utils/getSpreadColumnTitle';

export const PeriodHeaderComponent = ({
  period,
  spreadRows,
  showFormTypeInSpreadColumnHeader,
  periodDisplayFormat,
}: {
  period: Period;
  spreadRows: SpreadRow[];
  showFormTypeInSpreadColumnHeader: boolean;
  periodDisplayFormat: PeriodDisplayFormat;
}) => {
  const columnSources = useMemo(() => {
    const columnId = `${period.startDate}-${period.endDate}`;
    return compact(spreadRows?.map((row) => row.valueByPeriod[columnId]?.financialAttribute?.selectedSource));
  }, [period, spreadRows]);

  return (
    <th>
      <Flex flexDirection={'column'} alignItems={'flex-end'} width={'100%'} gap={'0.2rem'}>
        <Text variant={'body3'}>{getPeriodColumnTitle(period, periodDisplayFormat)}</Text>
        <Text variant={'body4'} color={'secondary'}>
          {getPeriodMonthsTitle(period, periodDisplayFormat)}
        </Text>
        <Text variant={'body4'} color={'secondary'}>
          {getFormTypeColumnTitle(period, columnSources, showFormTypeInSpreadColumnHeader)}
        </Text>
      </Flex>
    </th>
  );
};
