import React, { useMemo, useContext } from 'react';
import type { FC } from 'react';
import type { LinkTabProps } from '@lama/app-components';
import { LinkTabs } from '@lama/app-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { compact } from 'lodash-es';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { openAiKey } from '../../framework/environment';
import { ApplicationContext } from './ApplicationContext';

const tabs = ({
  enableUnderwriting,
  enableCreditMemo,
  showDocumentHubTab,
  enableClosingTab,
  enableInsightsTab,
  fibtDemo,
}: {
  enableUnderwriting: boolean;
  enableCreditMemo: boolean;
  showDocumentHubTab: boolean;
  enableClosingTab: boolean;
  enableInsightsTab: boolean;
  fibtDemo: boolean;
}): LinkTabProps[] =>
  compact([
    { label: 'Overview', href: 'summary' },
    { label: 'Application', href: 'requirements' },
    showDocumentHubTab ? { label: 'Document Hub', href: 'document-hub' } : null,
    enableUnderwriting ? { label: 'Underwriting', href: 'underwriting' } : null,
    enableCreditMemo ? { label: fibtDemo ? 'Memo' : 'Credit Memo', href: 'credit-memo' } : null,
    { label: 'Closing', ...(enableClosingTab ? { href: 'closing', disabled: false } : { disabled: true }) },
    enableCreditMemo ? { label: 'Notes', href: 'notes' } : null,
    enableInsightsTab ? { label: 'Insights', href: 'insights', icon: <AutoAwesomeIcon fontSize={'small'} /> } : null,
  ]);

const accountOpeningTabs = (showDocumentHubTab: boolean) =>
  compact([
    { label: 'Overview', href: 'summary' },
    { label: 'Application', href: 'requirements' },
    showDocumentHubTab ? { label: 'Document Hub', href: 'document-hub' } : null,
    { label: 'Verification', disabled: true },
  ]);

export const ApplicationTabs: FC = () => {
  const {
    enableUnderwriting,
    accountOpening,
    enableCreditMemo: enableCreditMemoFeatureFlag,
    showDocumentHubTab,
    enableClosingTab,
    smartAssistantEnabled,
    fibtDemo,
  } = useFlags();
  const {
    product,
    opportunity: { openAiConfiguration },
  } = useContext(ApplicationContext);

  const applicationTabs = useMemo(() => {
    if (accountOpening) {
      return accountOpeningTabs(!!showDocumentHubTab);
    }

    const enableCreditMemo = enableCreditMemoFeatureFlag && product.creditMemoConfiguration?.sections.length;
    const enableInsightsTab = smartAssistantEnabled && openAiKey && openAiConfiguration?.assistantId && openAiConfiguration.threadId;

    return tabs({
      enableUnderwriting,
      enableCreditMemo,
      showDocumentHubTab,
      enableClosingTab,
      enableInsightsTab,
      fibtDemo,
    });
  }, [
    accountOpening,
    enableClosingTab,
    enableCreditMemoFeatureFlag,
    enableUnderwriting,
    openAiConfiguration?.assistantId,
    openAiConfiguration?.threadId,
    product.creditMemoConfiguration?.sections.length,
    showDocumentHubTab,
    smartAssistantEnabled,
    fibtDemo,
  ]);

  return <LinkTabs tabs={applicationTabs} />;
};
