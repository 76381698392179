import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, grayPalette, greyPalette } from '@lama/design-system';
import type { ColDef, ITooltipParams } from '@ag-grid-community/core';
import { isNil } from 'lodash-es';
import styled from 'styled-components';
import { parseISO } from 'date-fns';
import { Close } from '@mui/icons-material';
import { LoadingPage } from '@lama/app-components';
import type { SpreadRow } from '@lama/spreads-generator-client';
import { useUsersByPartnerQuery } from '../../../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { ApplicationContext } from '../../../../ApplicationContext';
import type { OpenDocumentParams } from '../SpreadTable/types';
import { valueDescriptionBySource } from './valueDescriptionBySource/valueDescriptionBySource';
import { SpreadTooltipFooter } from './SpreadTooltipFooter/SpreadTooltipFooter';

export interface SpreadTooltipProps extends ITooltipParams<SpreadRow> {
  applicationId: string;
  openDocument: (openDocumentParams: OpenDocumentParams) => void;
  startDate: string;
  endDate: string;
  allowAdjustments?: boolean;
}

const StyledTooltipContainer = styled(Flex)`
  border-radius: 4px;
  border: 1px solid ${grayPalette[300]};
  box-shadow: 0px 8px 25px 0px rgba(56, 0, 100, 0.08);
  max-width: 450px;
  position: relative;
`;

export const SpreadTooltip: FC<SpreadTooltipProps> = (props) => {
  const { api, colDef, rowIndex, applicationId, openDocument, hideTooltipCallback, allowAdjustments } = props;
  const { opportunity } = useContext(ApplicationContext);
  const { data: users, isLoading: loadingUsers } = useUsersByPartnerQuery(opportunity.partnerId);

  const row = useMemo(() => (!isNil(rowIndex) ? api?.getDisplayedRowAtIndex(rowIndex) : null), [api, rowIndex]);

  const currentValue = useMemo(() => {
    const columnId = (colDef as ColDef<SpreadRow>).colId;
    if (!columnId || !row?.data?.valueByPeriod) {
      return null;
    }

    return row.data.valueByPeriod[columnId] ?? null;
  }, [colDef, row?.data?.valueByPeriod]);

  const allowRowAdjustments = useMemo(
    () => allowAdjustments && row?.data?.propertyConfig.adjustable,
    [allowAdjustments, row?.data?.propertyConfig],
  );

  const financialAttribute = useMemo(() => currentValue?.financialAttribute, [currentValue]);

  const selectedSource = useMemo(() => financialAttribute?.selectedSource ?? null, [financialAttribute]);

  const valueSource = useMemo(() => {
    if (!selectedSource) {
      return null;
    }

    return selectedSource.financialDataSource;
  }, [selectedSource]);

  const financialEditedBy = useMemo(() => {
    if (!valueSource) {
      return null;
    }

    if (valueSource.type === 'Document' || valueSource.type === 'Calculation') {
      return null;
    }

    const userId = valueSource.user?.type === 'Lender' ? valueSource.user?.userId : null;

    return users?.find((user) => user.id === userId) ?? null;
  }, [valueSource, users]);

  const financialEditedAt = useMemo(() => {
    if (valueSource?.type === 'Document' || valueSource?.type === 'Calculation') {
      return null;
    }

    const updatedAt = selectedSource?.updatedAt ?? null;

    return updatedAt ? parseISO(updatedAt) : null;
  }, [selectedSource?.updatedAt, valueSource?.type]);

  const FieldDescription = useMemo(() => {
    if (!valueSource || isNil(selectedSource)) {
      return valueDescriptionBySource.NoSource;
    }

    if (valueSource.type === 'Manual' && valueSource.relatedDocumentId) {
      return valueDescriptionBySource.Document;
    }

    return valueDescriptionBySource[valueSource.type];
  }, [valueSource, selectedSource]);

  const onOpenDocument = useCallback(
    (openDocumentParams: OpenDocumentParams) => {
      openDocument(openDocumentParams);
      hideTooltipCallback?.();
    },
    [hideTooltipCallback, openDocument],
  );

  if (loadingUsers) {
    return <LoadingPage />;
  }

  if (!row?.data?.entityId) {
    return null;
  }

  return (
    <StyledTooltipContainer flexDirection={'column'} backgroundColor={'white'} p={4} gap={4}>
      <Close
        onClick={hideTooltipCallback}
        sx={{ color: greyPalette[500], cursor: 'pointer', position: 'absolute', top: 12, right: 12, height: '20px' }}
      />
      {currentValue && FieldDescription ? (
        <FieldDescription
          updatedAt={financialEditedAt}
          editedBy={financialEditedBy}
          openDocument={onOpenDocument}
          value={currentValue}
          applicationId={applicationId}
          property={row?.data?.property}
        />
      ) : null}
      <SpreadTooltipFooter
        currentValue={currentValue}
        existingAdjustment={selectedSource?.adjustment}
        property={row?.data?.property}
        entityId={row?.data?.entityId}
        allowAdjustments={allowRowAdjustments}
        {...props}
      />
    </StyledTooltipContainer>
  );
};
