import React from 'react';
import { Flex, Grid } from '@lama/design-system';
import { sumBy } from 'lodash-es';
import { KeyValuePair } from '@lama/app-components';
import { InlineNote } from '../../../../shared/InlineNote/InlineNote';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import type { CellContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';
import { totalRow } from '../../creditMemoTable/creditMemoTableRowCreator';

const useOfFunds: { type: string; amount?: number }[] = [
  { type: 'Working Capital O/S', amount: 5347 },
  { type: 'Excess Availability', amount: 153 },
  { type: 'Excess Liquidity' },
  { type: 'Stock Purchase' },
];

const headers = [
  { text: 'Type', width: '40%' },
  { text: 'Amount', width: '40%' },
  { text: 'Percentage', width: '20%' },
];

const UseOfFundsSummaryTable = () => {
  const total = sumBy(useOfFunds, (a) => a.amount ?? 0);
  const totalRowDefinition: CellContent[] = [
    ...totalRow('Total', [total], 'currency'),
    { value: 100, type: 'percent', align: 'right', bold: true },
  ];
  const rows: CellContent[][] = useOfFunds.map((item) => [
    { value: item.type },
    { value: item.amount, type: 'currency', align: 'right' },
    { value: (item.amount ? item.amount / total : 0) * 100, type: 'percent', align: 'right' },
  ]);

  return (
    <SegmentContainer title={'Use of Funds Summary'} bordered={false}>
      <CreditMemoTable headers={headers} rows={[...rows, totalRowDefinition]} />
    </SegmentContainer>
  );
};

export const FirstBankDemoFacilitySection = () => (
  <Flex flexDirection={'column'} gap={8}>
    <SegmentContainer title={'Facility Summary'}>
      <Grid columns={3}>
        <KeyValuePair name={'Borrower'} value={'Monarch Metals, Inc.'} />
        <KeyValuePair name={'Guarantors'} value={'Unlimited PG of Tom Kluempers and Deborah Kluempers'} />
        <KeyValuePair name={'Loan Amount'} value={'$5,500,000'} />
        <KeyValuePair name={'Facility Type'} value={'LOC'} />
        <KeyValuePair name={'Purpose / Use of Proceeds'} value={'Working Capital'} />
        <KeyValuePair name={'Collateral'} value={'All Business Assets and assignment of $1MM life insurance policy of Tom Kluempers'} />
        <KeyValuePair name={'Term / Maturity'} value={'12 month'} />
        <KeyValuePair name={'Amortization'} value={'Interest Only'} />
        <KeyValuePair name={'Pricing'} value={'1 month Sofr + 285bps'} />
        <KeyValuePair name={'Upfront Fees'} value={'$1,000'} />
        <KeyValuePair name={'Recurring Fees'} value={'None'} />
      </Grid>
    </SegmentContainer>
    <InlineNote title={'Pending Diligence Items'} noteName={'Pending Diligence Items'} />
    <InlineNote title={'Policy Exceptions'} noteName={'Policy Exceptions'} />
    <UseOfFundsSummaryTable />
    <InlineNote title={'Financial Analysis/ Templates'} noteName={'Financial Analysis/ Templates'} />
  </Flex>
);
