import React from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import type { CreditMemoSectionProps } from '../creditMemoSectionProps';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { GlobalCashFlowSpreadSegment } from '../../../shared/Spreads/GlobalCashFlowSpreadSegment';

const note = 'Global Cash Flow Details';

export const GlobalCashFlowSection: FC<CreditMemoSectionProps> = ({ printMode, section }) => (
  <Flex flexDirection={'column'} gap={8}>
    <GlobalCashFlowSpreadSegment printMode={!!printMode} allowAdjustments={false} spreadId={section.spreadId} />
    <InlineNote title={note} noteName={note} />
  </Flex>
);
