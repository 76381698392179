import type { FC } from 'react';
import React, { useMemo, useCallback, useContext } from 'react';
import { Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import { uniq, orderBy } from 'lodash-es';
import { Flex } from '@lama/design-system';
import { StyledStatusBadge, requirementStatusToTextMapping } from '../RequirementStatusBadge';
import { ApplicationContext } from '../../ApplicationContext';
import { noteTypeToIcon } from '../../shared/noteTypeToIcon';
import { SideNavSectionItemContainer } from '../../../../shared/components/PageSideNav/SideNavSectionItemContainer';

interface RequirementListItemProps {
  requirement: EvaluatedOpportunityRequirement;
}

export const RequirementListItem: FC<RequirementListItemProps> = ({ requirement }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { opportunity } = useContext(ApplicationContext);

  const currentRequirement = searchParams.get('requirementId');

  const selected = useMemo(() => currentRequirement === requirement.id, [currentRequirement, requirement.id]);

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      setSearchParams({ requirementId: requirement.id }, { preventScrollReset: true });
      e.stopPropagation();
    },
    [requirement.id, setSearchParams],
  );

  const requirementNoteTypes = useMemo(
    () =>
      orderBy(
        uniq(opportunity.underwriting?.notes?.filter((note) => note.relatedRequirementId === requirement.id).map(({ type }) => type)),
      ),
    [opportunity.underwriting?.notes, requirement.id],
  );

  return (
    <SideNavSectionItemContainer selected={selected} onClick={onClick} key={requirement.id}>
      <Flex justifyContent={'space-between'} alignItems={'center'} flex={1}>
        <Typography variant={'body2'} color={selected ? 'primary.main' : 'text.secondary'} noWrap data-sentry-unmask>
          {requirement.name}
        </Typography>
        <Flex alignItems={'center'} gap={1}>
          {requirementNoteTypes.map(noteTypeToIcon)}
        </Flex>
      </Flex>
      <Flex gap={2} alignItems={'center'}>
        <StyledStatusBadge status={requirement.status} size={'8px'} />
        <Typography variant={'caption'} color={'text.secondary'} noWrap>
          {requirementStatusToTextMapping[requirement.status]}
        </Typography>
      </Flex>
    </SideNavSectionItemContainer>
  );
};
