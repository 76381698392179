import { useSearchParams } from 'react-router-dom';
import React, { useCallback } from 'react';
import type { FC } from 'react';
import type { OpportunityClosingTask } from '@lama/contracts';
import { Flex } from '@lama/design-system';
import { ClosingTaskCard } from './ClosingTaskCard';

interface ClosingTasksProps {
  closingTasks: OpportunityClosingTask[];
}

export const ClosingTasks: FC<ClosingTasksProps> = ({ closingTasks }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const closingTaskId = searchParams.get('task');

  const onTaskClick = useCallback(
    (taskId: string) => {
      if (closingTaskId === taskId) {
        setSearchParams({}, { preventScrollReset: true });
        return;
      }
      setSearchParams({ task: taskId }, { replace: true, preventScrollReset: true });
    },
    [closingTaskId, setSearchParams],
  );

  return (
    <Flex flexDirection={'column'} gap={4}>
      {closingTasks.map((task) => (
        <ClosingTaskCard key={task.id} task={task} open={task.id === closingTaskId} onClick={onTaskClick} />
      ))}
    </Flex>
  );
};
