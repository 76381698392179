import { ToggleButton, styled, Typography, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import type { Granularity } from '../../types';

interface GranularityToggleProps {
  value: Granularity;
  toggleGranularityScale: (event: React.MouseEvent<HTMLElement>, value: string | null) => void;
}

const GranularityToggleButton = styled(ToggleButton)({
  height: '32px',
  backgroundColor: 'white',
  border: '1px solid #E0E0E0',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.hover': {
    backgroundColor: '#EEEEEE',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: '#FAFAFA',
    zIndex: 2,
  },
});

export const GranularityToggle: React.FC<GranularityToggleProps> = ({ value, toggleGranularityScale }) => (
  <ToggleButtonGroup
    value={value}
    exclusive
    onChange={toggleGranularityScale}
    // eslint-disable-next-line @typescript-eslint/naming-convention
    sx={{ '& .MuiToggleButtonGroup-grouped': { borderRadius: '4px' } }}
  >
    <GranularityToggleButton value={'week'}>
      <Typography variant={'buttonSmall'} color={'text.secondary'}>
        {'Weekly'}
      </Typography>
    </GranularityToggleButton>
    <GranularityToggleButton value={'month'}>
      <Typography variant={'buttonSmall'} color={'text.secondary'}>
        {'Monthly'}
      </Typography>
    </GranularityToggleButton>
  </ToggleButtonGroup>
);
