import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import { applicationBorrowingRelatedBusinessesSelector, businessName } from '@lama/selectors';
import { uniqBy } from 'lodash-es';
import { ApplicationContext } from '../../../ApplicationContext';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { AffiliatesSummarySegment } from '../../../shared/Affiliate/AffiliatesSummarySegment';
import { AffiliateSegment } from '../../../shared/Affiliate/AffiliateSegment';

export const AffiliatesSection: FC = () => {
  const { application } = useContext(ApplicationContext);

  const borrowingBusinesses = useMemo(() => applicationBorrowingRelatedBusinessesSelector(application), [application]);

  const allAffiliates = useMemo(
    () =>
      uniqBy(
        borrowingBusinesses.flatMap((b) => b.business.affiliates ?? []),
        (a) => a.id,
      ),
    [borrowingBusinesses],
  );

  if (!allAffiliates.length) {
    return <Text variant={'body1'}>{'There are no affiliates associated with this application'}</Text>;
  }

  return (
    <Flex flexDirection={'column'} gap={8}>
      <AffiliatesSummarySegment />
      {borrowingBusinesses.map((b) => (
        <Flex key={b.business.id} flexDirection={'column'} gap={2}>
          <Text variant={'h6'}>{`${businessName(b.business)} affiliates`}</Text>
          {b.business.affiliates?.map((affiliate) => (
            <AffiliateSegment key={affiliate.id} affiliate={affiliate} />
          ))}
        </Flex>
      ))}
      <InlineNote title={'Affiliate Details'} noteName={'Affiliates Overview'} />
    </Flex>
  );
};
