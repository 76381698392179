import React from 'react';
import type { FC } from 'react';
import { DocumentCardContainer } from '@lama/app-components';
import { Button, Flex, greyPalette, Spinner, Text } from '@lama/design-system';
import DocumentArrowLeft from '../../../../../../shared/icons/document_arrow_left.svg';

export const PullDocumentBox: FC<{
  taskId: string;
  description: string;
  orderStatus?: string;
  middeskDocumentType: string;
  fetchingDocuments: boolean;
  onClick: () => void;
}> = ({ orderStatus, description, fetchingDocuments, middeskDocumentType, onClick }) => {
  if (fetchingDocuments || orderStatus === 'pending' || orderStatus === 'created') {
    return (
      <DocumentCardContainer isDragActive={false} justifyContent={'center'} borderColor={greyPalette[300]} loading>
        <Flex gap={4} justifyContent={'center'} alignItems={'flex-end'} paddingY={1}>
          <Spinner size={'24px'} />
          <Text variant={'body2'} color={'secondary'}>
            {`Fetching ${middeskDocumentType ?? ' '} documents, this might take a moment...`}
          </Text>
        </Flex>
      </DocumentCardContainer>
    );
  }

  return (
    <DocumentCardContainer
      isDragActive={false}
      justifyContent={'space-between'}
      backgroundColor={greyPalette[50]}
      borderColor={greyPalette[300]}
    >
      <Flex gap={2} color={'secondary'}>
        <DocumentArrowLeft />
        <Text variant={'body1'} color={'secondary'}>
          {description}
        </Text>
      </Flex>
      <Flex flexDirection={'row'} gap={2} justifyContent={'flex-end'}>
        <Button variant={'secondary'} color={'primary'} onClick={onClick}>
          {'Pull Documents'}
        </Button>
      </Flex>
    </DocumentCardContainer>
  );
};
