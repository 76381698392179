import type { ApplicationRelatedPersonApiModel, ApplicationRelatedBusinessApiModel } from '@lama/clients';
import type { Property, Relation } from '@lama/contracts';
import type { EntityGroups } from '@lama/partner-service-client';
import { intersection } from 'lodash-es';
import type { Entity } from '@lama/common-types';
import type { EntitiesByType, EntitiesWithoutRelation } from './types';

export const filterRelatedEntitiesByRelation = <T extends { relations: Relation[] }>(relatedEntities: T[], relations: EntityGroups): T[] =>
  relations.length ? relatedEntities.filter((entity) => intersection(relations, entity.relations).length) : relatedEntities;

export const filterRelatedEntitiesWithMultipleRelationsByRelation = <T extends { relations: Relation[] }>(
  relatedEntities: T[],
  relations: EntityGroups,
): T[] => (relations.length ? relatedEntities.filter((entity) => !!intersection(entity.relations, relations).length) : relatedEntities);

const getEntitiesWithoutRelation = (entitiesByType: EntitiesByType, entityType: Entity): EntitiesWithoutRelation => {
  switch (entityType) {
    case 'person': {
      return entitiesByType[entityType].map((p) => p.person);
    }
    case 'business': {
      return entitiesByType[entityType].map((p) => p.business);
    }
    default: {
      return entitiesByType[entityType];
    }
  }
};

const filterEntititesByRelation = ({
  entitiesByType,
  entityType,
  relations,
}: {
  entitiesByType: EntitiesByType;
  entityType: Entity;
  relations: EntityGroups;
}) => {
  switch (entityType) {
    case 'person': {
      return filterRelatedEntitiesByRelation<ApplicationRelatedPersonApiModel>(entitiesByType.person, relations);
    }
    case 'business': {
      return filterRelatedEntitiesWithMultipleRelationsByRelation<ApplicationRelatedBusinessApiModel>(entitiesByType.business, relations);
    }
    default: {
      return entitiesByType[entityType];
    }
  }
};

export const getEntitiesForRow = ({
  entitiesByType,
  property,
  entityGroups,
}: {
  entitiesByType: EntitiesByType;
  property: Property;
  entityGroups: EntityGroups;
}) => {
  if (!property.entityType || !entitiesByType[property.entityType]) {
    return [];
  }

  const filteredEntitiesByType = {
    ...entitiesByType,
    [property.entityType]: filterEntititesByRelation({
      entitiesByType,
      entityType: property.entityType,
      relations: entityGroups ?? [],
    }),
  };

  return getEntitiesWithoutRelation(filteredEntitiesByType, property.entityType);
};
