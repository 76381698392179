import { v4 as uuidv4 } from 'uuid';

import type { CreateCondition } from '@lama/clients';
import type { Condition, RangeMatcher } from '@lama/contracts';
import { Box, Slider, Stack, TextField, InputAdornment } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { FC } from 'react';
import React, { useMemo, useState, useCallback } from 'react';
import { useDebounce } from 'react-use';
import { isNil } from 'lodash-es';
import type { RangeConditionConfiguration } from '@lama/conditions';
import { formatValue } from '@lama/data-formatters';
import { ConditionCard } from './ConditionCard';

export interface ConditionWithRangeMatcher extends Condition {
  conditionMatcher: RangeMatcher;
}

interface RangeConditionProps {
  condition?: ConditionWithRangeMatcher;
  configuration: RangeConditionConfiguration;
  onChange: (condition: Condition | CreateCondition) => void;
}

const RangeDivider = () => <Box sx={{ borderTop: ` 1px solid ${grey[300]};`, flex: 0.8, height: '0.5px' }} />;

export const RangeCondition: FC<RangeConditionProps> = ({ condition, configuration, onChange: onConditionChange }) => {
  const [range, setRange] = useState<{ min: number; max: number }>({
    min: condition?.conditionMatcher.min ?? configuration.default.min,
    max: condition?.conditionMatcher.max ?? configuration.default.max,
  });
  const { min, max } = range;

  const onChange = useCallback((event: Event, rangeUpdate: number[] | number) => {
    if (typeof rangeUpdate === 'number' || rangeUpdate.length !== 2) {
      return;
    }

    const [minUpdate, maxUpdate] = rangeUpdate;

    setRange({ min: minUpdate!, max: maxUpdate! });
  }, []);

  const conditionUpdated = useMemo(() => {
    if (condition) {
      return min !== condition?.conditionMatcher.min || max !== condition.conditionMatcher.max;
    }

    return min !== configuration.default.min || max !== configuration.default.max;
  }, [condition, configuration.default.max, configuration.default.min, max, min]);

  const upsertCondition = useCallback(
    (active?: boolean) => {
      if (!conditionUpdated && isNil(active)) {
        return;
      }

      const conditionUpdate: CreateCondition = condition
        ? { ...condition, conditionMatcher: { ...condition.conditionMatcher, min, max }, active: active ?? true }
        : {
            id: uuidv4(),
            conditionMatcher: {
              entityType: configuration.entityType,
              fieldName: configuration.selector,
              type: configuration.type,
              min,
              max,
            },
            name: configuration.displayName,
            active: true,
          };

      onConditionChange(conditionUpdate);
    },
    [
      condition,
      conditionUpdated,
      configuration.displayName,
      configuration.entityType,
      configuration.selector,
      configuration.type,
      max,
      min,
      onConditionChange,
    ],
  );

  useDebounce(upsertCondition, 1000, [range]);

  const marks = [
    {
      value: configuration.min,
      label: formatValue(configuration.min, configuration.format),
    },
    {
      value: configuration.max,
      label: formatValue(configuration.max, configuration.format),
    },
  ];

  const setMaxValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRange({ min, max: Number(event.target.value) });
    },
    [min],
  );

  const onMaxBlur = useCallback(() => {
    if (max > configuration.max) {
      setRange({ min, max: configuration.max });
    }
  }, [configuration.max, max, min]);

  const setMinValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRange({ min: Number(event.target.value), max });
    },
    [max],
  );

  const onMinBlur = useCallback(() => {
    if (min < configuration.min) {
      setRange({ min: configuration.min, max });
    }
  }, [configuration.min, max, min]);

  const getAriaValueText = useCallback(
    (value: number) => (configuration?.format ? formatValue(value, configuration.format) : value.toString()),
    [configuration.format],
  );

  return (
    <ConditionCard configuration={configuration} active={condition?.active} onChange={upsertCondition}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <TextField
          sx={{ width: '45%' }}
          id={`min-${configuration.selector}}`}
          label={`Min ${configuration.displayName}`}
          type={'number'}
          onChange={setMinValue}
          onBlur={onMinBlur}
          value={min}
          InputProps={{
            startAdornment: configuration?.format === 'currency' ? <InputAdornment position={'end'}>{'$'}</InputAdornment> : undefined,
            inputProps: { min: configuration.min, max: configuration.max },
          }}
        />
        <RangeDivider />
        <TextField
          sx={{ width: '45%' }}
          id={`max-${configuration.selector}}`}
          label={`Max ${configuration.displayName}`}
          type={'number'}
          onChange={setMaxValue}
          onBlur={onMaxBlur}
          value={max}
          InputProps={{
            startAdornment: configuration?.format === 'currency' ? <InputAdornment position={'end'}>{'$'}</InputAdornment> : undefined,
            inputProps: { min: configuration.min, max: configuration.max },
          }}
        />
      </Stack>
      <Box px={2}>
        <Slider
          marks={marks}
          value={[min, max]}
          step={configuration.step}
          min={configuration.min}
          max={configuration.max}
          onChange={onChange}
          valueLabelDisplay={'auto'}
          getAriaValueText={getAriaValueText}
        />
      </Box>
    </ConditionCard>
  );
};
