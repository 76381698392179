import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import * as selectors from '@lama/selectors';
import { Grid } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../SegmentContainer';
import { ApplicationContext } from '../../ApplicationContext';

export const LoanTermsBorrowerDetailsSegment: FC = () => {
  const { application } = useContext(ApplicationContext);
  const { fibtDemo } = useFlags();

  const borrowerNames = useMemo(
    () =>
      selectors
        .applicationBorrowingRelatedBusinessesSelector(application)
        .map((b) => selectors.businessName(b.business))
        .join(', '),
    [application],
  );

  const guarantorNames = useMemo(
    () =>
      selectors
        .applicationGuarantorsSelector(application)
        ?.map((guarantor) => selectors.personFullName(guarantor))
        .join(', '),
    [application],
  );

  return (
    <SegmentContainer title={`${fibtDemo ? 'Application' : 'Borrowing'} Structure`}>
      <Grid columns={2}>
        <KeyValuePair name={`${fibtDemo ? 'Applicant' : 'Borrower'}(s)`} value={borrowerNames} />
        <KeyValuePair name={`${fibtDemo ? 'Owner' : 'Guarantor'}(s)`} value={guarantorNames} />
      </Grid>
    </SegmentContainer>
  );
};
