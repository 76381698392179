import type { FC } from 'react';
import React, { useMemo } from 'react';
import { max } from 'date-fns';
import { Flex, Text } from '@lama/design-system';
import { parseDateWithoutTime, parseUTCDatetoString } from '@lama/data-formatters';
import type { ApplicationApiModel, ApplicationRelatedBusinessApiModel } from '@lama/clients';
import { businessNameWithRelationsSelector } from '@lama/selectors';
import { BusinessBalanceSheetSpreadSegment } from '../../../shared/Spreads/BusinessBalanceSheetSpreadSegment';

export const BusinessBalanceSheetSpread: FC<{
  application: ApplicationApiModel;
  business: ApplicationRelatedBusinessApiModel;
  printMode: boolean;
  spreadId?: string;
}> = ({ application, business, printMode, spreadId }) => {
  const hasFinancials = useMemo(() => !!business.business.financials.length, [business.business]);

  const latestFinancialDate = useMemo(
    () => (hasFinancials ? max(business.business.financials.map(({ endDate }) => parseDateWithoutTime(endDate))) : undefined),
    [business.business.financials, hasFinancials],
  );

  return (
    <Flex flexDirection={'column'} gap={2}>
      {latestFinancialDate ? (
        <Flex justifyContent={'flex-end'}>
          <Text variant={'body2'}>{`As of ${parseUTCDatetoString(latestFinancialDate)}`}</Text>
        </Flex>
      ) : null}
      <BusinessBalanceSheetSpreadSegment
        title={businessNameWithRelationsSelector(business.business.id, application)}
        printMode={!!printMode}
        spreadId={spreadId}
        allowAdjustments={false}
        business={business}
      />
    </Flex>
  );
};
