import React from 'react';
import type { UpdateAudit } from '@lama/contracts';
import type { FC } from 'react';
import type { EntityAuditLogProps } from '../../GroupedAudit';
import { OpportunityUpdateAuditLog } from './OpportunityUpdateAuditLog';

export const OpportunityAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'update') {
    return <OpportunityUpdateAuditLog audit={audit as UpdateAudit[]} groupInfo={groupInfo} />;
  }

  return null;
};
