import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { FC } from 'react';
import { exportMultipleSheetsAsExcel } from '@ag-grid-enterprise/excel-export';
import type { MemoSection } from '@lama/contracts';
import type { ApplicationApiModel, ApplicationRelatedBusinessApiModel, ApplicationRelatedPersonApiModel } from '@lama/clients';
import { Flex, Text } from '@lama/design-system';
import {
  personFullName,
  applicationGuarantorsSelector,
  loanDisplayNameSelector,
  applicationBorrowingRelatedBusinessesSelector,
  businessName,
  applicationImportantPeopleSelector,
} from '@lama/selectors';
import { ApplicationContext } from '../../Application/ApplicationContext';
import { BusinessBalanceSheetSpreadSegment } from '../../Application/shared/Spreads/BusinessBalanceSheetSpreadSegment';
import { BusinessCashFlowSpreadSegment } from '../../Application/shared/Spreads/BusinessCashFlowSpreadSegment';
import { GlobalCashFlowSpreadSegment } from '../../Application/shared/Spreads/GlobalCashFlowSpreadSegment';
import { PersonCashFlowSpreadSegment } from '../../Application/shared/Spreads/PersonCashFlowSpreadSegment';
import type { SpreadTableRef } from '../../Application/shared/Spreads/types';

interface SpreadExportComponentProps {
  section: MemoSection;
  application: ApplicationApiModel;
  addSheet: (sheet: string, sheetName: string) => void;
}

interface SpreadExportComponentConfig {
  Component: FC<SpreadExportComponentProps>;
}

const useAddSheet = (segmentRef: SpreadTableRef | null, addSheet: (sheet: any, sheetName: string) => void, sheetName: string) => {
  useEffect(() => {
    if (segmentRef) {
      const spread = segmentRef.getSpread(sheetName);
      if (!spread) {
        return;
      }
      addSheet(spread, sheetName);
    }
  }, [segmentRef, addSheet, sheetName]);
};

interface PersonCashFlowExportProps {
  section: MemoSection;
  addSheet: (sheet: any, sheetName: string) => void;
  person: ApplicationRelatedPersonApiModel;
}

interface BusinessSpreadExportProps {
  section: MemoSection;
  addSheet: (sheet: any, sheetName: string) => void;
  business: ApplicationRelatedBusinessApiModel;
}

const PersonCashFlowExport = ({ section, addSheet, person }: PersonCashFlowExportProps) => {
  const segmentRef = useRef<SpreadTableRef>(null);
  useAddSheet(segmentRef.current, addSheet, `${personFullName(person.person)} Cash Flow`);
  return <PersonCashFlowSpreadSegment ref={segmentRef} person={person} spreadId={section.spreadId} printMode={false} />;
};

const BusinessBalanceSheetExport = ({ section, addSheet, business }: BusinessSpreadExportProps) => {
  const segmentRef = useRef<SpreadTableRef>(null);
  useAddSheet(segmentRef.current, addSheet, `${businessName(business.business)} Balance Sheet`);
  return <BusinessBalanceSheetSpreadSegment ref={segmentRef} spreadId={section.spreadId} printMode={false} business={business} />;
};

const BusinessCashflowExport = ({ section, addSheet, business }: BusinessSpreadExportProps) => {
  const segmentRef = useRef<SpreadTableRef>(null);
  useAddSheet(segmentRef.current, addSheet, `${businessName(business.business)} Cash Flow`);
  return <BusinessCashFlowSpreadSegment ref={segmentRef} spreadId={section.spreadId} printMode={false} business={business} />;
};

const spreadExportsConfig: Record<string, SpreadExportComponentConfig> = {
  newBalanceSheet: {
    Component: ({ section, addSheet, application }: SpreadExportComponentProps) => {
      const borrowingBusinesses = applicationBorrowingRelatedBusinessesSelector(application);
      return (
        <div>
          {borrowingBusinesses.map((relatedBusiness) => (
            <BusinessBalanceSheetExport
              key={relatedBusiness.business.id}
              business={relatedBusiness}
              section={section}
              addSheet={addSheet}
            />
          ))}
        </div>
      );
    },
  },
  businessCashFlow: {
    Component: ({ section, addSheet, application }: SpreadExportComponentProps) => {
      const borrowingBusinesses = applicationBorrowingRelatedBusinessesSelector(application);
      return (
        <div>
          {borrowingBusinesses.map((relatedBusiness) => (
            <BusinessCashflowExport key={relatedBusiness.business.id} business={relatedBusiness} section={section} addSheet={addSheet} />
          ))}
        </div>
      );
    },
  },
  globalCashFlow: {
    Component: ({ section, addSheet }: SpreadExportComponentProps) => {
      const segmentRef = useRef<SpreadTableRef>(null);
      useAddSheet(segmentRef.current, addSheet, 'Global Cash Flow');
      return <GlobalCashFlowSpreadSegment ref={segmentRef} spreadId={section.spreadId} printMode={false} />;
    },
  },
  personalCashFlow: {
    Component: ({ section, application, addSheet }: SpreadExportComponentProps) => {
      const relevantPeople = useMemo(() => applicationImportantPeopleSelector(application), [application]);

      return (
        <div>
          {relevantPeople.map((relatedPerson) => (
            <Flex flexDirection={'column'} gap={4} key={relatedPerson.person.id}>
              <Text variant={'h6'}>{personFullName(relatedPerson.person)}</Text>
              <PersonCashFlowExport person={relatedPerson} section={section} addSheet={addSheet} />
            </Flex>
          ))}
        </div>
      );
    },
  },
};

const spreadTypesToExport = new Set(Object.keys(spreadExportsConfig));

interface SpreadsExportProps {
  exportWhenReady: boolean;
  onExportComplete: () => void;
}

export const SpreadsExport: FC<SpreadsExportProps> = ({ exportWhenReady, onExportComplete }) => {
  const { product, application } = useContext(ApplicationContext);
  const { creditMemoConfiguration } = product;
  const [sheets, setSheets] = useState<Record<string, any>>({});
  const spreads = creditMemoConfiguration?.sections.filter((section) => spreadTypesToExport.has(section.name));
  const guarantors = useMemo(() => applicationGuarantorsSelector(application), [application]);
  const expectedSheetNumber = useMemo(() => (spreads ? spreads?.length - 1 + (guarantors?.length ?? 0) : 0), [spreads, guarantors]);

  const addSheet = useCallback(
    (sheet: any, sheetName: string) => {
      setSheets((prev) => ({ ...prev, [sheetName]: sheet }));
    },
    [setSheets],
  );

  useEffect(() => {
    if (!exportWhenReady || Object.keys(sheets).length < expectedSheetNumber) {
      return;
    }

    if (!spreads?.length) {
      onExportComplete();
      return;
    }

    const loanDisplayName = loanDisplayNameSelector(application);
    exportMultipleSheetsAsExcel({
      fileName: `${loanDisplayName} - Spreads`,
      data: Object.values(sheets),
    });
    onExportComplete();
  }, [sheets, expectedSheetNumber, spreads?.length, exportWhenReady, application, onExportComplete]);

  if (!spreads?.length || !exportWhenReady) {
    return null;
  }

  return (
    <div className={'hiddenContainer'}>
      {spreads.map((section) => {
        const Component = spreadExportsConfig[section.name]?.Component;
        return Component ? <Component key={section.name} section={section} application={application} addSheet={addSheet} /> : null;
      })}
    </div>
  );
};
