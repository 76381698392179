import type { FinancialAttribueSource, Period } from '@lama/selectors';
import { getPeriodMonths } from '@lama/selectors';
import { financialStatementFormNames, taxReturnBusinessMainFormNames, taxReturnPersonMainFormNames } from '@lama/spreading';
import { compact, intersection } from 'lodash-es';
import { parseDateWithoutTime } from '@lama/data-formatters';
import { isFuture } from 'date-fns';
import type { PeriodDisplayFormat } from '../types';
import { getShortPeriodName, getShortPeriodNameWithoutMonths } from './getShortPeriodName';

export const getFormTypeColumnTitle = (
  period: Period,
  columnSources: FinancialAttribueSource[],
  showFormTypeInSpreadColumnHeader: boolean,
) => {
  if (!showFormTypeInSpreadColumnHeader) {
    return '';
  }

  if (isFuture(parseDateWithoutTime(period.endDate))) {
    return 'Projection';
  }

  const adjustmentFinancials = columnSources.some(({ financialDataSource }) => financialDataSource.type === 'Adjustment');

  const columnFormTypes = compact(
    columnSources.map(({ financialDataSource }) =>
      financialDataSource.type === 'Document' || financialDataSource.type === 'Manual' ? financialDataSource.formType : null,
    ),
  );

  if (!columnFormTypes.length) {
    return adjustmentFinancials ? 'Manually Stated' : '-';
  }

  const taxReturnMainFormNames =
    columnSources.find(({ entityType }) => entityType)?.entityType === 'person'
      ? taxReturnPersonMainFormNames
      : taxReturnBusinessMainFormNames;

  return intersection(financialStatementFormNames, columnFormTypes).length
    ? getPeriodMonths(period) === 12
      ? 'Co. Prep'
      : 'Interim'
    : taxReturnMainFormNames.find((taxReturnMainForm) => columnFormTypes.some((formType) => formType.includes(taxReturnMainForm))) ??
        'Tax Return';
};

export const getPeriodColumnTitle = (period: Period, periodDisplayFormat: PeriodDisplayFormat): string =>
  periodDisplayFormat === 'balance sheet' ? `As of ${getShortPeriodNameWithoutMonths(period)}` : getShortPeriodNameWithoutMonths(period);

export const getPeriodMonthsTitle = (period: Period, periodDisplayFormat: PeriodDisplayFormat): string =>
  periodDisplayFormat === 'balance sheet' ? '' : getShortPeriodName(period);

export const getPeriodDateWithMonths = (period: Period, periodDisplayFormat: PeriodDisplayFormat): string =>
  `${getPeriodColumnTitle(period, periodDisplayFormat)} ${getPeriodMonthsTitle(period, periodDisplayFormat)}`;
