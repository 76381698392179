import type { RelatedPersonApiModel } from '@lama/clients';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OfacReportSummary } from './OfacReportSummary';

const ofacSearchLists = ['SDN', 'PLC', 'FSE'];

interface OfacReportIndividualSegmentProps {
  individual: RelatedPersonApiModel;
}

export const OfacReportIndividualSegment: FC<OfacReportIndividualSegmentProps> = ({ individual }) => {
  const { t } = useTranslation();
  const creditReportFetched = useMemo(() => individual.thirdPartyData?.creditReport?.fetchStatus === 'Fetched', [individual]);

  return (
    <OfacReportSummary
      timestamp={individual.thirdPartyData?.creditReport?.ficoScoreDate}
      searchLists={ofacSearchLists}
      ofacStatus={individual.thirdPartyData?.creditReport?.ofacStatus}
      dataFetched={creditReportFetched}
      dataNotFetchedText={t('ofacLookupResults.creditReportNotFetched')}
    />
  );
};
