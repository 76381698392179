import React, { forwardRef, useContext, useMemo } from 'react';
import { guarantors } from '@lama/selectors';
import { LoadingPage } from '@lama/app-components';
import { Text } from '@lama/design-system';
import { ApplicationContext } from '../../ApplicationContext';
import type { EntitiesByType } from '../../CreditMemo/Spreads/components/SpreadTable/types';
import { SpreadTable } from '../../CreditMemo/Spreads/components/SpreadTable';
import { useGetMultiEntityFinancialsQuery } from '../../../../shared/hooks/react-query/financials/useGetMultiEntityFinancialsQuery';
import { useSpreadConfigurationById } from './hooks/useSpreadConfiguration';
import type { BusinessSpreadSegmentProps, SpreadTableRef } from './types';

export const BusinessCashFlowSpreadSegment = forwardRef<SpreadTableRef, BusinessSpreadSegmentProps>(
  ({ printMode, spreadId, allowAdjustments, business, ...restOfProps }, spreadTableRef) => {
    const { application, opportunity, properties } = useContext(ApplicationContext);
    const { financials, loading } = useGetMultiEntityFinancialsQuery([{ entityId: business.business.id }], false);

    const spreadConfiguration = useSpreadConfigurationById(spreadId);
    const businessGuarantors = useMemo(
      () =>
        (guarantors(business.business) ?? []).map((person) => ({
          person,
          relation: 'guarantor' as const,
          relations: ['guarantor' as const],
        })),
      [business],
    );
    const spreadEntities: EntitiesByType = useMemo(
      () => ({
        affiliate: business.business.affiliates ?? [],
        business: [business],
        person: businessGuarantors,
        application: [application],
        opportunity: [opportunity],
      }),
      [application, business, opportunity, businessGuarantors],
    );

    if (!spreadConfiguration) {
      return <Text variant={'body1'}>{'Spreads are not configured, please contact support.'}</Text>;
    }

    if (loading) {
      return <LoadingPage />;
    }

    return (
      <SpreadTable
        entities={spreadEntities}
        properties={properties}
        applicationId={application.id}
        opportunity={opportunity}
        spreadsSettings={opportunity.spreadsSettings}
        spreadConfig={spreadConfiguration}
        financials={financials}
        defaultYearColumns={spreadConfiguration?.spreadYears ?? [2022]}
        printMode={printMode}
        spreadName={'BusinessCashFlow'}
        periodDisplayFormat={'income statement'}
        ref={spreadTableRef}
        allowAdjustments={allowAdjustments}
        showPeopleNames={false}
        title={restOfProps.title}
      />
    );
  },
);
