import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { businessNameWithRelationsSelector, relatedBusinessesByRelationSelector } from '@lama/selectors';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { PropertiesCashFlowSpreadSegment } from '../../../shared/Spreads/PropertiesCashFlowSpreadSegment';
import { ApplicationContext } from '../../../ApplicationContext';
import type { CreditMemoSectionProps } from '../creditMemoSectionProps';

const note = 'Properties Cash Flow Details';

export const PropertiesCashFlowSection: FC<CreditMemoSectionProps> = ({ printMode, section }) => {
  const { application } = useContext(ApplicationContext);

  const properties = useMemo(() => relatedBusinessesByRelationSelector(application, 'property'), [application]);

  return (
    <Flex flexDirection={'column'} gap={8}>
      {properties.map((relatedBusiness) => (
        <Flex key={relatedBusiness.business.id} flexDirection={'column'} gap={2}>
          <PropertiesCashFlowSpreadSegment
            title={businessNameWithRelationsSelector(relatedBusiness.business.id, application)}
            printMode={!!printMode}
            spreadId={section.spreadId}
            property={relatedBusiness}
          />
        </Flex>
      ))}
      <InlineNote title={note} noteName={note} />
    </Flex>
  );
};
