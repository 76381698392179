import React, { useMemo } from 'react';
import type { FC } from 'react';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { Flex } from '@lama/design-system';
import { DocumentBox } from '../../../shared/components/DocumentBox/DocumentBox';

interface RequirementDrawerProps {
  requirement: EvaluatedOpportunityRequirement;
  requirementDocuments: DocumentWithIssues[];
}

export const RequirementDocuments: FC<RequirementDrawerProps> = ({ requirement, requirementDocuments: documents }) => {
  const { sources, entityId, entityType, applicationId, referenceRequirementId, key } = requirement;

  const documentBoxes = useMemo(() => {
    const topics = new Set(sources.uploadFilesSource?.map((file) => file.topic) ?? []);
    const arbitraryDocumentsComponents = documents
      .filter((document) => !topics.has(document.topic))
      .map((document) => (
        <DocumentBox
          key={document.id}
          description={document.filename}
          topic={document.topic}
          document={document}
          requirement={requirement}
          sharedRequirementId={referenceRequirementId}
          requirementKey={key}
          entityId={entityId}
          entityType={entityType}
          applicationId={applicationId}
        />
      ));

    const predefinedDocumentsComponents =
      sources.uploadFilesSource?.map(({ name: documentName, topic, dismissDataByEntity }) => (
        <DocumentBox
          key={documentName}
          description={documentName}
          topic={topic}
          document={documents.find((document) => document.topic === topic && document.relatedEntityId === entityId)}
          requirement={requirement}
          sharedRequirementId={referenceRequirementId}
          requirementKey={key}
          entityId={entityId}
          entityType={entityType}
          applicationId={applicationId}
          dismissed={dismissDataByEntity?.[entityId]}
        />
      )) ?? [];

    return [...predefinedDocumentsComponents, ...arbitraryDocumentsComponents];
  }, [applicationId, documents, entityId, entityType, key, referenceRequirementId, requirement, sources.uploadFilesSource]);

  return documentBoxes.length ? (
    <Flex flexDirection={'column'} gap={6}>
      {documentBoxes}
    </Flex>
  ) : null;
};
