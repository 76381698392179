/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { Flex, greenPalette, greyPalette, redPalette, Text } from '@lama/design-system';
import styled from 'styled-components';
import type { MenuProps } from '@mui/material';
import { Divider, Menu, MenuItem } from '@mui/material';
import type { ApplicationStatus } from '@lama/contracts';
import { getApprovalFlow } from '@lama/approval-flows';
import { useConfirmModal } from '@lama/app-components';
import { inactiveApplicationStatuses } from '@lama/clients';
import { isOpportunityAutoRejectedSelector } from '@lama/selectors';
import { statusToChip } from '../../../../shared/components/ApplicationStatusChip';
import { ApplicationContext } from '../../ApplicationContext';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useUsersByPartnerQuery } from '../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { useUpdateApplicationMutation } from '../../../../shared/hooks/react-query/application/useUpdateApplication';
import { ApplicationStatusDisplayNameMappingContext } from '../../../../shared/context/ApplicationStatusDisplayNameMappingContext';
import { SetStatusMenu } from './SetStatusMenu';
import { DismissApplicationDialog } from './DismissApplicationDialog';
import { DownwardArrow } from './assets/DownwardArrow';

export type StatusTransition = {
  actionLabel: string;
  relevantPermission: 'all' | 'decisionMakers' | 'nonDecisionMakers';
} & (
  | {
      action: 'transition';
      target: ApplicationStatus;
    }
  | { action: 'dismiss' }
);

export const workflowTransitions: Record<ApplicationStatus, StatusTransition[]> = {
  Draft: [
    { action: 'transition', target: 'OnboardingCompleted', actionLabel: 'Pre-Qualify', relevantPermission: 'all' },
    { action: 'dismiss', actionLabel: 'Request to Dismiss', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  OnboardingCompleted: [
    { action: 'transition', target: 'InReview', actionLabel: 'Move to Review', relevantPermission: 'all' },
    { action: 'dismiss', actionLabel: 'Request to Dismiss', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  InReview: [
    { action: 'transition', target: 'PendingApproval', actionLabel: 'Request Approval', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Request to Dismiss', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  PendingLost: [
    { action: 'transition', target: 'PendingApproval', actionLabel: 'Request Approval', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  PendingApproval: [
    { action: 'dismiss', actionLabel: 'Request to Dismiss', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  Rejected: [],
  ExpresslyWithdrawn: [],
  Cancelled: [],
  ApprovedNotAccepted: [],
  WithdrawalOfApprovedApplication: [],
  Closing: [
    { action: 'transition', target: 'Closed', actionLabel: 'Mark as Closed', relevantPermission: 'all' },
    { action: 'dismiss', actionLabel: 'Request to Dismiss', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  Closed: [],
  Deleted: [],
};

const Chip = styled(Flex)<{ bgcolor: string; borderColor: string }>`
  cursor: pointer;
  padding: 0px 12px;
  border-radius: 16px;
  background-color: ${({ bgcolor }) => bgcolor};
  border: 1px solid ${({ borderColor }) => borderColor};
`;

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'right' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'right' as const,
  },
  PaperProps: {
    elevation: 0,
    sx: {
      width: '300px',
      maxWidth: '100%',
      border: 0,
      borderWidth: '1px',
      borderColor: greyPalette[300],
      boxShadow: '0px 11px 15px 0px #DBDBDB26',
    },
  },
};

const MenuItemAction: FC<{ onClick: () => void; label: string; icon?: React.ReactNode; disabled?: boolean }> = ({
  onClick,
  label,
  icon,
  disabled,
}) => (
  <MenuItem onClick={onClick} sx={{ '&:hover': { bgcolor: greyPalette[100] } }} disabled={disabled}>
    <Flex alignItems={'center'} gap={2}>
      {icon}
      <Text variant={'body2'}>{label}</Text>
    </Flex>
  </MenuItem>
);

const OverrideStatusMenuItem: FC<{ onClick: () => void; disabled: boolean }> = ({ onClick, disabled }) => (
  <MenuItemAction
    onClick={onClick}
    label={'Status Override'}
    icon={<EditOutlinedIcon sx={{ color: greyPalette[700] }} />}
    disabled={disabled}
  />
);

const StatusTransitionMenuItem: FC<{ onDone: () => void; transition: StatusTransition; onDismissalTransition: () => void }> = ({
  onDone,
  transition,
  onDismissalTransition,
}) => {
  const {
    application: { id: applicationId },
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { applicationStatusToDisplayName } = useContext(ApplicationStatusDisplayNameMappingContext);
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(applicationId, opportunityId);
  const { confirm } = useConfirmModal();

  const onClick = useCallback(async () => {
    if (transition.action === 'dismiss') {
      onDismissalTransition();
      return;
    }

    const confirmed = await confirm({
      title: `Are you sure you want to move this application to ${applicationStatusToDisplayName[transition.target]}?`,
    });

    if (!confirmed) {
      onDone();
      return;
    }

    await updateApplication({ updateApplicationPayload: { status: transition.target } });
    onDone();
  }, [applicationStatusToDisplayName, confirm, onDismissalTransition, onDone, transition, updateApplication]);

  const icon = useMemo(() => {
    const color = transition.action === 'transition' ? greenPalette[700] : redPalette[700];
    return transition.action === 'transition' ? <ArrowForwardIcon sx={{ color }} /> : <CloseIcon sx={{ color }} />;
  }, [transition.action]);

  return <MenuItemAction onClick={onClick} label={transition.actionLabel} icon={icon} disabled={updatingApplication} />;
};

const isTransitionRelevantForCurrentUser = (transition: StatusTransition, isCurrentUserDecisionMaker: boolean) => {
  if (transition.relevantPermission === 'all') {
    return true;
  }

  return transition.relevantPermission === 'decisionMakers' ? isCurrentUserDecisionMaker : !isCurrentUserDecisionMaker;
};

export const ApplicationStatusWorkflowChip: FC = () => {
  const {
    application: { status },
    opportunity,
  } = useContext(ApplicationContext);
  const { partnerId } = opportunity;
  const { userId } = useContext(UserDetailsContext);
  const { applicationStatusToDisplayName } = useContext(ApplicationStatusDisplayNameMappingContext);
  const isApplicationAutoDeclined = useMemo(
    () => status === 'Rejected' && isOpportunityAutoRejectedSelector(opportunity),
    [opportunity, status],
  );
  const {
    label,
    style: { bgcolor, borderColor, color },
  } = useMemo(
    () => statusToChip(status, applicationStatusToDisplayName, isApplicationAutoDeclined),
    [applicationStatusToDisplayName, isApplicationAutoDeclined, status],
  );
  const [open, setOpen] = useState(false);
  const [overrideSubMenuOpen, setOverrideSubMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const { data: users } = useUsersByPartnerQuery(partnerId);

  const isCurrentUserDecisionMaker = useMemo(() => {
    const currentUser = users?.find((user) => user.id === userId);
    const approvingRoles = getApprovalFlow(partnerId)?.({ opportunity }) ?? [];

    return approvingRoles.some((role) => currentUser?.roles.includes(role));
  }, [opportunity, partnerId, userId, users]);
  const [dismissMode, setDismissMode] = useState<'decision' | 'recommendation' | null>(null);

  const handleCloseMenu = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCloseSubMenu = useCallback(() => {
    setOverrideSubMenuOpen(false);
  }, []);

  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  const onOverrideStatusClicked = useCallback(() => {
    setOverrideSubMenuOpen(true);
  }, []);

  const onSubMenuBack = useCallback(() => {
    handleCloseSubMenu();
  }, [handleCloseSubMenu]);

  const onSubMenuClose = useCallback(() => {
    handleCloseSubMenu();
    handleCloseMenu();
  }, [handleCloseMenu, handleCloseSubMenu]);

  const onDismissApplicationModalDone = useCallback(() => {
    setDismissMode(null);
    handleCloseMenu();
  }, [handleCloseMenu]);

  const onDismissalTransition = useCallback(() => {
    setDismissMode(isCurrentUserDecisionMaker ? 'decision' : 'recommendation');
  }, [isCurrentUserDecisionMaker]);

  const menuItemsForStatus = useMemo(() => {
    const transitions = workflowTransitions[status];
    return transitions
      .filter((t) => isTransitionRelevantForCurrentUser(t, isCurrentUserDecisionMaker))
      .map((t) => (
        <StatusTransitionMenuItem
          key={t.actionLabel}
          onDone={handleCloseMenu}
          transition={t}
          onDismissalTransition={onDismissalTransition}
        />
      ));
  }, [status, isCurrentUserDecisionMaker, handleCloseMenu, onDismissalTransition]);

  const overrideStatusDisabled = useMemo(
    () => !isCurrentUserDecisionMaker && inactiveApplicationStatuses.includes(status),
    [isCurrentUserDecisionMaker, status],
  );

  return (
    <>
      <Chip gap={2} alignItems={'center'} onClick={onClick} bgcolor={bgcolor} borderColor={borderColor} ref={menuRef}>
        <Text variant={'body3'} color={color}>
          {label}
        </Text>
        <DownwardArrow color={borderColor} />
      </Chip>
      <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={handleCloseMenu}>
        {menuItemsForStatus}
        {menuItemsForStatus.length ? <Divider /> : null}
        <OverrideStatusMenuItem onClick={onOverrideStatusClicked} disabled={overrideStatusDisabled} />
      </Menu>
      {overrideSubMenuOpen ? (
        <SetStatusMenu
          open={overrideSubMenuOpen}
          menuProps={menuProps}
          onBack={onSubMenuBack}
          onClose={onSubMenuClose}
          menuRef={menuRef}
          isCurrentUserDecisionMaker={isCurrentUserDecisionMaker}
        />
      ) : null}
      {dismissMode ? <DismissApplicationDialog open={!!dismissMode} onClose={onDismissApplicationModalDone} mode={dismissMode} /> : null}
    </>
  );
};
