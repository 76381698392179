import React, { useContext, useMemo, type FC } from 'react';
import { formatAlertMessage } from '@lama/alerts-service-client';
import {
  yearsDiffFromReferenceYearSelector,
  globalDscrSouthState,
  relatedBusinessesByRelationSelector,
  loanDisplayNameSelector,
  usStates,
  averageIndividualsCreditScore,
  applicationSbss,
  applicationNumberSelector,
  combinedBusinessDSCRSelector,
} from '@lama/selectors';
import { compact, sortBy, uniq, isNil, keyBy } from 'lodash-es';
import { useAsyncFn } from 'react-use';
import { type ApplicationStatus } from '@lama/contracts';
import { isAfter, isWithinInterval } from 'date-fns';
import { useGetApplicationsByPartnerQuery } from '../../../../shared/hooks/react-query/application/useGetApplicationsQuery';
import { useGetOpportunityEntitiesQuery } from '../../../../shared/hooks/react-query/opportunity/useGetOpportunityEntitiesQuery';
import { useGetPipelineQuery } from '../../../../shared/hooks/react-query/pipeline/useGetPipelineQuery';
import { ReportsFilterContext } from '../ReportsFilterContext';
import { ReportCard } from '../ReportCard';
import type { ApplicationExportRow } from '../applicationByStatusExportSchema';
import { excelSchema } from '../applicationByStatusExportSchema';
import { ApplicationStatusDisplayNameMappingContext } from '../../../../shared/context/ApplicationStatusDisplayNameMappingContext';
import { useApplicationsByStatusChartData } from '../Charts/hooks/useApplicationsByStatusChartData';
import { ApplicationsByStatusChart } from '../Charts/ApplicationsByStatusBarChart';

const finalStatuses = new Set<ApplicationStatus>(['Closed', 'Rejected', 'ExpresslyWithdrawn']);

export const PipelineReportOld: FC = () => {
  const { partnerId, productId, startDate, endDate } = useContext(ReportsFilterContext);
  const { applicationStatusToDisplayName } = useContext(ApplicationStatusDisplayNameMappingContext);
  const { data: pipelineQueryData, isLoading: loadingPipeline } = useGetPipelineQuery({});
  const { data: chartData, isPending: loadingChartData } = useApplicationsByStatusChartData({ endDate, startDate, productId, partnerId });
  const { data: applications, isPending: loadingApplications } = useGetApplicationsByPartnerQuery(partnerId ?? undefined);
  const { data: opportunityEntities, isPending: loadingOpportunityEntities } = useGetOpportunityEntitiesQuery({
    partnerId: partnerId ?? undefined,
  });

  const pipelineItemsByApplicationId = useMemo(
    () => (pipelineQueryData?.pipeline ? keyBy(pipelineQueryData.pipeline, 'applicationId') : {}),
    [pipelineQueryData?.pipeline],
  );

  const [{ loading: exporting }, exportReport] = useAsyncFn(async () => {
    if (!applications) {
      return;
    }

    const { default: writeXlsxFile } = await import('write-excel-file');

    const applicationsData: ApplicationExportRow[] = compact(
      sortBy(applications, (application) => new Date(application.createdAt).getTime()).map((application) => {
        const opportunity = opportunityEntities?.find((opp) => opp.applicationId === application.id);

        if (!opportunity) {
          return null;
        }

        if (productId !== 'allProducts' && opportunity.productId !== productId) {
          return null;
        }

        if (
          startDate &&
          endDate &&
          isAfter(endDate, startDate) &&
          !isWithinInterval(new Date(opportunity.createdAt), { start: startDate, end: endDate })
        ) {
          return null;
        }

        const yearsBack = yearsDiffFromReferenceYearSelector(opportunity.referenceYear);

        const finalFundedLoanAmount = application.requestedAmount;
        const globalDscr = globalDscrSouthState(application, yearsBack);
        const combinedDscr = combinedBusinessDSCRSelector(application, yearsBack);

        const borrowingBusinesses = relatedBusinessesByRelationSelector(application, 'borrower');

        const exportRow: ApplicationExportRow = {
          opportunityId: opportunity.id,
          applicationNumber: opportunity.partnerSequenceNumber ? applicationNumberSelector(opportunity) : null,
          applicationDate: new Date(application.createdAt),
          borrowerName: loanDisplayNameSelector(application),
          borrowerState: uniq(borrowingBusinesses.flatMap(({ business }) => usStates(business) ?? [])).join(', '),
          globalDscr: !isNil(globalDscr) ? globalDscr.toFixed(2) : null,
          currentStatus: applicationStatusToDisplayName[application.status],
          loanAmount: application.originalRequestedAmount!,
          avgFico: averageIndividualsCreditScore(application)!,
          combinedDscr: !isNil(combinedDscr) ? combinedDscr.toFixed(2) : null,
          sbss: applicationSbss(application),
          ...(finalStatuses.has(application.status) ? { finalStatus: applicationStatusToDisplayName[application.status] } : {}),
          fundingDate: new Date(opportunity.originationDate || application.createdAt),
          finalFundedLoanAmount,
          referrer: application.referrer,
          loanType: opportunity.loanType,
          alert: formatAlertMessage(pipelineItemsByApplicationId[application.id]?.alerts?.[0]),
        };

        return exportRow;
      }),
    );

    await writeXlsxFile(applicationsData, {
      schema: excelSchema,
      fileName: `Application Report ${new Date().toLocaleDateString()}.xlsx`,
    });
  }, [applicationStatusToDisplayName, applications, endDate, opportunityEntities, pipelineItemsByApplicationId, productId, startDate]);

  const loading = useMemo(
    () => loadingApplications || loadingOpportunityEntities || loadingPipeline || loadingChartData,
    [loadingApplications, loadingOpportunityEntities, loadingPipeline, loadingChartData],
  );

  return (
    <ReportCard
      disableActions={loading || exporting}
      actionText={'Download Report'}
      onClickAction={exportReport}
      actionLoading={exporting}
      loading={loading}
      title={'Pipeline Report'}
    >
      <ApplicationsByStatusChart chartData={chartData} />
    </ReportCard>
  );
};
