import { useCallback } from 'react';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import type { MiddeskReportTracking } from '@lama/verify-business-service-client';
import { verifyBusinessServiceClient } from '../../../../../../../shared/clients/verifyBusinessServiceClient';

export const useMiddeskReportTrackingQuery = (
  businessId: string | undefined,
  options?: Partial<UseQueryOptions<MiddeskReportTracking | null>>,
): UseQueryResult<MiddeskReportTracking | null> => {
  const { getAccessTokenSilently } = useAuth0();

  const getMiddeskReportTrackingQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!businessId) {
      return null;
    }

    const businessMiddeskReportTracking = await verifyBusinessServiceClient.getBusinessMiddeskReportTracking(businessId, token);

    return businessMiddeskReportTracking;
  }, [getAccessTokenSilently, businessId]);

  const query = useQuery({ queryKey: ['middeskReportTracking', businessId], queryFn: getMiddeskReportTrackingQuery, ...options });

  return query;
};
