import React, { useCallback, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import type { DialogMode } from '@lama/app-components';
import { FormikStatePicker, FormikPicker, BaseDialog, PropertyFormikInput, ModifyItemButton } from '@lama/app-components';
import type { AffiliateApiModel } from '@lama/clients';
import { Divider, Grid } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { businessLegalNameValidation, businessTinValidation, zipCodeValidation } from '@lama/yup-validations';
import * as selectors from '@lama/selectors';
import { Flex } from '@lama/design-system';
import type { LabeledValue } from '@lama/contracts';
import { ApplicationContext } from '../../../../ApplicationContext';

interface AddOrEditDialogProps {
  open: boolean;
  handleClose: (values: AffiliateApiModel | null) => void;
  initialValues: AffiliateApiModel | null;
  businessId: string;
  isLoading?: boolean;
}

interface AffiliateValues {
  legalName: string;
  owner: string;
  tin: string;
  address1?: string;
  city?: string;
  state?: string;
  zip?: string;
}

const emptyAffiliate = {
  legalName: '',
  owner: '',
  tin: '',
  address1: '',
  city: '',
  state: '',
  zip: '',
};

const validationSchema = yup.object({
  legalName: businessLegalNameValidation.required('Legal Name is required'),
  tin: businessTinValidation,
  zip: zipCodeValidation,
});

export const AddOrEditRelatedCompanyDialog: React.FC<AddOrEditDialogProps> = ({
  open,
  handleClose,
  initialValues,
  isLoading,
  businessId,
}) => {
  const { t } = useTranslation();
  const { application } = useContext(ApplicationContext);

  const business = useMemo(
    () => application.relatedBusinesses.find((b) => b.business.id === businessId)?.business,
    [application.relatedBusinesses, businessId],
  );

  const owners = useMemo(() => (business ? selectors.ownersSelector(business) ?? [] : []), [business]);
  const owningBusinesses = useMemo(() => (business ? selectors.owningBusinesses(business) ?? [] : []), [business]);

  const mode: DialogMode = initialValues ? 'edit' : 'add';

  const getOwnerLabel = useCallback(
    (ownerId: string) => {
      if (!ownerId) {
        return '';
      }

      const person = owners.find((principal) => principal.id === ownerId);

      if (person) {
        return selectors.personFullName(person);
      }

      const owner = owningBusinesses.find((b) => b.id === ownerId) ?? (business?.id === ownerId ? business : null);

      return owner?.legalName ?? '';
    },
    [business, owningBusinesses, owners],
  );

  const ownersOptions: LabeledValue[] = useMemo(
    () =>
      [...owners.map((principal) => principal.id), ...owningBusinesses.map((b) => b.id), ...(business ? [business.id] : [])].map((id) => ({
        value: id,
        label: getOwnerLabel(id),
      })),
    [owners, owningBusinesses, business, getOwnerLabel],
  );

  const onSubmit = useCallback(
    (values: AffiliateValues) => {
      const ownerPerson = owners.find((principal) => principal.id === values.owner);
      const ownerBusiness = owningBusinesses.find((b) => b.id === values.owner) ?? business;

      if (!ownerBusiness) {
        return;
      }

      const newValues: AffiliateApiModel = {
        id: initialValues?.id ?? uuidv4(),
        legalName: values.legalName,
        tin: values.tin,
        addresses: [
          {
            address1: values.address1,
            city: values.city,
            state: values.state,
            zip: values.zip,
            country: 'US',
          },
        ],
        ...(ownerPerson
          ? { relatedPeople: [{ id: ownerPerson.id, ownershipPercentage: 50 }] }
          : { owningBusinesses: [{ id: ownerBusiness.id, ownershipPercentage: 50 }], relatedPeople: [] }),
      };

      handleClose(newValues);
    },
    [business, handleClose, initialValues?.id, owningBusinesses, owners],
  );

  const initialValuesInner: AffiliateValues = useMemo(
    () =>
      initialValues
        ? {
            id: initialValues.id ?? uuidv4(),
            legalName: initialValues.legalName ?? '',
            tin: initialValues.tin ?? '',
            owner: initialValues.owningBusinesses?.[0]?.id ?? initialValues.relatedPeople[0]?.id ?? '',
            ...(initialValues.addresses?.[0] ?? { state: '', country: '', city: '', zip: '', address1: '' }),
          }
        : emptyAffiliate,
    [initialValues],
  );

  return (
    <BaseDialog open={open} onClose={handleClose} title={t(`affiliates.dialog.title.${mode}`)}>
      <Formik validationSchema={validationSchema} initialValues={initialValuesInner} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <PropertyFormikInput name={'legalName'} label={'Legal Name'} required fullWidth />
            </Grid>
            <Grid item md={6} xs={12}>
              <PropertyFormikInput name={'tin'} label={'Tax ID'} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <FormikPicker name={'owner'} label={'Owned By'} values={ownersOptions} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <PropertyFormikInput name={'address1'} label={'Address Line'} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <PropertyFormikInput name={'city'} label={'City'} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikStatePicker name={'state'} label={'State'} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <PropertyFormikInput name={'zip'} label={'Zip Code'} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} marginTop={2}>
              <Flex justifyContent={'center'}>
                <ModifyItemButton onClick={handleSubmit} text={t(`principals.dialog.cta.${mode}`)} showIcon={false} loading={isLoading} />
              </Flex>
            </Grid>
          </Grid>
        )}
      </Formik>
    </BaseDialog>
  );
};
