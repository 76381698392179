import React from 'react';
import type { CreateAudit, UpdateAudit } from '@lama/contracts';
import type { FC } from 'react';
import { CreateEntityAuditLog } from '../CreateEntityAuditLog';
import type { EntityAuditLogProps } from '../../GroupedAudit';
import { ApplicationUpdateAuditLog } from './ApplicationUpdateAuditLog';

export const ApplicationAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'update') {
    return <ApplicationUpdateAuditLog audit={audit as UpdateAudit[]} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'create') {
    return <CreateEntityAuditLog audit={audit as CreateAudit[]} groupInfo={groupInfo} />;
  }

  return null;
};
