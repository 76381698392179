import { ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ConfirmationModal, ConfirmationModalProvider } from '@lama/app-components';
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Slide, ToastContainer } from 'react-toastify';
import { ThemeProvider as LamaThemeProvider, getLightTheme } from '@lama/design-system';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { injectStyle as injectToastStyle } from 'react-toastify/dist/inject-style';
import { agGridLicenceKey } from './framework/environment';
import { theme } from './shared/styling/theme';
import { queryClient } from './framework/queryClient';
import i18n from './framework/i18n';
import { GooglePlacesServiceProvider } from './components/Application/GooglePlacesServiceContext';
import { UserDetailsProvider } from './shared/context/UserDetailsContext';
import { Auth0ProviderWithHistory } from './Auth0HistoryProvider';
import { router } from './router';
import { MobileBoundary } from './MobileBoundary';
import { DemoAutoApprovalProvider } from './components/Application/Products/ProductSettings/AutoApproval/context/AutoApprovalContext';

injectToastStyle();

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ServerSideRowModelModule,
  ClipboardModule,
  RowGroupingModule,
  ExcelExportModule,
  MenuModule,
  SetFilterModule,
  ColumnsToolPanelModule,
]);

LicenseManager.setLicenseKey(agGridLicenceKey as string);

const lamaLightTheme = getLightTheme();

const App = () => (
  <Auth0ProviderWithHistory>
    <ThemeProvider theme={{ ...lamaLightTheme, ...theme }}>
      <LamaThemeProvider theme={lamaLightTheme}>
        <CssBaseline>
          <I18nextProvider i18n={i18n}>
            <ToastContainer position={'top-center'} autoClose={3000} transition={Slide} theme={'light'} closeButton={false} />
            <QueryClientProvider client={queryClient}>
              <MobileBoundary>
                <ConfirmationModalProvider>
                  <GooglePlacesServiceProvider>
                    <UserDetailsProvider>
                      <DemoAutoApprovalProvider>
                        <RouterProvider router={router} />
                      </DemoAutoApprovalProvider>
                    </UserDetailsProvider>
                  </GooglePlacesServiceProvider>
                  <ConfirmationModal />
                </ConfirmationModalProvider>
              </MobileBoundary>
            </QueryClientProvider>
          </I18nextProvider>
        </CssBaseline>
      </LamaThemeProvider>
    </ThemeProvider>
  </Auth0ProviderWithHistory>
);
export default App;
