import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { GetOpportunityEntitiesQueryParams } from '@lama/clients';
import { createApplicationServiceClient } from '@lama/clients';
import type { Opportunity } from '@lama/contracts';
import { applicationServiceBaseUrl } from '../../../../framework/environment';

const applicationServiceClient = createApplicationServiceClient(applicationServiceBaseUrl);

export const useGetOpportunityEntitiesQuery = (
  params: GetOpportunityEntitiesQueryParams,
  options?: UseQueryOptions<Opportunity[] | null>,
) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const getOpportunityEntitiesQuery = useCallback(async () => {
    if (!Object.values(params).some(Boolean)) {
      return null;
    }

    const token = await getAccessTokenSilently();

    const data = await applicationServiceClient.getOpportunityEntities(params, token);

    data?.forEach((oppEntity) => {
      queryClient.setQueryData(['opportunityEntity', oppEntity.id], oppEntity);
    });

    return data;
  }, [getAccessTokenSilently, params, queryClient]);

  const query = useQuery<Opportunity[] | null>({
    queryKey: ['opportunityEntities', ...(params.applicationIds ?? []), params.partnerId],
    queryFn: getOpportunityEntitiesQuery,
    ...options,
  });

  return query;
};
