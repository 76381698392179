import { Stack } from '@mui/material';
import type { FC } from 'react';
import type { AccountData } from '@lama/contracts';
import React from 'react';
import { TransactionItem } from './TransactionItem';

export const TransactionsList: FC<{ transactions: AccountData['transactions'] }> = ({ transactions }) => (
  <Stack spacing={1.5} width={'100%'}>
    {transactions.map((transaction) => (
      <TransactionItem key={transaction.transactionId} transaction={transaction} />
    ))}
  </Stack>
);
