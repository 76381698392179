/* eslint-disable @typescript-eslint/naming-convention */
import type { ApplicationApiModel, OpportunityApiModel } from '@lama/clients';
import type { Collateral, PaymentPeriod } from '@lama/contracts';
import type { LenderProfile, User } from '@lama/user-service-client';
import { stateNameToAbbreviation } from '@lama/contracts';
import * as selectors from '@lama/selectors';
import { applicationCollateralSelector, personFullName } from '@lama/selectors';
import { format } from 'date-fns';

type LaserProYesNoAnswer = 'N' | 'Y';

const loanTypeToProductFileMapping = {
  'CD Secured Loan': '38.tov',
  'CD Secured Line of Credit': '38.tov',
  'Home Equity Line of Credit': '72.tov',
  'Contractor Construction': '105.tov',
  'Commercial Construction': '105.tov',
  'Secured Line of Credit': '32.tov',
  'Unsecured Line of Credit': '32.tov',
  'Commercial Real Estate': '42.tov',
  'Comm RE Balloon': '42.tov',
  'Res RE (1-4 Family)': '42.tov',
  'Res RE Balloon (1-4 Family)': '42.tov',
  'Manufactured Home': '42.tov',
  'Residential Lot Loan': '42.tov',
  'Commercial Lot Loan': '42.tov',
  Unsecured: '40.tov',
  'Checking Reserve': '69.tov',
};

const getPrefixedEntityArrayValues = (entities: any[], prefixBase: string) =>
  entities.reduce((acc, entity, index) => {
    const prefix = `${prefixBase}${index + 1}`;
    for (const key in entity) {
      if (entity.hasOwnProperty(key)) {
        acc[`${prefix}_${key}`] = entity[key];
      }
    }
    return acc;
  }, {});

const businessTypeToLaserProEntityType = (businessLegalEntityType: string) => {
  switch (businessLegalEntityType) {
    case 'Sole Proprietorship': {
      return 'Sole Proprietorship';
    }
    case 'Partnership': {
      return 'Partnership';
    }
    case 'S Corp': {
      return 'Corporation';
    }
    case 'C Corp': {
      return 'Corporation';
    }
    case 'LLC': {
      return 'Limited Liability Company';
    }
    case 'Non Profit': {
      return 'Corportation';
    }
    default: {
      return '';
    }
  }
};

const getBorrowersValues = (application: ApplicationApiModel) => {
  const borrowingBusinesses = selectors.applicationBorrowingRelatedBusinessesSelector(application);

  const borrowingBusinessValues = borrowingBusinesses.map(({ business }) => {
    const EntityType = businessTypeToLaserProEntityType(business.legalEntityType ?? '');
    const Capacity = 'Borrower';
    const LastName = business.legalName?.slice(0, 30) ?? '';
    const tin = business.tin ? `${business.tin.slice(0, 2)}-${business.tin.slice(2)}` : '';
    const AddrLine1 = business.addresses?.[0]?.address1?.slice(0, 30) ?? '';
    const City = business.addresses?.[0]?.city?.slice(0, 20) ?? '';
    const ST = stateNameToAbbreviation(business.addresses?.[0]?.state ?? '') ?? '';
    const Zip = business.addresses?.[0]?.zip ?? '';
    const MailingAddr1 = business.addresses?.[0]?.address1?.slice(0, 30) ?? '';
    const MailingCity = business.addresses?.[0]?.city?.slice(0, 20) ?? '';
    const MailingST = stateNameToAbbreviation(business.addresses?.[0]?.state ?? '') ?? '';
    const MailingZip = business.addresses?.[0]?.zip ?? '';
    const PrimaryPhone = business.phoneNumber?.replace(/\D/g, '').slice(0, 10) ?? '';
    const SIC = business.naicsCodes?.[0]?.slice(0, 5) ?? '';
    const PartnershipType = business.partnershipType ?? '';
    const NonprofitYN: LaserProYesNoAnswer = business.legalEntityType === 'Non Profit' ? 'Y' : 'N';

    return {
      EntityType,
      Capacity,
      LastName,
      'SSN/TIN': tin,
      AddrLine1,
      City,
      ST,
      Zip,
      MailingAddr1,
      MailingCity,
      MailingST,
      MailingZip,
      PrimaryPhone,
      SIC,
      PartnershipType,
      NonprofitYN,
    };
  });

  const borrowingPeople = selectors.relatedPeopleByRelationSelector(application, 'borrower');

  const borrowingPeopleValues = borrowingPeople.map((person) => {
    const EntityType = 'Individual';
    const Capacity = 'Borrower';
    const LastName = person.person.lastName;
    const ssn = person.person.ssn ? `${person.person.ssn.slice(0, 3)}-${person.person.ssn.slice(3, 5)}-${person.person.ssn.slice(5)}` : '';
    const AddrLine1 = person.person.address?.address1 ?? '';
    const City = person.person.address?.city ?? '';
    const ST = stateNameToAbbreviation(person.person.address?.state ?? '') ?? '';
    const Zip = person.person.address?.zip ?? '';
    const MailingAddr1 = person.person.address?.address1 ?? '';
    const MailingCity = person.person.address?.city ?? '';
    const MailingST = stateNameToAbbreviation(person.person.address?.state ?? '') ?? '';
    const MailingZip = person.person.address?.zip ?? '';
    const PrimaryPhone = person.person.phoneNumber?.replace(/\D/g, '').slice(0, 10) ?? '';
    const BirthDt = person.person.dateOfBirth ? format(new Date(person.person.dateOfBirth), 'MM-dd-yyyy') : '';

    return {
      EntityType,
      Capacity,
      LastName,
      'SSN/TIN': ssn,
      AddrLine1,
      City,
      ST,
      Zip,
      MailingAddr1,
      MailingCity,
      MailingST,
      MailingZip,
      PrimaryPhone,
      BirthDt,
    };
  });

  return getPrefixedEntityArrayValues([...borrowingBusinessValues, ...borrowingPeopleValues], 'Entity');
};

const getGuarantorsValues = (application: ApplicationApiModel) => {
  const guarantorValues =
    selectors
      .applicationGuarantorsSelector(application)
      ?.sort((a, b) => (b.ownershipPercentage ?? 0) - (a.ownershipPercentage ?? 0))
      .map((guarantor) => {
        const EntityType = 'Individual';
        const FirstName = guarantor.firstName?.slice(0, 20) ?? '';
        const LastName = guarantor.lastName?.slice(0, 20) ?? '';
        const ssn = guarantor.ssn ? `${guarantor.ssn.slice(0, 3)}-${guarantor.ssn.slice(3, 5)}-${guarantor.ssn.slice(5)}` : '';
        const addressLine = guarantor.address?.address1?.slice(0, 30) ?? '';
        const city = guarantor.address?.city?.slice(0, 20) ?? '';
        const state = stateNameToAbbreviation(guarantor.address?.state ?? '') ?? '';
        const zip = guarantor.address?.zip ?? '';
        const phoneNumber = guarantor.phoneNumber?.replace(/\D/g, '').slice(0, 10) ?? '';
        const dateOfBirth = guarantor.dateOfBirth ? format(new Date(guarantor.dateOfBirth), 'MM-dd-yyyy') : '';

        return {
          EntityType,
          FirstName,
          LastName,
          'SSN/TIN': ssn,
          Addrline1: addressLine,
          City: city,
          ST: state,
          Zip: zip,
          PrimaryPhone: phoneNumber,
          BirthDt: dateOfBirth,
        };
      }) ?? [];

  return getPrefixedEntityArrayValues(guarantorValues, 'G');
};

const getPriorLienFields = (collateral: Collateral) => {
  if (!collateral.lienDetails) {
    return {};
  }
  const LienHolderEntityType = collateral.lienDetails.lienHolderEntityType ?? '';
  const LienHolderFirstName = collateral.lienDetails.lienHolderFirstName ?? '';
  const LienHolderLastName = collateral.lienDetails.lienHolderLastName ?? '';
  const LienPriority = collateral.lienDetails.lienPriority ?? '';
  const LienCurrentBal = collateral.currentLienBalance?.toString() ?? '';

  return { LienHolderEntityType, LienHolderFirstName, LienHolderLastName, LienPriority, LienCurrentBal };
};

const getTitledFields = (collateral: Collateral) => {
  const CollType = 'Titled';
  const CollSubType = collateral.type ?? '';

  if (!collateral.vehicleDetails) {
    return {};
  }

  const CollLawSt = collateral.vehicleDetails?.state ?? '';
  const CollStreetAddress = collateral.vehicleDetails.address ?? '';
  const CollCity = collateral.vehicleDetails.city ?? '';
  const CollZip = collateral.vehicleDetails.zip ?? '';
  const CollST = stateNameToAbbreviation(collateral.vehicleDetails.state ?? '') ?? '';
  const CollBodyStyle = collateral.vehicleDetails.bodyStyle ?? '';
  const CollMake = collateral.vehicleDetails.make ?? '';
  const CollManufacturer = collateral.vehicleDetails.manufacturer ?? '';
  const CollModel = collateral.vehicleDetails.model ?? '';
  const CollSerialNumber = collateral.vehicleDetails.vin ?? '';
  const CollYear = collateral.vehicleDetails.year?.toString() ?? '';
  const SimpleDesc = collateral.description?.slice(0, 50) ?? '';
  const uccFinancingStatement: LaserProYesNoAnswer = collateral.vehicleDetails.uccFinancingStatement ? 'Y' : 'N';
  const VIN = collateral.vehicleDetails.vin ?? '';

  return {
    CollType,
    CollSubType,
    CollLawSt,
    CollStreetAddress,
    CollCity,
    CollZip,
    CollST,
    CollBodyStyle,
    CollMake,
    CollManufacturer,
    CollModel,
    CollSerialNumber,
    CollYear,
    SimpleDesc,
    uccFinancingStatement,
    VIN,
  };
};

const getPossessoryFields = (collateral: Collateral) => {
  const CollType = 'Possessory';
  const CollSubType = collateral.possessoryDetails?.subType ?? '';
  const CollPossType = collateral.possessoryDetails?.thirdType ?? '';
  const CollLawSt = collateral.possessoryDetails?.state ?? '';
  const CollFaceValue = collateral.possessoryDetails?.faceValue?.toString() ?? '';
  const CollNoteTo3rdPtyYN: LaserProYesNoAnswer = collateral.possessoryDetails?.includeControlAgreement ? 'Y' : 'N';
  const CollPossNumb = collateral.possessoryDetails?.accountNumber ?? '';

  return {
    CollType,
    CollSubType,
    CollPossType,
    CollLawSt,
    CollFaceValue,
    CollNoteTo3rdPtyYN,
    CollPossNumb,
  };
};

const getUccFields = (collateral: Collateral) => {
  const CollType = 'UCC';
  const CollSubType = collateral.type ?? '';
  const CollLawSt = collateral.vehicleDetails?.state ?? '';
  const CollMktValue = collateral.method === 'Market Value' ? collateral.totalValue?.toString() : '';
  const PurchaseMoneyYN: LaserProYesNoAnswer = collateral.purchaseMoney ? 'Y' : 'N';

  return {
    CollType,
    CollSubType,
    CollLawSt,
    CollMktValue,
    PurchaseMoneyYN,
  };
};

const getRealEstateFields = (collateral: Collateral) => {
  const CollType = 'Real Estate';
  const CollSubType = collateral.realEstateDetails?.subType ?? '';
  const CollLawSt = collateral.realEstateDetails?.state ?? '';
  const CollCity = collateral.realEstateDetails?.city ?? '';
  const CollCounty = collateral.realEstateDetails?.county ?? '';
  const CollST = stateNameToAbbreviation(collateral.realEstateDetails?.state ?? '') ?? '';
  const CollStreetAddress = collateral.realEstateDetails?.address ?? '';
  const CollZip = collateral.realEstateDetails?.zip ?? '';
  const CollExistingLienYN: LaserProYesNoAnswer = collateral.ownershipStatus === 'Under Lien' ? 'Y' : 'N';
  const CollFirstLienYN: LaserProYesNoAnswer = collateral.ownershipStatus === 'Free and clear' ? 'Y' : 'N';
  const OwnerOccupiedYN: LaserProYesNoAnswer = ['1-4 Family', 'Multiy-Family', 'Construction', 'Mobile Home'].includes(
    collateral.realEstateDetails?.subType ?? '',
  )
    ? 'Y'
    : 'N';

  return {
    CollType,
    CollSubType,
    CollLawSt,
    CollCity,
    CollCounty,
    CollST,
    CollStreetAddress,
    CollZip,
    CollExistingLienYN,
    CollFirstLienYN,
    OwnerOccupiedYN,
  };
};

const getCollateralValues = (application: ApplicationApiModel) => {
  const collateralValues =
    applicationCollateralSelector(application)
      ?.sort((a, b) => (b.totalValue ?? 0) - (a.totalValue ?? 0))
      .slice(0, 4)
      .map((collateral) => {
        const CollInsReqYN: LaserProYesNoAnswer = collateral.hasInsurance ? 'Y' : 'N';
        const BusnContactName = collateral.insuranceDetails?.agent;
        const CollCapacityID = collateral.insuranceDetails?.agency;
        const CollInsPhone = collateral.insuranceDetails?.phoneNumber;

        const uccFields = getUccFields(collateral);
        const priorLien = getPriorLienFields(collateral);
        const titledFields = getTitledFields(collateral);
        const possessoryFields = getPossessoryFields(collateral);
        const realEstateFields = getRealEstateFields(collateral);

        return {
          CollInsReqYN,
          BusnContactName,
          CollCapacityID,
          CollInsPhone,
          ...uccFields,
          ...priorLien,
          ...titledFields,
          ...possessoryFields,
          ...realEstateFields,
        };
      }) ?? [];

  return getPrefixedEntityArrayValues(collateralValues, 'L');
};

const getLaserProProduct = (opportunity: OpportunityApiModel) => {
  const {
    application: { collateral, loanType },
  } = opportunity;

  const mappedLoanTypes = Object.keys(loanTypeToProductFileMapping);

  if (loanType && mappedLoanTypes.includes(loanType)) {
    return loanTypeToProductFileMapping[loanType as keyof typeof loanTypeToProductFileMapping];
  }

  const mostValuableCollateral = collateral?.sort((a, b) => (b.totalValue ?? 0) - (a.totalValue ?? 0))[0];

  if (!mostValuableCollateral) {
    return;
  }

  if (mostValuableCollateral.typeSpecification === 'UCC') {
    return '45.tov';
  }

  if (mostValuableCollateral.typeSpecification === 'Titled') {
    return '39.tov';
  }

  // complete when we have unsecured
  return '39.tov';
};

const getTotalNumberOfPayments = (termLengthMonths: number, paymentPeriod: PaymentPeriod) => {
  switch (paymentPeriod) {
    case 'Weekly': {
      return termLengthMonths * 4;
    }
    case 'Bi-Weekly': {
      return termLengthMonths * 2;
    }
    case 'Semi-Monthly': {
      return termLengthMonths * 2;
    }
    case 'Monthly': {
      return termLengthMonths;
    }
    case 'Bi-Monthly': {
      return Math.ceil(termLengthMonths / 2);
    }
    case 'Quarterly': {
      return Math.ceil(termLengthMonths / 3);
    }
    case 'Semi-Annual': {
      return Math.ceil(termLengthMonths / 6);
    }
    case 'Annual': {
      return Math.ceil(termLengthMonths / 12);
    }
  }
};

const getGeneralDataValues = ({
  opportunity,
  assignedLenderProfiles,
  assignedUsers,
  laserProOfficerCodePrefix = '',
}: {
  opportunity: OpportunityApiModel;
  assignedUsers: User[];
  assignedLenderProfiles: LenderProfile[];
  laserProOfficerCodePrefix?: string;
}) => {
  const { application, terms, partnerSequenceNumber, branchCode } = opportunity;
  const borrowingBusiness = selectors.applicationBorrowingBusinessSelector(application);

  const transOfficer = assignedUsers?.find((user) => user.roles.includes('transOfficer'));
  const processor = assignedUsers?.find((user) => user.roles.includes('processor'));
  const processorOffierCode = assignedLenderProfiles?.find((lenderProfile) => lenderProfile.userId === processor?.id)?.officerCode ?? '';
  const transOfficerOffierCode =
    assignedLenderProfiles?.find((lenderProfile) => lenderProfile.userId === transOfficer?.id)?.officerCode ?? '';

  const AmtReq = application.requestedAmount?.toString() ?? '';
  const BorrST = stateNameToAbbreviation(borrowingBusiness?.addresses?.[0]?.state ?? '') ?? '';
  const AppDt = application.submittedAt ? format(new Date(application.submittedAt), 'MM-dd-yyyy') : '';
  const AppIDNumber = partnerSequenceNumber ? `${partnerSequenceNumber}BL`.padStart(6, '0') : '';
  const ClassBranch = branchCode?.slice(0, 8) ?? '';
  const FirstPmtDt = terms?.firstPaymentDate ? format(new Date(terms.firstPaymentDate), 'MM-dd-yyyy') : '';
  const NoteRate = terms?.rate?.isFixed ? terms.rate.spread : undefined;
  const NumOfPmts =
    terms?.termLength && terms.paymentPeriod ? getTotalNumberOfPayments(terms.termLength, terms.paymentPeriod).toString() : '';
  const PmtPrd = terms?.paymentPeriod ?? '';
  const RateType = terms?.rate?.isFixed ? 'Fixed' : 'Variable';
  const Processor = processorOffierCode ? `${laserProOfficerCodePrefix}${processorOffierCode}` : '';
  const ProcessorName = processor ? personFullName(processor) : '';
  const TransOfficer = transOfficerOffierCode ? `${laserProOfficerCodePrefix}${transOfficerOffierCode}` : '';
  const TransOfficerName = transOfficer ? personFullName(transOfficer) : '';
  const StandardProduct = getLaserProProduct(opportunity);
  const AutoPayYN = 'N';

  return {
    AmtReq,
    BorrST,
    AppDt,
    AppIDNumber,
    ClassBranch,
    FirstPmtDt,
    NumOfPmts,
    NoteRate,
    PmtPrd,
    Processor,
    ProcessorName,
    RateType,
    StandardProduct,
    TransOfficer,
    TransOfficerName,
    AutoPayYN,
  };
};

const fieldDelimiter = '|';

export const getLaserProValuesRecord = ({
  opportunity,
  assignedLenderProfiles,
  assignedUsers,
  laserProOfficerCodePrefix,
}: {
  opportunity: OpportunityApiModel;
  assignedLenderProfiles: LenderProfile[];
  assignedUsers: User[];
  laserProOfficerCodePrefix?: string;
}) => {
  const { application } = opportunity;

  const record: Record<string, number | string | undefined> = {
    ...getGeneralDataValues({ opportunity, assignedUsers, assignedLenderProfiles, laserProOfficerCodePrefix }),
    ...getBorrowersValues(application),
    ...getGuarantorsValues(application),
    ...getCollateralValues(application),
  };

  return record;
};

export const transformRecordToLaserProTextFormat = (record: Record<string, number | string | undefined>) =>
  Object.entries(record)
    .filter(([, value]) => value !== undefined && value !== '')
    .map(([key, value]) => `${key}${fieldDelimiter}${value}`)
    .join('\n');

export const transformToLaserProPremiumFormat = ({
  opportunity,
  assignedLenderProfiles,
  assignedUsers,
  laserProOfficerCodePrefix,
}: {
  opportunity: OpportunityApiModel;
  assignedLenderProfiles: LenderProfile[];
  assignedUsers: User[];
  laserProOfficerCodePrefix?: string;
}) => {
  const record: Record<string, number | string | undefined> = getLaserProValuesRecord({
    opportunity,
    assignedLenderProfiles,
    assignedUsers,
    laserProOfficerCodePrefix,
  });

  return transformRecordToLaserProTextFormat(record);
};
