import React, { useCallback } from 'react';
import { ArbitraryDocumentUploadButton } from '@lama/app-components';
import type { FC } from 'react';
import type { Entity } from '@lama/common-types';
import type { OpportunityRequirement } from '@lama/contracts';
import { useUploadDocumentsMutation } from './hooks/useUploadDocumentsQuery';

interface UploadArbitraryDocumentButtonProps {
  applicationId: string;
  entityId: string;
  entityType: Entity;
  requirementId: string;
  sharedRequirementId?: string;
  requirementKey: string | undefined;
  exportConfiguration?: OpportunityRequirement['exportConfiguration'];
}

export const UploadArbitraryDocumentButton: FC<UploadArbitraryDocumentButtonProps> = ({
  applicationId,
  entityId,
  entityType,
  requirementId,
  sharedRequirementId,
  requirementKey,
  exportConfiguration,
}) => {
  const { mutate: uploadDocuments, isPending: isUploading } = useUploadDocumentsMutation();

  const uploadDocumentsCallback = useCallback(
    async (files: FileList) => {
      uploadDocuments(
        [...files].map((file) => ({
          applicationId,
          description: '',
          entityId,
          entityType,
          file,
          topic: '',
          requirementId,
          sharedRequirementId,
          requirementKey,
          exportConfiguration,
        })),
      );
    },
    [applicationId, entityId, entityType, requirementId, requirementKey, sharedRequirementId, uploadDocuments, exportConfiguration],
  );

  return <ArbitraryDocumentUploadButton onFilesSelected={uploadDocumentsCallback} uploading={isUploading} />;
};
