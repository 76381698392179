import React, { useContext, useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowOutward, EmailOutlined } from '@mui/icons-material';
import { Typography, Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@lama/app-components';
import { type ApplicationStatus } from '@lama/contracts';
import { useToggle } from 'react-use';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { applicationPrimaryContactSelector } from '@lama/selectors';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../../../components/Application/ApplicationContext';
import { useSendRejectionEmailMutation } from '../../hooks/react-query/useSendRejectionEmailMutation';
import { ApplicationStatusDisplayNameMappingContext } from '../../context/ApplicationStatusDisplayNameMappingContext';
import DeclineSvg from './assets/DeclineSvg.svg';
import ApproveSvg from './assets/ApproveSvg.svg';

interface DecisionDoneProps {
  decision: ApplicationStatus;
  onClose: () => void;
}

const finalStatuses = new Set<ApplicationStatus>(['Rejected', 'ExpresslyWithdrawn']);

export const DecisionDone: FC<DecisionDoneProps> = ({ decision, onClose }) => {
  const { applicationStatusToDisplayName } = useContext(ApplicationStatusDisplayNameMappingContext);
  const navigate = useNavigate();
  const {
    application,
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { enableManualRejectionEmail } = useFlags();
  const [emailSent, toggleEmailSent] = useToggle(false);

  const primaryContactEmail = useMemo(() => applicationPrimaryContactSelector(application)?.email, [application]);

  const { mutateAsync: sendRejectionEmail, isPending: loading } = useSendRejectionEmailMutation(application.id, opportunityId);

  const backToPipeline = useCallback(() => {
    onClose();
    navigate('/pipeline');
  }, [navigate, onClose]);

  const sendMessageToApplicant = useCallback(async () => {
    toggleEmailSent();
    try {
      await sendRejectionEmail({});
    } catch {
      toggleEmailSent();
    }
  }, [sendRejectionEmail, toggleEmailSent]);

  return (
    <Box gap={4} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
      {decision === 'PendingApproval' ? <ApproveSvg /> : <DeclineSvg />}
      <Flex flexDirection={'column'} gap={3} alignItems={'center'}>
        <Typography variant={'h5'} textAlign={'center'}>
          {`You have marked this application as ${applicationStatusToDisplayName[decision]}.`}
        </Typography>
        <Flex flexDirection={'column'} gap={1} alignItems={'center'} justifyContent={'center'}>
          <Tooltip title={'Send an email message with a default adverse selection template'} placement={'top-start'}>
            {!enableManualRejectionEmail && decision && finalStatuses.has(decision) ? (
              <LoadingButton
                variant={'contained'}
                size={'large'}
                color={'primary'}
                startIcon={<EmailOutlined />}
                onClick={sendMessageToApplicant}
                sx={{ padding: '8px 16px', borderRadius: '4px' }}
                loading={loading}
                disabled={emailSent}
              >
                {'Message Applicant'}
              </LoadingButton>
            ) : (
              <Button
                variant={'contained'}
                size={'large'}
                color={'primary'}
                target={'_top'}
                startIcon={<EmailOutlined />}
                rel={'noopener noreferrer'}
                href={primaryContactEmail ? `mailto:${primaryContactEmail}` : ''}
                sx={{ padding: '8px 16px', borderRadius: '4px' }}
                disabled={!primaryContactEmail}
              >
                {'Message Applicant'}
              </Button>
            )}
          </Tooltip>
          <Button variant={'text'} onClick={backToPipeline} endIcon={<ArrowOutward />}>
            {'go back to Pipeline'}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
