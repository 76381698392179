import type { MemoSection } from '@lama/contracts';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Flex } from '@lama/design-system';
import styled from 'styled-components';
import { sectionComponentByName } from '../../sectionComponentByName';
import { CommentsSegment } from '../../../../../shared/components/Comments/CommentsSegment';
import { SectionDecisioning } from '../../../shared/SectionDecisioning';
import { SectionTitle } from '../../../shared/SectionTitle/SectionTitle';
import { GenericSection } from '../../../shared/GenericSection/GenericSection';
import { creditMemoSectionDisplayConfigurations } from '../../../shared/sectionDisplayConfigurations';

const MemoContainer = styled(Flex)`
  @media print {
    page-break-inside: auto !important;
    break-inside: auto !important;
  }
`;

interface CreditMemoContentProps {
  section?: MemoSection;
}

export const getCreditMemoSectionComponent = ({ section, printMode }: { section: MemoSection; printMode?: boolean }) => {
  if (!section?.name) {
    return null;
  }

  const sectionSegments = creditMemoSectionDisplayConfigurations[section.name];
  if (sectionSegments) {
    return <GenericSection segments={sectionSegments} sectionId={section.id} />;
  }

  const SectionComponent = sectionComponentByName[section.name];

  if (SectionComponent) {
    return <SectionComponent section={section} printMode={printMode} />;
  }

  return null;
};

export const CreditMemoContent: FC<CreditMemoContentProps> = ({ section }) => {
  const sectionComponent = useMemo(() => (section ? getCreditMemoSectionComponent({ section }) : null), [section]);

  if (!section || !sectionComponent) {
    return null;
  }

  return (
    <MemoContainer flexDirection={'column'} px={14} py={10} flex={1} overflow={'auto'} alignItems={'center'}>
      <Flex flexDirection={'column'} width={'100%'} maxWidth={'960px'} gap={8}>
        <Flex flexDirection={'column'} gap={4}>
          <SectionTitle sectionId={section.id} sectionType={'creditMemoSection'} title={section.prettyName} />
          {section.hideUnderwritingActions ? null : <SectionDecisioning sectionId={section.id} sectionType={'creditMemoSection'} />}
        </Flex>
        {sectionComponent}
        <Flex flexDirection={'column'} pt={20}>
          <CommentsSegment relatedItemId={section.id} relatedItemType={'creditMemoSection'} />
        </Flex>
      </Flex>
    </MemoContainer>
  );
};
