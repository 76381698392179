import React, { useCallback, type FC } from 'react';
import * as yup from 'yup';
import { Button, Flex, Text } from '@lama/design-system';
import { CloseRounded } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Formik } from 'formik';
import { PropertyFormikInput } from '@lama/app-components';
import { useUpdateOpportunityMutation } from '../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';

const validationSchema = yup.object({
  reason: yup.string().trim().required('Please provide a reason'),
});

export const GrantCompleteApplicationExtensionModal: FC<{ opportunityId: string; onClose: () => void }> = ({ opportunityId, onClose }) => {
  const { mutate: updateOpportunity } = useUpdateOpportunityMutation(opportunityId);

  const grantExtension = useCallback(
    (reason: string) => {
      updateOpportunity({
        completeApplicationExtension: { days: 30, grantedAt: new Date().toISOString(), reason },
      });
    },
    [updateOpportunity],
  );

  const onSubmit = useCallback(
    (formValues: { reason: string }) => {
      grantExtension(formValues.reason.trim());
      onClose();
    },
    [grantExtension, onClose],
  );

  return (
    <Dialog open maxWidth={'md'} fullWidth>
      <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={onClose}>
        <CloseRounded fontSize={'large'} sx={{ color: grey[500] }} />
      </IconButton>
      <DialogContent sx={{ gap: 4, px: 10, py: 6 }}>
        <Formik onSubmit={onSubmit} initialValues={{ reason: '' }} validationSchema={validationSchema}>
          {({ handleSubmit }) => (
            <Flex flexDirection={'column'} gap={8} width={'100%'} justifyContent={'center'}>
              <Text variant={'h5'} textAlign={'center'}>
                {'Extend Time to Submission'}
              </Text>
              <Flex flexDirection={'column'} gap={4}>
                <PropertyFormikInput name={'reason'} label={'Extension Reason'} fullWidth required />
              </Flex>
              <Flex justifyContent={'center'} alignItems={'center'}>
                <Button size={'l'} variant={'primary'} onClick={handleSubmit as any}>
                  {'Extend'}
                </Button>
              </Flex>
            </Flex>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
