import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../../../ApplicationContext';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { AffiliatesSummarySegment } from '../../../shared/Affiliate/AffiliatesSummarySegment';
import { AffiliateSegment } from '../../../shared/Affiliate/AffiliateSegment';

export const HanoverAffiliationSection: FC = () => {
  const { application } = useContext(ApplicationContext);

  const business = useMemo(
    () => application.relatedBusinesses.find(({ relations }) => relations.includes('borrower'))?.business,
    [application.relatedBusinesses],
  );

  if (!business) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={8}>
      <AffiliatesSummarySegment />
      {business.affiliates?.map((affiliate) => (
        <AffiliateSegment key={affiliate.id} affiliate={affiliate} />
      ))}
      <InlineNote title={'Affiliate Details'} noteName={'Affiliates Overview'} />
    </Flex>
  );
};
