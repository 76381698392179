import { Button, Flex, Text, yellowPalette } from '@lama/design-system';
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import type { RelatedPersonApiModel } from '@lama/clients';
import { useTranslation } from 'react-i18next';
import { useConfirmModal } from '@lama/app-components';
import { personFullName } from '@lama/selectors';
import {
  getErrorPullingCreditReportMessage,
  getMissingInformationForCreditPullMessage,
  getNoRecordFoundMessage,
  internalErrorCodesToMessage,
} from '@lama/credit-report-retriever-client';
import { ErrorOutlineRounded } from '@mui/icons-material';
import type { CreditReportData } from '@lama/contracts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OfacReportIndividualSegment } from '../../../../shared/OfacReport/OfacReportIndividualSegment';
import { PersonFraudShieldAlertsSegment } from '../../../../shared/FraudShieldAlert/PersonFraudShieldAlertsSegment';
import { useRepullCreditReport } from '../../../../../../shared/hooks/react-query/creditReport/useRepullCreditReport';
import { ApplicationContext } from '../../../../ApplicationContext';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import { DerogatoryHistory } from './DerogatoryHistory';
import { InquiryDetails } from './InquiryDetails';
import { ScoreSummary } from './ScoreSummary';
import { TradeSummary } from './TradeSummary';
import { Tradelines } from './Tradelines';

interface CreditReportProps {
  person: RelatedPersonApiModel;
  tradelinesStartExpanded?: boolean;
}

const CreditReportAlerts: FC<{ opportunityId: string; creditReport?: CreditReportData; personName: string }> = ({
  opportunityId,
  creditReport,
  personName,
}) => {
  const { mutate: repullCredit, isPending: repullingCreditReport } = useRepullCreditReport(opportunityId);
  const { confirm } = useConfirmModal();
  const { t } = useTranslation();

  const fetchStatus = useMemo(() => creditReport?.fetchStatus, [creditReport?.fetchStatus]);

  const errorMessage = useMemo(() => {
    if (!fetchStatus) {
      return getMissingInformationForCreditPullMessage(personName);
    }

    const errorCodes = creditReport?.fetchErrorCodes;

    if (errorCodes?.length) {
      return errorCodes.map((errorCode) => internalErrorCodesToMessage[errorCode](personName)).at(0);
    }

    if (fetchStatus === 'Error') {
      return getErrorPullingCreditReportMessage(personName);
    }

    if (fetchStatus === 'Not Found') {
      return getNoRecordFoundMessage(personName);
    }
  }, [creditReport?.fetchErrorCodes, fetchStatus, personName]);

  const repullEnabled = useMemo(() => fetchStatus === 'Error' || fetchStatus === 'Not Found', [fetchStatus]);

  const onRepullCreditClick = useCallback(async () => {
    const confirmed = await confirm({
      title: t('creditReport.dialog.title'),
      message: t('creditReport.dialog.message'),
      confirmText: t('creditReport.dialog.confirmText'),
    });
    if (!confirmed) {
      return;
    }

    repullCredit();
  }, [confirm, repullCredit, t]);

  if (!errorMessage) {
    return null;
  }

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'space-between'}
      gap={2}
      width={'100%'}
      p={4}
      borderRadius={'4px'}
      height={'64px'}
      backgroundColor={yellowPalette[50]}
    >
      <Flex gap={2} alignItems={'center'}>
        <ErrorOutlineRounded color={'warning'} />
        <Text variant={'body2'}>{errorMessage}</Text>
      </Flex>
      {repullEnabled ? (
        <Button variant={'secondary'} color={'neutral'} onClick={onRepullCreditClick} loading={repullingCreditReport}>
          {t('creditReport.cta')}
        </Button>
      ) : null}
    </Flex>
  );
};

export const CreditReport: FC<CreditReportProps> = ({ person, tradelinesStartExpanded }) => {
  const { hideOldCreditReports } = useFlags();

  const {
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);

  const fetchStatus = useMemo(() => person?.thirdPartyData?.creditReport?.fetchStatus, [person?.thirdPartyData]);

  // This is a patch for SouthState, so they don't see the old CRS credit reports.
  const fetchDate = person?.thirdPartyData?.creditReport?.ficoScoreDate;
  const shouldHideReport = useMemo(() => hideOldCreditReports && fetchDate && fetchDate < '2024-06-27', [fetchDate, hideOldCreditReports]);

  return (
    <Flex flexDirection={'column'} gap={8}>
      <CreditReportAlerts
        opportunityId={opportunityId}
        creditReport={person.thirdPartyData?.creditReport}
        personName={personFullName(person)}
      />
      {shouldHideReport ? (
        <Text>{'You do not have permissions to view this report.'}</Text>
      ) : fetchStatus === 'Fetched' ? (
        <>
          <InquiryDetails guarantor={person} />
          <ScoreSummary person={person} />
          <TradeSummary guarantor={person} />
          <DerogatoryHistory guarantor={person} />
          <Tradelines guarantor={person} tradelinesStartExpanded={tradelinesStartExpanded} />
          <SegmentContainer title={'OFAC Lookup'} bordered={false}>
            <OfacReportIndividualSegment individual={person} />
          </SegmentContainer>
          <SegmentContainer title={'Fraud Shield Alert'}>
            <PersonFraudShieldAlertsSegment person={person} />
          </SegmentContainer>
        </>
      ) : null}
    </Flex>
  );
};
