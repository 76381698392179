import React from 'react';
import type { FC } from 'react';
import { Tooltip } from '@lama/app-components';
import { Flex, Text } from '@lama/design-system';
import styled from 'styled-components';
import { PersonIcon } from '../../shared/components/Icons/PersonIcon';
import { BusinessDefaultImage } from '../../shared/components/BusinessDefaultImage';
import { NumberIcon } from './Icons/NumberIcon';

interface BusinessCellProps {
  businessName: string;
  fullName?: string;
  imageUrl?: string;
  applicationNumber: string | null;
}

interface BusinessImageProps {
  imageUrl?: string;
  size: number;
}

const StyledBusinessImage = styled.img<{ size: number }>`
  border-radius: 4px;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  object-fit: cover;
`;

const BusinessImage: FC<BusinessImageProps> = ({ imageUrl, size }) =>
  imageUrl ? <StyledBusinessImage size={size} src={imageUrl} /> : <BusinessDefaultImage size={size} />;

export const BusinessCell: FC<BusinessCellProps> = ({ businessName, fullName, imageUrl, applicationNumber }) => (
  <Flex gap={3} alignItems={'center'} width={'100%'}>
    <BusinessImage imageUrl={imageUrl} size={42} />
    <Flex flexDirection={'column'} flex={1} minWidth={0}>
      <Tooltip title={businessName}>
        <Flex flexDirection={'row'} alignItems={'center'} gap={2} minWidth={0}>
          <Flex flex={1} minWidth={0} flexDirection={'row'}>
            <Text variant={'body2'} color={'text.primary'} weight={500} ellipsis>
              {businessName}
            </Text>
          </Flex>
        </Flex>
      </Tooltip>
      <Flex flexDirection={'row'} alignItems={'center'} gap={2}>
        {applicationNumber ? (
          <Flex gap={1} alignItems={'center'}>
            <NumberIcon size={'16px'} />
            <Text variant={'body3'} color={'secondary'} ellipsis>
              {applicationNumber}
            </Text>
          </Flex>
        ) : null}
        {fullName ? (
          <Tooltip title={fullName}>
            <Flex flexDirection={'row'} alignItems={'center'} gap={1}>
              <PersonIcon />
              <Text variant={'body3'} color={'secondary'} ellipsis>
                {fullName}
              </Text>
            </Flex>
          </Tooltip>
        ) : null}
      </Flex>
    </Flex>
  </Flex>
);
