import React, { forwardRef, useContext, useMemo } from 'react';
import { applicationImportantPeopleSelector, relatedBusinessesByRelationsSelector } from '@lama/selectors';
import { LoadingPage } from '@lama/app-components';
import { Text } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { Relation } from '@lama/contracts';
import { ApplicationContext } from '../../ApplicationContext';
import type { EntitiesByType } from '../../CreditMemo/Spreads/components/SpreadTable/types';
import { SpreadTable } from '../../CreditMemo/Spreads/components/SpreadTable';
import { useGetMultiEntityFinancialsQuery } from '../../../../shared/hooks/react-query/financials/useGetMultiEntityFinancialsQuery';
import type { SpreadSegmentProps, SpreadTableRef } from './types';
import { useSpreadConfigurationById } from './hooks/useSpreadConfiguration';

const relevantBusinessRelations = new Set<Relation>(['guarantor', 'borrower', 'property', 'seller']);

export const GlobalCashFlowSpreadSegment = forwardRef<SpreadTableRef, SpreadSegmentProps>(
  ({ printMode, spreadId, allowAdjustments, ...restOfProps }, spreadTableRef) => {
    const { application, opportunity, properties } = useContext(ApplicationContext);

    const { showEntityNamesInGlobalSpread } = useFlags();

    const relevantBusinesses = useMemo(
      () => relatedBusinessesByRelationsSelector(application, [...relevantBusinessRelations]),
      [application],
    );

    const affiliates = relevantBusinesses.flatMap((b) => b.business.affiliates ?? []);
    const importantPeople = applicationImportantPeopleSelector(application);

    const { financials, loading } = useGetMultiEntityFinancialsQuery(
      [
        ...relevantBusinesses.map((b) => ({ entityId: b.business.id })),
        ...(importantPeople?.map((p) => ({ entityId: p.person.id })) ?? []),
      ],
      false,
    );

    const spreadConfiguration = useSpreadConfigurationById(spreadId);

    const spreadEntities: EntitiesByType = useMemo(
      () => ({
        affiliate: affiliates,
        business: relevantBusinesses,
        person: importantPeople,
        application: [application],
        opportunity: [opportunity],
      }),
      [affiliates, application, relevantBusinesses, importantPeople, opportunity],
    );

    if (!spreadConfiguration) {
      return <Text variant={'body1'}>{'Spreads are not configured, please contact support.'}</Text>;
    }

    if (loading) {
      return <LoadingPage />;
    }

    return (
      <SpreadTable
        entities={spreadEntities}
        properties={properties}
        applicationId={application.id}
        opportunity={opportunity}
        spreadsSettings={opportunity.spreadsSettings}
        spreadConfig={spreadConfiguration}
        financials={financials}
        defaultYearColumns={spreadConfiguration?.spreadYears ?? [2022]}
        printMode={printMode}
        spreadName={'GlobalCashFlow'}
        periodDisplayFormat={'income statement'}
        allowAdjustments={allowAdjustments}
        showPeopleNames={!!showEntityNamesInGlobalSpread}
        ref={spreadTableRef}
        title={restOfProps.title}
      />
    );
  },
);
