import type { FC } from 'react';
import React from 'react';
import type { UnderwritingNote } from '@lama/contracts';
import type { User } from '@lama/user-service-client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NoteCard } from './NoteCard';

interface FlagCardProps {
  flag: UnderwritingNote;
  user?: User;
}

export const FlagCard: FC<FlagCardProps> = ({ flag, user }) => {
  const { enableClearFlag } = useFlags();
  return (
    <NoteCard
      note={flag}
      user={user}
      deleteEnabled={!enableClearFlag}
      changeNoteTypeButtonsProps={[
        { changeNoteTypeCTA: 'Mark Exception', noteTypeTarget: 'exception' },
        ...(enableClearFlag
          ? [
              {
                changeNoteTypeCTA: 'Clear',
                noteTypeTarget: 'cleared' as const,
                confirmMessage: 'Are you sure you want to clear this flag?',
              },
            ]
          : []),
      ]}
    />
  );
};
