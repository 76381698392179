import type { Relation } from '@lama/contracts';
import type { MultipliedUnderwritingSection } from '../Underwriting/UnderwritingPage';
import { getPrimaryBusinessRelation } from '../../../shared/utils/relatedBusinesses';

export const getSpreadIdBySectionAndRelations = (section: MultipliedUnderwritingSection, relations: Relation[]) => {
  const primaryRelation = getPrimaryBusinessRelation(relations);

  return primaryRelation && section.spreadIdByRelation?.[primaryRelation]
    ? section.spreadIdByRelation?.[primaryRelation]
    : section.spreadId;
};
