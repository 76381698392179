/* eslint-disable @typescript-eslint/naming-convention */
import type { MutableRefObject, FC } from 'react';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import { Menu, MenuItem, Stack } from '@mui/material';
import { Text } from '@lama/design-system';
import { type ApplicationStatus } from '@lama/contracts';
import { getApprovalFlow } from '@lama/approval-flows';
import { intersection } from 'lodash-es';
import { useConfirmModal } from '@lama/app-components';
import { ApplicationContext } from '../ApplicationContext';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { DecisionModal } from '../../../shared/components/DecisionDialog/DecisionModal';
import { useUserQuery } from '../../../shared/hooks/react-query/user/useUserQuery';
import { useUpdateOpportunityMutation } from '../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { ApplicationStatusDisplayNameMappingContext } from '../../../shared/context/ApplicationStatusDisplayNameMappingContext';
import { SubMenuHeader } from './SubMenuHeader';
import { useUpdateStatusMutation } from './hooks/useUpdateStatusMutation';
import { ActionMenuSearchInput } from './ActionMenuSearchInput';

interface SetStatusMenuProps {
  menuProps: any;
  onBack: () => void;
  open: boolean;
  menuRef: MutableRefObject<null>;
  onClose: () => void;
}

const menuStatuses: ApplicationStatus[] = [
  'Draft',
  'OnboardingCompleted',
  'InReview',
  'PendingApproval',
  'Closing',
  'Closed',
  'ExpresslyWithdrawn',
  'Rejected',
];
const statusesWithReason = new Set<ApplicationStatus>(['Rejected', 'ExpresslyWithdrawn']);

export const SetStatusMenu: FC<SetStatusMenuProps> = ({ menuProps, onBack, open, menuRef, onClose }) => {
  const { application, opportunity } = useContext(ApplicationContext);
  const { applicationStatusToDisplayName } = useContext(ApplicationStatusDisplayNameMappingContext);

  const menuStatusesDisplayNames = useMemo(
    () =>
      menuStatuses.map((status) => ({
        id: status,
        text: applicationStatusToDisplayName[status],
      })),
    [applicationStatusToDisplayName],
  );

  const { partner, userId } = useContext(UserDetailsContext);
  const [decision, setDecision] = useState<ApplicationStatus | null>(null);
  const { confirm } = useConfirmModal();

  const [filterOptions, setFilterOptions] = useState('');
  const { mutate: updateStatus } = useUpdateStatusMutation(application.id, opportunity.id);
  const { mutate: updateOpportunity } = useUpdateOpportunityMutation(opportunity.id);

  const handleClose = useCallback(() => {
    setFilterOptions('');
    onClose();
  }, [onClose]);

  const { data: user } = useUserQuery(userId);

  const approvingRoles = useMemo(() => {
    const getApprovingRoles = getApprovalFlow(partner ?? '');
    return getApprovingRoles?.({ opportunity }) ?? [];
  }, [opportunity, partner]);

  const canUserMakeDecision = useMemo(
    () => !!user?.roles?.length && !!approvingRoles.length && !!intersection(user.roles, approvingRoles).length,
    [approvingRoles, user],
  );

  const updateApplicationStatus = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      const status = event.currentTarget.getAttribute('value') as ApplicationStatus | null;

      if (!status) {
        return;
      }

      if (statusesWithReason.has(status)) {
        setDecision(status);
      } else {
        const confirmed = await confirm({
          title: `Are you sure you want to move this application from ${applicationStatusToDisplayName[application.status]} to ${
            applicationStatusToDisplayName[status]
          }?`,
        });

        if (confirmed) {
          updateStatus({
            updateApplicationPayload: {
              status,
            },
          });

          if (statusesWithReason.has(application.status)) {
            updateOpportunity({
              adverseActionNoticeSent: false,
            });
          }
        }
      }

      handleClose();
    },
    [application.status, applicationStatusToDisplayName, confirm, handleClose, updateOpportunity, updateStatus],
  );

  const onFilterChange = useCallback((value: string) => {
    setFilterOptions(value);
  }, []);

  const options = useMemo(
    () => menuStatusesDisplayNames.filter((stat) => `${stat.text.toLocaleLowerCase()}`.includes(filterOptions.toLocaleLowerCase())) ?? [],
    [filterOptions, menuStatusesDisplayNames],
  );

  const resetSelection = useCallback(() => {
    setDecision(null);
  }, []);

  return (
    <>
      <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={handleClose}>
        <Stack gap={1}>
          <SubMenuHeader onClick={onBack} text={'Set Status'} />
          <ActionMenuSearchInput onInputChange={onFilterChange} />
          <Stack pt={1} px={1} sx={{ maxHeight: '300px', overflow: 'auto' }}>
            {options.map(({ id, text }) => (
              <MenuItem
                key={id}
                value={id}
                onClick={updateApplicationStatus}
                disabled={id === 'Rejected' && !canUserMakeDecision}
                sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px', px: 2 }}
              >
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Text variant={'body2'} ellipsis>
                    {text}
                  </Text>
                </Stack>
              </MenuItem>
            ))}
          </Stack>
        </Stack>
      </Menu>
      {decision ? (
        <DecisionModal decision={decision} onClose={resetSelection} open={!!decision} canCurrentUserApprove={canUserMakeDecision} />
      ) : null}
    </>
  );
};
