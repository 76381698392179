import React, { useMemo, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { CreateCondition } from '@lama/clients';
import type { Condition, GreaterThanMatcher, LessThanMatcher } from '@lama/contracts';
import { Slider, TextField } from '@mui/material';
import type { FC } from 'react';
import { useDebounce } from 'react-use';
import { isNil } from 'lodash-es';
import type { GreaterThanConditionConfiguration, LessThanConditionConfiguration } from '@lama/conditions';
import { formatValue } from '@lama/data-formatters';
import { ConditionCard } from './ConditionCard';

export interface ConditionWithInequalityMatcher extends Condition {
  conditionMatcher: GreaterThanMatcher | LessThanMatcher;
}

interface NumberConditionProps {
  condition?: ConditionWithInequalityMatcher;
  configuration: GreaterThanConditionConfiguration | LessThanConditionConfiguration;
  onChange: (condition: Condition | CreateCondition) => void;
}

export const InequalityCondition: FC<NumberConditionProps> = ({ condition, configuration, onChange: onConditionChange }) => {
  const [value, setValue] = useState<number>(condition?.conditionMatcher.value ?? configuration.default);

  const conditionUpdated = useMemo(() => {
    if (condition) {
      return value !== condition.conditionMatcher.value;
    }

    return value !== configuration.default;
  }, [condition, configuration.default, value]);

  const upsertCondition = useCallback(
    (active?: boolean) => {
      if (!conditionUpdated && isNil(active)) {
        return;
      }

      const conditionUpdate: CreateCondition = condition
        ? { ...condition, conditionMatcher: { ...condition.conditionMatcher, value }, active: active ?? true }
        : {
            id: uuidv4(),
            conditionMatcher: {
              entityType: configuration.entityType,
              fieldName: configuration.selector,
              type: configuration.type,
              value,
            },
            name: configuration.displayName,
            active: true,
          };

      onConditionChange(conditionUpdate);
    },
    [
      condition,
      conditionUpdated,
      configuration.displayName,
      configuration.entityType,
      configuration.selector,
      configuration.type,
      onConditionChange,
      value,
    ],
  );

  const marks = useMemo(
    () => [
      {
        value: configuration.min,
        label: formatValue(configuration.min, configuration.format),
      },
      {
        value: configuration.max,
        label: formatValue(configuration.max, configuration.format),
      },
    ],
    [configuration?.format, configuration.max, configuration.min],
  );

  useDebounce(upsertCondition, 500, [value]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value));
  }, []);

  const onSliderChange = useCallback((event: Event, changedValue: number[] | number) => {
    setValue(changedValue as number);
  }, []);

  return (
    <ConditionCard configuration={configuration} active={condition?.active} onChange={upsertCondition}>
      <TextField
        id={`min-${configuration.selector}}`}
        label={configuration.displayName}
        type={'number'}
        onChange={onChange}
        InputProps={{ inputProps: { min: configuration.min, max: configuration.max, step: configuration.step } }}
        value={value}
        fullWidth
      />
      <Slider
        id={`slider-${configuration.selector}}`}
        value={value}
        onChange={onSliderChange}
        marks={marks}
        min={configuration.min}
        max={configuration.max}
        step={configuration.step}
      />
    </ConditionCard>
  );
};
