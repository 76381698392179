import React, { useContext, type FC } from 'react';
import { useAsyncFn } from 'react-use';
import { displayToast } from '@lama/app-components';
import type { ApplicationByStatusExportRow } from '@lama/reports-service-client';
import { applicationByStatusReportExcelSchema } from '@lama/reports-service-client';
import { format } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReportsFilterContext } from '../ReportsFilterContext';
import { ReportCard } from '../ReportCard';
import { useDownloadReportMutation } from '../../hooks/downloadReportMutation';
import { useApplicationsByStatusChartData } from '../Charts/hooks/useApplicationsByStatusChartData';
import { ApplicationsByStatusChart } from '../Charts/ApplicationsByStatusBarChart';

const REPORT_NAME = 'Pipeline';

export const PipelineReport: FC = () => {
  const { partnerId, productId, startDate, endDate } = useContext(ReportsFilterContext);
  const { getPersistPipelineReport } = useFlags();
  const { mutateAsync: downloadReport } = useDownloadReportMutation(getPersistPipelineReport ? 'PersistStatus' : 'Status');
  const { data: chartData, isPending: loadingChartData } = useApplicationsByStatusChartData({ endDate, startDate, productId, partnerId });

  const [{ loading: exporting }, exportReport] = useAsyncFn(async () => {
    if (!startDate || !endDate || !partnerId || !productId) {
      displayToast(`${REPORT_NAME} report download unavailable, please contact support`, 'error');

      console.error('some params are missing for downloading', REPORT_NAME, { partnerId, productId, startDate, endDate });

      return;
    }

    const filename = `${REPORT_NAME} Report ${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}.xlsx`;

    const { default: writeXlsxFile } = await import('write-excel-file');

    const reportData: ApplicationByStatusExportRow[] = await downloadReport({
      body: {
        partnerId,
        productId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        format: 'application/json',
      },
      filename,
    });

    await writeXlsxFile(reportData, {
      schema: applicationByStatusReportExcelSchema,
      fileName: `Application Report ${new Date().toLocaleDateString()}.xlsx`,
    });
  }, [downloadReport, endDate, partnerId, productId, startDate]);

  return (
    <ReportCard
      disableActions={loadingChartData || exporting}
      actionText={'Download Report'}
      onClickAction={exportReport}
      actionLoading={exporting}
      loading={loadingChartData}
      title={'Pipeline Report'}
    >
      <ApplicationsByStatusChart chartData={chartData} />
    </ReportCard>
  );
};
