import { Flex, Text, Button } from '@lama/design-system';
import { LoadingButton } from '@mui/lab';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import type { OpportunityApiModel } from '@lama/clients';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfirmModal } from '@lama/app-components';
import { useCreateDocumentIssueMutation } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useCreateDocumentIssueMutation';
import { useGetFinancialsQuery } from '../../../../shared/hooks/react-query/financials/useGetFinancialsQuery';
import { useSubmitFinancialsMutation } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useSubmitFinancialsMutation';
import { useUpdateDocumentMutation } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useUpdateDocumentMutation';
import { SpreadingDocumentContext } from '../../SpreadingDocumentContext';
import { useDocumentLineItemsQuery } from '../../../../shared/hooks/react-query/document/lineItems/useDocumentLineItemsQuery';
import { useUpdateDocumentLineItemsMutation } from '../../../../shared/hooks/react-query/document/lineItems/useUpdateDocumentLineItemsMutation';
import type { FinancialUpdatePayload } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/types';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useGetDocumentAttributes } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useGetDocumentAttributes';
import { useGroupedDocumentPagesByFormInstance } from '../../hooks/useGetGroupedDocumentPAgesByFormInstance';
import { useGetDocumentCopies } from '../../../../shared/hooks/react-query/document/useGetDocumentCopies';
import { CreateDocumentErrorPopover } from './CreateDocumentErrorPopover';
import { exportSampleData } from './exportSampleData.js';
import { copytoSpreadingTestsPartner } from './copytoSpreadingTestsPartner.js';

const errorTypes = [
  'Wrong document',
  'Missing form',
  'Wrong year',
  'Bad scan quality',
  'Automatic spreading is not supported for password protected documents',
  'Duplicate document',
  'File format is not supported',
  'Tax return required, IRS transcript received',
];

interface SpreadingToolbarActionsProps {
  opportunity?: OpportunityApiModel;
  applicationId?: string;
}

export const SpreadingToolbarActions: FC<SpreadingToolbarActionsProps> = ({ opportunity, applicationId }) => {
  const { currentDocument } = useContext(SpreadingDocumentContext);

  const { spreadingBackOfficeMode } = useFlags();
  const { isPending: creatingIssue, mutateAsync: createDocumentIssue } = useCreateDocumentIssueMutation(opportunity?.id, applicationId);
  const { data: documentAttributes } = useGetDocumentAttributes();
  const { confirm } = useConfirmModal();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { data: entityFinancials, isPending: fetchingFinancials } = useGetFinancialsQuery(
    currentDocument.relatedEntityId,
    !!spreadingBackOfficeMode,
  );

  const { data: documentCopies } = useGetDocumentCopies(currentDocument.id, { enabled: !!spreadingBackOfficeMode });

  const { mutateAsync: updateFinancialData } = useSubmitFinancialsMutation(
    currentDocument.relatedEntityId,
    currentDocument.relatedEntityType,
    opportunity?.id,
  );
  const { mutateAsync: updateDocument } = useUpdateDocumentMutation({ opportunityId: opportunity?.id, applicationId });

  const { data: documentLineItems } = useDocumentLineItemsQuery(currentDocument.id, !!spreadingBackOfficeMode);
  const { mutateAsync: updateDocumentLineItems } = useUpdateDocumentLineItemsMutation();

  const { getAccessTokenSilently } = useAuth0();
  const user = useContext(UserDetailsContext);

  const onSendErrorClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const sendDocumentIssue = useCallback(
    async (reason: string) => {
      if (currentDocument?.id) {
        await createDocumentIssue({
          documentId: currentDocument.id,
          issue: {
            id: uuidv4(),
            description: reason,
          },
        });
      }
    },
    [createDocumentIssue, currentDocument],
  );

  const validateFinancials = useCallback(async () => {
    const financialsToVerify = entityFinancials
      ?.filter(
        (f) =>
          (f.source.type === 'Document' && f.source.documentId === currentDocument?.id && f.source.unverified) ||
          (f.source.type === 'Manual' && f.source.relatedDocumentId === currentDocument?.id && f.source.unverified),
      )
      .map<FinancialUpdatePayload>(({ id, source }) => ({
        action: 'update',
        id,
        source: {
          ...source,
          unverified: false,
        },
      }));

    if (!financialsToVerify?.length) {
      return;
    }

    await updateFinancialData(financialsToVerify);
  }, [currentDocument, entityFinancials, updateFinancialData]);

  const validateLineItems = useCallback(async () => {
    const lineItemsToUpdate = documentLineItems?.filter((lineItem) => lineItem.unverified).map(({ id }) => id);

    if (!lineItemsToUpdate) {
      return;
    }

    await updateDocumentLineItems({
      documentId: currentDocument.id,
      lineItemUpdateBody: { lineItemUpdates: lineItemsToUpdate.map((id) => ({ id, unverified: false })) },
    });
  }, [documentLineItems, currentDocument, updateDocumentLineItems]);

  const hasUnverifiedFields = useMemo(
    () =>
      entityFinancials?.some(
        ({ source }) =>
          ((source.type === 'Document' && source.documentId === currentDocument.id) ||
            (source.type === 'Manual' && source.relatedDocumentId === currentDocument.id)) &&
          source.unverified,
      ) || documentLineItems?.some(({ unverified }) => unverified),
    [entityFinancials, documentLineItems, currentDocument.id],
  );

  const shouldShowVerificationButton = useMemo(
    () => spreadingBackOfficeMode && (hasUnverifiedFields || currentDocument.status === 'Uploaded'),
    [spreadingBackOfficeMode, hasUnverifiedFields, currentDocument],
  );

  const [{ loading: validatingData }, validateSpreadingData] = useAsyncFn(async () => {
    const confirmed = await confirm({
      title: 'Are you sure?',
      message: 'Are you sure you want to send all fields to the user?\nMake sure you have verified and filled all fields',
    });

    if (!confirmed) {
      return false;
    }

    const token = await getAccessTokenSilently();

    await validateLineItems();
    await validateFinancials();

    if (currentDocument && currentDocument.status === 'Uploaded') {
      await updateDocument({ documentId: currentDocument.id, payload: { status: 'Processed', extracted: true }, systemUpdate: true });
    }

    await copytoSpreadingTestsPartner(user, currentDocument, token, documentCopies);
  }, [confirm, getAccessTokenSilently, validateLineItems, validateFinancials, currentDocument, user, documentCopies, updateDocument]);

  const groupedDocumentPagesByFormInstance = useGroupedDocumentPagesByFormInstance();

  const exportAsSample = useCallback(async () => {
    void exportSampleData(
      currentDocument,
      groupedDocumentPagesByFormInstance,
      entityFinancials ?? [],
      documentAttributes ?? [],
      documentLineItems ?? [],
    );
  }, [currentDocument, documentAttributes, documentLineItems, entityFinancials, groupedDocumentPagesByFormInstance]);

  const onCopyToTestPartnerClick = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const newOpportunityId = await copytoSpreadingTestsPartner(user, currentDocument, token, documentCopies);
    if (newOpportunityId) {
      open(`/pipeline/${newOpportunityId}`);
    }
  }, [currentDocument, documentCopies, getAccessTokenSilently, user]);

  return (
    <Flex gap={1} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
      {spreadingBackOfficeMode ? (
        <>
          {shouldShowVerificationButton ? (
            <LoadingButton
              type={'button'}
              onClick={validateSpreadingData}
              disabled={!hasUnverifiedFields}
              sx={{ height: '40px', cursoer: 'pointer', borderRadius: '4px' }}
              variant={'contained'}
              disableElevation
              loading={validatingData || fetchingFinancials}
            >
              <Text variant={'body2'}>{'Validate'}</Text>
            </LoadingButton>
          ) : null}
          <Button style={{ height: '40px' }} onClick={exportAsSample}>
            {'Download Sample'}
          </Button>
          <Button style={{ height: '40px' }} onClick={onCopyToTestPartnerClick} disabled={!!documentCopies?.length}>
            {'Copy to Test Partner'}
          </Button>
          <LoadingButton
            type={'button'}
            onClick={onSendErrorClick}
            disabled={!currentDocument || !!currentDocument.issues?.length}
            color={'error'}
            sx={{ height: '40px', cursoer: 'pointer', borderRadius: '4px' }}
            variant={'contained'}
            disableElevation
            loading={creatingIssue}
          >
            <Text variant={'body2'}>{'Send Document Error'}</Text>
          </LoadingButton>
          {anchorEl ? (
            <CreateDocumentErrorPopover
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              onSubmit={sendDocumentIssue}
              options={errorTypes}
            />
          ) : null}
        </>
      ) : null}
    </Flex>
  );
};
