import React, { useCallback, useContext, useRef, useState, useMemo } from 'react';
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { grey } from '@mui/material/colors';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Tooltip, displayToast } from '@lama/app-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationContext } from '../ApplicationContext';
import { isAllowedToEditApplication } from '../../../shared/utils/isAllowedToEditApplication';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { getProtocolAndHost } from '../../../shared/utils/getProtocolAndHost';
import { CreateOpportunityRequirementSearchMenu } from './CreateOpportunityRequirementSearchMenu';
import { SetStatusMenu } from './SetStatusMenu';
import { ExportMenu } from './ExportMenu';
import { ChangeOpportunityProductMenu } from './ChangeOpportunityProductMenu';
import { CommunicationMenu } from './CommunicationMenu';
import { SetReferrerMenu } from './SetReferrerMenu';
import { useRecreateOpportunityMustation } from './hooks/useRecrateOpportunityMutation';
import { ShareWithApplicantSearchMenu } from './ShareWithApplicantSearchMenu';

export const menuProps = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'right' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'right' as const,
  },
  PaperProps: {
    elevation: 0,
    sx: {
      border: 0,
      py: 0,
      mt: 1,
      borderWidth: '1px',
      borderColor: grey[300],
      boxShadow: '0px 11px 15px 0px #DBDBDB26',
    },
  },
};

interface ApplicationActionsMenuItemProps {
  text: string;
  icon?: React.ReactNode;
  onClick: () => void;
  color?: string;
}

const ApplicationActionMenuItem: React.FC<ApplicationActionsMenuItemProps> = ({ text, onClick, icon, color }) => (
  // eslint-disable-next-line @typescript-eslint/naming-convention
  <MenuItem onClick={onClick} sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}>
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <Typography variant={'body1'} color={color ?? 'text.secondary'}>
        {text}
      </Typography>
      <Typography variant={'body1'} color={color ?? 'text.secondary'}>
        {icon}
      </Typography>
    </Stack>
  </MenuItem>
);

export const ApplicationActionsMenu: React.FC = () => {
  const { application, opportunity } = useContext(ApplicationContext);
  const userDetails = useContext(UserDetailsContext);

  const { id: applicationId, originatingPartner } = application;
  const { showEditInBorrower, enableChangeProduct, showCommunicationMenu, enableSetReferrer, enableRecreateOpportunity, enableWorkflows } =
    useFlags();

  const { mutateAsync: recreateOpportunity } = useRecreateOpportunityMustation();

  const menuRef = useRef(null);

  const [open, setOpen] = useState(false);

  const [openSubMenu, setOpenSubMenu] = useState<
    | 'addNote'
    | 'changeProduct'
    | 'communication'
    | 'createRequirement'
    | 'decline'
    | 'export'
    | 'setReferrer'
    | 'shareWithApplicant'
    | 'stage'
    | null
  >(null);

  const allowedToEdit = useMemo(() => isAllowedToEditApplication(application, userDetails), [application, userDetails]);

  const addRequirement = useCallback(() => {
    setOpenSubMenu('createRequirement');
    setOpen(false);
  }, []);

  const onSubMenuClose = useCallback(() => {
    setOpenSubMenu(null);
    setOpen(false);
  }, []);

  const onSetStageClick = useCallback(() => {
    setOpenSubMenu('stage');
    setOpen(false);
  }, []);

  const onShareWithApplicantMenuClick = useCallback(() => {
    setOpenSubMenu('shareWithApplicant');
    setOpen(false);
  }, []);

  const onExportMenuClick = useCallback(() => {
    setOpenSubMenu('export');
    setOpen(false);
  }, []);

  const onChangeProductClick = useCallback(() => {
    setOpenSubMenu('changeProduct');
    setOpen(false);
  }, []);

  const onSetReferrerClick = useCallback(() => {
    setOpenSubMenu('setReferrer');
    setOpen(false);
  }, []);

  const onRecreateOpportunityClick = useCallback(async () => {
    setOpen(false);
    await recreateOpportunity({ opportunityId: opportunity.id });
    displayToast('Opportunity has been recreated.', 'success');
  }, [opportunity.id, recreateOpportunity]);

  const onSubMenuBack = useCallback(() => {
    setOpenSubMenu(null);
    setOpen(true);
  }, []);

  const onIconClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onEditClick = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const onCommunicationClick = useCallback(() => {
    setOpenSubMenu('communication');
    setOpen(false);
  }, []);

  return (
    <>
      <Tooltip title={'Actions'}>
        <IconButton
          onClick={onIconClick}
          // eslint-disable-next-line @typescript-eslint/naming-convention
          sx={{ borderRadius: '8px', '&:hover': { bgcolor: 'primary.light' }, bgcolor: open || openSubMenu ? 'primary.light' : undefined }}
          ref={menuRef}
          role={'button'}
          disableRipple
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        key={'application-actions-menu'}
        anchorEl={menuRef.current}
        open={open}
        onClose={handleClose}
        {...menuProps}
        MenuListProps={{ sx: { py: 0.5, px: 0.5 } }}
      >
        {allowedToEdit && showEditInBorrower ? (
          <a
            href={`${getProtocolAndHost(originatingPartner)}/lender-login?returnTo=/applications/${applicationId}`}
            target={'_blank'}
            rel={'noreferrer'}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <ApplicationActionMenuItem text={'Edit '} icon={<ArrowOutwardIcon />} onClick={onEditClick} />
          </a>
        ) : null}
        {allowedToEdit ? <ApplicationActionMenuItem text={'Add Requirement'} onClick={addRequirement} /> : null}
        <ApplicationActionMenuItem text={'Copy Applicant Share Link'} onClick={onShareWithApplicantMenuClick} />
        <ApplicationActionMenuItem text={'Export'} onClick={onExportMenuClick} />
        {enableWorkflows ? null : <ApplicationActionMenuItem text={'Set Status'} onClick={onSetStageClick} />}
        {showCommunicationMenu ? <ApplicationActionMenuItem text={'Communication'} onClick={onCommunicationClick} /> : null}
        {enableChangeProduct ? <ApplicationActionMenuItem text={'Change Product'} onClick={onChangeProductClick} /> : null}
        {enableSetReferrer ? <ApplicationActionMenuItem text={'Set Referrer'} onClick={onSetReferrerClick} /> : null}
        {enableRecreateOpportunity ? (
          <ApplicationActionMenuItem text={'Recreate Opportunity'} onClick={onRecreateOpportunityClick} />
        ) : null}
      </Menu>
      {open || openSubMenu === 'changeProduct' ? (
        <ChangeOpportunityProductMenu
          open={openSubMenu === 'changeProduct'}
          menuProps={menuProps}
          onBack={onSubMenuBack}
          onClose={onSubMenuClose}
          menuRef={menuRef}
        />
      ) : null}
      {open || openSubMenu === 'createRequirement' ? (
        <CreateOpportunityRequirementSearchMenu
          open={openSubMenu === 'createRequirement'}
          menuProps={menuProps}
          onBack={onSubMenuBack}
          onClose={onSubMenuClose}
          menuRef={menuRef}
        />
      ) : null}
      {enableSetReferrer && (open || openSubMenu === 'setReferrer') ? (
        <SetReferrerMenu
          open={openSubMenu === 'setReferrer'}
          menuProps={menuProps}
          onBack={onSubMenuBack}
          onClose={onSubMenuClose}
          menuRef={menuRef}
        />
      ) : null}
      <SetStatusMenu
        open={openSubMenu === 'stage'}
        menuProps={menuProps}
        onBack={onSubMenuBack}
        onClose={onSubMenuClose}
        menuRef={menuRef}
      />
      {showCommunicationMenu ? (
        <CommunicationMenu
          open={openSubMenu === 'communication'}
          menuProps={menuProps}
          onBack={onSubMenuBack}
          onClose={onSubMenuClose}
          menuRef={menuRef}
        />
      ) : null}
      {open || openSubMenu === 'shareWithApplicant' ? (
        <ShareWithApplicantSearchMenu
          open={openSubMenu === 'shareWithApplicant'}
          menuProps={menuProps}
          onBack={onSubMenuBack}
          onClose={onSubMenuClose}
          menuRef={menuRef}
        />
      ) : null}
      <ExportMenu open={openSubMenu === 'export'} menuProps={menuProps} onBack={onSubMenuBack} onClose={onSubMenuClose} menuRef={menuRef} />
    </>
  );
};
