/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
import type { MutableRefObject, FC } from 'react';
import React, { useRef, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { saveAs } from 'file-saver';
import { capitalCase } from 'change-case-all';
import * as selectors from '@lama/selectors';
import { useReactToPrint } from 'react-to-print';
import { Flex, Spinner, Text } from '@lama/design-system';
import { displayToast } from '@lama/app-components';
import { useTranslation } from 'react-i18next';
import { compact } from 'lodash-es';
import { ApplicationContext } from '../ApplicationContext';
import { CreditMemoExport } from '../../ApplicationPrint/CreditMemoExport/CreditMemoExport';
import { ApplicationExport } from '../../ApplicationPrint/ApplicationExport/ApplicationExport';
import { SpreadsExport } from '../../ApplicationPrint/SpreadsExport/SpreadsExport';
import { useOpportunityRequirementsQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { useLenderProfilesByPartnerQuery } from '../../../shared/hooks/react-query/lenderProfile/useLenderProfilesByPartnerQuery';
import { useUsersByPartnerQuery } from '../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { useGetPartnerQuery } from '../../../shared/hooks/react-query/partner/useGetPartnerQuery';
import { downloadDocumentsZip } from './downloadDocumentsZip';
import { transformToLaserProFormat } from './utils/laserPro';
import { SubMenuHeader } from './SubMenuHeader';
import { ExportCreditReportMenu } from './ExportCreditReportMenu';
import { transformToLaserProPremiumFormat } from './utils/laserProPermium';

interface ExportMenuProps {
  menuProps: any;
  onBack: () => void;
  open: boolean;
  menuRef: MutableRefObject<null>;
  onClose: () => void;
}

export const ExportMenu: FC<ExportMenuProps> = ({ menuProps, open, menuRef, onClose, onBack }) => {
  const { application, opportunity } = useContext(ApplicationContext);
  const businessName = useMemo<string | null>(() => selectors.loanDisplayNameSelector(application), [application]);
  const [openSubMenu, setOpenSubMenu] = useState<'exportCreditReport' | null>(null);
  const { t } = useTranslation();
  const { data: requirements, isPending: loadingRequirements } = useOpportunityRequirementsQuery(opportunity.id, { enabled: open });
  const { getAccessTokenSilently } = useAuth0();

  const { showExportToLaserPro, showCreditReportExport, showExportSpreads, waitForCreditMemoExport, showExportToLaserProPremium } =
    useFlags();

  const creditMemoRef = useRef(null);
  const applicationRef = useRef(null);
  const [exportSpreadsRequested, setExportSpreadsRequested] = useState(false);

  const { data: partner, isPending: loadingPartner } = useGetPartnerQuery(opportunity.partnerId);
  const { data: users, isPending: loadingUsers } = useUsersByPartnerQuery(opportunity.partnerId);
  const { data: lenderProfiles, isPending: loadingLenderProfiles } = useLenderProfilesByPartnerQuery(opportunity.partnerId);

  const lenderProfile = useMemo(() => {
    const assginedRM = users?.find((u) => (opportunity.assigneeIds ?? []).includes(u.id) && u.roles.includes('relationshipManager'));
    return lenderProfiles?.find((p) => p.userId === assginedRM?.id);
  }, [lenderProfiles, opportunity.assigneeIds, users]);

  const assignedUsers = useMemo(
    () => users?.filter((u) => (opportunity.assigneeIds ?? []).includes(u.id)) ?? [],
    [opportunity.assigneeIds, users],
  );

  const assignedLenderProfiles = useMemo(
    () => assignedUsers.map((u) => lenderProfiles?.find((p) => p.userId === u.id)) ?? [],
    [assignedUsers],
  );

  const [printApplicationExport, setPrintApplicationExport] = useState(false);

  const onBeforeGetApplicationExportContentResolveRef = useRef();

  useEffect(() => {
    // Wait for the state update
    if (onBeforeGetApplicationExportContentResolveRef.current && printApplicationExport) {
      // @ts-ignore
      onBeforeGetApplicationExportContentResolveRef.current(); // Resolve the promise
      onBeforeGetApplicationExportContentResolveRef.current = undefined;
    }
  }, [printApplicationExport]);

  const createHandler = useCallback(
    (handler: () => void, timeout?: number) => () => {
      if (timeout) {
        setTimeout(() => {
          handler();
          onClose();
        }, timeout);
      } else {
        handler();
        onClose();
      }
    },
    [onClose, waitForCreditMemoExport],
  );

  const exportCreditMemo = useReactToPrint({
    content: () => creditMemoRef.current,
    documentTitle: `${businessName} Credit Memo`,
  });

  const exportApplication = useReactToPrint({
    content: () => applicationRef.current,
    documentTitle: `${businessName} Application`,
    onBeforeGetContent: () => {
      setPrintApplicationExport(true);
      return new Promise((resolve) => {
        // @ts-ignore
        onBeforeGetApplicationExportContentResolveRef.current = resolve;
      });
    },
    onAfterPrint: () => {
      setPrintApplicationExport(false);
    },
  });

  const requestExportSpreads = useCallback(() => {
    setExportSpreadsRequested(true);
  }, []);

  const onExportSpreadsComplate = useCallback(() => {
    setExportSpreadsRequested(false);
    onClose();
  }, [onClose]);

  const downloadDocuments = useCallback(async () => {
    displayToast(t('export.documents.loading'), 'info');
    const token = await getAccessTokenSilently();
    await downloadDocumentsZip(application, token);
  }, [application, getAccessTokenSilently, t]);

  const exportMenuOptions = useMemo(
    () => [
      {
        id: 'exportCreditMemo',
        text: 'Export Credit Memo',
        handle: createHandler(exportCreditMemo, typeof waitForCreditMemoExport === 'number' ? waitForCreditMemoExport : 0),
      },
      {
        id: 'exportApplication',
        text: 'Export Application',
        loading: loadingRequirements,
        handle: createHandler(exportApplication),
      },
      ...(showExportSpreads
        ? [
            {
              id: 'exportSpreading',
              text: 'Export Financial Spreads',
              loading: exportSpreadsRequested,
              handle: requestExportSpreads,
            },
          ]
        : []),
      ...(showCreditReportExport
        ? [
            {
              id: 'exportCreditReport',
              text: 'Export Credit Report',
              handle: createHandler(() => {
                setOpenSubMenu('exportCreditReport');
              }),
            },
          ]
        : []),
      ...(showExportToLaserPro
        ? [
            {
              id: 'exportLaserPro',
              text: 'Export to LaserPro',
              loading: loadingLenderProfiles || loadingUsers,
              handle: createHandler(() => {
                const laserProData = transformToLaserProFormat(opportunity, lenderProfile);

                const blob = new Blob([laserProData], {
                  type: 'application/zip',
                });
                saveAs(blob, `${businessName ? capitalCase(businessName, { delimiter: '' }) : application.id}.txt`);
              }),
            },
          ]
        : []),
      ...(showExportToLaserProPremium
        ? [
            {
              id: 'exportLaserProPremium',
              text: 'Export to LaserPro Plus',
              loading: loadingLenderProfiles || loadingUsers || loadingPartner,
              handle: createHandler(() => {
                const laserProData = transformToLaserProPremiumFormat({
                  opportunity,
                  assignedLenderProfiles: compact(assignedLenderProfiles) ?? [],
                  assignedUsers: assignedUsers ?? [],
                  laserProOfficerCodePrefix: partner?.laserProOfficerCodePrefix,
                });

                const blob = new Blob([laserProData], {
                  type: 'application/zip',
                });
                saveAs(blob, `${businessName ? capitalCase(businessName, { delimiter: '' }) : application.id}.txt`);
              }),
            },
          ]
        : []),
      {
        id: 'downloadDocuments',
        text: 'Download All Documents',
        handle: createHandler(downloadDocuments),
      },
    ],
    [
      createHandler,
      exportCreditMemo,
      loadingRequirements,
      exportApplication,
      showExportSpreads,
      exportSpreadsRequested,
      requestExportSpreads,
      showCreditReportExport,
      showExportToLaserPro,
      showExportToLaserProPremium,
      loadingLenderProfiles,
      loadingUsers,
      downloadDocuments,
      opportunity,
      lenderProfile,
      businessName,
      application.id,
      waitForCreditMemoExport,
    ],
  );

  const onSubMenuClose = useCallback(() => {
    setOpenSubMenu(null);
  }, []);

  const onSubMenuBack = useCallback(() => {
    setOpenSubMenu(null);
  }, []);

  return (
    <>
      <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={onClose}>
        <Flex flexDirection={'column'} gap={2}>
          <SubMenuHeader onClick={onBack} text={'Export'} />
          <Flex flexDirection={'column'} px={1} maxHeight={'300px'} overflow={'auto'}>
            {exportMenuOptions.map(({ id, text, loading, handle }) => (
              <MenuItem
                key={id}
                value={id}
                disabled={loading}
                onClick={handle}
                sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px', px: 2 }}
              >
                <Flex flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                  <Text variant={'body1'} ellipsis>
                    {text}
                  </Text>
                  <Flex flex={0.2} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                    {loading ? <Spinner size={'s'} /> : null}
                  </Flex>
                </Flex>
              </MenuItem>
            ))}
          </Flex>
          <CreditMemoExport key={'creditMemoExport'} ref={creditMemoRef} businessName={businessName ?? ''} />
          {/* : null} */}
          {!loadingRequirements && requirements?.length && printApplicationExport ? (
            <ApplicationExport key={'applicationExport'} ref={applicationRef} requirements={requirements} />
          ) : null}
          {showExportSpreads ? (
            <SpreadsExport key={'spreadsExport'} exportWhenReady={exportSpreadsRequested} onExportComplete={onExportSpreadsComplate} />
          ) : null}
        </Flex>
      </Menu>
      {showCreditReportExport && openSubMenu === 'exportCreditReport' ? (
        <ExportCreditReportMenu
          open={openSubMenu === 'exportCreditReport'}
          menuProps={menuProps}
          onBack={onSubMenuBack}
          onClose={onSubMenuClose}
          menuRef={menuRef}
        />
      ) : null}
    </>
  );
};
