/* eslint-disable react/jsx-no-bind */
import type { FC } from 'react';
import React, { useMemo, useContext } from 'react';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { FormikMoneyInputField, FormikMultiPicker } from '@lama/app-components';
import { naicsCodes as naicsCodesOptions, type FinancialData } from '@lama/contracts';
import { useAsyncFn } from 'react-use';
import { xor } from 'lodash-es';
import { Flex, Grid } from '@lama/design-system';
import { ApplicationContext } from '../../../../ApplicationContext';
import { UserDetailsContext } from '../../../../../../shared/context/UserDetailsContext';
import type { RequirementScreenProps } from '../types';
import { useUpdateBusiness } from '../../../../../../shared/hooks/react-query/business/useUpdateBusiness';
import { SaveFormButton } from '../../../../shared/SaveFormButton';
import { useSubmitFinancialsMutation } from './hooks/useSubmitFinancialsMutation';
import { getFinancialFieldByYear, getFinancialPayload } from './financialFieldUtils';

export const AffiliateVerificationScreen: FC<RequirementScreenProps> = ({ requirement }) => {
  const {
    opportunity: { referenceYear, id: opportunityId },
    application: { relatedBusinesses },
  } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const business = useMemo(
    () => relatedBusinesses.find(({ business: { affiliates } }) => affiliates?.some(({ id }) => id === requirement.entityId))?.business,
    [relatedBusinesses, requirement.entityId],
  );
  const { isPending: updatingBusiness, mutateAsync: updateBusiness } = useUpdateBusiness(opportunityId);
  const { isPending: savingFinancials, mutateAsync: updateFinancialData } = useSubmitFinancialsMutation(
    requirement.entityId,
    requirement.entityType,
    opportunityId,
  );

  const affiliateEntity = useMemo(
    () => business?.affiliates?.find(({ id }) => id === requirement.entityId),
    [business, requirement.entityId],
  );
  const affiliateFinancials = useMemo<FinancialData[]>(() => affiliateEntity?.financials ?? [], [affiliateEntity?.financials]);

  const initialValues = useMemo(
    () => ({
      naicsCodes: affiliateEntity?.naicsCodes ?? [],
      netOperatingIncomeCurrentYear: getFinancialFieldByYear(affiliateFinancials, referenceYear, 'Net Operating Income')?.value?.toString(),
      netOperatingIncomePreviousYear: getFinancialFieldByYear(
        affiliateFinancials,
        referenceYear - 1,
        'Net Operating Income',
      )?.value?.toString(),
      tangibleNetWorth: getFinancialFieldByYear(affiliateFinancials, referenceYear, 'Tangible Net Worth')?.value?.toString(),
    }),
    [affiliateEntity?.naicsCodes, affiliateFinancials, referenceYear],
  );

  const [, submitFinancial] = useAsyncFn(
    async (newValues: typeof initialValues, { resetForm }: FormikHelpers<any>) => {
      if (!business) {
        return null;
      }

      const { netOperatingIncomeCurrentYear, netOperatingIncomePreviousYear, tangibleNetWorth, naicsCodes } = newValues;

      if (xor(naicsCodes, affiliateEntity?.naicsCodes ?? []).length) {
        await updateBusiness({
          businessId: business.id,
          updateBusinessPayload: {
            affiliates: business.affiliates?.map((affiliate) => {
              if (affiliate.id === requirement.entityId) {
                return {
                  ...affiliate,
                  naicsCodes,
                };
              }
              return affiliate;
            }),
          },
        });
      }

      const financialDataPayload = userId
        ? [
            ...getFinancialPayload({
              entityId: affiliateEntity?.id ?? '',
              financials: affiliateFinancials,
              year: referenceYear,
              fieldName: 'Net Operating Income',
              value: netOperatingIncomeCurrentYear,
              userId,
            }),
            ...getFinancialPayload({
              entityId: affiliateEntity?.id ?? '',
              financials: affiliateFinancials,
              year: referenceYear - 1,
              fieldName: 'Net Operating Income',
              value: netOperatingIncomePreviousYear,
              userId,
            }),
            ...getFinancialPayload({
              entityId: affiliateEntity?.id ?? '',
              financials: affiliateFinancials,
              year: referenceYear,
              fieldName: 'Tangible Net Worth',
              value: tangibleNetWorth,
              userId,
            }),
          ]
        : [];

      if (financialDataPayload.length) {
        await updateFinancialData(financialDataPayload);
      }

      resetForm({ values: newValues });
    },
    [
      business,
      affiliateEntity?.naicsCodes,
      affiliateEntity?.id,
      userId,
      affiliateFinancials,
      referenceYear,
      updateBusiness,
      requirement.entityId,
      updateFinancialData,
    ],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={submitFinancial}>
      {({ dirty, isValid, handleSubmit }) => (
        <Flex flexDirection={'column'} gap={8}>
          <Grid columns={2}>
            <FormikMultiPicker name={'naicsCodes'} label={'NAICS Code'} options={naicsCodesOptions} maxItems={1} required fullWidth />
            <FormikMoneyInputField name={'tangibleNetWorth'} label={`Tangible Net Worth ${referenceYear}`} required />
            <FormikMoneyInputField name={'netOperatingIncomeCurrentYear'} label={`Net Income ${referenceYear}`} />
            <FormikMoneyInputField name={'netOperatingIncomePreviousYear'} label={`Net Income ${referenceYear - 1}`} />
          </Grid>
          <SaveFormButton loading={updatingBusiness || savingFinancials} submit={handleSubmit} disabled={!dirty || !isValid} />
        </Flex>
      )}
    </Formik>
  );
};
