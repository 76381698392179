import type { FC } from 'react';
import type { CreditMemoSectionProps } from './Sections/creditMemoSectionProps';
import { MultiBusinessBalanceSheetSection } from './Sections/BusinessBalanceSheetSection/MultiBusinessBalanceSheetSection';
import { GlobalCashFlowSection } from './Sections/GlobalCashFlowSection/GlobalCashFlowSection';
import { BusinessCashFlowSection } from './Sections/BusinessCashFlow/BusinessCashFlowSection';
import { PersonCashFlowSection } from './Sections/PersonCashFlowSection/PersonCashFlowSection';
import { PersonalFinancialStatementsSection } from './Sections/PersonalFinancialStatementsSection/PersonalFinancialStatementsSection';
import { PropertiesCashFlowSection } from './Sections/PropertiesCashFlow/PropertiesCashFlowSection';
import { CombinedPropertiesCashFlowSection } from './Sections/PropertiesCashFlow/CombinedPropertiesCashflowSection';

export const sectionComponentByName: Record<string, FC<CreditMemoSectionProps>> = {
  newBalanceSheet: MultiBusinessBalanceSheetSection,
  businessCashFlow: BusinessCashFlowSection,
  globalCashFlow: GlobalCashFlowSection,
  personalCashFlow: PersonCashFlowSection,
  personalFinancialStatements: PersonalFinancialStatementsSection,
  propertiesCashFlow: PropertiesCashFlowSection,
  combinedPropertiesCashFlow: CombinedPropertiesCashFlowSection,
};
