import React from 'react';
import type { CreateAudit, UpdateAudit } from '@lama/contracts';
import type { FC } from 'react';
import { UpdateEntityAuditLog } from '../UpdateEntityAuditLog';
import type { EntityAuditLogProps } from '../../GroupedAudit';
import { CreatePersonAuditLog } from './CreatePersonAuditLog';

export const PersonAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'update') {
    return <UpdateEntityAuditLog audit={audit as UpdateAudit[]} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'create') {
    return <CreatePersonAuditLog audit={audit as CreateAudit[]} groupInfo={groupInfo} />;
  }

  return null;
};
