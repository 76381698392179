import React, { useContext, type FC, useCallback } from 'react';
import { displayToast } from '@lama/app-components';
import { format } from 'date-fns';
import { aanReportExcelSchema } from '@lama/reports-service-client';
import { useDownloadReportMutation } from '../../hooks/downloadReportMutation';
import { ReportsFilterContext } from '../ReportsFilterContext';
import { ReportCard } from '../ReportCard';
import { useAanChartData } from '../Charts/hooks/useAanChartData';
import { ApplicationsWeeklyChart } from '../Charts/ApplicationsWeeklyChart';
import { applicationStatusToColors } from '../../../../shared/utils/applicationStatusToColors';

export const AanReport: FC = () => {
  const { endDate, startDate, productId, partnerId } = useContext(ReportsFilterContext);
  const { data: chartData, isPending: loadingChartData } = useAanChartData({ endDate, startDate, productId, partnerId });

  const { mutateAsync: downloadAanReport, isPending: downloadingAanReport } = useDownloadReportMutation('AAN');

  const onClickDownloadAanReport = useCallback(async () => {
    if (!productId) {
      displayToast("Can't download AAN report without a product selected", 'info');
      return;
    }

    if (!partnerId) {
      displayToast("Can't download AAN report without a partner id", 'info');
      return;
    }

    if (!startDate || !endDate) {
      displayToast("Can't download AAN report without a date range selected", 'info');
      return;
    }

    const filename = `AAN Report ${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}.xlsx`;

    const data = await downloadAanReport({
      body: {
        partnerId,
        productId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        format: 'application/json',
      },
      filename,
    });

    const { default: writeXlsxFile } = await import('write-excel-file');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await writeXlsxFile(data, {
      schema: aanReportExcelSchema,
      fileName: filename,
    });
  }, [downloadAanReport, endDate, partnerId, productId, startDate]);

  return (
    <ReportCard
      disableActions={loadingChartData || downloadingAanReport}
      actionText={'Download Report'}
      onClickAction={onClickDownloadAanReport}
      actionLoading={downloadingAanReport}
      loading={loadingChartData}
      title={'AAN Report'}
    >
      <ApplicationsWeeklyChart chartData={chartData?.chartData} color={applicationStatusToColors.Rejected.color} />
    </ReportCard>
  );
};
