import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { BusinessApiModel } from '@lama/clients';
import * as selectors from '@lama/selectors';
import type { CellContent } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { SegmentContainer } from '../SegmentContainer';

const headers = [
  {
    text: 'Name',
  },
  {
    text: 'Type',
  },
  {
    text: 'Title',
  },
  {
    text: 'Ownership Percentage',
  },
];

interface OwnershipTableTableProps {
  business: BusinessApiModel;
}

export const OwnershipTable: FC<OwnershipTableTableProps> = ({ business }) => {
  const rows = useMemo(() => {
    const owners = selectors.legalOwners(business);
    const keyEmployees = business.people
      .filter((p) => !p.guarantor && !p.ownershipPercentage && !(p.position === 'None') && !(p.position === 'Unrelated'))
      .map((p) => ({
        name: selectors.personFullName(p),
        ownershipPercentage: p.ownershipPercentage ?? '',
        title: p.position ?? 'Key Employee',
      }));

    const tablePeople = [...new Set([...owners, ...keyEmployees])];

    return tablePeople.map<CellContent[]>((p) => [
      {
        value: p.name,
      },
      {
        value: p.ownershipPercentage ? 'Owner' : 'Key Employee',
      },
      {
        value: p.title,
      },
      {
        value: p.ownershipPercentage ?? 0,
        type: 'percent',
        align: 'right',
      },
    ]);
  }, [business]);

  return (
    <SegmentContainer title={'Ownership and Management'} bordered={false}>
      <CreditMemoTable headers={headers} rows={rows} />
    </SegmentContainer>
  );
};
