import { useContext, useMemo } from 'react';
import type { SuggestionOption } from '@lama/design-system';
import { personFullName } from '@lama/selectors';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { User } from '@lama/user-service-client';
import { useUsersByPartnerQuery } from '../../../hooks/react-query/user/useUsersByPartnerQuery';
import { UserDetailsContext } from '../../../context/UserDetailsContext';

export const useUserSuggestions = (queryOptions?: Partial<UseQueryOptions<User[]>>) => {
  const { partner } = useContext(UserDetailsContext);
  const { data: users } = useUsersByPartnerQuery(partner, queryOptions);
  return useMemo(() => users?.map<SuggestionOption>((user) => ({ id: user.id, label: personFullName(user) })) ?? [], [users]);
};
