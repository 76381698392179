import React, { useCallback, useContext, useMemo } from 'react';
import type { Tradeline } from '@lama/contracts';
import { Text, Button, Flex, greyPalette, greenPalette, Grid, Collapse } from '@lama/design-system';
import { Divider } from '@mui/material';
import type { FC } from 'react';
import { useToggle } from 'react-use';
import styled from 'styled-components';
import type { InformationTypes } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../../../ApplicationContext';
import { useUpdateTradelineMutation } from './hooks/useUpdateTradelineQuery';
import { TradelineCardHeader } from './TradelineCardHeader';
import { DismissTradelineDialog } from './DismissTradelineDialog';
import { getDangerousValueColor } from './getDangerousValueColor';

interface TradelineCardProps {
  personId: string;
  tradeline: Tradeline;
  startExpanded?: boolean;
}

const TradelineCardContainer = styled(Flex)`
  page-break-inside: avoid;
`;

export const TradelineCard: FC<TradelineCardProps> = ({ personId, tradeline, startExpanded }) => {
  const {
    id,
    status,
    subscriberName,
    accountNumber,
    openDate,
    isOpenDateGreaterThan5Years,
    isOpenDateGreaterThan10Years,
    balanceDate,
    monthsHistory,
    creditLimitAmount,
    amountPastDue,
    delinquencies30Days,
    delinquencies60Days,
    delinquencies90to180Days,
    dismissed,
    monthlyPaymentAmount,
    chargeOffAmount,
    originalLoanAmount,
    balanceAmount,
    accountType,
    firstDelinquencyDate,
    hasCollections,
    hasRepossessions,
    hasForeclosures,
    hasSettlements,
    hasChargeoffs,
  } = tradeline;
  const [open, toggleOpen] = useToggle(startExpanded ?? false);
  const [dismissDialogOpen, toggleDismissDialogOpen] = useToggle(false);
  const { opportunity } = useContext(ApplicationContext);

  const { mutateAsync: updateTradeline } = useUpdateTradelineMutation(opportunity.id);

  const onRestoreClick = useCallback(() => {
    void updateTradeline({ personId, tradelineId: id, dismissed: !dismissed });
  }, [dismissed, id, personId, updateTradeline]);

  const onDismissClick = useCallback(
    (reason: string) => {
      toggleDismissDialogOpen();
      void updateTradeline({ personId, tradelineId: id, dismissed: !dismissed, dismissedReason: reason });
    },
    [dismissed, id, personId, toggleDismissDialogOpen, updateTradeline],
  );

  const highCreditUtilization = useMemo(() => {
    if (creditLimitAmount) {
      return (balanceAmount ?? 0) / creditLimitAmount > 0.9;
    }

    return false;
  }, [balanceAmount, creditLimitAmount]);

  const openDateData = useMemo<{ value: string; type: InformationTypes }>(() => {
    if (openDate) {
      return { value: openDate, type: 'date' };
    } else if (isOpenDateGreaterThan5Years) {
      return { value: 'Greater than 5 years', type: 'string' };
    } else if (isOpenDateGreaterThan10Years) {
      return { value: 'Greater than 10 years', type: 'string' };
    }

    return { value: '', type: 'string' };
  }, [isOpenDateGreaterThan10Years, isOpenDateGreaterThan5Years, openDate]);

  const redFlagIndicators = useMemo(
    () => ({
      hasChargeoffs: hasChargeoffs ? 1 : 0,
      hasForeclosures: hasForeclosures ? 1 : 0,
      hasRepossessions: hasRepossessions ? 1 : 0,
      hasSettlements: hasSettlements ? 1 : 0,
      hasCollections: hasCollections ? 1 : 0,
      delinquencies30Days: delinquencies30Days ? 1 : 0,
      delinquencies60Days: delinquencies60Days ? 1 : 0,
      delinquencies90to180Days: delinquencies90to180Days ? 1 : 0,
      chargeOffAmount: chargeOffAmount ? 1 : 0,
      amountPastDue: amountPastDue ? 1 : 0,
    }),
    [
      hasChargeoffs,
      hasForeclosures,
      hasRepossessions,
      hasSettlements,
      delinquencies30Days,
      delinquencies60Days,
      delinquencies90to180Days,
      chargeOffAmount,
      hasCollections,
      amountPastDue,
    ],
  );

  return (
    <TradelineCardContainer
      flexDirection={'column'}
      border={`1px solid ${greyPalette[300]}`}
      justifyContent={'center'}
      borderRadius={'8px'}
    >
      <TradelineCardHeader
        tradeline={tradeline}
        redFlag={redFlagIndicators ? Object.values(redFlagIndicators).includes(1) : false}
        onExpandClick={toggleOpen}
        isCardOpen={open}
        highUtilization={highCreditUtilization}
      />
      <Collapse expanded={open}>
        <Divider />
        <Flex flexDirection={'column'} width={'100%'} gap={6} p={6}>
          <Flex height={'28px'} width={'100%'} backgroundColor={greyPalette[100]} py={1} px={2} alignItems={'center'} borderRadius={'4px'}>
            <Text variant={'body2'} color={greyPalette[500]}>
              {status}
            </Text>
          </Flex>
          <Grid columns={3}>
            <KeyValuePair name={'Account Type'} value={accountType} />
            <KeyValuePair name={'Creditor'} value={subscriberName} />
            <KeyValuePair name={'Account Number'} value={accountNumber} />
            <KeyValuePair name={'Balance'} value={balanceAmount} type={'currency'} />
            <KeyValuePair name={'Date Opened'} value={openDateData.value} type={openDateData.type} />
            <KeyValuePair name={'Date of Last Activity'} value={balanceDate} type={'date'} />
            <KeyValuePair name={'Months Reviewed'} value={monthsHistory} />
            <KeyValuePair name={'High Credit or Limit'} value={creditLimitAmount ?? originalLoanAmount} type={'currency'} />
            <KeyValuePair name={'Monthly Payment'} value={monthlyPaymentAmount} type={'currency'} />
            <KeyValuePair
              name={'Amount Past Due'}
              value={amountPastDue ?? 0}
              valueColor={getDangerousValueColor(redFlagIndicators.amountPastDue)}
              type={'currency'}
            />
            <KeyValuePair
              name={'30 Days Late'}
              value={delinquencies30Days ?? 0}
              valueColor={getDangerousValueColor(redFlagIndicators.delinquencies30Days)}
            />
            <KeyValuePair
              name={'60 Days Late'}
              value={delinquencies60Days ?? 0}
              valueColor={getDangerousValueColor(redFlagIndicators.delinquencies60Days)}
            />
            <KeyValuePair
              name={'90 Days Late'}
              value={delinquencies90to180Days ?? 0}
              valueColor={getDangerousValueColor(redFlagIndicators.delinquencies90to180Days)}
            />
            <KeyValuePair
              name={'Charge Off Amount'}
              value={chargeOffAmount ?? 0}
              valueColor={getDangerousValueColor(redFlagIndicators.chargeOffAmount)}
              type={'currency'}
            />
            <KeyValuePair name={'Latest Delinquency Date'} value={firstDelinquencyDate} />
            <KeyValuePair
              name={'Has Collections'}
              value={hasCollections ? 'Yes' : 'No'}
              valueColor={getDangerousValueColor(redFlagIndicators.hasCollections)}
            />
            <KeyValuePair
              name={'Has Repossessions'}
              value={hasRepossessions ? 'Yes' : 'No'}
              valueColor={getDangerousValueColor(redFlagIndicators.hasRepossessions)}
            />
            <KeyValuePair
              name={'Has Foreclosures'}
              value={hasForeclosures ? 'Yes' : 'No'}
              valueColor={getDangerousValueColor(redFlagIndicators.hasForeclosures)}
            />
            <KeyValuePair
              name={'Has Settlements'}
              value={hasSettlements ? 'Yes' : 'No'}
              valueColor={getDangerousValueColor(redFlagIndicators.hasSettlements)}
            />
            <KeyValuePair
              name={'Has Charges offs'}
              value={hasChargeoffs ? 'Yes' : 'No'}
              valueColor={getDangerousValueColor(redFlagIndicators.hasChargeoffs)}
            />
          </Grid>
          <Flex justifyContent={'flex-end'}>
            {!dismissed ? (
              <Button variant={'secondary'} color={'neutral'} iconColor={greenPalette[400]} size={'m'} onClick={toggleDismissDialogOpen}>
                {'Dismiss'}
              </Button>
            ) : (
              <Button variant={'secondary'} color={'neutral'} iconColor={greenPalette[400]} size={'m'} onClick={onRestoreClick}>
                {'Restore'}
              </Button>
            )}
          </Flex>
        </Flex>
      </Collapse>
      <DismissTradelineDialog open={dismissDialogOpen} onDismissClick={onDismissClick} onCancelClick={toggleDismissDialogOpen} />
    </TradelineCardContainer>
  );
};
