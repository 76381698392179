/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Flex, amberPalette, Text, grayPalette } from '@lama/design-system';
import { Box, Card, LinearProgress, Stack } from '@mui/material';
import { WarningAmber, Flip, HelpOutline, BarChart } from '@mui/icons-material';
import type { FC } from 'react';
import type { DocumentWithIssues } from '@lama/document-service-client';

export const DemoAlert = () => (
  <Stack
    flexDirection={'row'}
    p={2}
    gap={1}
    alignItems={'center'}
    sx={{
      borderRadius: '4px',
      backgroundColor: amberPalette[50],
    }}
  >
    <WarningAmber sx={{ color: amberPalette[700] }} />
    <Text variant={'body2'} color={'light.warning'}>
      {'The business name extracted from the tax return does not match the business name in the application.'}
    </Text>
  </Stack>
);

interface DocumentSummaryProps {
  documents: DocumentWithIssues[];
  children?: React.ReactNode;
  onClick?: () => void;
}

export const DemoDocumentSummary: FC<DocumentSummaryProps> = ({ documents, onClick }) => {
  if (!documents.length) {
    return null;
  }
  return (
    <Card
      elevation={0}
      sx={{
        p: 4,
        border: `1px solid ${grayPalette[300]}`,
        borderRadius: 1,
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0px 8px 32px 0px #3800641F',
        },
      }}
      onClick={onClick}
    >
      <Flex flexDirection={'column'} gap={8}>
        <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
          <Flex flexDirection={'row'} gap={2}>
            <Text variant={'h5'} color={'primary.main'}>
              {'94%'}
            </Text>
            <Text variant={'h5'} color={'secondary'}>
              {'Successfully Analyzed'}
            </Text>
          </Flex>
          <Text variant={'body2'} color={'primary.main'}>
            {'Go to Spreading'}
          </Text>
        </Flex>
        <Flex flexDirection={'row'} gap={13}>
          <Flex gap={3} alignItems={'center'}>
            <Box borderRadius={'2px'} display={'flex'} sx={{ backgroundColor: 'primary.light' }} p={0.5}>
              <Flip sx={{ color: 'primary.main', width: '18px', height: '18px' }} />
            </Box>
            <Text variant={'body2'} color={'secondary'}>
              {'263 / 278 Analyzed'}
            </Text>
          </Flex>
          <Flex gap={3} alignItems={'center'}>
            <Box borderRadius={'2px'} display={'flex'} sx={{ backgroundColor: amberPalette[50] }} p={0.5}>
              <HelpOutline sx={{ color: amberPalette[700], width: '18px', height: '18px' }} />
            </Box>
            <Text variant={'body2'} color={'secondary'}>
              {'2 Missing Fields'}
            </Text>
          </Flex>
          <Flex gap={3} alignItems={'center'}>
            <Box borderRadius={'2px'} display={'flex'} sx={{ backgroundColor: grayPalette[50] }} p={0.5}>
              <BarChart sx={{ color: grayPalette[500], width: '18px', height: '18px' }} />
            </Box>
            <Text variant={'body2'} color={'secondary'}>
              {'13 Low Confidence'}
            </Text>
          </Flex>
        </Flex>
        <LinearProgress
          variant={'determinate'}
          sx={{
            backgroundColor: grayPalette[100],
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '& .MuiLinearProgress-bar': {
              borderRadius: '300px',
              background: 'linear-gradient(270deg, #714EF5 0.01%, #F3F0FF 100%)',
            },
          }}
          value={94}
        />
      </Flex>
    </Card>
  );
};
