import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import React, { useCallback, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import type { AffiliateApiModel } from '@lama/clients';
import { useToggle } from 'react-use';
import { ModifyItemButton } from '@lama/app-components';
import type { RequirementScreenProps } from '../types';
import { ApplicationContext } from '../../../../ApplicationContext';
import { useUpdateBusiness } from '../../../../../../shared/hooks/react-query/business/useUpdateBusiness';
import { AddOrEditRelatedCompanyDialog } from './AddOrEditRelatedCompanyDialog';
import { NoPrincipalsImage } from './NoPrincipalsImage';
import { RelatedCompanyListItem } from './RelatedCompanyListItem';

export const RelatedCompaniesScreen: React.FC<RequirementScreenProps> = ({ requirement }) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [initialDialogValues, setInitialDialogValues] = useState<AffiliateApiModel | null>(null);
  const {
    opportunity: { id: opportunityId },
    application: { relatedBusinesses },
  } = useContext(ApplicationContext);
  const business = useMemo(
    () => relatedBusinesses.find(({ business: { id } }) => id === requirement.entityId)?.business,
    [relatedBusinesses, requirement.entityId],
  );

  const { mutate: updateBusiness, isPending: updateingBusiness } = useUpdateBusiness(opportunityId);

  const [noRelatedCompanies, toggleNoRelatedCompanies] = useToggle(!!business?.markedNoRelatedCompanies);

  const relatedCompanies = useMemo(
    () => business?.affiliates?.filter(({ ownershipPercentage }) => !ownershipPercentage) ?? [],
    [business?.affiliates],
  );

  const onAddAffiliateClick = useCallback(() => {
    setInitialDialogValues(null);
    setDialogOpen(true);
  }, []);

  const addAffiliate = useCallback(
    (affiliate: AffiliateApiModel) => {
      if (!business) {
        return;
      }

      const updatedAffiliates = [...(business.affiliates ?? []), affiliate];
      updateBusiness({ businessId: business.id, updateBusinessPayload: { affiliates: updatedAffiliates } });
    },
    [business, updateBusiness],
  );

  const editAffiliate = useCallback(
    (updatedAffiliate: AffiliateApiModel) => {
      if (!business) {
        return;
      }

      const updatedAffiliates = business.affiliates?.map((affiliate) =>
        affiliate.id === updatedAffiliate.id ? updatedAffiliate : affiliate,
      );
      updateBusiness({ businessId: business.id, updateBusinessPayload: { affiliates: updatedAffiliates } });
    },
    [business, updateBusiness],
  );

  const onEditAffiliateCard = useCallback(
    (id: string) => {
      if (!business) {
        return;
      }

      setInitialDialogValues(business.affiliates?.find((affiliate) => affiliate.id === id) ?? null);
      setDialogOpen(true);
    },
    [business],
  );

  const onDeleteAffiliateCard = useCallback(
    (id: string) => {
      if (!business) {
        return;
      }

      const filteredAffiliates = business.affiliates?.filter((affiliate) => affiliate.id !== id);
      updateBusiness({ businessId: business.id, updateBusinessPayload: { affiliates: filteredAffiliates } });
    },
    [business, updateBusiness],
  );

  const onNoRelatedCompaniesMarkClick = useCallback(() => {
    if (!business) {
      return;
    }

    toggleNoRelatedCompanies();
    updateBusiness({ businessId: business.id, updateBusinessPayload: { markedNoRelatedCompanies: !noRelatedCompanies } });
  }, [toggleNoRelatedCompanies, updateBusiness, business, noRelatedCompanies]);

  const handleClose = useCallback(
    (affiliate: AffiliateApiModel | null) => {
      if (!business) {
        return;
      }

      if (affiliate) {
        if (business.affiliates?.some((s) => s.id === affiliate.id)) {
          editAffiliate(affiliate);
        } else {
          addAffiliate(affiliate);
        }
      }
      setDialogOpen(false);
    },
    [addAffiliate, business, editAffiliate],
  );

  if (!business) {
    return null;
  }

  return (
    <>
      <Stack alignItems={'center'} flex={1} gap={4} px={4} height={'100%'}>
        {relatedCompanies.length ? (
          relatedCompanies?.map((affiliate) => (
            <RelatedCompanyListItem
              business={business}
              key={affiliate.id}
              affiliate={affiliate}
              onDelete={onDeleteAffiliateCard}
              onEdit={onEditAffiliateCard}
            />
          ))
        ) : (
          <Stack width={'100%'} height={'100%'} alignItems={'center'}>
            <NoPrincipalsImage />
          </Stack>
        )}
        <ModifyItemButton onClick={onAddAffiliateClick} text={t('affiliates.cta.add')} disabled={noRelatedCompanies} variant={'text'} />
        {!relatedCompanies.length ? (
          <FormControlLabel
            label={'No related companies'}
            control={<Checkbox checked={noRelatedCompanies} onChange={onNoRelatedCompaniesMarkClick} />}
          />
        ) : null}
      </Stack>
      <AddOrEditRelatedCompanyDialog
        open={dialogOpen}
        handleClose={handleClose}
        initialValues={initialDialogValues}
        isLoading={updateingBusiness}
        businessId={requirement.entityId}
      />
    </>
  );
};
