import type { FC } from 'react';
import React, { useCallback, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { InputValue } from '@lama/design-system';
import { Avatar, Button, Flex, greyPalette, RichInput } from '@lama/design-system';
import type { UnderwritingNoteCreateModel } from '@lama/clients';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useToggle } from 'react-use';
import { AddOutlined } from '@mui/icons-material';
import { personFullName } from '@lama/selectors';
import mixpanel from 'mixpanel-browser';
import { ApplicationContext } from '../../../components/Application/ApplicationContext';
import { useCreateNoteMutation } from '../../hooks/react-query/opportunity/useCreateNoteMutation';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { getUnderwritingNoteRelatedItemProperty } from '../../../components/Application/shared/noteUtils';
import type { CommentsSegmentProps } from './CommentsSegment';
import { useUserSuggestions } from './hooks/useUserSuggestions';

interface CommentPlaceholderProps extends Partial<CommentsSegmentProps> {
  startInEditMode?: boolean;
  initialShowNewCommentButton?: boolean;
  onSubmit?: (change: InputValue) => Promise<void> | void;
  onCancel?: () => void;
}

export const CommentPlaceholder: FC<CommentPlaceholderProps> = ({
  relatedItemType,
  relatedItemId,
  startInEditMode: emptyCommentSection,
  initialShowNewCommentButton: initialShowNewComment,
  onSubmit,
  onCancel,
}) => {
  const { opportunity } = useContext(ApplicationContext);
  const { userId, ...user } = useContext(UserDetailsContext);
  const { enableCommentMentions } = useFlags();
  const userSuggestions = useUserSuggestions({ enabled: enableCommentMentions });
  const location = useLocation();
  const [showNewComment, toggleShowNewComment] = useToggle(initialShowNewComment ?? false);

  const { mutateAsync: addNote } = useCreateNoteMutation(opportunity.id, userId);

  const onTextSubmit = useCallback(
    async (change: InputValue) => {
      const fullPath = `${location.pathname}${location.search}`;

      const note: Omit<UnderwritingNoteCreateModel, 'createdBy' | 'updatedBy'> = {
        type: 'general',
        text: change.value,
        mentionedUserIds: change.mentionedIds,
        id: uuidv4(),
        url: fullPath,
        threadId: uuidv4(),
        ...(relatedItemId && relatedItemType && { [getUnderwritingNoteRelatedItemProperty(relatedItemType)]: relatedItemId }),
      };

      await addNote(note);

      await onSubmit?.(change);

      mixpanel.track('Thread - Create', {
        threadId: note.threadId,
        mentionedUserIds: change.mentionedIds,
        mentionedUserCount: change.mentionedIds?.length,
        commentId: note.id,
        creator: userId,
      });
    },
    [addNote, location.pathname, location.search, onSubmit, relatedItemId, relatedItemType, userId],
  );

  return (
    <Flex flexDirection={'column'} gap={2} mt={2}>
      {!showNewComment && !emptyCommentSection && (
        <Flex flexDirection={'row'} gap={2} justifyContent={'flex-start'}>
          <Button startIcon={<AddOutlined />} color={'neutral'} variant={'tertiary'} onClick={toggleShowNewComment}>
            {'Add comment'}
          </Button>
        </Flex>
      )}
      {emptyCommentSection || showNewComment ? (
        <Flex
          flexDirection={'row'}
          gap={2}
          border={`1px solid ${greyPalette[300]}`}
          p={3}
          borderRadius={'8px'}
          alignItems={showNewComment ? 'flex-start' : 'center'}
        >
          {user.firstName && user.lastName ? (
            <Avatar size={'24px'} name={personFullName({ firstName: user.firstName, lastName: user.lastName })} />
          ) : null}
          <RichInput
            editMode={showNewComment}
            toggleEditMode={toggleShowNewComment}
            placeholder={'Comment'}
            onSubmit={onTextSubmit}
            suggestions={userSuggestions}
            enableMentions={enableCommentMentions}
            currentUserId={userId}
            onCancel={onCancel}
          />
        </Flex>
      ) : null}
    </Flex>
  );
};
