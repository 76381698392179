import { Flex, grayPalette } from '@lama/design-system';
import type { FC } from 'react';
import React, { useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { businessName, personFullName } from '@lama/selectors';
import type { ApplicationApiModel, BusinessApiModel, OpportunityApiModel } from '@lama/clients';
import type { Entity } from '@lama/common-types';
import type { EntityGroup, UnderwritingSection } from '@lama/contracts';
import { getApplicationEntityByType } from '@lama/properties';
import { evaluateMatcher } from '@lama/conditions';
import { isNil } from 'lodash-es';
import { ApplicationContext } from '../ApplicationContext';
import { UnderwritingSideNav } from './SideNav/UnderwritingSideNav';
import { UnderwritingSectionContent } from './UnderwritingSectionContent';

export type MultipliedUnderwritingSection = UnderwritingSection & { entity?: Record<string, any> & { id: string } };

const entityTypeToNameFunction: Partial<Record<Entity, (entity: any) => string>> = {
  person: personFullName,
  business: (entity: any) => businessName(entity as BusinessApiModel) ?? '',
  affiliate: (entity: any) => businessName(entity as BusinessApiModel) ?? '',
};

interface Multiplier {
  entityType: Entity;
  entityGroups: EntityGroup[];
}

// TODO: This should be pulled from the underwriting configuration
const sectionToMultiplier: Record<string, Multiplier> = {
  creditReport: { entityType: 'person', entityGroups: ['guarantor', 'borrower'] },
};

const multiplySectionForRelevantEntities = (
  section: UnderwritingSection,
  application: ApplicationApiModel,
): MultipliedUnderwritingSection[] => {
  const multiplier: Multiplier | undefined = section.entityType
    ? { entityType: section.entityType, entityGroups: section.entityGroups ?? [] }
    : sectionToMultiplier[section.name];
  if (multiplier) {
    return getApplicationEntityByType(application, multiplier.entityType, multiplier.entityGroups).map((e) => {
      const multipliedSection: MultipliedUnderwritingSection = {
        ...section,
        id: `${section.id}_${e.id}`,
        prettyName: `${section.prettyName} - ${entityTypeToNameFunction[multiplier.entityType]?.(e) ?? 'Unknown'}`,
        entity: e,
      };
      return multipliedSection;
    });
  }

  return [section];
};

const filterSectionsByEnabledMatcher = (sections: MultipliedUnderwritingSection[], opportunity: OpportunityApiModel) =>
  sections.filter(({ enabledMatcher, entity }) => {
    if (!enabledMatcher) {
      return true;
    }

    const evaluationResult = evaluateMatcher({
      matcher: enabledMatcher,
      application: opportunity.application,
      entityId: entity?.id,
      opportunity: { ...opportunity, applicationId: opportunity.application.id },
    });

    return evaluationResult.result && !isNil(evaluationResult.extractedValue);
  });

export const UnderwritingPage: FC = () => {
  const { product, application, opportunity } = useContext(ApplicationContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const section = searchParams.get('section');

  const multipliedUnderwritingConfiguration = useMemo(
    () =>
      product.underwritingConfiguration?.map((group) => {
        const multipliedSections = group.sections.flatMap((s) => multiplySectionForRelevantEntities(s, application));
        const filteredSections = filterSectionsByEnabledMatcher(multipliedSections, opportunity);

        return {
          ...group,
          sections: filteredSections,
        };
      }) ?? [],
    [application, opportunity, product.underwritingConfiguration],
  );

  const currentUnderwritingSection = useMemo(
    () => multipliedUnderwritingConfiguration.flatMap(({ sections }) => sections).find((s) => s.id === section),
    [multipliedUnderwritingConfiguration, section],
  );

  useEffect(() => {
    if (!currentUnderwritingSection) {
      const firstSection = multipliedUnderwritingConfiguration[0]?.sections[0]?.id;

      if (firstSection) {
        setSearchParams({ section: firstSection }, { replace: true });
      }
    }
  }, [currentUnderwritingSection, multipliedUnderwritingConfiguration, setSearchParams]);

  return (
    <Flex flex={1} border={`1px solid ${grayPalette[300]}`} borderRadius={1}>
      <UnderwritingSideNav underwritingCategories={multipliedUnderwritingConfiguration} />
      <UnderwritingSectionContent section={currentUnderwritingSection} />
    </Flex>
  );
};
