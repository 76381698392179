import type { RelatedPersonApiModel } from '@lama/clients';
import { Flex, Text } from '@lama/design-system';
import React, { useMemo } from 'react';
import type { FC } from 'react';
import { orderBy } from 'lodash-es';
import { TradelineCard } from './TradelineCard';

interface TradelinesProps {
  guarantor: RelatedPersonApiModel;
  tradelinesStartExpanded?: boolean;
}

export const Tradelines: FC<TradelinesProps> = ({ guarantor: { tradelines, id }, tradelinesStartExpanded }) => {
  const sortedTradelines = useMemo(() => orderBy(tradelines ?? [], [(t) => t.dismissed, (t) => t.open], ['desc', 'desc']), [tradelines]);

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'body1'}>{'Tradelines'}</Text>
      {sortedTradelines?.map((t) => (
        <TradelineCard key={t.id} personId={id} tradeline={t} startExpanded={tradelinesStartExpanded} />
      ))}
    </Flex>
  );
};
