import React, { useContext, type FC, useCallback, useMemo, useRef, useState } from 'react';
import type { Opportunity } from '@lama/contracts';
import { displayToast } from '@lama/app-components';
import { format, isWithinInterval } from 'date-fns';
import saveAs from 'file-saver';
import { type ApplicationApiModel } from '@lama/clients';
import { Menu, MenuItem, MenuList } from '@mui/material';
import { isApplicationHMDAReportableSelector } from '@lama/selectors';
import { useDownloadReportMutation } from '../../hooks/downloadReportMutation';
import { ApplicationsByLastStatusUpdateBarChart } from '../Charts/ApplicationsByLastStatusUpdateBarChart';
import { ReportsFilterContext } from '../ReportsFilterContext';
import { useGetApplicationsByPartnerQuery } from '../../../../shared/hooks/react-query/application/useGetApplicationsQuery';
import { useGetOpportunityEntitiesQuery } from '../../../../shared/hooks/react-query/opportunity/useGetOpportunityEntitiesQuery';
import { ReportCard } from '../ReportCard';

export const HmdaReport: FC = () => {
  const { endDate, startDate, productId, partnerId } = useContext(ReportsFilterContext);
  const { data: applications, isPending: loadingApplications } = useGetApplicationsByPartnerQuery(partnerId ?? undefined);
  const { data: opportunityEntities, isPending: loadingOpportunityEntities } = useGetOpportunityEntitiesQuery({
    partnerId: partnerId ?? undefined,
  });
  const { mutateAsync: downloadHmdaReport, isPending: downloadingHmdaReport } = useDownloadReportMutation('HMDA');
  const { mutateAsync: downloadHmdaTxtReport, isPending: downloadingHmdaTxtReport } = useDownloadReportMutation('HMDAPipeTxt');

  const [showHmdaExportMenu, setShowHmdaExportMenu] = useState(false);
  const menuRef = useRef(null);

  const onClickDownloadButton = useCallback(() => {
    setShowHmdaExportMenu(true);
  }, []);
  const onCloseExportMenu = useCallback(() => {
    setShowHmdaExportMenu(false);
  }, []);

  const onClickDownloadHmdaReport = useCallback(async () => {
    if (!productId) {
      displayToast("Can't download HMDA report without a product selected", 'info');
      return;
    }

    if (!startDate || !endDate) {
      displayToast("Can't download HMDA report without a date range selected", 'info');
      return;
    }

    if (!partnerId) {
      displayToast("Can't download HMDA report without a partner id", 'info');
      return;
    }

    const filename = `HMDA Report ${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}.csv`;

    const data = await downloadHmdaReport({
      body: {
        partnerId,
        productId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        format: 'text/csv',
      },
      filename,
    });

    const blob = new Blob([data], {
      type: 'text/csv',
    });

    saveAs(blob, filename);
    setShowHmdaExportMenu(false);
  }, [downloadHmdaReport, endDate, partnerId, productId, startDate]);

  const onClickDownloadHmdaTxtReport = useCallback(async () => {
    if (!productId) {
      displayToast("Can't download HMDA report without a product selected", 'info');
      return;
    }

    if (!startDate || !endDate) {
      displayToast("Can't download HMDA report without a date range selected", 'info');
      return;
    }

    if (!partnerId) {
      displayToast("Can't download HMDA report without a partner id", 'info');
      return;
    }

    const filename = `HMDA Report ${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}.txt`;

    const data = await downloadHmdaTxtReport({
      body: {
        partnerId,
        productId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
      filename,
    });

    const blob = new Blob([data]);

    saveAs(blob, filename);
    setShowHmdaExportMenu(false);
  }, [downloadHmdaTxtReport, endDate, partnerId, productId, startDate]);

  // this code is duplicate of opportunityHmdaReadyReport.ts in report-service and should be deleted after the report-service contains the chart generation of the HMDA report
  const hmdaOpportunityFilter = useCallback(
    (opportunity: Opportunity, application: ApplicationApiModel) => {
      if (!startDate || !endDate) {
        return false;
      }

      if (opportunity.productId !== productId && productId !== 'allProducts') {
        return false;
      }

      const { hmda } = application;

      if (!hmda?.actionTakenDate || !isWithinInterval(new Date(hmda.actionTakenDate), { start: startDate, end: endDate })) {
        return false;
      }

      if (!isApplicationHMDAReportableSelector(application)) {
        return false;
      }

      return true;
    },
    [endDate, productId, startDate],
  );

  const loading = useMemo(() => loadingApplications || loadingOpportunityEntities, [loadingApplications, loadingOpportunityEntities]);

  return (
    <>
      <ReportCard
        disableActions={loading || downloadingHmdaReport || downloadingHmdaTxtReport}
        actionText={'Download Report'}
        onClickAction={onClickDownloadButton}
        actionLoading={downloadingHmdaReport || downloadingHmdaTxtReport}
        title={'HMDA Report'}
        loading={loading}
        actionMenuRef={menuRef}
      >
        <ApplicationsByLastStatusUpdateBarChart
          opportunityFilter={hmdaOpportunityFilter}
          applications={applications}
          opportunityEntities={opportunityEntities}
        />
      </ReportCard>
      <Menu
        open={showHmdaExportMenu}
        anchorEl={menuRef.current}
        onClose={onCloseExportMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuList>
          <MenuItem onClick={onClickDownloadHmdaReport}>{'CSV Format'}</MenuItem>
          <MenuItem onClick={onClickDownloadHmdaTxtReport}>{'Pipe Separator Format'}</MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
