import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Input, Flex } from '@lama/design-system';
import { BaseDialog } from '@lama/app-components';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import { useAsyncFn } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { useUpdateRequirement } from '../../../../../shared/hooks/react-query/requirement/useUpdateRequirement';

interface RequestAnotherDocumentModalProps {
  open: boolean;
  onClose: () => void;
  requirement: EvaluatedOpportunityRequirement;
}

export const RequestAnotherDocumentModal: FC<RequestAnotherDocumentModalProps> = ({ open, onClose, requirement }) => {
  const [documentName, setDocumentName] = useState('');

  const { mutateAsync: updateRequirement } = useUpdateRequirement(requirement.opportunityId, requirement.id);
  const [{ loading: updatingRequirement }, onSubmit] = useAsyncFn(async () => {
    await updateRequirement({
      updateRequirementPayload: {
        sources: {
          ...requirement.sources,
          uploadFilesSource: [
            ...(requirement.sources.uploadFilesSource ?? []),
            {
              extractable: true,
              name: documentName,
              topic: `lender-requested_${uuidv4()}`,
            },
          ],
        },
      },
    });

    onClose();
  }, [documentName, onClose, requirement.sources, updateRequirement]);

  const saveDisabled = useMemo(() => !documentName.length, [documentName]);

  const onDocumentNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentName(e.target.value);
  }, []);

  return (
    <BaseDialog open={open} onClose={onClose} title={'Request additional document'}>
      <Flex flexDirection={'column'} gap={6} width={'100%'}>
        <Input value={documentName} onChange={onDocumentNameChange} placeholder={'Document Name'} />
        <Flex flex={1} alignItems={'center'} justifyContent={'center'}>
          <Button onClick={onSubmit} variant={'tertiary'} color={'primary'} disabled={saveDisabled} loading={updatingRequirement}>
            {'Submit'}
          </Button>
        </Flex>
      </Flex>
    </BaseDialog>
  );
};
