import React from 'react';
import { type PropertyComponentProps } from '@lama/app-components';
import { GenericListForm } from './GenericListForm';

export const customComponents: Record<string, React.FC<PropertyComponentProps>> = {
  table: ({ property: { fieldName, childProperties, displayName, noItemsFieldName }, entityType }) => (
    <GenericListForm
      fieldName={fieldName}
      entityType={entityType}
      itemProperties={childProperties}
      displayName={displayName}
      noItemsFieldName={noItemsFieldName}
    />
  ),
};
