import { v4 as uuidv4 } from 'uuid';
import type { DocumentLineItem, Document } from '@lama/document-service-client';
import type { MRT_Row } from 'material-react-table';
import type { Period } from '../components/FinancialStatementsSpreadingContent';

export const createNewEmptyRow = (document: Document, allPeriods: Period[]): DocumentLineItem => ({
  id: 'tmp',
  text: '',
  entityId: document.relatedEntityId,
  entityType: document.relatedEntityType,
  documentId: document.id,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  values: [...allPeriods].map(({ startDate, endDate }) => ({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    value: undefined,
    boundingBox: undefined,
  })),
});

export const createNewLineItemFromRow = (
  row: MRT_Row<DocumentLineItem>,
  values: Partial<DocumentLineItem>,
  currentDocument: Document,
  currentPage: number,
  allPeriods: Period[],
) => {
  const { text, parentId, attribute, documentId, entityId, entityType } = values;

  return {
    id: uuidv4(),
    text: text ?? '',
    parentId: parentId || undefined,
    attribute: attribute || undefined,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    documentId: documentId ?? currentDocument.id,
    entityId: entityId ?? currentDocument.relatedEntityId,
    entityType: entityType ?? currentDocument.relatedEntityType,
    page: currentPage,
    unverified: true,
    values: [...allPeriods].map(({ startDate, endDate }) => {
      const parsedValue = Number(row.getValue(`${startDate}-${endDate}`));
      const itemStartDate = startDate.toISOString();
      const itemEndDate = endDate.toISOString();
      return {
        startDate: itemStartDate,
        endDate: itemEndDate,
        value: Number.isNaN(parsedValue) ? undefined : parsedValue,
      };
    }),
  };
};
