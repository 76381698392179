/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useMemo, useCallback, useState, useContext } from 'react';
import { ToggleButton } from '@mui/material';
import { isEqual } from 'lodash-es';
import { usePrompt } from '@lama/app-components';
import { Button, Flex } from '@lama/design-system';
import { ApplicationContext } from '../../../ApplicationContext';
import { useUpdateApplicationMutation } from '../../../../../shared/hooks/react-query/application/useUpdateApplication';
import type { RequirementScreenProps } from './types';

export const UseOfFundsScreen: FC<RequirementScreenProps> = () => {
  const {
    product,
    application: { useOfFunds, id: applicationId },
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const options: string[] = useMemo(
    () => product.useOfFundsOptions ?? product.screensConfiguration.screenParams.useOfFunds?.options ?? [],
    [product],
  );
  const { mutate: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(applicationId, opportunityId);

  const [currentUseOfFunds, setCurrentUseOfFunds] = useState<string[]>(useOfFunds ?? []);

  const onOptionClick = useCallback(
    (_e: any, value: string) => {
      if (currentUseOfFunds.includes(value)) {
        setCurrentUseOfFunds(currentUseOfFunds.filter((v) => v !== value));
      } else {
        setCurrentUseOfFunds([...currentUseOfFunds, value]);
      }
    },
    [currentUseOfFunds],
  );

  const onSaveClick = useCallback(() => {
    updateApplication({ updateApplicationPayload: { useOfFunds: currentUseOfFunds } });
  }, [currentUseOfFunds, updateApplication]);

  const saveButtonDisabled = useMemo(
    () => isEqual(useOfFunds, currentUseOfFunds) || (!currentUseOfFunds.length && !useOfFunds),
    [currentUseOfFunds, useOfFunds],
  );

  usePrompt('You have unsaved changes, Are you sure you want to leave without saving?', !saveButtonDisabled);

  return (
    <Flex flexDirection={'column'} gap={10} width={'100%'} justifyContent={'center'} alignItems={'center'}>
      <Flex flexDirection={'row'} flexWrap={'wrap'} gap={2} width={'100%'}>
        {options.map((option) => (
          <ToggleButton
            key={option}
            value={option}
            color={'primary'}
            onClick={onOptionClick}
            selected={currentUseOfFunds?.includes(option)}
            sx={{
              '&.Mui-selected': {
                borderColor: 'primary.main',
              },

              maxHeight: '100px',
              display: 'inline-block',
              p: 2,
              boxSizing: 'border-box',
              textAlign: 'center',
              maxWidth: 'calc(33.33333333% - 16px)',
              flex: '1 00 calc(33.33333333% - 16px)',
            }}
            fullWidth
          >
            {option}
          </ToggleButton>
        ))}
      </Flex>
      <Flex flexDirection={'column'} justifyContent={'flex-end'} alignItems={'flex-end'} width={'100%'}>
        <Button
          type={'submit'}
          disabled={saveButtonDisabled}
          color={'primary'}
          width={'130px'}
          height={'40px'}
          loading={updatingApplication}
          onClick={onSaveClick}
        >
          {'Save'}
        </Button>
      </Flex>
    </Flex>
  );
};
