import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { applicationImportantPeopleSelector, personNameWithRelationsSelector } from '@lama/selectors';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../../../ApplicationContext';
import type { CreditMemoSectionProps } from '../creditMemoSectionProps';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { PersonCashFlowSpreadSegment } from '../../../shared/Spreads/PersonCashFlowSpreadSegment';

const noteName = 'Guarantor Cash Flow Details';
const noteTitle = 'Person Cash Flow Details';

export const PersonCashFlowSection: FC<CreditMemoSectionProps> = ({ printMode, section }) => {
  const { application } = useContext(ApplicationContext);

  const people = useMemo(() => applicationImportantPeopleSelector(application), [application]);

  return (
    <Flex flexDirection={'column'} gap={8}>
      {people.map((relatedPerson) => (
        <Flex flexDirection={'column'} gap={4} key={relatedPerson.person.id}>
          <PersonCashFlowSpreadSegment
            title={personNameWithRelationsSelector(relatedPerson.person.id, application)}
            person={relatedPerson}
            printMode={!!printMode}
            spreadId={section.spreadId}
            allowAdjustments={false}
          />
        </Flex>
      ))}
      <InlineNote title={noteTitle} noteName={noteName} />
    </Flex>
  );
};
