import React, { forwardRef, useContext, useMemo } from 'react';
import { LoadingPage } from '@lama/app-components';
import { Text } from '@lama/design-system';
import type { ApplicationRelatedPersonApiModel } from '@lama/clients';
import { ApplicationContext } from '../../ApplicationContext';
import type { EntitiesByType } from '../../CreditMemo/Spreads/components/SpreadTable/types';
import { SpreadTable } from '../../CreditMemo/Spreads/components/SpreadTable';
import { useGetMultiEntityFinancialsQuery } from '../../../../shared/hooks/react-query/financials/useGetMultiEntityFinancialsQuery';
import { useSpreadConfigurationById } from './hooks/useSpreadConfiguration';
import type { SpreadSegmentProps, SpreadTableRef } from './types';

interface PersonalFinancialStatementsSpreadSegmentProps extends SpreadSegmentProps {
  guarantor: ApplicationRelatedPersonApiModel;
}

export const PersonalFinancialStatementsSpreadSegment = forwardRef<SpreadTableRef, PersonalFinancialStatementsSpreadSegmentProps>(
  ({ printMode, spreadId, allowAdjustments, guarantor }, spreadTableRef) => {
    const { application, opportunity, properties } = useContext(ApplicationContext);
    const { financials, loading } = useGetMultiEntityFinancialsQuery([{ entityId: guarantor.person.id }], false);

    const spreadConfiguration = useSpreadConfigurationById(spreadId);

    const entities: EntitiesByType = useMemo(
      () => ({
        application: [application],
        business: [],
        affiliate: [],
        person: [guarantor],
        opportunity: [opportunity],
      }),
      [application, guarantor, opportunity],
    );

    if (!spreadConfiguration) {
      return <Text variant={'body1'}>{'Spreads are not configured, please contact support.'}</Text>;
    }

    if (loading) {
      return <LoadingPage />;
    }

    return (
      <SpreadTable
        entities={entities}
        properties={properties}
        applicationId={application.id}
        opportunity={opportunity}
        spreadsSettings={opportunity.spreadsSettings}
        spreadConfig={spreadConfiguration}
        financials={financials}
        defaultYearColumns={spreadConfiguration?.spreadYears ?? [2022]}
        printMode={printMode}
        spreadName={'PersonalFinancialStatements'}
        periodDisplayFormat={'income statement'}
        ref={spreadTableRef}
        allowAdjustments={allowAdjustments}
      />
    );
  },
);
