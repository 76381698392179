import { Flex } from '@lama/design-system';
import type { AutocompleteRenderInputParams, SxProps, TextFieldProps, Theme } from '@mui/material';
import { TextField, Autocomplete } from '@mui/material';
import { uniqBy } from 'lodash-es';
import type { FC } from 'react';
import React, { useContext, useMemo, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isBefore } from 'date-fns';
import { StyledDatePickerWrapper } from '../../Spreading/Forms/FinancialStatements/components/StyledDatePicker';
import { useProductsByPartnerQuery } from '../../../shared/hooks/react-query/product/useProductsByPartnerQuery';
import { UserPartnerSelection } from '../../../shared/components/UserPartnerSelection';
import { ReportsFilterContext } from './ReportsFilterContext';

import 'react-datepicker/dist/react-datepicker.css';

const autocompleteSxProps: SxProps<Theme> = { maxWidth: '300px', width: '100%' };
const datePickerSxProps: SxProps<Theme> = { minWidth: '200px', maxWidth: '300px' };

const renderInput = (props: TextFieldProps) => <TextField {...props} variant={'outlined'} />;

const renderAutocompleteInput = (label: string) => (params: AutocompleteRenderInputParams) =>
  <TextField {...params} label={label} variant={'outlined'} />;

const isOptionEqualToValue = (option: { value: string }, value: { value: string }) => option.value === value.value;

const EndDateInput = renderInput({ label: 'End Date', fullWidth: true, sx: { ...datePickerSxProps } });
const StartDateInput = renderInput({ label: 'Start Date', fullWidth: true, sx: { ...datePickerSxProps } });
const ProductsInput = renderAutocompleteInput('Products');
const ReportInput = renderAutocompleteInput('Report');

export const ReportsFilters: FC = () => {
  const {
    enablePartnerSelection,
    enableReportSelection,
    enableRejectionReports,
    enableHmdaReport,
    enableOfacResultsReport,
    enableNoaReport,
    enableRegBReport,
    enableCreditAdminReport,
  } = useFlags();

  const { partnerId, setPartnerId, setProductId, productId, startDate, endDate, setStartDate, setEndDate, setReport } =
    useContext(ReportsFilterContext);

  const reportOptions = useMemo(() => {
    const reports = [{ label: 'Pipeline', value: 'pipeline' }];

    if (enableRejectionReports) {
      reports.push({ label: 'AAN', value: 'aan' }, { label: 'Withdrawal', value: 'withdrawal' });
    }

    if (enableHmdaReport) {
      reports.push({ label: 'HMDA', value: 'hmda' });
    }

    if (enableOfacResultsReport) {
      reports.push({ label: 'OFAC', value: 'ofac' });
    }

    if (enableNoaReport) {
      reports.push({ label: 'NOA', value: 'noa' });
    }

    if (enableRegBReport) {
      reports.push({ label: 'Reg B', value: 'regB' });
    }

    if (enableCreditAdminReport) {
      reports.push({ label: 'Credit Admin', value: 'creditAdmin' });
    }
    return reports;
  }, [enableCreditAdminReport, enableHmdaReport, enableNoaReport, enableOfacResultsReport, enableRegBReport, enableRejectionReports]);

  const { data: allPartnerProducts, isPending: loadingPartnerProducts } = useProductsByPartnerQuery(partnerId ?? undefined);

  const productOptions = useMemo(
    () =>
      uniqBy(
        [
          ...(allPartnerProducts
            ? allPartnerProducts.map((p) => ({
                label: p.prettyName,
                value: p.id,
              }))
            : []),
          {
            value: 'allProducts',
            label: 'All Products',
          },
        ],
        (option) => option.label,
      ),
    [allPartnerProducts],
  );

  const currentProduct = useMemo(() => productOptions.find((p) => p.value === productId), [productOptions, productId]);

  const onPartnerChange = useCallback(
    (event: any, value: { label: string; value: string } | null) => {
      if (value?.value) {
        setProductId('allProducts');
        setPartnerId(value?.value ?? null);
      }
    },
    [setPartnerId, setProductId],
  );

  const onProductChange = useCallback(
    (event: any, value: { label: string; value: string } | null) => {
      if (value?.value) {
        setProductId(value.value);
      }
    },
    [setProductId],
  );

  const onStartDateChange = useCallback(
    (date: Date | null) => {
      if (date === null) {
        setStartDate(startDate);
        return;
      }

      date?.setHours(0, 0, 0, 0);
      setStartDate(date);
    },
    [setStartDate, startDate],
  );

  const onEndDateChange = useCallback(
    (date: Date | null) => {
      if (date === null) {
        setEndDate(endDate);
        return;
      }

      date?.setHours(23, 59, 59, 0);
      setEndDate(date);

      if (!!startDate && isBefore(date, startDate)) {
        setStartDate(date);
      }
    },
    [setEndDate, setStartDate, endDate, startDate],
  );

  const onReportChanged = useCallback(
    (event: any, value: { label: string; value: string } | null) => {
      setReport(value?.value ?? '');
    },
    [setReport],
  );

  return (
    <Flex gap={4}>
      {enablePartnerSelection ? (
        <UserPartnerSelection
          partnerId={partnerId}
          onPartnerChange={onPartnerChange}
          filterProductionPartners={false}
          autocompleteStyleProps={{ ...autocompleteSxProps }}
        />
      ) : null}
      <Autocomplete
        value={currentProduct}
        options={productOptions}
        renderInput={ProductsInput}
        onChange={onProductChange}
        loading={loadingPartnerProducts}
        isOptionEqualToValue={isOptionEqualToValue}
        disableClearable
        sx={{
          ...autocompleteSxProps,
        }}
      />
      {enableReportSelection ? (
        <Autocomplete
          options={reportOptions}
          renderInput={ReportInput}
          onChange={onReportChanged}
          isOptionEqualToValue={isOptionEqualToValue}
          sx={{
            ...autocompleteSxProps,
          }}
        />
      ) : null}
      <StyledDatePickerWrapper>
        <DatePicker
          customInput={StartDateInput}
          selected={startDate}
          startDate={startDate}
          onChange={onStartDateChange}
          dropdownMode={'select'}
          showMonthDropdown
          showYearDropdown
          selectsStart
          maxDate={endDate}
        />
      </StyledDatePickerWrapper>
      <StyledDatePickerWrapper>
        <DatePicker
          customInput={EndDateInput}
          selectsEnd
          dropdownMode={'select'}
          showMonthDropdown
          showYearDropdown
          selected={endDate}
          endDate={endDate}
          maxDate={new Date()}
          onChange={onEndDateChange}
        />
      </StyledDatePickerWrapper>
    </Flex>
  );
};
