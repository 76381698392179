import React from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { Avatar, ItemActions } from '@lama/app-components';
import { grey } from '@mui/material/colors';

interface RelatedCompanyCardProps {
  name: string;
  ownerName: string;
  deleteEnabled: boolean;
  onDelete?: (id: string) => void;
  onEdit: (id: string) => void;
  id: string;
}

export const RelatedCompanyCard: React.FC<RelatedCompanyCardProps> = ({ name, ownerName, deleteEnabled, onDelete, onEdit, id }) => (
  <Stack
    gap={2}
    height={'100px'}
    px={4}
    py={3}
    sx={{
      border: 1,
      borderColor: grey[300],
    }}
    borderRadius={'4px'}
    direction={'row'}
    width={'100%'}
    justifyContent={'space-between'}
  >
    <Stack direction={'row'} alignItems={'center'} gap={2} minWidth={0}>
      <Avatar firstName={name} lastName={''} />
      <Stack minWidth={0}>
        <Tooltip title={name} placement={'top'}>
          <Typography variant={'h6'} noWrap>
            {name}
          </Typography>
        </Tooltip>
        <Tooltip title={ownerName} placement={'top'}>
          <Typography variant={'body2'} noWrap>
            {`Owned by ${ownerName}`}
          </Typography>
        </Tooltip>
      </Stack>
    </Stack>
    <ItemActions item={{ id }} onDeleteClick={deleteEnabled ? onDelete : undefined} onEditClick={onEdit} itemType={'business'} />
  </Stack>
);
