/* eslint-disable @typescript-eslint/naming-convention */
import type { MutableRefObject, FC } from 'react';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import type { MenuProps } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { type ApplicationStatus } from '@lama/contracts';
import { useConfirmModal } from '@lama/app-components';
import { inactiveApplicationStatuses } from '@lama/clients';
import { useUpdateOpportunityMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { ActionMenuSearchInput } from '../../ActionsMenu/ActionMenuSearchInput';
import { useUpdateStatusMutation } from '../../ActionsMenu/hooks/useUpdateStatusMutation';
import { SubMenuHeader } from '../../ActionsMenu/SubMenuHeader';
import { ApplicationContext } from '../../ApplicationContext';
import { ApplicationStatusDisplayNameMappingContext } from '../../../../shared/context/ApplicationStatusDisplayNameMappingContext';
import { DismissApplicationDialog, dismissApplicationStatuses } from './DismissApplicationDialog';

interface SetStatusMenuProps {
  menuProps: Partial<MenuProps>;
  onBack: () => void;
  open: boolean;
  menuRef: MutableRefObject<null>;
  onClose: () => void;
  isCurrentUserDecisionMaker: boolean;
}

const menuStatuses: ApplicationStatus[] = [
  'Draft',
  'OnboardingCompleted',
  'InReview',
  'PendingApproval',
  'PendingLost',
  'Closing',
  'Closed',
  'Rejected',
  'ExpresslyWithdrawn',
  'ApprovedNotAccepted',
  'WithdrawalOfApprovedApplication',
  'Cancelled',
];

export const SetStatusMenu: FC<SetStatusMenuProps> = ({ menuProps, onBack, open, menuRef, onClose, isCurrentUserDecisionMaker }) => {
  const {
    application,
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { applicationStatusToDisplayName } = useContext(ApplicationStatusDisplayNameMappingContext);

  const [dismissApplicationModalOpen, setDismissApplicationModalOpen] = useState(false);
  const { confirm } = useConfirmModal();

  const [filterOptions, setFilterOptions] = useState('');
  const { mutate: updateStatus } = useUpdateStatusMutation(application.id, opportunityId);
  const { mutate: updateOpportunity } = useUpdateOpportunityMutation(opportunityId);

  const handleClose = useCallback(() => {
    setFilterOptions('');
    onClose();
  }, [onClose]);

  const updateApplicationStatus = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      const status = event.currentTarget.getAttribute('value') as ApplicationStatus | null;

      if (!status) {
        return;
      }

      if (status === application.status) {
        handleClose();
        return;
      }

      if (dismissApplicationStatuses.includes(status)) {
        setDismissApplicationModalOpen(true);
        return;
      }

      const confirmed = await confirm({
        title: `Are you sure you want to move this application to ${applicationStatusToDisplayName[status]}?`,
      });

      if (confirmed) {
        updateStatus({
          updateApplicationPayload: {
            status,
          },
        });

        if (dismissApplicationStatuses.includes(application.status)) {
          updateOpportunity({
            adverseActionNoticeSent: false,
          });
        }
      }

      handleClose();
    },
    [application.status, applicationStatusToDisplayName, confirm, handleClose, updateOpportunity, updateStatus],
  );

  const onFilterChange = useCallback((value: string) => {
    setFilterOptions(value);
  }, []);

  const options = useMemo(
    () =>
      menuStatuses.filter((status) =>
        applicationStatusToDisplayName[status].toLocaleLowerCase().includes(filterOptions.toLocaleLowerCase()),
      ) ?? [],
    [applicationStatusToDisplayName, filterOptions],
  );

  const onDismissApplicationModalDone = useCallback(() => {
    setDismissApplicationModalOpen(false);
    handleClose();
  }, [handleClose]);

  return (
    <>
      <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={handleClose}>
        <Flex flexDirection={'column'} gap={2}>
          <SubMenuHeader onClick={onBack} text={'Back'} />
          <ActionMenuSearchInput onInputChange={onFilterChange} />
          <Flex flexDirection={'column'} pt={1} maxHeight={'300px'} overflow={'auto'}>
            {options.map((status) => (
              <MenuItem
                key={status}
                value={status}
                onClick={updateApplicationStatus}
                disabled={inactiveApplicationStatuses.includes(status) && !isCurrentUserDecisionMaker}
                sx={{ '&:hover': { bgcolor: greyPalette[100] } }}
                selected={status === application.status}
              >
                <Text variant={'body2'} ellipsis>
                  {applicationStatusToDisplayName[status]}
                </Text>
              </MenuItem>
            ))}
          </Flex>
        </Flex>
      </Menu>
      {dismissApplicationModalOpen ? (
        <DismissApplicationDialog
          open={dismissApplicationModalOpen}
          onClose={onDismissApplicationModalDone}
          mode={isCurrentUserDecisionMaker ? 'decision' : 'recommendation'}
        />
      ) : null}
    </>
  );
};
