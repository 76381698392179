import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { applicationImportantPeopleSelector, personNameWithRelationsSelector } from '@lama/selectors';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../../ApplicationContext';
import { InlineNote } from '../../shared/InlineNote/InlineNote';
import { PersonCashFlowSpreadSegment } from '../../shared/Spreads/PersonCashFlowSpreadSegment';
import type { UnderwritingSectionProps } from '../Sections/underwritingSectionProps';

const noteTitle = 'Person Cash Flow Details';
const noteName = 'Guarantor Cash Flow Details';

export const PersonCashFlowSection: FC<UnderwritingSectionProps> = ({ printMode, section }) => {
  const { application } = useContext(ApplicationContext);

  const people = useMemo(() => applicationImportantPeopleSelector(application), [application]);

  return (
    <Flex flexDirection={'column'} gap={8}>
      {people?.map((relatedPerson) => (
        <Flex flexDirection={'column'} gap={4} key={relatedPerson.person.id}>
          <PersonCashFlowSpreadSegment
            title={personNameWithRelationsSelector(relatedPerson.person.id, application)}
            person={relatedPerson}
            printMode={!!printMode}
            spreadId={section.spreadId}
          />
        </Flex>
      ))}
      <InlineNote title={noteTitle} noteName={noteName} />
    </Flex>
  );
};
