import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import React, { useContext, useMemo } from 'react';
import { Flex, Text } from '@lama/design-system';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { RequirementPrintContainer } from '../RequirementPrintContainer';
import { AffiliateSegment } from './Segments/AffiliateSegment';

export const RelatedCompaniesView = ({ requirement }: { requirement: EvaluatedOpportunityRequirement }) => {
  const { application } = useContext(ApplicationContext);

  const business = useMemo(
    () => application.relatedBusinesses.find((b) => b.business.id === requirement.entityId)?.business,
    [application.relatedBusinesses, requirement.entityId],
  );

  const relatedCompanies = useMemo(
    () => business?.affiliates?.filter(({ ownershipPercentage }) => !ownershipPercentage) ?? [],
    [business?.affiliates],
  );

  if (!relatedCompanies.length) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Text variant={'body2'}>{'No information found'}</Text>
      </RequirementPrintContainer>
    );
  }

  return (
    <RequirementPrintContainer title={requirement.name}>
      <Flex flexDirection={'column'} gap={4}>
        {relatedCompanies.map((affiliate) => (
          <AffiliateSegment key={affiliate.id} affiliate={affiliate} />
        ))}
      </Flex>
    </RequirementPrintContainer>
  );
};
