import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ApplicationApiModel } from '@lama/clients';
import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

const RESULTS_LIMIT = 1000;

export const useGetApplicationsByPartnerQuery = (partner: string | undefined, options?: UseQueryOptions<ApplicationApiModel[] | null>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getApplicationsByPartnerQuery = useCallback(async () => {
    if (!partner) {
      return null;
    }

    const token = await getAccessTokenSilently();

    const result = await applicationServiceClient.getApplicationsByPartner(partner, token);
    if (result.length >= RESULTS_LIMIT) {
      displayToast(
        `The number of applications retrieved has reached the limit of ${RESULTS_LIMIT}. Please refine your search criteria.`,
        'info',
      );
    }
    return result;
  }, [getAccessTokenSilently, partner]);

  const query = useQuery<ApplicationApiModel[] | null>({
    queryKey: ['applications', partner],
    queryFn: getApplicationsByPartnerQuery,
    ...options,
  });

  return query;
};
