import { useAuth0 } from '@auth0/auth0-react';
import type { Entity } from '@lama/common-types';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { financialsServiceClient } from '../../../../../shared/clients/financialsServiceClient';

interface DeleteFinancialsParams {
  financialIds?: string[];
  documentId?: string;
  entityId: string;
  entityType: Entity;
}

export const useDeleteFinancialsMutation = (options?: UseMutationOptions<{ entityId: string }, unknown, DeleteFinancialsParams>) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const deleteFinancialsMutation = useCallback(
    async ({ entityId, entityType, documentId, financialIds }: DeleteFinancialsParams) => {
      const token = await getAccessTokenSilently();

      await financialsServiceClient.deleteFinancials(entityType, entityId, { financialIds, documentId }, token);

      return { entityId };
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: deleteFinancialsMutation,
    onSuccess: async ({ entityId }) => {
      await queryClient.invalidateQueries({ queryKey: ['financials', entityId] });
    },
    ...options,
  });
};
