/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
import type { FC } from 'react';
import React from 'react';
import type { TableCellProps } from '@mui/material';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Flex, Text } from '@lama/design-system';
import { Information, type InformationLine } from '@lama/app-components';

export type CellContent = {
  colspan?: number;
  bgColor?: string;
  align?: TableCellProps['align'];
} & (InformationLine | { customComponent: React.ReactNode });

export interface BasicTableProps {
  headers: { text: string[] | string; width?: number | string; colspan?: number }[];
  rows: CellContent[][];
  printMode?: boolean;
}

export const CreditMemoTable: FC<BasicTableProps> = ({ headers, rows, printMode }) => (
  <TableContainer component={Paper} sx={{ border: '1px solid #0000001F', boxShadow: 'none', borderRadius: '8px' }}>
    <Table>
      <TableHead
        sx={{
          backgroundColor: grey[200],
          boxShadow: 'none',
        }}
      >
        <TableRow>
          {headers.map((header, index) => (
            <TableCell
              key={index}
              width={header.width}
              sx={{
                border: 'solid #0000001F',
                borderWidth: `0px ${index === headers.length - 1 ? '0' : '1'}px 1px ${index === 0 ? '0' : '1'}px`,
                paddingLeft: printMode ? 1 : undefined,
                paddingRight: printMode ? 0 : undefined,
                textAlign: printMode ? 'center' : undefined,
                fontSize: printMode ? '10px' : undefined,
              }}
              colSpan={header.colspan}
            >
              {typeof header.text === 'string' ? (
                <Text variant={printMode ? 'body4' : 'body2'}>{header.text}</Text>
              ) : (
                <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                  {header.text.map((text, index) => (
                    <Text key={`${text}_${index}`} variant={printMode ? 'body4' : 'body2'}>
                      {text}
                    </Text>
                  ))}
                </Flex>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((rowCells, index) => (
          <TableRow key={index}>
            {rowCells.map((cell, cellIndex) => (
              <TableCell
                key={`cell_${cellIndex}`}
                sx={{
                  border: 'solid #0000001F',
                  borderWidth: `1px ${cellIndex === rowCells.length - 1 ? '0' : '1'}px 1px ${cellIndex === 0 ? '0' : '1'}px`,
                  backgroundColor: cell.bgColor,
                  paddingLeft: printMode ? 1 : undefined,
                  paddingRight: printMode ? 0 : undefined,
                }}
                colSpan={cell.colspan}
                align={cell.align}
              >
                {'value' in cell ? (
                  <Information value={cell.value} type={cell.type} bold={cell.bold} underline={cell.underline} />
                ) : (
                  cell.customComponent
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
