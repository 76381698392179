/* eslint-disable react/jsx-no-bind */
import { BaseDialog, LoadingPage, PropertyFormikInput } from '@lama/app-components';
import type { ApplicationStatus, DecisionReason } from '@lama/contracts';
import { Button, Flex, Text } from '@lama/design-system';
import { Formik } from 'formik';
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { v4 as uuid4 } from 'uuid';
import * as yup from 'yup';
import { orderBy, uniq } from 'lodash-es';
import { personFullName } from '@lama/selectors';
import { inactiveApplicationStatuses } from '@lama/clients';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useCreateDecisionMutation } from '../../../../shared/hooks/react-query/opportunity/useCreateDecisionMutation';
import { useUserQuery } from '../../../../shared/hooks/react-query/user/useUserQuery';
import { ApplicationContext } from '../../ApplicationContext';
import { defaultDecisionReasons } from '../../../../shared/components/DecisionDialog/defaultDecisionReasons';
import { useCreateDecisionRecommendationMutation } from '../../../../shared/hooks/react-query/opportunity/useCreateDecisionRecommendationMutation';
import { useUpdateApplicationMutation } from '../../../../shared/hooks/react-query/application/useUpdateApplication';
import { FormikDecisionReasonsMultiPicker } from './FormikDecisionReasonsMultiPicker';
import { FormikDecisionPicker } from './FormikDecisionPicker';

interface DecisionFormValues {
  decision: ApplicationStatus;
  reasons: DecisionReason[];
  otherReason: string;
  comments: string;
}

export const dismissApplicationStatuses: ApplicationStatus[] = inactiveApplicationStatuses.filter((status) => status !== 'Closed');

export const DismissApplicationDialog: FC<{ open: boolean; onClose: () => void; mode: 'decision' | 'recommendation' }> = ({
  open,
  onClose,
  mode,
}) => {
  const {
    application: { id: applicationId },
    opportunity: { id: opportunityId, underwriting },
    product,
  } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const { data: user, isPending: fetchingActiveUser } = useUserQuery(userId);
  const { mutateAsync: createDecisionRecommendation, isPending: creatingDecisionRecommendation } =
    useCreateDecisionRecommendationMutation(opportunityId);
  const { mutateAsync: createDecision, isPending: creatingDecision } = useCreateDecisionMutation(opportunityId);
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(applicationId, opportunityId);

  const dismissRecommendation = useMemo(
    () =>
      mode === 'decision'
        ? orderBy(underwriting?.decisionRecommendations ?? [], ({ createdAt }) => createdAt, ['desc']).find(({ recommendation }) =>
            dismissApplicationStatuses.includes(recommendation),
          )
        : null,
    [mode, underwriting?.decisionRecommendations],
  );

  const { data: recommendationUser, isPending: fetchingRecommendingUser } = useUserQuery(dismissRecommendation?.userId);

  const recommendationIndication = useMemo(() => {
    if (!dismissRecommendation) {
      return null;
    }

    const recommendationUserName =
      recommendationUser?.firstName && recommendationUser?.lastName
        ? personFullName({ firstName: recommendationUser.firstName, lastName: recommendationUser.lastName })
        : '';

    return `This application was requested to be dismissed${recommendationUserName ? ` by ${recommendationUserName}` : ''}.`;
  }, [dismissRecommendation, recommendationUser]);

  const initialValues = useMemo<DecisionFormValues>(
    () => ({
      decision: dismissRecommendation?.recommendation ?? ('' as ApplicationStatus),
      reasons: dismissRecommendation?.reasons ?? [],
      otherReason: dismissRecommendation?.reasons?.find((reason) => reason.id === 'other')?.text ?? '',
      comments: dismissRecommendation?.comment ?? '',
    }),
    [dismissRecommendation?.comment, dismissRecommendation?.reasons, dismissRecommendation?.recommendation],
  );

  const title = useMemo(() => (mode === 'decision' ? 'Dismiss Application' : 'Request to Dismiss'), [mode]);
  const actionText = useMemo(() => (mode === 'decision' ? 'Make Decision' : 'Make Request'), [mode]);

  const statusesWithReasons = useMemo(() => {
    const decisionReasons = product.decisionReasons ?? defaultDecisionReasons;
    return uniq(decisionReasons.flatMap((reason) => reason.relevantStatuses));
  }, [product.decisionReasons]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        text: yup.string(),
        decision: yup.string().required('Decision is required'),
        reasons: yup
          .array()
          .when('decision', ([decision]: ApplicationStatus[], schema: any) =>
            decision && statusesWithReasons.includes(decision) ? schema.min(1, 'At least one reason is required').required() : schema,
          ),
        otherReason: yup
          .string()
          .when('reasons', ([reasons]: DecisionReason[][], schema: any) =>
            reasons?.some((reason) => reason.id === 'other') ? schema.required('Other reason is required') : schema,
          ),
      }),
    [statusesWithReasons],
  );

  const onSubmit = useCallback(
    async (values: DecisionFormValues) => {
      if (!user) {
        return;
      }

      if (mode === 'decision') {
        await createDecision({
          decision: values.decision,
          text: values.comments,
          reasons: values.reasons.map((reason) => ({
            ...reason,
            text: reason.id === 'other' ? `Other - ${values.otherReason}` : reason.text,
          })),
          roles: user?.roles ?? [],
          userId: userId ?? '',
          id: uuid4(),
        });
      } else {
        await createDecisionRecommendation({
          recommendation: values.decision,
          comment: values.comments,
          reasons: values.reasons.map((reason) => ({
            ...reason,
            text: reason.id === 'other' ? `Other - ${values.otherReason}` : reason.text,
          })),
          userRoles: user?.roles ?? [],
          userId: userId ?? '',
          id: uuid4(),
        });
        await updateApplication({
          updateApplicationPayload: {
            status: 'PendingLost',
          },
        });
      }

      onClose();
    },
    [createDecision, createDecisionRecommendation, mode, onClose, updateApplication, user, userId],
  );

  const isLoading = useMemo(() => fetchingActiveUser || fetchingRecommendingUser, [fetchingActiveUser, fetchingRecommendingUser]);

  return (
    <BaseDialog open={open} onClose={onClose} title={title} data-testid={'dismiss-application-dialog'}>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit} validateOnChange enableReinitialize>
          {({ values, handleSubmit }) => (
            <Flex flexDirection={'column'} gap={8} width={'100%'} justifyContent={'center'}>
              {recommendationIndication ? <Text variant={'body2'}>{recommendationIndication}</Text> : null}
              <FormikDecisionPicker
                label={mode === 'decision' ? 'Decision' : 'Request'}
                statusFieldName={'decision'}
                reasonsFieldName={'reasons'}
                reasonsOtherFieldName={'otherReason'}
              />
              <FormikDecisionReasonsMultiPicker product={product} reasonsFieldName={'reasons'} statusFieldName={'decision'} />
              {values.reasons.some((reason) => reason.id === 'other') ? (
                <PropertyFormikInput
                  name={'otherReason'}
                  label={'Explain the "Other" reason'}
                  variant={'outlined'}
                  helperText={'Note: This will be shared with the applicant'}
                  required
                  multiline
                  rows={4}
                />
              ) : (
                <PropertyFormikInput name={'comments'} label={'Comments'} variant={'outlined'} multiline rows={4} />
              )}
              <Flex justifyContent={'flex-end'} alignItems={'center'}>
                <Button
                  size={'l'}
                  variant={'primary'}
                  onClick={() => {
                    handleSubmit();
                  }}
                  loading={creatingDecision || creatingDecisionRecommendation || updatingApplication}
                >
                  {actionText}
                </Button>
              </Flex>
            </Flex>
          )}
        </Formik>
      )}
    </BaseDialog>
  );
};
