import type { EvaluatedOpportunityRequirement, Relation } from '@lama/contracts';
import React, { useContext, useMemo } from 'react';
import { Flex, Text } from '@lama/design-system';
import { relatedBusinessesByRelationsSelector, relatedPeopleByRelationsSelector } from '@lama/selectors';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { RequirementPrintContainer } from '../RequirementPrintContainer';
import { RelatedPersonSegment } from './Segments/RelatedPersonSegment';
import { RelatedBusinessSegment } from './Segments/RelatedBusinessSegment';

export const RelationsListView = ({ requirement, relation }: { requirement: EvaluatedOpportunityRequirement; relation: Relation }) => {
  const { application } = useContext(ApplicationContext);

  const relevantBusinesses = useMemo(() => relatedBusinessesByRelationsSelector(application, [relation]), [application, relation]);

  const relevantPeople = useMemo(() => relatedPeopleByRelationsSelector(application, [relation]), [application, relation]);

  const personProperties = useMemo(() => requirement.properties.filter((p) => p.entityType === 'person'), [requirement.properties]);

  const businessProperties = useMemo(() => requirement.properties.filter((p) => p.entityType === 'business'), [requirement.properties]);

  if (!relevantPeople?.length && !relevantBusinesses.length) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Text variant={'body2'}>{'No information found'}</Text>
      </RequirementPrintContainer>
    );
  }

  return (
    <RequirementPrintContainer title={requirement.name}>
      <Flex flexDirection={'column'} gap={4}>
        {relevantBusinesses.map((entity) => (
          <RelatedBusinessSegment business={entity.business} properties={businessProperties} key={entity.business.id} />
        ))}
        {relevantPeople.map((entity) => (
          <RelatedPersonSegment person={entity.person} properties={personProperties} key={entity.person.id} />
        ))}
      </Flex>
    </RequirementPrintContainer>
  );
};
