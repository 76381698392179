import React, { useMemo } from 'react';
import type { DataProcessedAudit } from '@lama/contracts';
import type { FC } from 'react';
import { capitalCase, sentenceCase } from 'change-case-all';
import type { AuditGroupInfo } from '../types';
import { AuditLog } from '../../AuditLog';

interface DataProcessedAuditLogProps {
  audit: DataProcessedAudit[];
  groupInfo: AuditGroupInfo;
}

export const DataProcessedAuditLog: FC<DataProcessedAuditLogProps> = ({ audit, groupInfo }) => {
  const data = useMemo(
    () => audit.map((entry) => `${sentenceCase(entry.dataType)} from ${capitalCase(entry.serviceName)}`).join(', '),
    [audit],
  );

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Fetched ${data}`}
      timestamp={groupInfo.timestamp}
    />
  );
};
