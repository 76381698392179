import React, { useContext, useMemo } from 'react';
import * as selectors from '@lama/selectors';
import { sumBy } from 'lodash-es';
import type { CellContent } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../ApplicationContext';
import { totalRow } from '../../CreditMemo/Sections/creditMemoTable/creditMemoTableRowCreator';

const headers = [
  { text: 'Type', width: '50%' },
  { text: 'Amount', width: '50%' },
];

export const UseOfFundsSummaryTable = () => {
  const { application } = useContext(ApplicationContext);

  const rows = useMemo(() => {
    const breakdownByTypeRows = selectors
      .useOfFundsBreakdownByType(application)
      .map<CellContent[]>((item) => [{ value: item.type }, { value: item.amount, type: 'currency', align: 'right' }]);

    const totalAmount = sumBy(application.useOfFundsBreakdown ?? [], (a) => a.amount ?? 0);
    const unaccountedUseOfFunds =
      application.requestedAmount && totalAmount < application.requestedAmount ? application.requestedAmount - totalAmount : 0;
    const unaccountedRow: CellContent[] =
      unaccountedUseOfFunds > 0 ? [{ value: 'Unaccounted' }, { value: unaccountedUseOfFunds, type: 'currency', align: 'right' }] : [];
    const totalRowDefinition = totalRow('Total', [totalAmount + unaccountedUseOfFunds], 'currency');

    return [...breakdownByTypeRows, unaccountedRow, totalRowDefinition];
  }, [application]);

  return <CreditMemoTable headers={headers} rows={rows} />;
};
