import React from 'react';
import type { FC } from 'react';
import { useAsyncFn } from 'react-use';
import { BaseDialog } from '@lama/app-components';
import { Button, Flex } from '@lama/design-system';

interface SendEmailDialogProps {
  title: string;
  subtitle?: string;
  open: boolean;
  onClose: () => void;
  sendEmail: ({ preview }: { preview: boolean }) => Promise<void>;
}

export const SendEmailDialog: FC<SendEmailDialogProps> = ({ title, subtitle, sendEmail, open, onClose }) => {
  const [{ loading: sendingToApplicants }, onEmailApplicants] = useAsyncFn(async () => {
    await sendEmail({ preview: false });
    onClose();
  }, [onClose, sendEmail]);

  const [{ loading: sendingPreview }, onPreviewEmail] = useAsyncFn(async () => {
    await sendEmail({ preview: true });
    onClose();
  }, [onClose, sendEmail]);

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={title}
      subtitle={subtitle ?? 'Are you sure you want to email the borrower? This action cannot be reversed.'}
    >
      <Flex flexDirection={'row'} gap={2} justifyContent={'space-between'} width={'100%'}>
        <Button variant={'tertiary'} color={'neutral'} disabled={sendingPreview || sendingToApplicants} onClick={onClose}>
          {'Cancel'}
        </Button>
        <Flex flexDirection={'row'} gap={2}>
          <Button variant={'tertiary'} color={'neutral'} onClick={onPreviewEmail} loading={sendingPreview} disabled={sendingToApplicants}>
            {'Email Me a Preview'}
          </Button>
          <Button variant={'primary'} onClick={onEmailApplicants} loading={sendingToApplicants} disabled={sendingPreview}>
            {'Email Applicants'}
          </Button>
        </Flex>
      </Flex>
    </BaseDialog>
  );
};
