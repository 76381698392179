import type { BarDatum, BarTooltipProps, ComputedDatum } from '@nivo/bar';
import { ResponsiveBar } from '@nivo/bar';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Flex, Text } from '@lama/design-system';
import { applicationStatuses, type ApplicationStatus } from '@lama/contracts';
import type { GetApplicationsByStatusChartDataResponseSchema } from '@lama/reports-service-client';
import { applicationStatusToColors } from '../../../../shared/utils/applicationStatusToColors';
import { ApplicationStatusDisplayNameMappingContext } from '../../../../shared/context/ApplicationStatusDisplayNameMappingContext';
import { formatChartAxisValue } from './chartUtils';

interface ApplicationChartDatum extends BarDatum {
  statusText: string;
  status: ApplicationStatus;
  amount: number;
}

const tableMargins = {
  top: 20,
  right: 0,
  bottom: 90,
  left: 10,
};

const BarToolTip: FC<BarTooltipProps<ApplicationChartDatum>> = ({ formattedValue }) => (
  <Flex bg={'#ffffff'} borderRadius={'4px'} alignItems={'center'} justifyContent={'center'} py={1} px={2}>
    <Text variant={'body4'}>{formattedValue}</Text>
  </Flex>
);

const getColor = (bar: ComputedDatum<ApplicationChartDatum>) => applicationStatusToColors[bar.data.status].color;

export const ApplicationsByStatusChart: FC<{ chartData: GetApplicationsByStatusChartDataResponseSchema | null | undefined }> = ({
  chartData,
}) => {
  const { applicationStatusToDisplayName } = useContext(ApplicationStatusDisplayNameMappingContext);

  const finalChartData = useMemo<ApplicationChartDatum[]>(
    () =>
      applicationStatuses
        .filter((status) => status !== 'Deleted')
        .map((status) => ({
          statusText: applicationStatusToDisplayName[status],
          status,
          amount: chartData?.[status] ?? 0,
        })),
    [chartData, applicationStatusToDisplayName],
  );

  return (
    <Flex flex={1} height={'280px'} maxHeight={'280px'}>
      <ResponsiveBar
        data={finalChartData}
        keys={['amount']}
        indexBy={'statusText'}
        colorBy={'indexValue'}
        colors={getColor}
        margin={tableMargins}
        padding={0.5}
        enableGridX={false}
        enableGridY={false}
        enableLabel={false}
        axisLeft={{
          tickSize: 1,
          tickPadding: -15,
          format: formatChartAxisValue,
        }}
        axisBottom={{
          tickSize: 0,
          tickRotation: 45,
        }}
        tooltip={BarToolTip}
      />
    </Flex>
  );
};
