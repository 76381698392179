import React, { useCallback, useMemo, useState } from 'react';
import type { FC } from 'react';
import { BaseDialog } from '@lama/app-components';
import type { AccountData } from '@lama/contracts';
import { Pagination, Stack, useTheme } from '@mui/material';
import { orderBy } from 'lodash-es';
import { TransactionsList } from './TransactionsDialog/TransactionsList';

interface TransactionsDialogProps {
  transactions: AccountData['transactions'];
  open: boolean;
  onClose: () => void;
}

export const TransactionsDialog: FC<TransactionsDialogProps> = ({ transactions, open, onClose }) => {
  const theme = useTheme();
  const sortedTransactions = useMemo(() => orderBy(transactions, (t) => t.date, 'desc'), [transactions]);
  const [currentTransactions, setCurrentTransactions] = useState(sortedTransactions.slice(0, 9));
  const pageCount = useMemo(() => Math.ceil(transactions.length / 9), [transactions]);

  const onPageChange = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      setCurrentTransactions(sortedTransactions.slice((page - 1) * 9, page * 9));
    },
    [sortedTransactions],
  );

  return (
    <BaseDialog open={open} onClose={onClose} title={'Transactions list'}>
      <Stack alignItems={'center'} width={'100%'} spacing={4}>
        <TransactionsList transactions={currentTransactions} />
        <Pagination
          count={pageCount}
          // eslint-disable-next-line @typescript-eslint/naming-convention
          sx={{ '& .MuiPaginationItem-root.Mui-selected': { backgroundColor: theme.palette.primary.light } }}
          onChange={onPageChange}
        />
      </Stack>
    </BaseDialog>
  );
};
