import React, { useMemo } from 'react';
import type { ApplicationStatus } from '@lama/contracts';
import { Avatar, Tooltip } from '@lama/app-components';
import { sentenceCase } from 'change-case-all';
import type { FC } from 'react';
import { amberPalette, Flex, greyPalette, redPalette, Text } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatValue } from '@lama/data-formatters';
import { getAssetUrl } from '../../../../../shared/utils/getAssetUrl';

interface DecisionMakerTitleProps {
  role: string;
  firstName: string;
  lastName: string;
  decision?: ApplicationStatus;
  decisionTimestamp?: string;
  disabled?: boolean;
}

const lamaDecisionAvatar = getAssetUrl('lamaAuditAvatar.png');

const getDecisionTextColor = (decision?: ApplicationStatus, disabled?: boolean) => {
  if (disabled) {
    return greyPalette[500];
  }

  if (!decision) {
    return 'inherit';
  }

  switch (decision) {
    case 'PendingApproval':
    case 'Closing': {
      return '#44CFA6';
    }
    case 'Rejected': {
      return redPalette[400];
    }
    default: {
      return amberPalette[700];
    }
  }
};

const getDecisionTitleText = (decision?: ApplicationStatus) => {
  if (!decision) {
    return '';
  }

  switch (decision) {
    case 'PendingApproval':
    case 'Closing': {
      return 'Approved the application';
    }
    case 'Rejected': {
      return 'Declined the application';
    }
    case 'ExpresslyWithdrawn': {
      return 'Withdrew the application';
    }
    case 'Cancelled': {
      return 'Cancelled the application';
    }
    case 'ApprovedNotAccepted': {
      return 'Approved, not accepted';
    }
    case 'WithdrawalOfApprovedApplication': {
      return 'Approved, withdrawn by applicant';
    }
    default: {
      return '';
    }
  }
};

const DotSeparator: FC = () => (
  <Text variant={'body2'} color={greyPalette[300]}>
    {'•'}
  </Text>
);

export const DecisionMakerTitle: FC<DecisionMakerTitleProps> = ({ disabled, role, decision, firstName, lastName, decisionTimestamp }) => {
  const { hideApprovalRoles } = useFlags();

  const decisionTitleText = useMemo(() => getDecisionTitleText(decision), [decision]);
  const decisionTitleTextColor = useMemo(() => getDecisionTextColor(decision, disabled), [decision, disabled]);

  const decisionDateTimeLabel = useMemo(() => formatValue(decisionTimestamp, 'datetime'), [decisionTimestamp]);
  const decisionDateLabel = useMemo(() => formatValue(decisionTimestamp, 'date'), [decisionTimestamp]);

  return (
    <Flex gap={2} alignItems={'center'}>
      <Avatar imgSrc={role === 'lama' ? lamaDecisionAvatar : undefined} firstName={firstName} lastName={lastName} />
      <Text variant={'body2'} color={disabled ? greyPalette[500] : 'inherit'}>
        {`${firstName} ${lastName}`}
      </Text>
      {hideApprovalRoles ? null : (
        <>
          <DotSeparator />
          <Text variant={'body2'} color={disabled ? greyPalette[400] : 'text.secondary'}>
            {sentenceCase(role)}
          </Text>
        </>
      )}
      {decisionTitleText ? (
        <>
          <DotSeparator />
          <Text variant={'body2'} color={decisionTitleTextColor}>
            {decisionTitleText}
          </Text>
        </>
      ) : null}
      {decisionTimestamp ? (
        <>
          <DotSeparator />
          <Tooltip title={decisionDateTimeLabel} placement={'top'}>
            <Text variant={'body2'} color={greyPalette[500]}>
              {decisionDateLabel}
            </Text>
          </Tooltip>
        </>
      ) : null}
    </Flex>
  );
};
