import React, { useCallback, useMemo, useState } from 'react';
import type { FC } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CloseRounded } from '@mui/icons-material';
import type { ApplicationStatus } from '@lama/contracts';
import { DecisionForm } from './DecisionForm';
import { DecisionDone } from './DecisionDone';

interface DecisionDialogProps {
  open: boolean;
  onClose: () => void;
  decision: ApplicationStatus;
  canCurrentUserApprove?: boolean;
}

export const DecisionModal: FC<DecisionDialogProps> = ({ open, onClose, decision, canCurrentUserApprove }) => {
  const [activeStep, setActiveStep] = useState(0);

  const nextStep = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const decisionDone = useCallback(() => {
    setActiveStep(0);
    onClose();
  }, [onClose]);

  const currentStep = useMemo(() => {
    switch (activeStep) {
      case 0: {
        return <DecisionForm onDecision={nextStep} decision={decision} />;
      }
      case 1: {
        if (canCurrentUserApprove) {
          return <DecisionDone onClose={decisionDone} decision={decision} />;
        }
        onClose();
        return;
      }
      default: {
        return null;
      }
    }
  }, [activeStep, canCurrentUserApprove, decision, decisionDone, nextStep, onClose]);

  return (
    <Dialog maxWidth={'md'} open={open} onClose={decisionDone} disableEscapeKeyDown fullWidth>
      <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={decisionDone}>
        <CloseRounded fontSize={'large'} sx={{ color: grey[500] }} />
      </IconButton>
      <DialogContent sx={{ bgcolor: 'white', gap: 4, px: 10, py: 6 }}>{currentStep}</DialogContent>
    </Dialog>
  );
};
