import type { BarTooltipProps } from '@nivo/bar';
import { ResponsiveBar } from '@nivo/bar';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { compact, keyBy } from 'lodash-es';
import { Flex, Text } from '@lama/design-system';
import type { Opportunity } from '@lama/contracts';
import type { ApplicationApiModel } from '@lama/clients';
import { isAfter } from 'date-fns';
import { ReportsFilterContext } from '../ReportsFilterContext';
import { formatChartAxisValue, getChartDataByWeeks } from './chartUtils';

interface ChartDatum {
  weekText: string;
  week: string;
  loading?: boolean;
  amount: number;
}

const tableMargins = {
  top: 20,
  right: 0,
  bottom: 90,
  left: 10,
};

const BarToolTip: FC<BarTooltipProps<ChartDatum>> = ({ formattedValue }) => (
  <Flex bg={'#ffffff'} borderRadius={'4px'} alignItems={'center'} justifyContent={'center'} py={1} px={2}>
    <Text variant={'body3'}>{formattedValue}</Text>
  </Flex>
);

interface ApplicationsByLastDecisionBarChartProps {
  opportunityFilter: (opportunity: Opportunity, application: ApplicationApiModel) => boolean;
  color?: string;
  applications?: ApplicationApiModel[] | null;
  opportunityEntities?: Opportunity[] | null;
}

export const ApplicationsByLastStatusUpdateBarChart: FC<ApplicationsByLastDecisionBarChartProps> = ({
  opportunityFilter,
  color,
  applications,
  opportunityEntities,
}) => {
  const { productId, startDate, endDate } = useContext(ReportsFilterContext);

  const chartData = useMemo(() => {
    if (!startDate || !endDate || !isAfter(endDate, startDate) || !productId || !opportunityEntities || !applications) {
      return [];
    }

    const applicationsById = keyBy(applications, (app) => app.id);

    const relevantOpportunities = compact(
      opportunityEntities
        .filter((opportunity) => {
          const application = applicationsById[opportunity.applicationId];

          if (!application) {
            return false;
          }

          return opportunityFilter(opportunity, application);
        })
        .map((opportunity) => {
          const application = applicationsById[opportunity.applicationId];

          if (!application) {
            return null;
          }

          return {
            ...opportunity,
            itemTimestamp: application.lastStatusUpdatedAt,
          };
        }),
    );

    return getChartDataByWeeks(relevantOpportunities);
  }, [applications, endDate, opportunityEntities, opportunityFilter, productId, startDate]);

  return (
    <Flex flex={1} height={'280px'} maxHeight={'280px'} justifyContent={'center'} alignItems={'center'}>
      {!chartData.length ? (
        <Text variant={'body2'}>{'No applications to display for the selected time range.'}</Text>
      ) : (
        <ResponsiveBar
          data={chartData}
          keys={['amount']}
          indexBy={'weekText'}
          colors={color}
          margin={tableMargins}
          padding={0.5}
          enableGridX={false}
          enableGridY={false}
          enableLabel={false}
          axisLeft={{
            tickSize: 1,
            tickPadding: -15,
            format: formatChartAxisValue,
          }}
          axisBottom={{
            tickSize: 0,
            tickRotation: 45,
          }}
          tooltip={BarToolTip}
        />
      )}
    </Flex>
  );
};
