/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import type { ColDef, FilterModel, SortModelItem } from '@ag-grid-community/core';
import { formatValue } from '@lama/data-formatters';
import { Tooltip } from '@lama/app-components';
import FlagsOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { applicationNumberSelector, personFullName, referrerDisplayName } from '@lama/selectors';
import { Text, Flex, orangePalette, redPalette } from '@lama/design-system';
import { differenceInDays } from 'date-fns';
import type { ApplicationStatus, ProductApiModel } from '@lama/contracts';
import type { User } from '@lama/user-service-client';
import { Link } from 'react-router-dom';
import type { PartnerApiModel } from '@lama/partner-service-client';
import type { GetPipelineItemsQueryParams } from '@lama/pipeline-items-service-client';
import { ApplicationStatusChip } from '../../shared/components/ApplicationStatusChip';
import type { ApplicationStatusDisplayNameMapping } from '../../shared/context/ApplicationStatusDisplayNameMappingContext';
import { BusinessCell } from './BusinessDetailsCell';
import { PartnerCell } from './PartnerCell';
import { AssigneeCell } from './AssigneeCell';
import { alertsColumnDefinition } from './ColumnDefinitions/alertsColumnDefinition';
import type { CalculatedPipelineItem, CustomCellRendererParams } from './types';
import { getSortParams } from './getSortParams';

const noAssigneeFilterText = 'No assignee';
const noLoanTypeFilterText = 'No loan type';

export const filtersToPipelineQueryParams = ({
  filterModel,
  userPartners,
  pipelineCategory,
  globalFilter,
  columnState: sortModel,
}: {
  userPartners: string[];
  filterModel?: FilterModel | null;
  pipelineCategory?: string;
  globalFilter?: string;
  columnState?: SortModelItem[];
}): Pick<
  GetPipelineItemsQueryParams,
  'assignedTo' | 'order' | 'partnerIds' | 'pipelineCategory' | 'productIds' | 'search' | 'sortBy' | 'statuses'
> => {
  const partnerIds = (filterModel?.partner?.values as string[]) || userPartners;
  const statuses: ApplicationStatus[] | undefined = filterModel?.applicationStatus?.values;
  const productIds: string[] | undefined = filterModel?.productId?.values;
  const assignedTo: string[] | undefined = filterModel?.assignedTo?.values;

  const { sortBy, order } = getSortParams(sortModel);

  return {
    ...(partnerIds ? { partnerIds } : {}),
    ...(pipelineCategory ? { pipelineCategory } : {}),
    ...(globalFilter ? { search: globalFilter } : {}),
    ...(statuses ? { statuses } : {}),
    ...(productIds ? { productIds } : {}),
    ...(assignedTo ? { assignedTo } : {}),
    ...(sortBy ? { sortBy } : {}),
    ...(order ? { order } : {}),
  };
};

export const getColumnDefinitions = ({
  showReferrerColumnInPipeline,
  showRegBColumnInPipeline,
  showSourceColumnInPipeline,
  showLastStatusUpdateInPipeline,
  userOptions,
  applicationStatusToDisplayName,
  productOptions,
  partnerOptions,
  enableArrayFieldSorting = true,
  fibtDemo,
}: {
  userOptions: User[];
  productOptions: ProductApiModel[];
  partnerOptions: PartnerApiModel[];
  showApplicationNumberColumnInPipeline: boolean;
  showReferrerColumnInPipeline: boolean;
  showRegBColumnInPipeline: boolean;
  showSourceColumnInPipeline: boolean;
  showLastStatusUpdateInPipeline: boolean;
  applicationStatusToDisplayName: ApplicationStatusDisplayNameMapping;
  enableArrayFieldSorting?: boolean;
  fibtDemo?: boolean;
}): ColDef<CalculatedPipelineItem>[] => {
  const columnDefs: ColDef<CalculatedPipelineItem>[] = [
    {
      headerName: fibtDemo ? 'Name' : 'Borrower(s)',
      field: 'businessId',
      minWidth: 320,
      filter: 'agTextColumnFilter',
      filterValueGetter: (params) =>
        `${params?.data?.businessName} ${params?.data?.loanDisplayName} ${params?.data?.primaryContactFullName} ${
          params?.data?.businessFullAddress
        } ${params?.data?.primaryContactEmail} ${applicationNumberSelector(params.data!)}`,
      valueGetter: (params) =>
        `${params?.data?.businessName} ${params?.data?.loanDisplayName} ${params?.data?.primaryContactFullName} ${params?.data?.businessFullAddress} ${params?.data?.primaryContactEmail}`,
      cellRenderer: (params: CustomCellRendererParams) => {
        if (!params.data) {
          return null;
        }

        const { businessName, primaryContactFullName, loanDisplayName } = params.data;
        return (
          <BusinessCell
            businessName={loanDisplayName ?? businessName ?? '-'}
            fullName={primaryContactFullName ?? '-'}
            applicationNumber={applicationNumberSelector(params?.data)}
          />
        );
      },
      headerCheckboxSelection: fibtDemo,
      checkboxSelection: fibtDemo,
      headerCheckboxSelectionFilteredOnly: fibtDemo,
    },
    {
      headerName: 'Amount',
      field: 'requestedAmount',
      hide: fibtDemo,
      maxWidth: 150,
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: CustomCellRendererParams) => (
        <Flex alignItems={'center'} px={3}>
          <Tooltip title={formatValue(params.value, 'currency')}>
            <Text variant={'body2'}>{formatValue(params.value, 'currencyCompact')}</Text>
          </Tooltip>
        </Flex>
      ),
    },
    {
      headerName: 'Loan Type',
      field: 'productId',
      hide: fibtDemo,
      filter: true,
      minWidth: 160,
      filterParams: {
        values: productOptions,
        refreshValuesOnOpen: true,
        comparator: (a: ProductApiModel, b: ProductApiModel) => {
          if (!a) {
            return -1;
          }

          if (!b) {
            return 1;
          }

          return (a.prettyName || a.name).localeCompare(b.prettyName || b.name);
        },
        valueFormatter: (params: { value: ProductApiModel }) =>
          params.value ? params.value.prettyName ?? params.value.name : noLoanTypeFilterText,
        keyCreator: (params: any) => (params.value ? params.value.id : noLoanTypeFilterText),
      },
      filterValueGetter: (params) => {
        const productId = params.data?.productId;
        return productId ? productOptions.find((p) => p.id === productId) : '-';
      },
      cellRenderer: (params: CustomCellRendererParams) => (
        <Flex pl={1}>
          <Text variant={'body2'} ellipsis>
            {params.data?.productName ?? ''}
          </Text>
        </Flex>
      ),
    },
    {
      headerName: 'Status',
      field: 'applicationStatus',
      filter: true,
      filterParams: {
        values: (params: CustomCellRendererParams) => {
          const statusOptions = Object.keys(applicationStatusToDisplayName);
          // @ts-ignore Pass the array back to the set filter
          params.success(statusOptions);

          return statusOptions;
        },
        comparator: (a: ApplicationStatus, b: ApplicationStatus) => {
          if (!a) {
            return -1;
          }

          if (!b) {
            return 1;
          }

          return a.localeCompare(b);
        },
        valueFormatter: (params: { value: ApplicationStatus }) => applicationStatusToDisplayName[params.value],
        keyCreator: (params: any) => params.value,
      },
      filterValueGetter: (params) => (params.data?.applicationStatus ? applicationStatusToDisplayName[params.data.applicationStatus] : '-'),
      cellRenderer: (params: CustomCellRendererParams) => (
        <ApplicationStatusChip
          status={params.value}
          size={'small'}
          sx={{ cursor: 'pointer' }}
          isApplicationAutoDeclined={!!params.data?.autoDeclined && params.value === 'Rejected'}
        />
      ),
    },
    {
      headerName: 'Partner',
      field: 'partner',
      hide: !showSourceColumnInPipeline,
      filter: true,
      filterValueGetter: (params) => {
        const partner = params.data?.partner;
        return partner ? partnerOptions.find((p) => p.name === partner) : '-';
      },
      filterParams: {
        values: partnerOptions,
        comparator: (a: PartnerApiModel, b: PartnerApiModel) => {
          if (!a) {
            return -1;
          }

          if (!b) {
            return 1;
          }

          return a.displayName.localeCompare(b.displayName);
        },
        valueFormatter: (params: { value: PartnerApiModel }) => (params.value ? params.value.displayName : '-'),
        keyCreator: (params: { value: PartnerApiModel }) => (params.value ? params.value.name : '-'),
        refreshValuesOnOpen: true,
      },
      cellRenderer: (params: CustomCellRendererParams) => {
        if (!params.data?.partner) {
          return <Text variant={'body2'}>{'-'}</Text>;
        }

        return <PartnerCell partnerId={params.data.partner} partnerDisplayName={params.data.partnerPrettyName ?? params.data?.partner} />;
      },
    },

    {
      headerName: 'Assignee',
      field: 'assignedTo',
      filter: 'agSetColumnFilter',
      sortable: enableArrayFieldSorting,
      maxWidth: 140,
      valueGetter: (params) => params.data?.assignedTo?.map((assignee) => personFullName(assignee)),
      cellRenderer: (params: CustomCellRendererParams) => {
        if (!params.data?.assignedTo) {
          return null;
        }
        return <AssigneeCell assignees={params.data.assignedTo} />;
      },
      filterParams: {
        values: (params: CustomCellRendererParams) => {
          // @ts-ignore Pass the array back to the set filter
          params.success([...userOptions]);

          return userOptions;
        },
        comparator: (a: User, b: User) => {
          if (!a) {
            return -1;
          }

          if (!b) {
            return 1;
          }

          return personFullName(a).localeCompare(personFullName(b));
        },
        valueFormatter: (params: { value: User }) =>
          params.value ? personFullName(params.value) || params.value.email : noAssigneeFilterText,
        keyCreator: (params: any) => (params.value ? params.value.id : noAssigneeFilterText),
      },
      filterValueGetter: (params) => {
        const assignedTo = params.data?.assignedTo ?? [];
        return assignedTo?.length ? assignedTo : undefined;
      },
    },
    {
      headerName: 'Reg B',
      hide: !showRegBColumnInPipeline,
      cellRenderer: (params: CustomCellRendererParams) => {
        if (!params.data?.applicationStatus || !params.data?.opportunityCreatedAt) {
          return <Text variant={'body2'}>{'-'}</Text>;
        }
        const { applicationStatus, opportunityCreatedAt } = params.data;
        const regBStatuses = ['InReview', 'Draft', 'OnboardingCompleted'];
        if (!applicationStatus || opportunityCreatedAt || regBStatuses.includes(applicationStatus) || !opportunityCreatedAt) {
          return <Text variant={'body2'}>{'-'}</Text>;
        }

        const elapsedDays = differenceInDays(new Date(), new Date(opportunityCreatedAt));
        const regBRemainDays = 30 - elapsedDays;
        const flagColor = elapsedDays < 20 ? orangePalette[500] : redPalette[400];

        return (
          <Tooltip title={formatValue(opportunityCreatedAt, 'datetime')}>
            <Flex gap={1}>
              {elapsedDays >= 10 ? <FlagsOutlinedIcon sx={{ color: flagColor }} /> : null}
              <Text variant={'body2'}>
                {Math.abs(regBRemainDays)}
                {' days remaining'}
              </Text>
            </Flex>
          </Tooltip>
        );
      },
    },

    {
      headerName: 'Referrer',
      field: 'referrerCode',
      hide: !showReferrerColumnInPipeline,
      width: 130,
      filter: true,
      valueGetter: (params) => referrerDisplayName(params.data ?? {}) ?? params.data?.referrerCode,
      filterValueGetter: (params) => referrerDisplayName(params.data ?? {}) ?? params.data?.referrerCode,
      cellRenderer: (params: CustomCellRendererParams) => (
        <Tooltip title={params.value}>
          <Text variant={'body2'} ellipsis>
            {params.data?.referrer?.name}
          </Text>
        </Tooltip>
      ),
    } as ColDef<CalculatedPipelineItem>,
    showLastStatusUpdateInPipeline
      ? {
          headerName: 'Last Status Update',
          field: 'lastStatusUpdatedAt',
          sort: 'desc',
          valueGetter: (params) => params.data?.lastStatusUpdatedAt ?? params.data?.opportunityCreatedAt,
          filterValueGetter: (params) => params.data?.lastStatusUpdatedAt ?? params.data?.opportunityCreatedAt,
          cellRenderer: (params: CustomCellRendererParams) => {
            const relevantTime = params.data?.lastStatusUpdatedAt ?? params.data?.opportunityCreatedAt;

            if (!relevantTime) {
              return null;
            }

            const absoluteDateTime = formatValue(relevantTime, 'datetime');
            const absoluteDate = formatValue(relevantTime, 'date');

            return (
              <Tooltip title={absoluteDateTime}>
                <Text variant={'body2'}>{absoluteDate}</Text>
              </Tooltip>
            );
          },
        }
      : {
          headerName: 'Last Updated',
          field: 'lastUpdated',
          sort: 'desc',
          cellRenderer: (params: CustomCellRendererParams) => {
            const lastUpdated = params.data?.lastUpdated;

            if (!lastUpdated) {
              return null;
            }

            const absoluteDateTime = formatValue(lastUpdated, 'datetime');
            const absoluteDate = formatValue(lastUpdated, 'date');

            return (
              <Tooltip title={absoluteDateTime}>
                <Text variant={'body2'}>{absoluteDate}</Text>
              </Tooltip>
            );
          },
        },
    alertsColumnDefinition({ enableSorting: enableArrayFieldSorting }),
  ];

  return columnDefs.map((columnDef) => ({
    ...columnDef,

    cellRenderer: (params: CustomCellRendererParams) => (
      <Link
        style={{
          textDecoration: 'none',
          color: 'inherit',
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          height: '100%',
          width: '100%',
        }}
        to={`${window.location.pathname}/${params.data?.opportunityId}`}
      >
        {columnDef.cellRenderer(params)}
      </Link>
    ),
  }));
};
