import React, { useMemo } from 'react';
import type { FC } from 'react';
import type { RelatedPersonApiModel } from '@lama/clients';
import { Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import { getDangerousValueColor } from './getDangerousValueColor';

interface DerogatoryHistoryProps {
  guarantor: RelatedPersonApiModel;
}

export const DerogatoryHistory: FC<DerogatoryHistoryProps> = ({ guarantor }) => {
  const chargeOffs = useMemo(
    () => guarantor.tradelines?.filter((t) => !t.dismissed && t.hasChargeoffs).length ?? 0,
    [guarantor.tradelines],
  );

  const collections = useMemo(
    () => guarantor.tradelines?.filter((t) => !t.dismissed && t.hasCollections).length ?? 0,
    [guarantor.tradelines],
  );

  const delinquencies30 = useMemo(
    () => guarantor.tradelines?.filter((t) => !t.dismissed && t.delinquencies30Days).length ?? 0,
    [guarantor.tradelines],
  );

  const delinquencies60 = useMemo(
    () => guarantor.tradelines?.filter((t) => !t.dismissed && t.delinquencies60Days).length ?? 0,
    [guarantor.tradelines],
  );

  const delinquencies90 = useMemo(
    () => guarantor.tradelines?.filter((t) => !t.dismissed && t.delinquencies90to180Days).length ?? 0,
    [guarantor.tradelines],
  );

  return (
    <SegmentContainer title={'Derogatory History'}>
      <Grid columns={3}>
        <KeyValuePair name={'Charge Offs'} value={chargeOffs} valueColor={getDangerousValueColor(chargeOffs)} />
        <KeyValuePair name={'Collections'} value={collections} valueColor={getDangerousValueColor(collections)} />
        <KeyValuePair name={'Public Records'} value={guarantor.thirdPartyData?.creditReport?.publicRecordsCount ?? 0} />
        <KeyValuePair name={'30 Days'} value={delinquencies30} valueColor={getDangerousValueColor(delinquencies30)} />
        <KeyValuePair name={'60 Days'} value={delinquencies60} valueColor={getDangerousValueColor(delinquencies60)} />
        <KeyValuePair name={'90 Days'} value={delinquencies90} valueColor={getDangerousValueColor(delinquencies90)} />
        <KeyValuePair name={'Inquiries'} value={guarantor.thirdPartyData?.creditReport?.totalInquiries ?? 0} />
        {/* <KeyValuePair name={'Most Recent Late'} value={mostRecentLateTradelineDate} type={'date'} /> */}
        <KeyValuePair name={'Disputes'} value={guarantor.thirdPartyData?.creditReport?.disputedAccountsExcluded ?? 0} />
      </Grid>
    </SegmentContainer>
  );
};
