/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { ApplicationStatus } from '@lama/contracts';
import type { PartnerApiModel } from '@lama/partner-service-client';

export type ApplicationStatusDisplayNameMapping = Record<ApplicationStatus, string>;

export const ApplicationStatusDisplayNameMappingContext = React.createContext<{
  applicationStatusToDisplayName: ApplicationStatusDisplayNameMapping;
}>(null!);

interface ApplicationStatusDisplayNameMappingProviderProps {
  children: React.ReactNode;
  partner: PartnerApiModel;
}

export const ApplicationStatusDisplayNameMappingProvider: FC<ApplicationStatusDisplayNameMappingProviderProps> = ({
  partner,
  children,
}) => {
  const value = useMemo(
    () => ({ applicationStatusToDisplayName: partner.applicationStatusDisplayNameMapping }),
    [partner.applicationStatusDisplayNameMapping],
  );

  return (
    <ApplicationStatusDisplayNameMappingContext.Provider value={value}>{children}</ApplicationStatusDisplayNameMappingContext.Provider>
  );
};
