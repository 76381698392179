import React, { useMemo } from 'react';
import type { RequirementProperty } from '@lama/contracts';
import type { BusinessApiModel } from '@lama/clients';
import { businessName } from '@lama/selectors';
import { EntityPropertiesExport } from './EntityPropertiesExport';

export const RelatedBusinessSegment = ({ properties, business }: { properties: RequirementProperty[]; business: BusinessApiModel }) => {
  const name = useMemo(() => businessName(business), [business]);

  return <EntityPropertiesExport entity={business} entityName={name ?? ''} properties={properties} />;
};
