import type { Referrer } from '@lama/contracts';
import type { ExcelColumnSchema } from '@lama/reports-service-client';

export interface ApplicationExportRow {
  applicationDate: Date;
  borrowerName: string;
  currentStatus: string;
  loanAmount: number;
  borrowerState: string;
  avgFico: number;
  sbss: number | null;
  combinedDscr: string | null;
  globalDscr: string | null;
  scoreAndGo?: string;
  finalFundedLoanAmount?: number;
  fundingDate?: Date;
  finalStatus?: string;
  referrer?: Referrer;
  loanType?: string;
  alert?: string;
  opportunityId: string;
  applicationNumber: string | null;
}

export const excelSchema: ExcelColumnSchema<ApplicationExportRow>[] = [
  {
    column: 'Application Date',
    type: Date,
    format: 'mm/dd/yyyy',
    value: (row) => row.applicationDate,
    width: 15,
  },
  {
    column: 'Application Number',
    type: 'Formula',
    value: (row) =>
      row.applicationNumber ? `HYPERLINK("https://app.lama.ai/pipeline/${row.opportunityId}", "${row.applicationNumber}")` : '',
    width: 25,
  },
  {
    column: 'Borrower Name',
    type: String,
    value: (row) => row.borrowerName,
    width: 25,
  },
  {
    column: 'Loan Type',
    type: String,
    value: (row) => row.loanType,
    width: 15,
  },
  {
    column: 'Current Status',
    type: String,
    value: (row) => row.currentStatus,
    width: 18,
  },
  {
    column: 'Loan Amount at Time of Application',
    type: Number,
    format: '#,##0.00',
    value: (row) => row.loanAmount,
    width: 18,
  },
  {
    column: 'Borrower State',
    type: String,
    value: (row) => row.borrowerState,
    width: 10,
  },
  {
    column: 'Average FICO Score',
    type: Number,
    value: (row) => row.avgFico,
    width: 6,
  },
  {
    column: 'SBSS',
    type: Number, // Assuming Number type, adjust as necessary
    value: (row) => row.sbss,
    width: 6,
  },
  {
    column: 'Combined DSCR',
    type: String,
    value: (row) => row.combinedDscr,
    width: 6,
  },
  {
    column: 'Global DSCR',
    type: String,
    value: (row) => row.globalDscr,
    width: 6,
  },
  {
    column: 'Loan Amount at Time of Application',
    type: Number,
    format: '#,##0.00',
    value: (row) => row.loanAmount,
    width: 15,
  },
  {
    column: 'Final Loan Amt. Funded',
    type: Number,
    format: '#,##0.00',
    value: (row) => row.finalFundedLoanAmount,
    width: 15,
  },
  {
    column: 'Loan amount difference',
    type: Number,
    value: (row) => (row.finalFundedLoanAmount && row.loanAmount ? row.loanAmount - row.finalFundedLoanAmount : ''),
    width: 9,
  },
  {
    column: 'Closing Date',
    type: Date,
    format: 'mm/dd/yyyy',
    value: (row) => row.fundingDate,
    width: 12,
  },
  {
    column: 'Final Status',
    type: String,
    value: (row) => row.finalStatus,
    width: 18,
  },
  {
    column: 'Alert',
    type: String,
    value: (row) => row.alert,
    width: 18,
  },
  {
    column: 'Referral ID',
    type: String,
    value: (row) => row.referrer?.id,
    width: 18,
  },
  {
    column: 'Referrer Name',
    type: String,
    value: (row) => row.referrer?.name,
    width: 18,
  },
  {
    column: 'Referral Type',
    type: String,
    value: (row) => row.referrer?.type,
    width: 18,
  },
  {
    column: 'Region',
    type: String,
    value: (row) => row.referrer?.region,
    width: 18,
  },
];
