import React, { useContext } from 'react';
import type { FC } from 'react';
import { Button, Card, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { getAssetUrl } from '../../../../shared/utils/getAssetUrl';
import { ProductSettingsContext } from './ProductSettingsContext';
import { ProductTitleEdit } from './ProductTitleSetting';

interface AppetiteHeaderCardProps {
  productPrettyName: string;
}

export const ProductSettingsHeader: FC<AppetiteHeaderCardProps> = ({ productPrettyName: productName }) => {
  const theme = useTheme();
  const { enableEngagement } = useFlags();
  const { saveAll, returnToAfterSave } = useContext(ProductSettingsContext);

  const selectedTab = window.location.pathname.split('/').pop();

  return (
    <Card
      elevation={0}
      sx={{
        pt: 5,
        px: 5,
        pb: 0,
        width: '100%',
        backgroundImage: `url(${getAssetUrl('appetiteHeaderBg.png')})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        minHeight: '200px',
        border: '1px solid #E0E0E0',
        borderRadius: '8px',
        backgroundColor: 'white',
      }}
    >
      <Stack gap={2}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <ProductTitleEdit productName={productName} />
          <Button variant={'contained'} onClick={saveAll}>
            {!returnToAfterSave ? 'Save All Changes' : 'Save & Continue'}
          </Button>
        </Stack>
        <Stack gap={4}>
          <Typography variant={'body1'} color={theme.palette.text.secondary}>
            {'Modify your appetite to secure top-matching leads'}
          </Typography>
          <Tabs value={selectedTab}>
            <Tab component={Link} to={'appetite'} value={'appetite'} role={'button'} aria-label={'Appetite'} label={'Appetite'} />
            <Tab
              component={Link}
              to={'requirements'}
              value={'requirements'}
              role={'button'}
              aria-label={'Requirements'}
              label={'Requirements'}
            />
            <Tab component={Link} to={'terms'} value={'terms'} role={'button'} aria-label={'Terms'} label={'Terms'} />
            {enableEngagement ? (
              <Tab component={Link} to={'engagement'} value={'engagement'} role={'button'} aria-label={'Engagement'} label={'Engagement'} />
            ) : null}
          </Tabs>
        </Stack>
      </Stack>
    </Card>
  );
};
