import type { AccountData } from '@lama/contracts';
import React from 'react';
import type { FC } from 'react';
import { Grid } from '@mui/material';
import { categoriesDesign } from './types';
import { TansactionsCategory } from './TransactionCategory';

const categories = new Set(categoriesDesign.map((category) => category.name));

interface TransactionsCategoriesProps {
  transactions: AccountData['transactions'];
}

export const TransactionsCategories: FC<TransactionsCategoriesProps> = ({ transactions }) => {
  const transactionsByCategory = transactions.reduce<Record<string, { profit: number; loss: number }>>((acc, transaction) => {
    if (transaction.category?.length) {
      const [category] = transaction.category;

      if (category && categories.has(category)) {
        if (!acc[category]) {
          acc[category] = { profit: 0, loss: 0 };
        }

        if (transaction.amount > 0) {
          acc[category]!.profit += transaction.amount;
        } else {
          acc[category]!.loss += transaction.amount;
        }
      }
    }
    return acc;
  }, {});

  return (
    <Grid container>
      {Object.entries(transactionsByCategory).map(({ 0: category, 1: grouped }) => (
        <TansactionsCategory
          key={category}
          category={categoriesDesign.find((categoryDesign) => categoryDesign.name === category)!}
          profit={grouped?.profit ?? 0}
          loss={grouped?.loss ?? 0}
        />
      ))}
    </Grid>
  );
};
