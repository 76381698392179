/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useContext, useState, useMemo } from 'react';
import type { FC } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Tooltip, displayToast } from '@lama/app-components';
import type { PersonApiModel } from '@lama/clients';
import { Flex, Spinner, Text } from '@lama/design-system';
import { getApplicationEntityByType } from '@lama/properties';
import { personFullName as personFullNameSelector } from '@lama/selectors';
import { ContentCopyOutlined } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { ApplicationContext } from '../ApplicationContext';
import { getProtocolAndHost } from '../../../shared/utils/getProtocolAndHost';
import { useInviteToApplicationMutation } from '../../../shared/hooks/react-query/application/useInviteToApplicationMutation';
import { SubMenuHeader } from './SubMenuHeader';
import { ActionMenuSearchInput } from './ActionMenuSearchInput';

interface ShareWithApplicantSearchMenuItemProps {
  person: PersonApiModel;
  originatingPartner: string;
  closeMenu: () => void;
}

export interface ShareWithApplicantSearchMenuProps {
  menuProps: any;
  onBack: () => void;
  onClose: () => void;
  open: boolean;
  menuRef: React.MutableRefObject<null>;
}

export const ShareWithApplicantSearchMenuItem: FC<ShareWithApplicantSearchMenuItemProps> = ({ person, originatingPartner }) => {
  const {
    application: { id: applicationId },
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const queryClient = useQueryClient();

  const { mutateAsync: inviteToApplication, isPending: invitingToApplication } = useInviteToApplicationMutation(
    { applicationId, opportunityId },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['opportunity', opportunityId],
          }),
        ]);
      },
    },
  );

  const onShareWithApplicant = useCallback(async () => {
    await inviteToApplication({ personId: person.id, skipInviteEmail: true });

    await navigator.clipboard.writeText(
      `${getProtocolAndHost(originatingPartner)}/login${person.email ? `?email=${encodeURIComponent(person.email)}` : ''}`,
    );

    displayToast('Link to the application was copied to clipboard.', 'success', {
      toastId: 'share-with-applicant',
      autoClose: 5000,
      hideProgressBar: true,
    });
  }, [inviteToApplication, originatingPartner, person.email, person.id]);

  const personFullName = useMemo(() => personFullNameSelector(person), [person]);

  return (
    <Tooltip title={'Copy link'} placement={'left'}>
      <MenuItem
        onClick={onShareWithApplicant}
        sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px', pl: 2, pr: 4 }}
        disabled={invitingToApplication}
      >
        <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
          <Flex gap={2} alignItems={'center'}>
            <ContentCopyOutlined sx={{ height: '20px' }} color={'action'} />
            <Text variant={'body1'} ellipsis>
              {personFullName}
            </Text>
          </Flex>
          {invitingToApplication ? (
            <Flex justifySelf={'flex-end'}>
              <Spinner size={'s'} />
            </Flex>
          ) : null}
        </Flex>
      </MenuItem>
    </Tooltip>
  );
};

export const ShareWithApplicantSearchMenu: FC<ShareWithApplicantSearchMenuProps> = ({ menuProps, onBack, open, menuRef, onClose }) => {
  const { application } = useContext(ApplicationContext);
  const [filterOptions, setFilterOptions] = useState('');

  const handleClose = useCallback(() => {
    setFilterOptions('');
    onClose();
  }, [onClose]);

  const onFilterChange = useCallback((value: string) => {
    setFilterOptions(value);
  }, []);

  const applicants = useMemo(
    () =>
      (getApplicationEntityByType(application, 'person', ['all']) as PersonApiModel[]).filter((person) =>
        personFullNameSelector(person).toLowerCase().includes(filterOptions.toLowerCase()),
      ),
    [application, filterOptions],
  );

  return (
    <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={handleClose}>
      <Flex flexDirection={'column'} gap={2}>
        <Flex flexDirection={'column'} gap={2}>
          <SubMenuHeader onClick={onBack} text={'Applicants'} />
          <ActionMenuSearchInput onInputChange={onFilterChange} />
        </Flex>
        {applicants.map((applicant) => (
          <ShareWithApplicantSearchMenuItem
            key={applicant.id}
            originatingPartner={application.originatingPartner}
            person={applicant}
            closeMenu={handleClose}
          />
        ))}
      </Flex>
    </Menu>
  );
};
