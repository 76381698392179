import React from 'react';
import type { FC } from 'react';
import type { Entity } from '@lama/common-types';
import type {
  ComponentConfiguration,
  CustomComponentConfiguration,
  GenericDataGridComponentConfiguration,
  GenericFormComponentConfiguration,
  GenericPropertyTextComponentConfiguration,
  InlineNoteComponentConfiguration,
} from '@lama/contracts';
import type { ApplicationApiModel, BusinessApiModel, PersonApiModel } from '@lama/clients';
import { GenericDataGridSegment } from '../Underwriting/GenericDataGridSegment/GenericDataGridSegment';
import { GenericSegmentForm } from '../Underwriting/GenericSegmentForm/GenericSegmentForm';
import { FirstBankBorrowers } from '../CreditMemo/Sections/SectionsForDemos/FirstBank/consumer/FirstBankBorrowers';
import { FirstBankCollateral } from '../CreditMemo/Sections/SectionsForDemos/FirstBank/consumer/FirstBankCollateral';
import { FirstBankDecision } from '../CreditMemo/Sections/SectionsForDemos/FirstBank/consumer/FirstBankDecision';
import { FirstBankDemoLoanDetails } from '../CreditMemo/Sections/SectionsForDemos/FirstBank/consumer/FirstBankLoanDetails';
import { FirstBankBalanceSheet } from '../CreditMemo/Sections/SectionsForDemos/FirstBank/FirstBankBalanceSheet';
import { FirstBankBusinessCashFlow } from '../CreditMemo/Sections/SectionsForDemos/FirstBank/FirstBankBusinessCashFlow';
import { FirstBankCommercialCollateral } from '../CreditMemo/Sections/SectionsForDemos/FirstBank/FirstBankCollateral';
import { FirstBankDemoFacilitySection } from '../CreditMemo/Sections/SectionsForDemos/FirstBank/FirstBankDemoFacilitySection';
import { FirstBankDemoLoanSummary } from '../CreditMemo/Sections/SectionsForDemos/FirstBank/FirstBankDemoLoanSummary';
import { FirstBankDemoRiskRatingSection } from '../CreditMemo/Sections/SectionsForDemos/FirstBank/FirstBankDemoRiskRatingSection';
import { HanoverAffiliationSection } from '../CreditMemo/Sections/Hanover/HanoverAffiliationSection';
import { HanoverApprovedSection } from '../CreditMemo/Sections/Hanover/HanoverApprovedSection';
import { HanoverClosingConditionsSection } from '../CreditMemo/Sections/Hanover/HanoverClosingConditionsSection';
import { HanoverGuarantorAnalysisSection } from '../CreditMemo/Sections/Hanover/HanoverGuarantorAnalysisSection';
import { HanoverLoanDetailsSection } from '../CreditMemo/Sections/Hanover/HanoverLoanDetailsSection';
import { HanoverLoanOverviewSection } from '../CreditMemo/Sections/Hanover/HanoverLoanOverviewSection';
import { HanoverSbaEligibilitySection } from '../CreditMemo/Sections/Hanover/HanoverSbaEligibilitySection';
import { ScoreCard } from '../Underwriting/Sections/DemoSections/ScoreCard/ScoreCard';
import { LocalActivitySection } from '../Underwriting/Sections/DemoSections/LocalActivitySection/LocalActivitySection';
import { OnlineActivitySection } from '../Underwriting/Sections/DemoSections/OnlineActivity/OnlineActivitySection';
import { OperationalEfficiencySection } from '../Underwriting/Sections/DemoSections/OperationalEfficiency/OperationalEfficiencySection';
import { HmdaLoanApplicationDetailsSegment } from '../Underwriting/Sections/HmdaReporting/HmdaLoanApplicationSection';
import { ConditionsSection } from '../CreditMemo/Sections/ConditionsSection/ConditionsSection';
import { AffiliatesSection } from '../CreditMemo/Sections/AffiliatesSection/AffiliatesSection';
import { DebtScheduleSegment } from '../CreditMemo/Sections/DebtScheduleSection/DebtScheduleSection';
import { SouthStateCollateralSegment } from '../CreditMemo/Sections/CollateralSection/SouthStateCollateralSection/SouthStateCollateralSection';
import { HmdaPropertySelection } from '../Underwriting/Sections/HmdaReporting/HmdaPropertySelection';
import { CreditReport } from '../OpportunityRequirements/OpportunityRequirements/RequirementScreens/creditReport/CreditReport';
import { FibtScoreCard } from '../Underwriting/Sections/DemoSections/ScoreCard/FibtScoreCard';
import { OwnershipTable } from './LoanSummary/OwnershipTable';
import { SBSSAndCreditScoreTable } from './LoanSummary/SBSSAndCreditScoreTable';
import { PropertyTextSegment } from './PropertyTextSegment/PropertyTextSegment';
import { InlineNote } from './InlineNote/InlineNote';
import { FlagsSegment } from './FinalComments/FlagsSegment';
import { ExceptionsSegment } from './FinalComments/ExceptionsSegment';
import { DecisionSegment } from './FinalComments/Decision/DecisionSegment';
import { SizeStandardSegment } from './Eligibilities/SizeStandardSegment';
import { CreditElsewhereSegment } from './Eligibilities/CreditElsewhereSegment';
import { PersonFraudShieldAlertsSegment } from './FraudShieldAlert/PersonFraudShieldAlertsSegment';
import { SegmentContainer } from './SegmentContainer';
import { CollateralBreakdownSegment } from './Collateral/CollateralBreakdownSegment';
import { SbssEtranSegment } from './Sbss/SbssEtranSegment';
import { AdditionalFinancialInfoSegment } from './AdditionalFinancialInfo/AdditionalFinancialInfoSegment';
import { OfacReportBusinessSegment } from './OfacReport/OfacReportBusinessSegment';
import { OfacReportIndividualSegment } from './OfacReport/OfacReportIndividualSegment';
import { OfacReportReviewSegment } from './OfacReport/OfacReportReviewSegment';
import { TermsViewSegment } from './TermsAndRelationship/TermsViewSegment';
import { UseOfFundsSummarySegment } from './UseOfFunds/UseOfFundsSummarySegment';
import { UseOfFundsBreakdownSegment } from './UseOfFunds/UseOfFundsBreakdownSegment';
import { LoanTermsBorrowerDetailsSegment } from './LoanTerms/LoanTermsBorrowerDetailsSegment';
import { LoanTermsAssignersSegment } from './LoanTerms/LoanTermsAssignersSegment';
import { LoanTermsDetailsSegment } from './LoanTerms/LoanTermsDetailsSegment';
import { LoanTermsTermsViewSegment } from './LoanTerms/LoanTermsTermsViewSegment';
import { LoanTermsTermsSegment } from './LoanTerms/LoanTermsTermsSegment';
import { FibtLoanTermsTermsViewSegment } from './LoanTerms/FibtLoanTermsTermsViewSegment';
import { FibtRiskLevel } from './FibtDemo/FibtRiskLevel';
import { GCLoanTermsTermsSegment } from './LoanTerms/GCLoanTermsTermsSegment';

interface ComponentProps {
  componentConfiguration: ComponentConfiguration;
  entity?: Record<string, any> & { id: string };
  entityType?: Entity;
}

export const componentByType: Record<string, FC<ComponentProps>> = {
  inlineNote: ({ componentConfiguration, entity }) => {
    const configuration = componentConfiguration as InlineNoteComponentConfiguration;
    return (
      <InlineNote
        title={configuration.title}
        noteName={configuration.noteName}
        valueTemplate={configuration.valueTemplate}
        placeholder={configuration.placeholder}
        backwardCompatibleNoteName={configuration.backwardCompatibleNoteName}
        entityId={entity?.id}
      />
    );
  },
  genericDataGrid: ({ componentConfiguration, entity }) => {
    const configuration = componentConfiguration as GenericDataGridComponentConfiguration;
    return entity ? (
      <GenericDataGridSegment entity={entity} propertyDefinitions={configuration.properties} title={configuration.title} />
    ) : null;
  },
  genericForm: ({ componentConfiguration, entity, entityType }) => {
    const configuration = componentConfiguration as GenericFormComponentConfiguration;
    return entity && entityType ? (
      <GenericSegmentForm
        entity={entity}
        entityType={entityType}
        propertyDefinitions={configuration.properties}
        title={configuration.title}
      />
    ) : null;
  },
  genericPropertyText: ({ componentConfiguration, entity }) => {
    const {
      property: { propertyKey },
      title,
    } = componentConfiguration as GenericPropertyTextComponentConfiguration;
    return entity && propertyKey ? <PropertyTextSegment propertyKey={propertyKey} entity={entity} title={title} /> : null;
  },
  firstBankDemoLoanSummary: () => <FirstBankDemoLoanSummary />,
  firstBankDemoFacility: () => <FirstBankDemoFacilitySection />,
  firstBankDemoRiskRating: () => <FirstBankDemoRiskRatingSection />,
  firstBankConsumerCollateral: () => <FirstBankCollateral />,
  firstBankConsumerBorrowers: () => <FirstBankBorrowers />,
  firstBankConsumerLoanDetails: () => <FirstBankDemoLoanDetails />,
  firstBankConsumerDecision: () => <FirstBankDecision />,
  firstBankBusinessCashFlow: () => <FirstBankBusinessCashFlow />,
  firstBankBusinessBalanceSheet: () => <FirstBankBalanceSheet />,
  firstBankCommercialCollateral: () => <FirstBankCommercialCollateral />,
  hanoverLoanDetails: () => <HanoverLoanDetailsSection />,
  hanoverApproved: () => <HanoverApprovedSection />,
  hanoverClosingConditions: () => <HanoverClosingConditionsSection />,
  hanoverGuarantorAnalysis: () => <HanoverGuarantorAnalysisSection />,
  hanoverLoanOverview: () => <HanoverLoanOverviewSection />,
  hanoverSbaEligibility: () => <HanoverSbaEligibilitySection />,
  hanoverAffiliation: () => <HanoverAffiliationSection />,
  scoreCard: () => <ScoreCard />,
  fibtScoreCard: () => <FibtScoreCard />,
  operationalEfficiency: () => <OperationalEfficiencySection />,
  onlineActivity: () => <OnlineActivitySection />,
  localActivity: () => <LocalActivitySection />,
  flags: () => <FlagsSegment />,
  exceptions: () => <ExceptionsSegment />,
  decision: () => <DecisionSegment />,
  sizeStandard: () => <SizeStandardSegment />,
  creditElsewhere: ({ componentConfiguration }) => {
    const { params } = componentConfiguration as CustomComponentConfiguration;
    return <CreditElsewhereSegment editable={!!params?.editable} />;
  },
  fraudShieldAlerts: ({ entity }: ComponentProps) => (
    <SegmentContainer>
      <PersonFraudShieldAlertsSegment person={entity as PersonApiModel} />
    </SegmentContainer>
  ),
  hmdaLoanApplicationDetailsSegment: () => <HmdaLoanApplicationDetailsSegment />,
  conditions: () => <ConditionsSection />,
  affiliates: () => <AffiliatesSection />,
  debtSchedule: ({ entity }: ComponentProps) => <DebtScheduleSegment business={entity as BusinessApiModel} />,
  collateral: () => <CollateralBreakdownSegment />,
  hmdaPropertySelection: ({ entity }: ComponentProps) => <HmdaPropertySelection application={entity as ApplicationApiModel} />,
  southStateCollateral: () => <SouthStateCollateralSegment />,
  sbssEtran: () => <SbssEtranSegment />,
  additionalFinancialInfo: ({ entity }: ComponentProps) => <AdditionalFinancialInfoSegment business={entity as BusinessApiModel} />,
  ofacReportBusiness: ({ entity }: ComponentProps) => <OfacReportBusinessSegment business={entity as BusinessApiModel} />,
  ofacReportIndividual: ({ entity }: ComponentProps) => <OfacReportIndividualSegment individual={entity as PersonApiModel} />,
  ofacReportReview: () => <OfacReportReviewSegment />,
  termsView: () => <TermsViewSegment />,
  useOfFundsSummaryTable: () => <UseOfFundsSummarySegment />,
  useOfFundsBreakdown: () => <UseOfFundsBreakdownSegment />,
  loanTermsBorrowerDetails: () => <LoanTermsBorrowerDetailsSegment />,
  loanTermsAssigners: () => <LoanTermsAssignersSegment />,
  loanTermsDetails: () => <LoanTermsDetailsSegment />,
  loanTermsTerms: () => <LoanTermsTermsSegment />,
  gcLoanTermsTerms: () => <GCLoanTermsTermsSegment />,
  loanTermsTermsView: () => <LoanTermsTermsViewSegment />,
  fibtLoanTermsTermsView: () => <FibtLoanTermsTermsViewSegment />,
  businessOwnershipTable: ({ entity }: ComponentProps) => <OwnershipTable business={entity as BusinessApiModel} />,
  SBSSAndCreditScoreTable: () => <SBSSAndCreditScoreTable title={'SBSS and Hard Credit Scores'} />,
  creditReport: ({ entity }: ComponentProps) => <CreditReport person={entity as PersonApiModel} />,
  fibtRiskLevel: () => <FibtRiskLevel />,
};
