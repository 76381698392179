import { useAuth0 } from '@auth0/auth0-react';
import type { ApplicationUpdateApiModel } from '@lama/clients';
import { createApplicationServiceClient } from '@lama/clients';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { displayToast } from '@lama/app-components';
import { applicationServiceBaseUrl } from '../../../../framework/environment';

const applicationServiceClient = createApplicationServiceClient(applicationServiceBaseUrl);

export const useUpdateApplicationMutation = (applicationId: string, opportunityId?: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ updateApplicationPayload }: { updateApplicationPayload: ApplicationUpdateApiModel }) => {
      const token = await getAccessTokenSilently();

      await applicationServiceClient.updateApplication({ applicationId, application: updateApplicationPayload, token });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityRequirements', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityEvaluation', opportunityId],
        }),
      ]);
    },
    onError: () => {
      displayToast('Something went wrong while updating the application. Please contact support.', 'error');
    },
  });
};
