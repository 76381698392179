import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import OnboardingCompletedIcon from '@mui/icons-material/PlaylistAddCheckCircleOutlined';
import { personFullName } from '@lama/selectors';
import { AuditLog } from '../../../AuditLog';
import { ApplicationStatusDisplayNameMappingContext } from '../../../../../context/ApplicationStatusDisplayNameMappingContext';
import type { ApplicationStatusAuditProps } from './applicationStatusAuditComponentMap';

export const ApplicationOnboardingCompleteAuditLog: FC<ApplicationStatusAuditProps> = ({ audit, groupInfo }) => {
  const { applicationStatusToDisplayName } = useContext(ApplicationStatusDisplayNameMappingContext);

  const auditText = useMemo(() => {
    if (audit.originType === 'system' || !audit.origin?.firstName || !audit.origin?.lastName) {
      return `Application moved to status ${applicationStatusToDisplayName.OnboardingCompleted}`;
    }

    if (audit.origin.type === 'lender') {
      return `${personFullName(
        audit.origin as { firstName: string; middleName?: string; lastName: string },
      )} has changed the application status to ${applicationStatusToDisplayName.OnboardingCompleted}`;
    }
    return `${personFullName(
      audit.origin as { firstName: string; middleName?: string; lastName: string },
    )} completed the onboarding process`;
  }, [applicationStatusToDisplayName.OnboardingCompleted, audit.origin, audit.originType]);

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={auditText}
      timestamp={audit.timestamp}
      key={audit.id}
      badge={
        <OnboardingCompletedIcon fontSize={'inherit'} color={'primary'} sx={{ backgroundColor: 'white', borderRadius: '50%', border: 0 }} />
      }
    />
  );
};
