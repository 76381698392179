import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import React, { useContext } from 'react';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { RequirementPrintContainer } from '../RequirementPrintContainer';
import { GenericDataGridSegment } from '../../../Application/Underwriting/GenericDataGridSegment/GenericDataGridSegment';
import { SegmentContainer } from '../../../Application/shared/SegmentContainer';
import {
  gc14FamilyLoanDetailsApplicationProperties,
  gc14FamilyLoanDetailsOpporunityProperties,
} from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/GatecityLoanDetailsRequirementScreen';

export const GC14LoanDetailsView = ({ requirement }: { requirement: EvaluatedOpportunityRequirement }) => {
  const { application, opportunity } = useContext(ApplicationContext);

  return (
    <RequirementPrintContainer title={requirement.name}>
      <Flex flexDirection={'column'} gap={4}>
        <SegmentContainer>
          <GenericDataGridSegment entity={application} propertyDefinitions={gc14FamilyLoanDetailsApplicationProperties} />
          <GenericDataGridSegment entity={opportunity} propertyDefinitions={gc14FamilyLoanDetailsOpporunityProperties} />
        </SegmentContainer>
      </Flex>
    </RequirementPrintContainer>
  );
};
