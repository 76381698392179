import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { applicationBorrowingRelatedBusinessesSelector, tangibleNetWorth, twoYearAverageNetOperatingIncome } from '@lama/selectors';
import { isNil, sum } from 'lodash-es';
import { formatValue } from '@lama/data-formatters';
import { Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../ApplicationContext';
import { SegmentContainer } from '../SegmentContainer';

export const AffiliatesSummarySegment: FC = () => {
  const {
    opportunity: { referenceYear },
    application,
  } = useContext(ApplicationContext);

  const yearsBackFromCurrentYear = useMemo(() => new Date().getUTCFullYear() - referenceYear, [referenceYear]);
  const affiliates = useMemo(
    () => applicationBorrowingRelatedBusinessesSelector(application).flatMap(({ business }) => business.affiliates ?? []),
    [application],
  );

  const formattedAverageNetOperatingIncome = useMemo(() => {
    if (affiliates.length === 0) {
      return 0;
    }

    const twoYearNetOperatingIncomeAverages = affiliates?.map((affiliate) =>
      twoYearAverageNetOperatingIncome(affiliate, yearsBackFromCurrentYear),
    );

    if (twoYearNetOperatingIncomeAverages?.some((average) => isNil(average))) {
      return '-';
    }

    return formatValue(sum(twoYearNetOperatingIncomeAverages), 'currency');
  }, [affiliates, yearsBackFromCurrentYear]);

  const formattedTangibleNetWorthSum = useMemo(() => {
    if (affiliates.length === 0) {
      return formatValue(0, 'currency');
    }

    const tangibleNetWorths = affiliates.map((affiliate) => tangibleNetWorth(affiliate, yearsBackFromCurrentYear));

    if (tangibleNetWorths?.some((t) => isNil(t))) {
      return '-';
    }

    return formatValue(sum(tangibleNetWorths), 'currency');
  }, [affiliates, yearsBackFromCurrentYear]);

  return (
    <SegmentContainer title={'Affiliates Summary'}>
      <Grid columns={3}>
        <KeyValuePair name={'2-Year Avg. Net Income'} value={formattedAverageNetOperatingIncome} />
        <KeyValuePair name={'Tangible Net Worth'} value={formattedTangibleNetWorthSum} />
      </Grid>
    </SegmentContainer>
  );
};
