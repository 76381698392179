import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Flex, greyPalette, Grid, Text } from '@lama/design-system';
import { Divider } from '@mui/material';
import type { CreditModel } from '@lama/contracts';
import { orderBy } from 'lodash-es';
import { sentenceCase } from 'change-case-all';
import type { RelatedPersonApiModel } from '@lama/clients';
import { getSelectedPropertyValue } from '@lama/properties';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import { ApplicationContext } from '../../../../ApplicationContext';

const ficoScorePropertyKey = 'people_ficoScore';

interface ScoreSummaryProps {
  person: RelatedPersonApiModel;
}

const creditModelIndicatorsToName: Record<CreditModel, string> = {
  F9: 'FICO 09',
  V2: 'Vantage 2.0',
  V3: 'Vantage 3.0',
  F: 'Fair Isaac V2',
};

export const ScoreSummary: FC<ScoreSummaryProps> = ({ person }) => {
  const { properties } = useContext(ApplicationContext);

  const ficoScore = useMemo(() => {
    const ficoScoreProperty = properties?.[ficoScorePropertyKey];
    if (!ficoScoreProperty) {
      return;
    }

    return getSelectedPropertyValue(ficoScoreProperty.fieldName, person, ficoScoreProperty.sources, ficoScoreProperty.type).value;
  }, [person, properties]);

  const creditReportModel = useMemo(
    () => person.thirdPartyData?.creditReport?.creditScoreModel,
    [person.thirdPartyData?.creditReport?.creditScoreModel],
  );
  const sortedFicoScoreFactors = useMemo(
    () => orderBy(person.thirdPartyData?.creditReport?.ficoFactors, (f) => f.importance),
    [person.thirdPartyData?.creditReport?.ficoFactors],
  );

  return (
    <SegmentContainer title={'Score Summary'}>
      <Flex flexDirection={'column'} width={'100%'} gap={6}>
        <Grid columns={3}>
          <Flex flexDirection={'column'} gap={2}>
            <Flex id={'score'}>
              <Text variant={'body2'} color={greyPalette[500]}>
                {'Score'}
              </Text>
            </Flex>
            <Text variant={'h4'} aria-labelledby={'score'}>
              {ficoScore ?? '-'}
            </Text>
          </Flex>
          <Flex flexDirection={'column'} gap={2}>
            <Flex id={'scoreType'}>
              <Text variant={'body2'} color={greyPalette[500]}>
                {'Score Type'}
              </Text>
            </Flex>
            <Text variant={'h4'} aria-labelledby={'scoreType'}>
              {creditModelIndicatorsToName[creditReportModel ?? ''] ?? 'FICO 09'}
            </Text>
          </Flex>
          <Flex flexDirection={'column'} gap={2}>
            <Flex id={'source'}>
              <Text variant={'body2'} color={greyPalette[500]}>
                {'Source'}
              </Text>
            </Flex>
            <Text variant={'h4'} aria-labelledby={'source'}>
              {'Experian'}
            </Text>
          </Flex>
        </Grid>
        <Divider />
        <Flex flexDirection={'column'} gap={2}>
          <Text variant={'body2'} color={greyPalette[500]}>
            {'Credit Score Factors'}
          </Text>
          <Flex flexDirection={'column'}>
            {sortedFicoScoreFactors.map(({ text, code }) => (
              <Flex key={code} gap={1}>
                <Text variant={'body2'}>{code}</Text>
                <Text variant={'body2'}>{sentenceCase(text)}</Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </SegmentContainer>
  );
};
