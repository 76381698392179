import React from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Flex, Text } from '@lama/design-system';

const StyledFlex = styled(Flex)`
  page-break-after: always;
`;

export const RequirementPrintContainer: FC<{
  title: string;
  children: ReactNode;
}> = ({ title, children }) => (
  <StyledFlex gap={4} pt={4} flexDirection={'column'}>
    <Text variant={'h5'}>{title}</Text>
    {children}
  </StyledFlex>
);
