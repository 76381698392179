import React, { useMemo } from 'react';
import type { FC } from 'react';
import { personFullName } from '@lama/selectors';
import type { AuditGroupInfo, CreateAuditLogProps } from '../types';
import { AuditLog } from '../../AuditLog';
import type { EmailTracking } from '../../../../../../../../packages/email-sender-client/dist/src';
import { useUserQuery } from '../../../../hooks/react-query/user/useUserQuery';
import { emailTypeToDisplay } from './EmailTrackingAuditLog';

interface EmailTrackingCreatedAuditLogProps {
  emailTracking: EmailTracking;
  groupInfo: AuditGroupInfo;
}

export const EmailTrackingCreatedAuditLog: FC<EmailTrackingCreatedAuditLogProps> = ({ groupInfo, emailTracking }) => {
  const { data: recipient, isPending: loadingRecipient } = useUserQuery(emailTracking.recipientId);

  const text = useMemo(() => {
    const emailTypeDisplay = emailTypeToDisplay(emailTracking.emailType);

    if (!emailTypeDisplay || !recipient) {
      return null;
    }

    return `Sent ${emailTypeDisplay} email to ${personFullName(recipient)}`;
  }, [emailTracking.emailType, recipient]);

  if (loadingRecipient) {
    return null;
  }

  if (!recipient || !text) {
    return null;
  }

  return (
    <AuditLog
      text={text}
      timestamp={groupInfo.timestamp}
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
    />
  );
};

export const EmailTrackingCreatedAuditLogs: FC<CreateAuditLogProps> = ({ audit, groupInfo }) => {
  const auditLogs = useMemo(
    () =>
      audit.map((a) => {
        const emailTracking = a.entity as EmailTracking;

        return <EmailTrackingCreatedAuditLog key={a.id} groupInfo={groupInfo} emailTracking={emailTracking} />;
      }),
    [groupInfo, audit],
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{auditLogs}</>;
};
