import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import { personFullName, applicationGuarantorsSelector } from '@lama/selectors';
import { InlineNote } from '../../shared/InlineNote/InlineNote';
import type { UnderwritingSectionProps } from '../Sections/underwritingSectionProps';
import { PersonalFinancialStatementsSpreadSegment } from '../../shared/Spreads/PersonalFinancialStatementsSpreadSegment';
import { ApplicationContext } from '../../ApplicationContext';

const note = 'Personal Financial Statements Note';

export const PersonalFinancialStatementsSection: FC<UnderwritingSectionProps> = ({ printMode, section }) => {
  const { application } = useContext(ApplicationContext);
  const guarantors = useMemo(() => applicationGuarantorsSelector(application), [application]).map((person) => ({
    person,
    relation: 'guarantor' as const,
    relations: ['guarantor' as const],
  }));

  return (
    <Flex flexDirection={'column'} gap={8}>
      {guarantors?.map((guarantor) => (
        <Flex flexDirection={'column'} gap={4} key={guarantor.person.id}>
          <Text variant={'h6'}>{personFullName(guarantor.person)}</Text>
          <PersonalFinancialStatementsSpreadSegment printMode={!!printMode} spreadId={section?.spreadId} guarantor={guarantor} />
        </Flex>
      ))}
      <InlineNote title={note} noteName={note} />
    </Flex>
  );
};
