import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { businessNameWithRelationsSelector } from '@lama/selectors';
import type { CreditMemoSectionProps } from '../creditMemoSectionProps';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { BusinessCashFlowSpreadSegment } from '../../../shared/Spreads/BusinessCashFlowSpreadSegment';
import { ApplicationContext } from '../../../ApplicationContext';
import { getPrimaryBusinessRelation } from '../../../../../shared/utils/relatedBusinesses';
import { getSpreadIdBySectionAndRelations } from '../../../shared/sectionSpreadId';

const note = 'Business Cash Flow Details';

export const BusinessCashFlowSection: FC<CreditMemoSectionProps> = ({ printMode, section }) => {
  const { application } = useContext(ApplicationContext);

  const businesses = useMemo(
    () => application.relatedBusinesses.filter((r) => !['property', undefined].includes(getPrimaryBusinessRelation(r.relations))),
    [application.relatedBusinesses],
  );

  return (
    <Flex flexDirection={'column'} gap={8}>
      {businesses.map((relatedBusiness) => (
        <Flex key={relatedBusiness.business.id} flexDirection={'column'} gap={2}>
          <BusinessCashFlowSpreadSegment
            title={businessNameWithRelationsSelector(relatedBusiness.business.id, application)}
            printMode={!!printMode}
            spreadId={getSpreadIdBySectionAndRelations(section, relatedBusiness.relations)}
            allowAdjustments={false}
            business={relatedBusiness}
          />
        </Flex>
      ))}
      <InlineNote title={note} noteName={note} />
    </Flex>
  );
};
